import * as React from "react";
import { Button } from "../../../Components/Button/Button";
import { Img } from "../../Img";
const defaultLogo = "./images/PlatformCard/logomark-tm.svg";
export const PlatformCardHeader = (props) => (React.createElement("div", { className: `platform-card__fixed-logo platform-card ${props.isActive
        ? "platform-card__is-active"
        : "platform-card__is-not-active"}` },
    props.haveRecommended ? (React.createElement("div", { className: "platform-card__recommended" },
        " ",
        props.recommendedText.toUpperCase(),
        " ")) : (React.createElement("div", { className: "platform-card__without-recommended" })),
    React.createElement("div", { className: "platform-card__logo-row" },
        React.createElement(Img, { src: props.imageUrl ? props.imageUrl : defaultLogo, alt: props.alt }),
        React.createElement("div", { className: "platform-card__logo-text" },
            React.createElement("h4", null,
                React.createElement("span", { className: "platform-card__logo-dark-text" }, props.logoDarkText ? props.logoDarkText : ""),
                React.createElement("span", { className: "platform-card__logo-green-text" }, props.logoGreenText ? props.logoGreenText : ""))))));
export const PlatformCardBody = (props) => (React.createElement("div", { className: "platform-card__inner" },
    React.createElement("div", { className: `platform-card__list-items ${props.isActive
            ? "platform-card__is-active"
            : "platform-card__is-not-active"}` },
        React.createElement("div", { className: "platform-card__container-scrollabe" }, props.content.map((item, index) => (React.createElement("div", { key: index, className: "platform-card__item" },
            React.createElement("div", { className: "platform-card__item-title text-weight-medium" },
                React.createElement("p", null, item.title)),
            item.haveImage ? (React.createElement(Img, { className: "platform-card__item-image", src: item.imageUrl })) : (React.createElement("div", { className: "platform-card__item-description" },
                " ",
                React.createElement("p", null, item.description),
                " "))))))),
    React.createElement("div", { className: `platform-card__button ${props.isActive
            ? "platform-card__is-active"
            : "platform-card__is-not-active"}` },
        React.createElement(Button, { primary: true, label: props.buttonTitle, href: props.buttonHref }))));
