import React, { useState, useEffect, useCallback } from "react";
import { usePageViewStyle } from "../../../hooks";
import { ToggleExpandCollapseButton } from "./ToggleExpandCollapseButton";
const getComponentClassname = (cssClasses) => `toggle-expand-collapse ${cssClasses ? cssClasses : ""}`;
const getContentContainerClassname = (isContainerExpanded) => `toggle-expand-collapse__content-container ${isContainerExpanded ? "toggle-expand-collapse__content-container--open" : ""}`;
export const ToggleExpandCollapse = ({ isDefaultExpanded = false, isOnlyToggledFromOutside = false, children, cssClasses, onToggleExpand, onTransitionEnd, toggleButtonsProps, toggleViews = ["small"] }) => {
    const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
    const { viewStyle: view } = usePageViewStyle();
    const handleToggle = useCallback(() => {
        if (onToggleExpand) {
            onToggleExpand(!isExpanded);
        }
        setIsExpanded(!isExpanded);
    }, [isExpanded, onToggleExpand]);
    const handleTransitionEnd = useCallback(() => {
        if (onTransitionEnd) {
            onTransitionEnd();
        }
    }, [onTransitionEnd]);
    const checkShowToggleUI = () => toggleViews.includes(view);
    useEffect(() => {
        setIsExpanded(isDefaultExpanded);
    }, [isDefaultExpanded]);
    if (!checkShowToggleUI()) {
        return (React.createElement(React.Fragment, null, children));
    }
    return (React.createElement("div", { className: getComponentClassname(cssClasses) },
        !isOnlyToggledFromOutside && !isExpanded && React.createElement(ToggleExpandCollapseButton, { buttonFunction: "expand", onToggle: handleToggle, buttonIconUrl: toggleButtonsProps?.expandButtonProps?.buttonIconUrl, buttonText: toggleButtonsProps?.expandButtonProps?.buttonText }),
        React.createElement("div", { className: getContentContainerClassname(isExpanded), onTransitionEnd: handleTransitionEnd },
            React.createElement("div", { className: "toggle-expand-collapse__content" }, children)),
        !isOnlyToggledFromOutside && isExpanded && React.createElement(ToggleExpandCollapseButton, { buttonFunction: "collapse", onToggle: handleToggle, buttonIconUrl: toggleButtonsProps?.collapseButtonProps?.buttonIconUrl, buttonText: toggleButtonsProps?.collapseButtonProps?.buttonText })));
};
