import React from "react";
const checkIcon = ({ height = "24", width = "24" }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { id: "Check", clipPath: "url(#clip0_15151_4990)" },
        React.createElement("g", { id: "Group 6258" },
            React.createElement("path", { id: "Vector", d: "M7.40407 0H17.2502C21.1518 0 24.3271 3.17538 24.3271 7.07692V16.9231C24.3271 20.8246 21.1518 24 17.2502 24H7.40407C3.50253 24 0.327148 20.8246 0.327148 16.9231V7.07692C0.327148 3.17538 3.50253 0 7.40407 0Z", fill: "#6E7783" }),
            React.createElement("path", { id: "Vector_2", fillRule: "evenodd", clipRule: "evenodd", d: "M19.1734 7.20222C19.3749 7.40376 19.4881 7.67707 19.4881 7.96204C19.4881 8.24701 19.3749 8.52032 19.1734 8.72186L11.1182 16.7771C11.0117 16.8836 10.8853 16.9681 10.7462 17.0257C10.6071 17.0833 10.458 17.113 10.3075 17.113C10.1569 17.113 10.0078 17.0833 9.86873 17.0257C9.72963 16.9681 9.60325 16.8836 9.4968 16.7771L5.4946 12.7756C5.39195 12.6765 5.31008 12.5579 5.25376 12.4268C5.19743 12.2957 5.16778 12.1547 5.16654 12.012C5.1653 11.8693 5.1925 11.7277 5.24653 11.5957C5.30057 11.4636 5.38037 11.3436 5.48128 11.2427C5.58218 11.1418 5.70218 11.062 5.83425 11.0079C5.96633 10.9539 6.10785 10.9267 6.25055 10.928C6.39325 10.9292 6.53427 10.9588 6.66539 11.0152C6.7965 11.0715 6.91509 11.1534 7.01423 11.256L10.3071 14.5489L17.6531 7.20222C17.7529 7.10236 17.8714 7.02313 18.0018 6.96908C18.1323 6.91503 18.2721 6.88721 18.4133 6.88721C18.5545 6.88721 18.6943 6.91503 18.8247 6.96908C18.9551 7.02313 19.0736 7.10236 19.1734 7.20222Z", fill: "#5EE15A" }))),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_15151_4990" },
            React.createElement("rect", { width: "24", height: "24", fill: "white", transform: "translate(0.327148)" })))));
export default checkIcon;
