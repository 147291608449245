import React from "react";
import { Container, ContainerFW } from "../global";
import { ellipse } from "../svgIcons/ellipse";
import { usePageViewStyle } from "../../hooks";
export const LatestMarketNews = (props) => {
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    let numberOfCharacters = 0;
    isSmall ? numberOfCharacters = 42 : (isMedium ? numberOfCharacters = 85 : (isLarge ? numberOfCharacters = 83 : 0));
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "latest-market-news" },
                React.createElement("div", { className: "latest-market-news__title" },
                    React.createElement("h1", { className: "h1-regular-small" }, props.title)),
                React.createElement("div", { className: "latest-market-news__container" },
                    React.createElement("div", { className: "latest-market-news__last-news", style: { backgroundImage: `url(${props.news[0].imageURL})` } },
                        React.createElement("div", { className: "latest-market-news__image" },
                            React.createElement("div", { className: "latest-market-news__text" },
                                React.createElement("a", { href: props.news[0].articleURL },
                                    React.createElement("div", { className: "latest-market-news__news-details" },
                                        React.createElement("div", { className: "latest-market-news__left-details" },
                                            React.createElement("p", null,
                                                React.createElement("span", { className: "latest-market-news__green-text" }, props.news[0].articleCfdTrading),
                                                ellipse(),
                                                React.createElement("span", { className: "latest-market-news__category-text" }, props.news[0].articleCategory),
                                                ellipse(),
                                                React.createElement("span", { className: "latest-market-news__date-text" }, new Date(props.news[0].articleDate ? props.news[0].articleDate : "").toDateString().slice(4)))),
                                        React.createElement("div", { className: "latest-market-news__right-details" },
                                            React.createElement("p", null,
                                                React.createElement("span", { className: "latest-market-news__author-text" }, props.news[0].articleAuthor)))),
                                    React.createElement("div", { className: "latest-market-news__news-title" },
                                        React.createElement("h4", null, props.news[0].articleTitle)),
                                    React.createElement("div", { className: "latest-market-news__news-summary" },
                                        React.createElement("p", null,
                                            props.news[0].articleSummary.slice(0, numberOfCharacters),
                                            "...")))))),
                    React.createElement("div", { className: "latest-market-news__news-list" }, props.news.map((item, i) => {
                        if (i > 0) {
                            return (React.createElement("div", { key: `news-number-${i}`, className: `latest-market-news__news-list-item-${i}` },
                                React.createElement("a", { className: `latest-market-news__item-container-${i}`, href: item.articleURL },
                                    React.createElement("div", { className: "latest-market-news__item-details" },
                                        React.createElement("p", null,
                                            React.createElement("span", { className: "latest-market-news__green-text" }, item.articleCfdTrading),
                                            ellipse(),
                                            React.createElement("span", { className: "latest-market-news__list-category-text" }, item.articleCategory),
                                            ellipse(),
                                            React.createElement("span", { className: "latest-market-news__list-date-text" }, new Date(item.articleDate ? item.articleDate : "").toDateString().slice(4)))),
                                    React.createElement("div", { className: "latest-market-news__item-title" },
                                        React.createElement("h4", null, item.articleTitle)))));
                        }
                        return "";
                    })))))));
};
