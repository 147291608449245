import * as React from "react";
import { Container } from "../../../global/Containers/Container/Container";
import { Button } from "../../../Button";
import { Img } from "../../../Img";
export const HeroBanner = (props) => (React.createElement("div", { className: `hero-banner ${props.darkTheme ? "hero-banner--dark" : ""}` },
    React.createElement(Img, { className: "hero-banner__desktop-bkgr", src: props.imageUrl, alt: props.title }),
    React.createElement(Container, null,
        React.createElement("div", { className: "hero-banner__inner-container" },
            React.createElement("h2", { className: "hero-banner__title text-jumbo" }, props.title),
            React.createElement("p", { className: "hero-banner__text" }, props.text),
            React.createElement("div", { className: "hero-banner__actions" },
                props.buttonTitle ? React.createElement(Button, { primary: true, label: props.buttonTitle, href: props.buttonHref }) : "",
                props.linkTitle ? React.createElement("a", { className: "hero-banner__link", href: props.linkHref }, props.linkTitle) : ""))),
    React.createElement("div", { className: "hero-banner__mobile-image" },
        React.createElement(Img, { className: "hero-banner__mobile-bkgr", src: props.mobileImageUrl, alt: props.title }))));
