import { debounce } from "debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { LoadingSpinner } from "../LoadingSpinner";
import { fetchSearchResults } from "./fetchSearchResults";
import { SearchResults } from "./SearchResults";
import { useGlobalSearchNavigation } from "./useGlobalSearchNavigation";
import { ContactUs } from "./ContactUs/ContactUs";
import { simulateMouseClickOnZendeskChatIcon } from "../StillHaveQuestionsItems/contactUs.utils";
export const SEARCH_PARAM_NAME = "searchterms";
export const PAGENUM_PARAM_NAME = "page";
export const INITIAL_SEARCH_STATUS = {
    requestedSearchValue: undefined,
    pageNum: undefined,
    isLoading: false,
    errorMessage: undefined,
    requestTimestamp: undefined
};
const checkIsValidSearchValue = (searchValue) => searchValue.length > 2;
export const GlobalSearch = ({ searchPlaceholder, searchResultsTitle, searchResultsSubtitle, initialSearchValue, initialResults, sessionToken, apiUrl, currentCulture, backgroundColor = "#fff", contact, createAccountValues, searchResultsFor, noResultsFoundText }) => {
    const [searchValue, setSearchValue] = useState(initialSearchValue || "");
    const [results, setResults] = useState(initialResults);
    const [status, setStatus] = useState(INITIAL_SEARCH_STATUS);
    const abortController = useRef(undefined);
    const contactValues = { ...contact, chatCallFunction: simulateMouseClickOnZendeskChatIcon };
    const { bgColor } = getColors(backgroundColor);
    const fetchResults = useCallback(({ searchValue, pageNum }, isRefetch = false) => {
        setStatus({
            requestedSearchValue: searchValue,
            pageNum,
            isLoading: true,
            errorMessage: undefined,
            requestTimestamp: Date.now(),
            isRefetch
        });
        if (abortController.current) {
            abortController.current.abort();
        }
        abortController.current = new AbortController();
        const onResolve = ((result) => {
            const { results, isError, errorType } = result;
            if (isError) {
                setResults({
                    totalResults: 0,
                    currentPage: 0,
                    resultsPerPage: 0,
                    query: "",
                    items: []
                });
                setStatus({
                    requestedSearchValue: undefined,
                    pageNum: undefined,
                    isLoading: false,
                    errorMessage: errorType,
                    requestTimestamp: undefined,
                    isRefetch: false
                });
                return;
            }
            if (results) {
                setResults(results);
                setStatus({
                    requestedSearchValue: undefined,
                    pageNum: undefined,
                    isLoading: false,
                    errorMessage: undefined,
                    requestTimestamp: undefined,
                    isRefetch: false
                });
            }
        });
        const onReject = (error) => {
            let message;
            if (error instanceof Error) {
                if (error.name === "AbortError") {
                    return;
                }
                message = error.message;
            }
            else {
                message = String(error);
            }
            console.error("Global search failed: ", message);
            throw error;
        };
        const onFinally = () => {
            abortController.current = undefined;
        };
        const payloadObj = {
            token: sessionToken,
            searchValue,
            pageNumber: pageNum || 1,
            wholeSiteCulture: currentCulture,
        };
        fetchSearchResults(`${apiUrl}/api/search`, payloadObj, abortController.current.signal)
            .then(onResolve)
            .catch(onReject)
            .finally(onFinally);
    }, [apiUrl, currentCulture, sessionToken]);
    const debouncedSearch = useCallback(({ searchValue, pageNum }, isRefetch = false) => debounce(() => {
        fetchResults({ searchValue, pageNum }, isRefetch);
    }, 500)(), [fetchResults]);
    useGlobalSearchNavigation({
        searchFunction: debouncedSearch,
        onRefetch: setSearchValue,
        status,
    });
    const handleSearch = useCallback(async (event) => {
        const { target } = event;
        const inputValue = target.value;
        if (!checkIsValidSearchValue(inputValue)) {
            return;
        }
        debouncedSearch({ searchValue: inputValue, pageNum: 0 });
    }, [debouncedSearch]);
    const handleChange = useCallback((event) => {
        const { target } = event;
        const inputValue = target.value;
        setSearchValue(inputValue);
    }, []);
    const handlePageChange = useCallback((pageNum) => {
        results?.query && fetchResults({
            searchValue: results.query,
            pageNum: pageNum + 1
        });
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, [fetchResults, results?.query]);
    useEffect(() => () => {
        if (abortController.current) {
            abortController.current.abort();
        }
    }, []);
    const getCorrectPageNumForPagination = () => results?.currentPage ? results.currentPage - 1 : 0;
    return (React.createElement("div", { className: "global-search__resultsWrapper" },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "global-search__results" },
                    status.isLoading
                        ? React.createElement(LoadingSpinner, null)
                        : React.createElement(React.Fragment, null,
                            status.errorMessage && React.createElement("div", { className: "global-search__error" }, status.errorMessage),
                            !status.errorMessage && results?.items?.length !== undefined &&
                                React.createElement(SearchResults, { title: searchResultsTitle, subtitle: searchResultsSubtitle, resultsList: results.items, searchValue: results.query, onPageChange: handlePageChange, resultsPerPage: results.resultsPerPage, currentPage: getCorrectPageNumForPagination(), totalResults: results.totalResults, handleSearch: handleSearch, onSearchChange: handleChange, inputSearchValue: searchValue, searchPlaceholder: searchPlaceholder, searchResultsFor: searchResultsFor, noResultsFoundText: noResultsFoundText })),
                    React.createElement(ContactUs, { contacts: contactValues, createAccountValues: createAccountValues }))))));
};
