export var TimescaleEnum;
(function (TimescaleEnum) {
    TimescaleEnum[TimescaleEnum["TS_UNKNOWN"] = 0] = "TS_UNKNOWN";
    TimescaleEnum[TimescaleEnum["TS_1MIN"] = 1] = "TS_1MIN";
    TimescaleEnum[TimescaleEnum["TS_2MIN"] = 2] = "TS_2MIN";
    TimescaleEnum[TimescaleEnum["TS_3MIN"] = 3] = "TS_3MIN";
    TimescaleEnum[TimescaleEnum["TS_5MIN"] = 4] = "TS_5MIN";
    TimescaleEnum[TimescaleEnum["TS_10MIN"] = 5] = "TS_10MIN";
    TimescaleEnum[TimescaleEnum["TS_15MIN"] = 6] = "TS_15MIN";
    TimescaleEnum[TimescaleEnum["TS_30MIN"] = 7] = "TS_30MIN";
    TimescaleEnum[TimescaleEnum["TS_1HOUR"] = 8] = "TS_1HOUR";
    TimescaleEnum[TimescaleEnum["TS_2HOUR"] = 9] = "TS_2HOUR";
    TimescaleEnum[TimescaleEnum["TS_3HOUR"] = 10] = "TS_3HOUR";
    TimescaleEnum[TimescaleEnum["TS_4HOUR"] = 11] = "TS_4HOUR";
    TimescaleEnum[TimescaleEnum["TS_6HOUR"] = 12] = "TS_6HOUR";
    TimescaleEnum[TimescaleEnum["TS_8HOUR"] = 13] = "TS_8HOUR";
    TimescaleEnum[TimescaleEnum["TS_12HOUR"] = 14] = "TS_12HOUR";
    TimescaleEnum[TimescaleEnum["TS_1DAY"] = 15] = "TS_1DAY";
    TimescaleEnum[TimescaleEnum["TS_1WEEK"] = 16] = "TS_1WEEK";
    TimescaleEnum[TimescaleEnum["TS_1MONTH"] = 17] = "TS_1MONTH";
    TimescaleEnum[TimescaleEnum["TS_1YEAR"] = 18] = "TS_1YEAR";
})(TimescaleEnum || (TimescaleEnum = {}));
export var UIDataRangeStatusEnum;
(function (UIDataRangeStatusEnum) {
    UIDataRangeStatusEnum["UKNOWN"] = "UNKNOWN";
    UIDataRangeStatusEnum["INVALID_RANGE_POINTS"] = "INVALID_RANGE_POINTS";
    UIDataRangeStatusEnum["UNDEFINED_INPUTS"] = "UNDEFINED_INPUTS";
    UIDataRangeStatusEnum["INCALCULABLE_INPUTS"] = "INCALCULABLE_INPUTS";
    UIDataRangeStatusEnum["ZERO_RANGE"] = "ZERO_RANGE";
    UIDataRangeStatusEnum["REGULAR_RANGE"] = "REGULAR_RANGE";
})(UIDataRangeStatusEnum || (UIDataRangeStatusEnum = {}));
