import React from "react";
export const IconAppStore = (React.createElement("svg", { width: "102", height: "32", viewBox: "0 0 102 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_9983_9581)" },
        React.createElement("path", { d: "M97.8956 32H4.10488C3.10386 31.9969 2.34267 31.4433 1.63484 30.6939C0.927013 29.9444 0.330079 29.06 0.327148 28.0001V3.99992C0.330079 2.94003 0.927013 2.05558 1.63484 1.30612C2.34267 0.556664 3.57613 0.00310264 4.57715 0H97.7502C98.5856 0.326531 99.6578 0.556664 100.366 1.30612C101.073 2.05558 101.67 2.94003 101.673 3.99992V28.0001C101.67 29.06 101.271 30.0756 100.564 30.825C99.8558 31.5745 98.8966 31.9969 97.8956 32Z", fill: "black" }),
        React.createElement("path", { d: "M98.0261 0.640954C98.467 0.640868 98.9036 0.727725 99.311 0.896564C99.7184 1.0654 100.089 1.31292 100.4 1.62496C100.712 1.93701 100.959 2.30748 101.128 2.7152C101.297 3.12293 101.384 3.55992 101.383 4.00122V28.0007C101.384 28.4421 101.297 28.8793 101.128 29.2872C100.96 29.695 100.713 30.0657 100.401 30.3778C100.089 30.69 99.7188 30.9377 99.3113 31.1066C98.9038 31.2755 98.4671 31.3624 98.0261 31.3623H3.99652C3.55559 31.3624 3.11897 31.2755 2.71159 31.1067C2.30421 30.9379 1.93406 30.6903 1.62227 30.3783C1.31049 30.0663 1.06319 29.6958 0.894496 29.2881C0.725801 28.8803 0.639017 28.4433 0.639103 28.002V4.00057C0.639017 3.55927 0.725801 3.12228 0.894496 2.71455C1.06319 2.30682 1.31049 1.93636 1.62227 1.62431C1.93406 1.31226 2.30421 1.06475 2.71159 0.895911C3.11897 0.727072 3.55559 0.640215 3.99652 0.640301L98.0261 0.640954ZM98.0261 0H3.99652C2.93741 0.00310346 1.92259 0.425608 1.17375 1.17521C0.424907 1.92481 0.00292784 2.94057 0 4.00057V28.0007C0.0031 29.0606 0.425156 30.0762 1.17398 30.8257C1.9228 31.5751 2.93753 31.9975 3.99652 32.0007H98.0261C99.0851 31.9975 100.1 31.5751 100.849 30.8257C101.597 30.0762 102.02 29.0606 102.023 28.0007V4.00057C102.02 2.94068 101.597 1.92509 100.849 1.17563C100.1 0.426171 99.0851 0.00375627 98.0261 0.000653632V0Z", fill: "#A6A6A6" }),
        React.createElement("path", { d: "M22.9459 15.367C22.9544 14.6993 23.1316 14.0445 23.4608 13.4638C23.7901 12.883 24.2609 12.395 24.8292 12.0453C24.4683 11.529 23.9919 11.1042 23.4381 10.8045C22.8843 10.5049 22.2682 10.3386 21.6389 10.3191C20.2968 10.1779 18.9957 11.1227 18.3096 11.1227C17.6124 11.1227 16.5594 10.3328 15.4255 10.3563C14.6919 10.38 13.977 10.5934 13.3504 10.9759C12.7238 11.3583 12.2069 11.8967 11.85 12.5385C10.3042 15.2174 11.4583 19.1539 12.9383 21.3192C13.6792 22.3796 14.5448 23.5635 15.6801 23.5217C16.7899 23.4759 17.2031 22.8141 18.5414 22.8141C19.8672 22.8141 20.2556 23.5217 21.4137 23.4949C22.6032 23.4753 23.3532 22.4299 24.0681 21.3597C24.6004 20.6041 25.01 19.769 25.2816 18.8854C24.5902 18.5932 23.9999 18.1038 23.5845 17.4781C23.1692 16.8524 22.947 16.1182 22.9459 15.367Z", fill: "white" }),
        React.createElement("path", { d: "M20.7624 8.89406C21.4109 8.11463 21.7304 7.11289 21.6529 6.10156C20.6613 6.20532 19.7452 6.67939 19.0873 7.42921C18.7659 7.79538 18.5198 8.22136 18.3629 8.6828C18.2061 9.14425 18.1417 9.6321 18.1734 10.1185C18.6694 10.1238 19.1601 10.0164 19.6086 9.80433C20.0571 9.59223 20.4516 9.28098 20.7624 8.89406Z", fill: "white" }),
        React.createElement("path", { d: "M36.9611 20.8357H33.1748L32.2661 23.521H30.666L34.25 13.5898H35.9146L39.4986 23.521H37.8665L36.9611 20.8357ZM33.5665 19.5943H36.5655L35.0875 15.2389H35.0483L33.5665 19.5943Z", fill: "white" }),
        React.createElement("path", { d: "M47.2372 19.9019C47.2372 22.1527 46.0335 23.5986 44.2173 23.5986C43.7572 23.6228 43.2996 23.5167 42.8969 23.2927C42.4942 23.0686 42.1627 22.7356 41.9403 22.3318H41.9057V25.9194H40.4199V16.2803H41.8561V17.4851H41.8835C42.1161 17.083 42.4532 16.7516 42.859 16.5261C43.2648 16.3006 43.724 16.1895 44.188 16.2045C46.0263 16.2045 47.2372 17.6576 47.2372 19.9019ZM45.7103 19.9019C45.7103 18.4357 44.953 17.4713 43.7976 17.4713C42.6623 17.4713 41.8992 18.456 41.8992 19.9019C41.8992 21.3615 42.6623 22.3383 43.7976 22.3383C44.953 22.3389 45.7103 21.3818 45.7103 19.9019Z", fill: "white" }),
        React.createElement("path", { d: "M55.2027 19.9019C55.2027 22.1527 53.9989 23.5986 52.1828 23.5986C51.7227 23.6228 51.2651 23.5167 50.8624 23.2927C50.4597 23.0686 50.1282 22.7356 49.9058 22.3318H49.8712V25.9194H48.3887V16.2803H49.8249V17.4851H49.8523C50.0848 17.083 50.4219 16.7516 50.8277 16.5261C51.2335 16.3006 51.6928 16.1895 52.1567 16.2045C53.9924 16.2045 55.2027 17.6576 55.2027 19.9019ZM53.6758 19.9019C53.6758 18.4357 52.9185 17.4713 51.7631 17.4713C50.6278 17.4713 49.8647 18.456 49.8647 19.9019C49.8647 21.3615 50.6278 22.3383 51.7631 22.3383C52.9185 22.3383 53.6758 21.3811 53.6758 19.9019Z", fill: "white" }),
        React.createElement("path", { d: "M60.4672 20.7553C60.5775 21.7405 61.5332 22.3887 62.8402 22.3887C64.0922 22.3887 64.9944 21.7418 64.9944 20.8533C64.9944 20.0823 64.4513 19.6204 63.1666 19.3041L61.8792 18.9957C60.0566 18.5554 59.2105 17.7021 59.2105 16.3169C59.2105 14.6031 60.7028 13.4258 62.8225 13.4258C64.92 13.4258 66.3575 14.6018 66.4065 16.3169H64.905C64.8149 15.3258 63.9963 14.7273 62.7997 14.7273C61.6031 14.7273 60.7844 15.333 60.7844 16.2137C60.7844 16.9161 61.3067 17.329 62.5869 17.6459L63.6803 17.9144C65.7171 18.3966 66.5631 19.2153 66.5631 20.6684C66.5631 22.5272 65.0839 23.6908 62.7311 23.6908C60.5299 23.6908 59.0434 22.554 58.9448 20.7572L60.4672 20.7553Z", fill: "white" }),
        React.createElement("path", { d: "M69.7673 14.5664V16.2802H71.1434V17.4563H69.7673V21.449C69.7673 22.069 70.0428 22.3585 70.648 22.3585C70.8114 22.3556 70.9746 22.344 71.1369 22.3238V23.494C70.8649 23.5448 70.5884 23.5678 70.3118 23.5626C68.8468 23.5626 68.275 23.0118 68.275 21.6071V17.4576H67.2227V16.2815H68.275V14.5664H69.7673Z", fill: "white" }),
        React.createElement("path", { d: "M71.9414 19.9025C71.9414 17.6236 73.2823 16.1914 75.3739 16.1914C77.4655 16.1914 78.807 17.6236 78.807 19.9025C78.807 22.1815 77.4792 23.6137 75.3739 23.6137C73.2686 23.6137 71.9414 22.1874 71.9414 19.9025ZM77.2945 19.9025C77.2945 18.339 76.5764 17.4197 75.3752 17.4197C74.174 17.4197 73.4566 18.3495 73.4566 19.9025C73.4566 21.4706 74.1747 22.3853 75.3752 22.3853C76.5757 22.3853 77.2945 21.4706 77.2945 19.9025Z", fill: "white" }),
        React.createElement("path", { d: "M80.0303 16.2821H81.4469V17.515H81.4815C81.5777 17.1304 81.8032 16.7906 82.1201 16.5527C82.437 16.3147 82.826 16.1931 83.2219 16.2083C83.3931 16.2077 83.5639 16.2263 83.7311 16.2638V17.6542C83.5151 17.588 83.2897 17.5576 83.0639 17.564C82.8481 17.5553 82.633 17.5934 82.4334 17.6757C82.2337 17.758 82.0542 17.8826 81.9072 18.0408C81.7601 18.1991 81.649 18.3873 81.5815 18.5926C81.514 18.7979 81.4917 19.0154 81.5161 19.2301V23.5254H80.0303V16.2821Z", fill: "white" }),
        React.createElement("path", { d: "M90.5802 21.4C90.3804 22.7146 89.1009 23.6169 87.4643 23.6169C85.359 23.6169 84.0527 22.205 84.0527 19.9404C84.0527 17.6758 85.3662 16.1953 87.4023 16.1953C89.4045 16.1953 90.6664 17.572 90.6664 19.7679V20.2776H85.5548V20.3677C85.5312 20.6343 85.5646 20.9028 85.6526 21.1555C85.7407 21.4082 85.8814 21.6392 86.0656 21.8332C86.2497 22.0273 86.4729 22.1799 86.7205 22.2809C86.9681 22.382 87.2343 22.4291 87.5015 22.4193C87.8525 22.4522 88.2048 22.3709 88.5059 22.1873C88.807 22.0038 89.0409 21.7279 89.1727 21.4007L90.5802 21.4ZM85.5588 19.238H89.1767C89.19 18.9984 89.1537 18.7585 89.0701 18.5335C88.9864 18.3085 88.8572 18.1032 88.6906 17.9306C88.5239 17.7579 88.3233 17.6216 88.1015 17.5302C87.8797 17.4388 87.6415 17.3943 87.4017 17.3995C87.1597 17.398 86.9199 17.4445 86.696 17.5363C86.4722 17.6281 86.2687 17.7634 86.0973 17.9343C85.926 18.1053 85.7902 18.3085 85.6978 18.5323C85.6053 18.756 85.5581 18.9959 85.5588 19.238Z", fill: "white" }),
        React.createElement("path", { d: "M33.382 6.1109C33.6934 6.0885 34.0059 6.13556 34.2969 6.24869C34.588 6.36181 34.8503 6.53818 35.065 6.76507C35.2798 6.99195 35.4415 7.2637 35.5385 7.56072C35.6356 7.85773 35.6656 8.17261 35.6263 8.48263C35.6263 10.0076 34.8031 10.8844 33.382 10.8844H31.6592V6.1109H33.382ZM32.4027 10.2095H33.3017C33.5241 10.2224 33.7466 10.1856 33.9531 10.1017C34.1595 10.0179 34.3448 9.88908 34.4953 9.72469C34.6459 9.5603 34.758 9.36444 34.8236 9.1513C34.8891 8.93816 34.9064 8.7131 34.8743 8.49243C34.9048 8.2725 34.8863 8.04853 34.82 7.83662C34.7538 7.62471 34.6414 7.43014 34.491 7.26689C34.3407 7.10364 34.156 6.97577 33.9503 6.89247C33.7447 6.80918 33.5231 6.77254 33.3017 6.78518H32.4001L32.4027 10.2095Z", fill: "white" }),
        React.createElement("path", { d: "M36.4624 9.08093C36.4397 8.84414 36.4666 8.60519 36.5416 8.37943C36.6165 8.15368 36.7378 7.9461 36.8976 7.77001C37.0574 7.59393 37.2523 7.45323 37.4696 7.35695C37.687 7.26067 37.922 7.21094 38.1597 7.21094C38.3974 7.21094 38.6325 7.26067 38.8498 7.35695C39.0672 7.45323 39.262 7.59393 39.4219 7.77001C39.5817 7.9461 39.703 8.15368 39.7779 8.37943C39.8528 8.60519 39.8798 8.84414 39.857 9.08093C39.8798 9.31773 39.8528 9.55668 39.7779 9.78243C39.703 10.0082 39.5817 10.2158 39.4219 10.3919C39.262 10.5679 39.0672 10.7086 38.8498 10.8049C38.6325 10.9012 38.3974 10.9509 38.1597 10.9509C37.922 10.9509 37.687 10.9012 37.4696 10.8049C37.2523 10.7086 37.0574 10.5679 36.8976 10.3919C36.7378 10.2158 36.6165 10.0082 36.5416 9.78243C36.4666 9.55668 36.4397 9.31773 36.4624 9.08093ZM39.1266 9.08093C39.1266 8.29689 38.776 7.84346 38.161 7.84346C37.5461 7.84346 37.1962 8.30081 37.1962 9.08093C37.1962 9.86105 37.5435 10.3223 38.161 10.3223C38.7786 10.3223 39.1266 9.86498 39.1266 9.08093Z", fill: "white" }),
        React.createElement("path", { d: "M44.3725 10.8846H43.6329L42.8887 8.23125H42.8325L42.0916 10.8846H41.3624L40.3701 7.28256H41.0882L41.7332 10.0313H41.7861L42.5264 7.28256H43.2079L43.9501 10.03H44.0063L44.648 7.28125H45.3583L44.3725 10.8846Z", fill: "white" }),
        React.createElement("path", { d: "M46.1934 7.28371H46.8762V7.85606H46.9291C47.0192 7.65063 47.171 7.47842 47.3635 7.36342C47.556 7.24842 47.7795 7.19635 48.003 7.21446C48.1781 7.20128 48.354 7.22772 48.5175 7.29182C48.681 7.35591 48.8281 7.45602 48.9477 7.58472C49.0673 7.71343 49.1565 7.86742 49.2086 8.0353C49.2606 8.20318 49.2743 8.38063 49.2485 8.55451V10.8864H48.5396V8.73027C48.5396 8.15139 48.2882 7.86325 47.7627 7.86325C47.6437 7.85775 47.5249 7.8781 47.4144 7.9229C47.304 7.9677 47.2045 8.03589 47.1229 8.1228C47.0413 8.2097 46.9794 8.31325 46.9416 8.42635C46.9037 8.53944 46.8908 8.65939 46.9036 8.77797V10.8851H46.1934V7.28371Z", fill: "white" }),
        React.createElement("path", { d: "M50.3804 5.875H51.0906V10.8818H50.3804V5.875Z", fill: "white" }),
        React.createElement("path", { d: "M52.0788 9.08093C52.056 8.84414 52.083 8.60519 52.1579 8.37943C52.2329 8.15368 52.3542 7.9461 52.514 7.77001C52.6738 7.59393 52.8686 7.45323 53.086 7.35695C53.3034 7.26067 53.5384 7.21094 53.7761 7.21094C54.0138 7.21094 54.2489 7.26067 54.4662 7.35695C54.6836 7.45323 54.8784 7.59393 55.0382 7.77001C55.1981 7.9461 55.3193 8.15368 55.3943 8.37943C55.4692 8.60519 55.4962 8.84414 55.4734 9.08093C55.4972 9.31829 55.4709 9.55799 55.3965 9.78459C55.322 10.0112 55.2009 10.2196 55.0409 10.3965C54.881 10.5734 54.6859 10.7148 54.468 10.8115C54.2501 10.9083 54.0144 10.9583 53.7761 10.9583C53.5378 10.9583 53.3021 10.9083 53.0842 10.8115C52.8664 10.7148 52.6712 10.5734 52.5113 10.3965C52.3514 10.2196 52.2302 10.0112 52.1558 9.78459C52.0813 9.55799 52.0551 9.31829 52.0788 9.08093ZM54.7429 9.08093C54.7429 8.29689 54.3924 7.84346 53.7774 7.84346C53.1625 7.84346 52.8126 8.30081 52.8126 9.08093C52.8126 9.86105 53.1599 10.3223 53.7774 10.3223C54.395 10.3223 54.7429 9.86497 54.7429 9.08093Z", fill: "white" }),
        React.createElement("path", { d: "M56.2217 9.86361C56.2217 9.21547 56.7041 8.84109 57.5606 8.78817L58.5352 8.73198V8.42097C58.5352 8.04071 58.2839 7.82576 57.7982 7.82576C57.402 7.82576 57.1271 7.97146 57.0481 8.22627H56.3614C56.4338 7.60753 57.0142 7.21094 57.8322 7.21094C58.7344 7.21094 59.2435 7.66045 59.2435 8.42163V10.8829H58.5594V10.3765H58.5033C58.3892 10.5581 58.2291 10.7062 58.0391 10.8056C57.8492 10.905 57.6363 10.9522 57.4222 10.9423C57.2711 10.958 57.1184 10.9419 56.9739 10.8949C56.8295 10.8479 56.6964 10.7712 56.5834 10.6696C56.4703 10.5681 56.3798 10.4439 56.3176 10.3052C56.2554 10.1665 56.223 10.0163 56.2223 9.86426L56.2217 9.86361ZM58.5352 9.55587V9.25467L57.6566 9.31086C57.1611 9.34418 56.9385 9.51275 56.9385 9.83356C56.9385 10.1544 57.2192 10.3464 57.6056 10.3464C57.7189 10.3576 57.8332 10.3459 57.9418 10.312C58.0504 10.2781 58.1511 10.2227 58.2379 10.1491C58.3246 10.0755 58.3958 9.98512 58.447 9.88343C58.4982 9.78175 58.5284 9.6708 58.5359 9.55718L58.5352 9.55587Z", fill: "white" }),
        React.createElement("path", { d: "M60.1768 9.08108C60.1768 7.94291 60.7643 7.22159 61.671 7.22159C61.896 7.21117 62.1193 7.26504 62.3147 7.37693C62.5102 7.48881 62.6698 7.65407 62.775 7.8534H62.8278V5.875H63.54V10.8818H62.8572V10.3127H62.8011C62.6879 10.5106 62.5228 10.6738 62.3236 10.7846C62.1244 10.8954 61.8988 10.9495 61.671 10.9412C60.7551 10.9432 60.1768 10.2219 60.1768 9.08108ZM60.9105 9.08108C60.9105 9.84486 61.2702 10.3048 61.8721 10.3048C62.474 10.3048 62.8409 9.83833 62.8409 9.08434C62.8409 8.33036 62.4668 7.86058 61.8721 7.86058C61.2774 7.86058 60.9105 8.32317 60.9105 9.08108Z", fill: "white" }),
        React.createElement("path", { d: "M66.4766 9.08093C66.4538 8.84414 66.4808 8.60519 66.5557 8.37943C66.6307 8.15368 66.7519 7.9461 66.9118 7.77001C67.0716 7.59393 67.2664 7.45323 67.4838 7.35695C67.7011 7.26067 67.9362 7.21094 68.1739 7.21094C68.4116 7.21094 68.6466 7.26067 68.864 7.35695C69.0814 7.45323 69.2762 7.59393 69.436 7.77001C69.5958 7.9461 69.7171 8.15368 69.7921 8.37943C69.867 8.60519 69.894 8.84414 69.8712 9.08093C69.894 9.31773 69.867 9.55668 69.7921 9.78243C69.7171 10.0082 69.5958 10.2158 69.436 10.3919C69.2762 10.5679 69.0814 10.7086 68.864 10.8049C68.6466 10.9012 68.4116 10.9509 68.1739 10.9509C67.9362 10.9509 67.7011 10.9012 67.4838 10.8049C67.2664 10.7086 67.0716 10.5679 66.9118 10.3919C66.7519 10.2158 66.6307 10.0082 66.5557 9.78243C66.4808 9.55668 66.4538 9.31773 66.4766 9.08093ZM69.1407 9.08093C69.1407 8.29689 68.7901 7.84346 68.1752 7.84346C67.5603 7.84346 67.2103 8.30081 67.2103 9.08093C67.2103 9.86105 67.5576 10.3223 68.1752 10.3223C68.7928 10.3223 69.1407 9.86498 69.1407 9.08093Z", fill: "white" }),
        React.createElement("path", { d: "M70.8232 7.28375H71.5067V7.8561H71.5616C71.6516 7.6506 71.8034 7.47834 71.9959 7.36333C72.1884 7.24832 72.412 7.19629 72.6354 7.21449C72.8106 7.20132 72.9864 7.22776 73.15 7.29185C73.3135 7.35595 73.4606 7.45606 73.5802 7.58476C73.6998 7.71346 73.789 7.86746 73.841 8.03534C73.8931 8.20321 73.9068 8.38067 73.881 8.55455V10.8864H73.1701V8.73031C73.1701 8.15142 72.9188 7.86329 72.3933 7.86329C72.2742 7.85778 72.1554 7.87813 72.045 7.92293C71.9345 7.96773 71.8351 8.03593 71.7534 8.12283C71.6718 8.20973 71.61 8.31329 71.5721 8.42638C71.5343 8.53947 71.5213 8.65943 71.5342 8.778V10.8851H70.8232V7.28375Z", fill: "white" }),
        React.createElement("path", { d: "M77.8949 6.38477V7.29949H78.675V7.89863H77.8949V9.75092C77.8949 10.1286 78.0503 10.2932 78.4041 10.2932C78.4946 10.293 78.5851 10.2875 78.675 10.2769V10.8688C78.5474 10.8918 78.4182 10.904 78.2886 10.9054C77.4987 10.9054 77.184 10.6271 77.184 9.93256V7.89667H76.6128V7.29753H77.184V6.38281L77.8949 6.38477Z", fill: "white" }),
        React.createElement("path", { d: "M79.6416 5.875H80.346V7.85993H80.4021C80.4968 7.65265 80.6528 7.47953 80.8492 7.36405C81.0455 7.24858 81.2725 7.19633 81.4995 7.2144C81.6738 7.20477 81.848 7.23376 82.0098 7.29932C82.1716 7.36487 82.3169 7.46536 82.4354 7.59362C82.5538 7.72188 82.6426 7.87474 82.6952 8.04128C82.7478 8.20783 82.7631 8.38395 82.7398 8.55708V10.8818H82.0309V8.73348C82.0309 8.15787 81.7632 7.86647 81.2612 7.86647C81.139 7.85644 81.0161 7.87328 80.9011 7.91581C80.7861 7.95835 80.6817 8.02555 80.5954 8.1127C80.5091 8.19985 80.4428 8.30484 80.4013 8.42032C80.3598 8.53579 80.3441 8.65896 80.3551 8.78118V10.8818H79.6416V5.875Z", fill: "white" }),
        React.createElement("path", { d: "M86.8846 9.91135C86.7887 10.2412 86.5799 10.5267 86.2947 10.7179C86.0095 10.909 85.6662 10.9936 85.325 10.9567C85.0877 10.9635 84.8518 10.9184 84.6337 10.8245C84.4157 10.7307 84.2207 10.5903 84.0624 10.4133C83.9041 10.2362 83.7863 10.0268 83.7172 9.79948C83.6481 9.57218 83.6293 9.33254 83.6623 9.09726C83.6307 8.86133 83.6501 8.62136 83.7192 8.39359C83.7883 8.16582 83.9055 7.95557 84.0628 7.77708C84.2202 7.59859 84.414 7.45602 84.6312 7.35903C84.8484 7.26203 85.0839 7.21288 85.3217 7.2149C86.3231 7.2149 86.9276 7.89963 86.9276 9.03061V9.27889H84.3863V9.31875C84.3751 9.45096 84.3916 9.58405 84.4348 9.70949C84.478 9.83492 84.547 9.94993 84.6372 10.0471C84.7274 10.1443 84.837 10.2216 84.9588 10.2739C85.0806 10.3263 85.212 10.3525 85.3446 10.3511C85.5145 10.3715 85.6866 10.3409 85.8391 10.2632C85.9917 10.1855 86.1176 10.0642 86.2011 9.91462L86.8846 9.91135ZM84.3856 8.75032H86.2037C86.2126 8.62943 86.1962 8.50801 86.1555 8.39385C86.1148 8.27969 86.0506 8.17532 85.9672 8.08745C85.8838 7.99958 85.7829 7.93016 85.6711 7.88363C85.5592 7.83711 85.4389 7.81452 85.3178 7.81731C85.195 7.81573 85.073 7.83878 84.9592 7.88511C84.8454 7.93144 84.742 8.00011 84.6551 8.08706C84.5682 8.17401 84.4996 8.2775 84.4533 8.39141C84.4071 8.50532 84.384 8.62736 84.3856 8.75032Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_9983_9581" },
            React.createElement("rect", { width: "102", height: "32", fill: "white" })))));
