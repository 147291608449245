import React from "react";
import { Container, ContainerFW, Row, Col } from "../../global";
import { PlatformInformationCard } from "./PlatformInformationCard/PlatformInformationCard";
export const PlatformInformationCardDeck = (props) => (React.createElement("div", { className: "information-card__deck information-card__deck--platform" },
    React.createElement(ContainerFW, null,
        React.createElement(Container, null,
            React.createElement("h2", { className: "information-card__deck-title", dangerouslySetInnerHTML: { __html: props.title }, style: { textAlign: props.titlePosition } }),
            (props.numberCols === "3") && React.createElement(Row.Cols3, null, props.cards.map((card, i) => React.createElement(Col, { key: `information-card-${i}` },
                React.createElement(PlatformInformationCard, { ...card, numberCols: props.numberCols })))),
            props.numberCols === "2" && React.createElement(Row.Cols2, null, props.cards.map((card, i) => React.createElement(Col, { key: `information-card-${i}` },
                React.createElement(PlatformInformationCard, { ...card, numberCols: props.numberCols })))),
            props.announcement && React.createElement("p", { className: "information-card__deck-announcement", dangerouslySetInnerHTML: { __html: props.announcement } })))));
