import * as React from "react";
import { Img } from "../../Img";
import { Container, ContainerFW } from "../../global";
export const HeroBannerImageRight = (props) => (React.createElement(ContainerFW, { backgroundColor: props.backgroundColor },
    React.createElement(Container, null,
        React.createElement("div", { className: "small-hero-banner" },
            React.createElement("div", { className: "small-hero-banner__content" },
                React.createElement("h1", { className: "h1-regular-small" }, props.title),
                React.createElement("p", null, props.text)),
            React.createElement(Img, { src: props.image, alt: props.alt })))));
