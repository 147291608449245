export const CULTURES_TO_LOCALE_MAP = {
    "en-US": {
        language: "en",
        skipTranslation: true
    },
    "en-GB": {
        language: "en",
        skipTranslation: true
    },
    "en-AU": {
        language: "en",
        skipTranslation: true
    },
    "en-NZ": {
        language: "en",
        skipTranslation: true
    },
    "en-ZA": {
        language: "en",
        skipTranslation: true
    },
    "en-EU": {
        language: "en",
        skipTranslation: true
    },
    "en-AE": {
        language: "en",
        skipTranslation: true
    },
    "en-KY": {
        language: "en",
        skipTranslation: true
    },
    "es-ES": {
        language: "es"
    },
    "de-DE": {
        language: "de",
    },
    "es-CO": {
        language: "latam",
    },
    "th-TH": {
        language: "th",
    },
    "zh-TW": {
        language: "tw",
    },
    "pt-BR": {
        language: "pt",
    },
    "ar-AE": {
        language: "ar",
    },
};
