import React from "react";
import { Container, ContainerFW } from "../../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { SingleCard } from "./SingleCard/SingleCard";
import { Heading } from "../../Heading";
import { getColors } from "../../../utils";
export const CardsDeckWithTitleAndSubSwiper = ({ title, cards, backGroundColor }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "cards-deck-with-title-sub-swiper__root" },
                React.createElement(Heading, { design: "h3-bold" }, title),
                React.createElement("div", { className: "cards-deck-with-title-sub-swiper__root__swiper-view" },
                    React.createElement(Swiper, { pagination: {
                            clickable: true,
                            renderBullet: () => ("<span class='cards-deck-with-title-sub-swipe__bullet swiper-pagination-bullet'></span>"),
                        }, breakpoints: {
                            375: {
                                slidesPerView: 1.05,
                                spaceBetween: 7
                            },
                            601: {
                                slidesPerView: 2.1,
                                spaceBetween: 12
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 24
                            },
                        } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `cards-deck-with-title-sub-swipe-slide-${i}` },
                        React.createElement(SingleCard, { ...card })))))))));
};
