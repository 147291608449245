import * as React from "react";
import "./";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { getColors } from "../../utils";
export const ArticleInsert = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "articleInsert" },
            React.createElement(Container, null,
                React.createElement("div", { className: `articleInsert__inner ${props.imagePosition === "left" ? "reverse-row-class" : "row-class"} ${props.textPosition === "center" ? "text-position-center" : ""}` },
                    React.createElement("div", { className: `articleInsert__text ${props.imagePosition === "left" ? "margin-right-0" : "margin-right-72"}` },
                        props.title && React.createElement("h2", null, props.title),
                        React.createElement(Img, { className: "articleInsert__img1", src: props.imageUrl, alt: props.imageAlt }),
                        React.createElement("div", { className: "articleInsert__content", dangerouslySetInnerHTML: { __html: props.text } }),
                        props.linkText && (React.createElement("a", { className: "articleInsert__cta", href: props.linkUrl }, props.linkText))),
                    React.createElement(Img, { className: "articleInsert__img2", src: props.imageUrl, alt: props.imageAlt }))))));
};
