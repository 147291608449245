import React, { useCallback } from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
import { Heading } from "../Heading";
export const MaintenanceCardsStrip = ({ designStyles, title, maintenanceCards }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    const renderCard = useCallback(({ title, iconUrl, iconAlt, specificDateMaintenanceHtml, regularMaintenance, noScheduledMaintenanceHtml }, i) => {
        function getNextTargetDayAndTime(targetWeekdayGMTPlus2, targetHourGMTPlus2, maintenanceDurationHours) {
            const now = new Date();
            const resultDate = new Date(now);
            const currentWeekday = now.getUTCDay();
            const currentHourGMTPlus2 = now.getUTCHours() + 2;
            let daysToAdd = targetWeekdayGMTPlus2 - currentWeekday;
            if (daysToAdd < 0) {
                daysToAdd += 7;
            }
            if (daysToAdd === 0 && currentHourGMTPlus2 >= targetHourGMTPlus2 + maintenanceDurationHours) {
                daysToAdd += 7;
            }
            resultDate.setUTCDate(now.getUTCDate() + daysToAdd);
            resultDate.setUTCHours(targetHourGMTPlus2 - 2, 0, 0, 0);
            const resultDateGMTPlus3 = new Date(resultDate.getTime() + 1 * 60 * 60 * 1000);
            const endDateGMTPlus3 = new Date(resultDateGMTPlus3.getTime() + maintenanceDurationHours * 60 * 60 * 1000);
            const startTime1 = resultDateGMTPlus3.toLocaleString("en-GB", { year: "2-digit", month: "2-digit", day: "2-digit" }).toUpperCase();
            const startTime2 = resultDateGMTPlus3.toLocaleString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase();
            const endTime = endDateGMTPlus3.toLocaleString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: true }).toUpperCase();
            return [`${startTime1.replace("/", ".").replace("/", ".")}`, ` ${startTime2} - ${endTime} GMT+3`];
        }
        const maintenanceTextHtml = () => {
            const weekday = parseInt(regularMaintenance.regularScheduledMaintenanceWeekdayInGmtPlus2);
            const hourGMTPlus2 = parseInt(regularMaintenance.regularScheduledMaintenanceHourInGmtPlus2);
            const durationHours = parseInt(regularMaintenance.regularScheduledMaintenanceHourDuration);
            const time = getNextTargetDayAndTime(weekday, hourGMTPlus2, durationHours);
            if (specificDateMaintenanceHtml) {
                return React.createElement("div", { dangerouslySetInnerHTML: { __html: specificDateMaintenanceHtml } });
            }
            if (noScheduledMaintenanceHtml) {
                return React.createElement("div", { dangerouslySetInnerHTML: { __html: noScheduledMaintenanceHtml } });
            }
            if (!isNaN(weekday) && !isNaN(hourGMTPlus2) && !isNaN(hourGMTPlus2)) {
                return React.createElement("div", { className: "caption2-regular maintenance-cards-strip__regular-maintenance-text" },
                    time[0],
                    " ",
                    React.createElement("br", null),
                    " ",
                    time[1]);
            }
            return "";
        };
        if (!maintenanceTextHtml()) {
            return;
        }
        return (React.createElement("div", { key: i, className: `maintenance-cards-strip__card centered-container ${noScheduledMaintenanceHtml ? "bg-color-neutral-accent-dark-bg-15" : ""}` },
            React.createElement("div", { className: "maintenance-cards-strip__card-title-bar" },
                React.createElement("div", { className: "h5-semi-bold" }, title),
                React.createElement("img", { src: iconUrl, alt: iconAlt })),
            React.createElement("div", { className: "maintenance-cards-strip__message-container" }, maintenanceTextHtml())));
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { style: { color: textColor }, className: "maintenance-cards-strip" },
                React.createElement("div", { className: "flex" },
                    React.createElement(Heading, { design: designStyles?.titleDesign }, title)),
                React.createElement("br", null),
                React.createElement("div", { className: "centered-container" },
                    React.createElement("div", { className: "maintenance-cards-strip__main-strip" }, maintenanceCards?.map((card, i) => renderCard(card, i))))))));
};
