import React from "react";
import { viewSizes } from "../../pageViewStyle";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { VideoBgComponentCard } from "./VideoBgComponentCard";
export const SimpleSwiperComponent = ({ cards, isReverseDirection }) => (React.createElement(Swiper, { autoHeight: true, slidesPerView: 3, spaceBetween: 10, loop: true, autoplay: {
        delay: 200,
        disableOnInteraction: false,
        reverseDirection: isReverseDirection,
    }, breakpoints: {
        [viewSizes.mediumMaxWidth]: {
            slidesPerView: 4,
        },
    }, speed: 1500, modules: [Autoplay], direction: "vertical" }, cards.length > 0 && cards.map((card, i) => React.createElement(SwiperSlide, { key: `SimpleSwiper-slide-${i}` }, React.createElement(VideoBgComponentCard, { key: `unique${card.LeftSubtitle}`, ...card })))));
