import * as React from "react";
import { SocialContacts } from "../../Components/Footer/SocialContacts/SocialContacts";
import { MainFooter } from "../../Components/Footer/MainFooter/MainFooter";
import { RiskWarning } from "../../Components/Footer/RiskWarning/RiskWarning";
import { ScrollToTopButton } from "../../Components/Footer/ScrollToTopButton/ScrollToTopButton";
export const Footer = (props) => (React.createElement("div", { className: "g-footer" },
    React.createElement(SocialContacts, { logoImageUrl: props.socialSectionLogoImageUrl, logoImageAlt: props.socialSectionLogoImageAlt, liveChatText: props.socialSectionLiveChatText, icons: props.socialSectionIcons, logoHref: props.logoHref }),
    React.createElement(MainFooter, { sections: [
            { title: "TRADE WITH US", links: [{ label: "Trade CFDs", url: "#" }, { label: "Social Trading", url: "#" }, { label: "Trading tools", url: "#" }, { label: "Pricing", url: "#" }] },
            { title: "ACCOUNT INFORMATION", links: [{ label: "Account Comparison", url: "#" }, { label: "Demo Account", url: "#" }, { label: "ThinkZero Account", url: "#" }, { label: "Manage Account", url: "#" }, { label: "Deposit & Withdraw", url: "#" }] },
            { title: "POPULAR IN LEARNING", links: [{ label: "Japanese Candlesticks", url: "#" }, { label: "Glossary of The Day", url: "#" }, { label: "Learn to Trade CFDs", url: "#" }, { label: "Successful Trading Strategies", url: "#" }] },
            { title: "COMPANY", links: [{ label: "About ThinkMarkets", url: "#" }, { label: "Press & Announcements", url: "#" }, { label: "Careers", url: "#" }] },
            { title: "SUPPORT", links: [{ label: "How to place a trade?", url: "#" }, { label: "How to create an account?", url: "#" }, { label: "How to deposit?", url: "#" }, { label: "How long does it take for account approval?", url: "#" }, { label: "What types of accounts do you offer?", url: "#" }] },
            { title: "LEGAL", links: [{ label: "Terms & conditions", url: "#" }, { label: "Privacy policy", url: "#" }, { label: "Risk disclaimer", url: "#" }, { label: "Regulations", url: "#" }] },
        ] }),
    React.createElement(RiskWarning, { text: props.riskWarningText }),
    React.createElement(ScrollToTopButton, null)));
