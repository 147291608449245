import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, ContainerFW } from "../global";
import { useMarketSentiment } from "../../hooks";
import { getColors } from "../../utils";
import { handIcon } from "../svgIcons";
const CardComponent = ({ symbol, longPercent, shortPercent, baseLinkUrl, startTradingUrl }) => {
    const longValue = parseFloat(longPercent);
    const shortValue = parseFloat(shortPercent);
    if (isNaN(longValue) || isNaN(shortValue) || longValue + shortValue !== 100) {
        return React.createElement(React.Fragment, null);
    }
    const renderCardContent = () => ((React.createElement("div", { className: "instruments-buy-sell-cards-bar__card-outline" },
        React.createElement("div", { className: "instruments-buy-sell-cards-bar__card-inner" },
            React.createElement("div", { className: "instruments-buy-sell-cards-bar__title caption-bold color-dark-default" }, symbol),
            React.createElement("div", { className: "instruments-buy-sell-cards-bar__bar" },
                React.createElement("div", { className: "instruments-buy-sell-cards-bar__percent-bar-main" },
                    React.createElement("div", { style: { width: `${longPercent}` }, className: "instruments-buy-sell-cards-bar__percent-bar-green" }))),
            React.createElement("div", { className: "instruments-buy-sell-cards-bar__percent flex color-dark-default caption" },
                React.createElement("div", null, longPercent),
                React.createElement("div", null, shortPercent))))));
    const instrumentLinkUrl = baseLinkUrl ? baseLinkUrl + symbol.toLowerCase() : symbol.toLowerCase();
    const linkUrl = startTradingUrl ? startTradingUrl : instrumentLinkUrl;
    return (React.createElement("a", { href: linkUrl, target: "_blank", className: "instruments-buy-sell-cards-bar__card-link", rel: "noreferrer" }, renderCardContent()));
};
export const InstrumentsBuySellCardsBar = ({ title, designStyles, symbols, securityKey, baseLinkUrl, startTradingUrl, startTradingDescription }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    const { marketSentiments, error } = useMarketSentiment(symbols || "USDJPY,USDCAD,EURUSD,US30,GER30,XAUUSD,GBPUSD,AUDUSD", securityKey || "zzz9bbb59df534b077c0e9a7e425ea97ffc");
    if (error || !marketSentiments.length) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "instruments-buy-sell-cards-bar", style: { color: textColor } },
                React.createElement("div", { className: "caption-bold instruments-buy-sell-cards-bar__mini-title" },
                    title,
                    React.createElement("span", { className: "caption flex" },
                        startTradingDescription && handIcon,
                        startTradingDescription)),
                React.createElement("div", null,
                    React.createElement(Swiper, { spaceBetween: 8, slidesPerView: "auto" }, marketSentiments?.map((card) => React.createElement(SwiperSlide, { key: `platforms-slider-slide-${card.symbol}${card.longPercent}` },
                        React.createElement(CardComponent, { symbol: card.symbol, baseLinkUrl: baseLinkUrl, startTradingUrl: startTradingUrl, longPercent: card.longPercent, shortPercent: card.shortPercent })))))))));
};
