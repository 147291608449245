import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { CULTURES_TO_LOCALE_MAP } from "./culturesToLocaleMap";
const getCultureConfig = (culture) => Object.entries(CULTURES_TO_LOCALE_MAP).find(([cultureCode]) => cultureCode === culture)?.[1];
export const setUpReactI18n = async (culture) => {
    const cultureConfig = getCultureConfig(culture);
    const lang = cultureConfig?.language;
    const mustSkipTranslation = cultureConfig?.skipTranslation ?? false;
    await i18n
        .use(HttpBackend)
        .use(initReactI18next)
        .init({
        lng: lang,
        fallbackLng: false,
        interpolation: {
            escapeValue: false,
        },
        debug: true,
        backend: {
            loadPath() {
                if (mustSkipTranslation) {
                    return undefined;
                }
                return "/locales/{{lng}}/{{ns}}.json";
            }
        },
    });
};
if (typeof window !== "undefined") {
    const cultureCode = document.documentElement.getAttribute("lang") || undefined;
    setUpReactI18n(cultureCode).catch((e) => {
        throw new Error(e);
    });
}
