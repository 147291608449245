import React from "react";
import { getColors } from "../../../utils";
import { Container, ContainerFW } from "../../global";
import { Heading } from "../../Heading";
import { CardWithImageAndButton } from "./CardWithImageAndButton/CardWithImageAndButton";
export const TwoCardsWithImagesAndButton = ({ title, backGroundColor, cards = [{}] }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "two-cards-with-images-and-button" },
                React.createElement("div", { className: "two-cards-with-images-and-button__text-wrapper" },
                    React.createElement(Heading, { design: "h2-regular" }, title)),
                React.createElement("div", { className: "two-cards-with-images-and-button__cards-wrapper" }, cards.length > 0 && cards.map((card, i) => (React.createElement(CardWithImageAndButton, { key: i, ...card }))))))));
};
