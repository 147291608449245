import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ToggleableTitleContentSwiperCard } from "./ToggleableTitleContentSwiperCard";
export const ToggleableTitleContentSwiper = ({ cards, content, mainTitle, date, imagePosition = "right", backgroundColor }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
    React.createElement("div", { className: "toggleable-title-content-swiper-root" },
        React.createElement(Container, null,
            React.createElement("div", { className: `toggleable-title-content-swiper-root__contentWrapper ${imagePosition === "left" ? "leftImagePosition" : ""}` },
                React.createElement("div", { className: "toggleable-title-content-swiper-root__contentWrapper__titleWrapper" },
                    React.createElement("p", null, date),
                    React.createElement(Heading, { design: "h2-regular-small" }, mainTitle)),
                React.createElement(Container, null,
                    React.createElement("div", { className: "toggleable-title-content-swiper-root__swiper-view" },
                        React.createElement(Swiper, { pagination: {
                                clickable: true,
                                renderBullet: () => ("<span class='toggleable-title-content-swiper-root__bullet swiper-pagination-bullet'></span>"),
                            } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `toggleable-title-content-swiper-root-slide-${i}` },
                            React.createElement(ToggleableTitleContentSwiperCard, { ...card })))),
                        React.createElement("div", { className: "toggleable-title-content-swiper-root__pagination" }))),
                React.createElement("p", { className: "toggleable-title-content-swiper-root__contentWrapper__content", dangerouslySetInnerHTML: {
                        __html: content ? content : "",
                    } }))))));
