import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { usePageViewStyle } from "../../hooks";
import { Container, ContainerFW } from "../global";
import { ThreeImagesCardComponentLarge } from "./ThreeImagesCardComponentLarge/ThreeImagesCardComponentLarge";
import { ThreeImagesCardComponentSwiper } from "./ThreeImagesCardComponentSwiper/ThreeImagesCardComponentSwiper";
export const ThreeImagesCards = ({ cards, mainTitle, subTitle, animation }) => {
    const { isLarge } = usePageViewStyle();
    const [alternativeComponent, setAlternativeComponent] = useState(React.createElement(React.Fragment, null));
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const currentComponentHandler = useCallback(() => {
        if (!isLarge && animation) {
            return React.createElement(ThreeImagesCardComponentSwiper, { cards: cards });
        }
        else {
            return React.createElement(ThreeImagesCardComponentLarge, { cards: cards, inView: inView });
        }
    }, [animation, cards, inView, isLarge]);
    useEffect(() => {
        setAlternativeComponent(currentComponentHandler());
    }, [currentComponentHandler]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement("div", { className: "threeImagesCardsWrapper", ref: ref },
            React.createElement(Container, null,
                React.createElement("h2", { className: "threeImagesCardsWrapper__mainTitle" }, mainTitle),
                React.createElement("p", { className: "threeImagesCardsWrapper__subTitle" }, subTitle),
                alternativeComponent))));
};
