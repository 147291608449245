import React, { useCallback, useState, useEffect } from "react";
import { Img } from "../../Img";
import { Container, ContainerFW } from "../../global";
import { SmallArrowIcon } from "../../svgIcons/smallArrowIcon";
import { usePageViewStyle } from "../../../hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
SwiperCore.use([Navigation, Autoplay, Pagination]);
export const DarkBackgroundCardDeckWithImage = ({ title, titleDescription, titlePosition, cards }) => {
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const [imageIndexState, setImageIndexState] = useState(0);
    const setIndexImageStateCallback = useCallback((e) => {
        const index = e.currentTarget.dataset.index;
        setImageIndexState(parseInt(index, 10));
    }, []);
    const [titleValue, setTitleValue] = useState("");
    const [titleDescValue, setTitleDescValue] = useState("");
    const [isSmallValue, setIsSmallValue] = useState(false);
    const [isMediumValue, setIsMediumValue] = useState(false);
    const [isLargeValue, setIsLargeValue] = useState(false);
    useEffect(() => {
        setTitleValue(title);
        setTitleDescValue(titleDescription);
        setIsSmallValue(isSmall);
        setIsMediumValue(isMedium);
        setIsLargeValue(isLarge);
    }, [title, titleDescription, isSmall, isMedium, isLarge]);
    const smallTitleContainer = (titlePosition) => (React.createElement("div", { className: 'card-deck-container-resources__title-container-small', style: { textAlign: titlePosition } },
        React.createElement("h2", { className: "color-pure-white" }, titleValue),
        React.createElement("h5", { className: "h5-semi-bold color-dark-20" }, titleDescValue)));
    const bigTitleContainer = (titlePosition) => (React.createElement("div", { style: { textAlign: titlePosition } },
        React.createElement("h2", { className: "color-pure-white" }, titleValue),
        React.createElement("h5", { className: "h5-semi-bold color-dark-20 card-deck-container-resources__title-description-lardium" }, titleDescValue)));
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "bg-color-dark-default" },
            React.createElement(Container, null,
                React.createElement("div", { className: "card-deck-container-resources bg-color-dark-default" },
                    isSmallValue && React.createElement("div", null,
                        smallTitleContainer(titlePosition === undefined ? "center" : titlePosition.toLowerCase()),
                        React.createElement(Swiper, { spaceBetween: 50, slidesPerView: 1, pagination: { clickable: true } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `platforms-slider-slide-${i}` },
                            React.createElement("a", { href: card.linkUrl, style: { textDecoration: "none", color: "inherit" } },
                                React.createElement("div", { key: i },
                                    React.createElement("div", null,
                                        React.createElement(Img, { src: card.imageUrl, alt: card.imageAlt, style: { width: "100%", height: "100%" } })),
                                    React.createElement("div", { className: "card-deck-container-resources__card-text-content-small" },
                                        React.createElement("div", { className: "card-deck-container-resources__flex", key: `accordion-card-${i}` },
                                            React.createElement(Img, { src: card.iconImageUrl, alt: card.iconAlt }),
                                            React.createElement("h5", { className: "color-pure-white" }, card.title),
                                            React.createElement("span", { className: "hide-large card-deck-container-resources__arrow-icon" }, SmallArrowIcon)),
                                        React.createElement("p", { className: "color-dark-20" }, card.text)))))))),
                    (isLargeValue || isMediumValue) && React.createElement("div", null,
                        React.createElement("div", { className: "card-deck-container-resources__main-container-lardium" },
                            React.createElement("div", null,
                                React.createElement("div", { className: "card-deck-container-resources__title-container-lardium" }, bigTitleContainer(titlePosition === undefined ? "center" : titlePosition.toLowerCase())),
                                React.createElement("div", { className: "card-deck-container-resources__top-image-container-medium hide-large" },
                                    React.createElement(Img, { src: cards[0].imageUrl, alt: cards[0].imageAlt })),
                                React.createElement("div", { className: "card-deck-container-resources__cards-grid" }, cards.map((card, i) => React.createElement("div", { key: `card-large-${i}`, "data-index": i, className: `card-deck-container-resources__card-large ${imageIndexState === i ? "hovered" : ""}`, onMouseEnter: setIndexImageStateCallback },
                                    React.createElement("a", { href: card.linkUrl, style: { textDecoration: "none", color: "inherit" } },
                                        React.createElement("div", { className: "card-deck-container-resources__card-title-container", key: `accordion-card-${i}` },
                                            React.createElement("div", { className: "card-deck-container-resources__flex" },
                                                React.createElement(Img, { src: card.iconImageUrl, alt: card.iconAlt }),
                                                React.createElement("h5", { className: "color-pure-white color-dark-20" }, card.title),
                                                React.createElement("div", { className: "hide-large card-deck-container-resources__arrow-icon" },
                                                    React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                                        React.createElement("path", { d: "M26.3 18.8c.235.234.366.552.367.883v.634c-.004.33-.136.647-.367.883l-8.567 8.55a.834.834 0 0 1-1.183 0l-1.183-1.183a.816.816 0 0 1 0-1.167l7.416-7.4-7.416-7.4a.833.833 0 0 1 0-1.183l1.183-1.167a.833.833 0 0 1 1.183 0L26.3 18.8z", fill: "#FFF" })))),
                                            React.createElement("p", { className: "color-dark-20" }, card.text))))))),
                            React.createElement("div", { className: "card-deck-container-resources__left-image-container-large hide-medium" },
                                React.createElement(Img, { src: cards[imageIndexState].imageUrl, alt: cards[imageIndexState].imageAlt, className: "image-large-deck" })))))))));
};
