import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
import { Items } from "./Items/Items";
export const SectionWithImageLateralItems = ({ title, imageUrl, items, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "section-with-image-lateral-items__root" },
                React.createElement("div", { className: "section-with-image-lateral-items__root__textsWrapper" },
                    React.createElement(Heading, { design: "h2-regular" }, title),
                    React.createElement("div", { className: "itemsWrapper" }, Array.isArray(items) && items.length > 0 && items.map((a) => React.createElement(Items, { key: `unique${a.title}`, ...a })))),
                React.createElement("div", { className: "section-with-image-lateral-items__root__imageWrapper" },
                    React.createElement(ImgV2, { src: imageUrl }))))));
};
