import React, { Fragment, useCallback, useState, useRef, useEffect, } from "react";
import { Gap } from "../../../Components/Gaps/Gap";
import { HotTopicCard } from "./HotTopicCard/HotTopicCard";
export const HotTopicCardDeck = (props) => {
    const [selectedCardId, setSelectedCardId] = useState(-1);
    const isFirstRender = useRef(true);
    const handleExpand = useCallback((id) => {
        setSelectedCardId((prevId) => (prevId === id ? -1 : id));
    }, []);
    const getCards = () => props.cards.map((card, i) => {
        const shouldCardBeOpened = isFirstRender.current
            ? card.opened
            : i === selectedCardId;
        return { ...card, id: i, opened: shouldCardBeOpened };
    });
    const cards = getCards();
    useEffect(() => {
        isFirstRender.current = false;
    }, []);
    return (React.createElement("div", { className: "hot-topic-card__deck" }, cards.map((card, i) => (React.createElement(Fragment, { key: `hot-topic-card-${i}` },
        React.createElement(HotTopicCard, { id: i, title: card.title, imageUrl: card.imageUrl, readingTime: card.readingTime, text: card.text, linkText: card.linkText, linkUrl: card.linkUrl, opensInNewTab: card.opensInNewTab, opened: card.opened, textAfterReadingTime: card.textAfterReadingTime, onExpand: handleExpand }),
        i !== cards.length - 1 && (React.createElement(Gap, { mobileGap: "24px", desktopGap: "24px" })))))));
};
