import React from "react";
import { Container, ContainerFW } from "../global";
import { ImgV2 } from "../ImgV2/ImgV2";
import { ButtonV2 } from "../ButtonV2";
import { getColors } from "../../utils";
import { Heading } from "../Heading";
import { CardsDeckInfoBox } from "../CardsDeckWithTitle/CardsDeckInfoBox";
export const ComponentColItemsDeckWithTitle = ({ cards, backgroundColor, mainTitle, mainSubtitle, mainTitlePosition, buttonUrl, buttonLabel, buttonOpenOnNewPage, infoBox }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    const titleWrapper = () => (React.createElement("div", { className: `ComponentColItemsDeckWithTitle-wrapper__title-wrapper ${mainTitlePosition === "left" ? "main-title-position-left" : (mainTitlePosition === "right" ? "main-title-position-right" : "")}` },
        React.createElement(Heading, { design: "h2-regular-small" }, mainTitle),
        React.createElement("p", null, mainSubtitle)));
    const buttonWrapper = () => (buttonUrl && React.createElement("div", { className: "ComponentColItemsDeckWithTitle-wrapper__button-wrapper" },
        React.createElement(ButtonV2, { href: buttonUrl, openInNewTab: buttonOpenOnNewPage, design: "cta-large-dbg" }, buttonLabel)));
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "ComponentColItemsDeckWithTitle-wrapper" },
            React.createElement(Container, null,
                titleWrapper(),
                React.createElement("div", { style: { color: textColor }, className: "ComponentColItemsDeckWithTitle-wrapper__grid-wrapper" }, cards.length > 0 &&
                    cards.map((a) => (React.createElement("div", { key: `unique${a.title}`, className: "ComponentColItemsDeckWithTitle-wrapper__grid-wrapper__cards-wrapper", style: {
                            textAlign: a.titleSubtitlePosition === undefined ? "center" : a.titleSubtitlePosition.toLowerCase(),
                            justifyItems: a.imagePosition === undefined ? "center" : a.imagePosition.toLowerCase()
                        } },
                        React.createElement(ImgV2, { src: a.imageUrl, alt: a.imageAlt, loading: "eager" }),
                        React.createElement("span", { className: "ComponentColItemsDeckWithTitle-wrapper__grid-wrapper__cards-wrapper__texts" },
                            React.createElement(Heading, { design: "h5-bold" }, a.title),
                            React.createElement("p", { className: "cardsDeckWithTitle-container__comments", dangerouslySetInnerHTML: {
                                    __html: a.comment
                                } })))))),
                buttonWrapper(),
                infoBox?.text && React.createElement(CardsDeckInfoBox, { text: infoBox.text, title: infoBox.title })))));
};
