import React, { useEffect, useState } from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { getColors } from "../../../utils/getColors";
import { usePageViewStyle } from "../../../hooks";
export const TradingViewDownloadBanner = ({ mainTitle, description, list, buttonList, linkURL, linkLabel, desktopImageUrl, mobileImageUrl, imageAlt, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const [largeView, setLargeView] = useState(false);
    useEffect(() => {
        setLargeView(isLarge);
    }, [isLarge]);
    const listSection = () => (React.createElement("ul", { className: "trading-view-download-banner__text-wrapper__list-container" }, list.map((item, index) => (React.createElement("li", { key: index, className: "trading-view-download-banner__text-wrapper__list-container__item" },
        React.createElement(ImgV2, { src: item.iconUrl, alt: item.iconAlt, loading: "eager" }),
        React.createElement("p", null, item.title))))));
    const linkSection = () => (React.createElement("div", { className: "trading-view-download-banner__link-wrapper" },
        React.createElement("a", { href: linkURL }, linkLabel)));
    const buttonsSection = () => (largeView && React.createElement("div", { className: "trading-view-download-banner__buttons-wrapper" }, buttonList.map((item, index) => (React.createElement("a", { key: index, href: item.linkURL },
        React.createElement(ImgV2, { src: item.iconUrl, alt: item.iconAlt, loading: "eager" }),
        React.createElement("span", { className: "trading-view-download-banner__buttons-wrapper__button-text" },
            React.createElement("span", { className: "trading-view-download-banner__buttons-wrapper__button-title" }, item.title),
            React.createElement("span", { className: "trading-view-download-banner__buttons-wrapper__button-subTitle" }, item.subTitle)))))));
    const imageSection = () => (React.createElement("div", { className: "trading-view-download-banner__image-section" },
        (isLarge || isMedium) && React.createElement(ImgV2, { src: desktopImageUrl, alt: imageAlt, loading: "eager" }),
        isSmall && React.createElement(ImgV2, { src: mobileImageUrl, alt: imageAlt, loading: "eager" })));
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "trading-view-download-banner" },
                React.createElement("div", { className: "trading-view-download-banner__text-section" },
                    React.createElement("div", { className: "trading-view-download-banner__text-wrapper" },
                        React.createElement("h2", { className: "trading-view-download-banner__text-wrapper__title-container" }, mainTitle),
                        React.createElement("div", { className: "trading-view-download-banner__text-wrapper__description-container" },
                            React.createElement("p", null, description)),
                        listSection()),
                    buttonsSection(),
                    linkSection()),
                imageSection()))));
};
