import React, { useEffect, useState, useCallback } from "react";
import Lottie from "lottie-react";
import { ImgV2 } from "../../../ImgV2";
export const CardWithHoverAnimation = ({ animationPath, iconUrl, iconAlt, title, text }) => {
    const [hoverImage, setHoverImage] = useState(true);
    const [hoverAnimation, setHoverAnimation] = useState(false);
    const [animation, setAnimation] = useState();
    useEffect(() => {
        if (animationPath && animationPath.includes("json")) {
            fetch(animationPath)
                .then(response => response.json())
                .then((jsonData) => {
                setAnimation(jsonData);
            })
                .catch((error) => {
                console.error(error);
            });
        }
    }, [animationPath]);
    const handlerOnMouseEnter = function () {
        setHoverAnimation(true);
        setHoverImage(false);
    };
    const handlerOnMouseEnterProp = useCallback(() => handlerOnMouseEnter(), []);
    const handlerOnMouseLeave = function () {
        setHoverAnimation(false);
        setHoverImage(true);
    };
    const handlerOnMouseLeaveProp = useCallback(() => handlerOnMouseLeave(), []);
    return (React.createElement("div", { className: "card-with-hover-animation" },
        React.createElement("div", { className: "card-with-hover-animation__icon-wrapper" },
            React.createElement("div", { className: "card-with-hover-animation__svg-lottie-wrapper", onMouseEnter: handlerOnMouseEnterProp, onMouseLeave: handlerOnMouseLeaveProp },
                hoverImage && React.createElement(ImgV2, { src: iconUrl, alt: iconAlt, loading: "eager" }),
                hoverAnimation && React.createElement(Lottie, { animationData: animation }))),
        React.createElement("div", { className: "card-with-hover-animation__text-wrapper" },
            React.createElement("h5", null, title),
            React.createElement("p", { dangerouslySetInnerHTML: { __html: text } }))));
};
