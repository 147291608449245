import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { usePageViewStyle } from "../../hooks";
import { ButtonV2 } from "../ButtonV2";
import { Gap } from "../Gaps/Gap";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { Logo } from "../Logo";
import { SimpleSwiperComponent } from "./SimpleSwiperComponent";
import { VideoBgComponent } from "./VideoBgComponent";
import { Img } from "../../Components/Img";
export const SwiperWithVideoBg = ({ videoUrl, cards, animationJson, title, subTitle, buttonLink, buttonLabel, buttonLink2, buttonLabel2, backgroundColor, evaluationLabel, evaluationValue, trustPilotIconImage, disclaimer, imageUrl, imageAlt, openInNewTabButton1 = false, openInNewTabButton2 = false, logoContent }) => {
    const [animation, setAnimation] = useState();
    const { isLarge } = usePageViewStyle();
    useEffect(() => {
        if (animationJson && animationJson.includes("json")) {
            fetch(animationJson)
                .then(response => response.json())
                .then((jsonData) => {
                setAnimation(jsonData);
            })
                .catch((error) => {
                console.error(error);
            });
        }
    }, [animationJson]);
    const buttonSection = () => (React.createElement("div", null,
        (buttonLink || buttonLink2) && React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__buttonWrapper" },
            React.createElement(ButtonV2, { href: buttonLink, design: "cta-large-wbg", openInNewTab: openInNewTabButton1 }, buttonLabel),
            buttonLink2 && React.createElement(ButtonV2, { href: buttonLink2, design: "secondary-large-wbg", openInNewTab: openInNewTabButton2 }, buttonLabel2)),
        evaluationValue && React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__evaluationWrapper" },
            React.createElement("p", null, evaluationLabel),
            React.createElement("p", null, evaluationValue),
            React.createElement(Img, { src: trustPilotIconImage, alt: evaluationLabel }))));
    const imageAndVideoSection = () => (React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__swiperContainer" },
        React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__swiperContainer__swiper" },
            animation &&
                React.createElement("div", { className: "swiper-with-video-bg-root__container" },
                    React.createElement("div", { className: "swiper-with-video-bg-root__lottie-wrapper" },
                        React.createElement(Lottie, { animationData: animation, loop: true, rendererSettings: {
                                filterSize: {
                                    width: "200%",
                                    height: "200%",
                                    x: "-50%",
                                    y: "-50%",
                                }
                            } }))),
            cards?.length && React.createElement("div", { className: "swiper-with-video-bg-root__container" },
                React.createElement(SimpleSwiperComponent, { cards: cards, isReverseDirection: false }),
                isLarge && React.createElement(SimpleSwiperComponent, { cards: cards, isReverseDirection: true })),
            videoUrl && React.createElement(VideoBgComponent, { videoUrl: videoUrl }),
            imageUrl && React.createElement(Img, { src: imageUrl, alt: imageAlt }))));
    return (React.createElement("div", { className: "swiper-with-video-bg-root" },
        React.createElement(ContainerFW, { backgroundColor: backgroundColor ? backgroundColor : "inherit" },
            React.createElement(Container, null,
                React.createElement("div", { className: "swiper-with-video-bg-root__wrapper" },
                    React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__logoContainer" },
                        React.createElement(Logo, { imageUrl: logoContent?.imageUrl, imageUrlReverse: logoContent?.imageUrlReverse, alt: logoContent?.alt })),
                    React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__titlesWrapper" },
                        React.createElement(Heading, { design: "h1-regular" }, title),
                        React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__rich-text", dangerouslySetInnerHTML: {
                                __html: subTitle,
                            } })),
                    disclaimer && React.createElement("span", null),
                    buttonSection(),
                    React.createElement(Gap, { mobileGap: "40px", desktopGap: "40px" }),
                    disclaimer &&
                        React.createElement("div", { className: "swiper-with-video-bg-root__wrapper__disclaimer centered-container", dangerouslySetInnerHTML: { __html: disclaimer } }),
                    imageAndVideoSection())))));
};
