export const getFilterItemsType = (filterData) => {
    const firstItem = filterData.items[0];
    if (typeof firstItem === "string") {
        return "string";
    }
    if (typeof firstItem === "object") {
        return "object";
    }
    return undefined;
};
