import React from "react";
const removeLeadingTilda = (string) => string.replace("~", "");
const generateSrcsetAttribute = ({ largeViewImageUrl, mediumViewImageUrl, smallViewImageUrl, srcsetWidthDescriptors }) => {
    const descriptorsArray = srcsetWidthDescriptors?.split(",");
    if (!descriptorsArray || !descriptorsArray.length) {
        return "";
    }
    return `
        ${smallViewImageUrl} ${removeLeadingTilda(descriptorsArray[0])},
        ${mediumViewImageUrl} ${removeLeadingTilda(descriptorsArray[1])},
        ${largeViewImageUrl} ${removeLeadingTilda(descriptorsArray[2])}
    `;
};
const checkIsHelperDataValid = (helperProps) => {
    const NUM_IMAGES = 3;
    if (!helperProps) {
        return false;
    }
    if (Object.values(helperProps).some(propValue => !propValue)) {
        return false;
    }
    const { srcsetWidthDescriptors, } = helperProps;
    const descriptorsArray = srcsetWidthDescriptors?.split(",");
    if (!descriptorsArray || descriptorsArray.length < NUM_IMAGES || descriptorsArray.filter(descriptor => descriptor).length < NUM_IMAGES) {
        return false;
    }
    return true;
};
export function ResponsiveImgDecorator(Component) {
    return function Decorator(props) {
        let htmlSrcset = "";
        if (props.helperProps && checkIsHelperDataValid(props.helperProps)) {
            htmlSrcset = generateSrcsetAttribute(props.helperProps);
        }
        if (!props.helperProps?.sizesDescriptor || !htmlSrcset) {
            return React.createElement(Component, { ...props });
        }
        return React.createElement(Component, { ...props, srcset: htmlSrcset, sizes: props.helperProps.sizesDescriptor });
    };
}
