import React, { useCallback } from "react";
export const TabsFilter = ({ options, onSelect, selectedOption, filterTitle }) => {
    const handleChange = useCallback((event) => {
        if (onSelect) {
            onSelect(event.target.value);
        }
    }, [onSelect]);
    return (React.createElement("div", { className: "tabs-filter" },
        React.createElement("fieldset", null,
            React.createElement("legend", null, filterTitle),
            React.createElement("div", { className: "tabs-filter__radios-container" }, options.map((option, index) => {
                const [optionText, optionValue] = typeof option === "string" ? [option, option] : [option.text, option.value];
                return (React.createElement("label", { key: index, className: "tabs-filter__tab" },
                    React.createElement("input", { type: "radio", onChange: handleChange, value: optionValue, checked: selectedOption === optionValue }),
                    React.createElement("span", null, optionText)));
            })))));
};
