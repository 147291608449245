import * as React from "react";
import { Breadcrumbs } from "../../Breadcrumbs";
import { Container, ContainerFW } from "../../global";
import { Gap } from "../../../Components/Gaps/Gap";
export const BasicBanner = (props) => {
    const subTitleComponent = () => (props.subtitle && React.createElement("p", { className: `${"h5-medium basic-banner__subtitle"} ${props.subtitlePosition === "center" ? "addMarginAuto" : ""}`, style: { textAlign: props.subtitlePosition === undefined ? "left" : props.subtitlePosition.toLowerCase() } }, props.subtitle));
    return (React.createElement(ContainerFW, { backgroundColor: props.backgroundColor ? props.backgroundColor : "transparent" },
        React.createElement(Container, null,
            React.createElement("div", { className: "basic-banner" },
                props.breadcrumbs && React.createElement(Breadcrumbs, { ...props.breadcrumbs }),
                React.createElement(Gap, { desktopGap: "40px", mobileGap: "40px" }),
                React.createElement("h1", { className: "h1-regular-small basic-banner__title", style: { textAlign: props.titlePosition === undefined ? "left" : props.titlePosition.toLowerCase() } }, props.title),
                subTitleComponent(),
                React.createElement(Gap, { desktopGap: "32px", mobileGap: "32px" })))));
};
