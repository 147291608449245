import React, { useCallback } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, ContainerFW } from "../global";
import { getColors, duplicateArrayItems } from "../../utils";
import { Heading } from "../Heading";
export const SectionCardsMovingInOppositeDirection = ({ title, description, upperRow, lowerRow, designStyles }) => {
    const renderCard = useCallback(({ title, description, imageUrl, imageAlt, i }) => (React.createElement("div", { key: i, className: "section-cards-moving-in-opposite-direction__card centered-container 1swiper-no-swiping" },
        React.createElement("div", { className: "section-cards-moving-in-opposite-direction__card-title-bar centered-container" },
            React.createElement("img", { src: imageUrl, alt: imageAlt, className: "section-cards-moving-in-opposite-direction__card-image" }),
            React.createElement("div", { className: "h5-semi-bold" }, title)),
        React.createElement("div", { className: "section-cards-moving-in-opposite-direction__message-container color-dark-80" },
            React.createElement("p", null,
                " ",
                description)))), []);
    const paymentRoller = useCallback((items, reverseDirection = false) => (React.createElement(React.Fragment, null,
        React.createElement(Swiper, { spaceBetween: 24, slidesPerView: "auto", loop: true, centeredSlides: true, autoplay: {
                delay: 0,
                disableOnInteraction: false,
                reverseDirection,
            }, speed: 2400, modules: [Autoplay] }, duplicateArrayItems(items, 5).map((item, index) => (React.createElement(SwiperSlide, { key: index }, renderCard({ ...item, i: `${index}a` }))))))), [renderCard]);
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: "section-cards-moving-in-opposite-direction", style: { color: textColor } },
                React.createElement("div", { className: "section-cards-moving-in-opposite-direction__main-container" },
                    React.createElement("div", { className: "section-cards-moving-in-opposite-direction__title_container centered-container" },
                        React.createElement(Heading, { design: designStyles?.titleDesign }, title),
                        React.createElement("h3", { className: "h5-semi-bold", dangerouslySetInnerHTML: {
                                __html: description || "",
                            } })),
                    React.createElement("div", null, upperRow && paymentRoller(upperRow)),
                    React.createElement("br", null),
                    React.createElement("div", null, lowerRow && paymentRoller(lowerRow, true)))))));
};
