import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils";
import { Heading } from "../../Heading";
import { CardWithTexts } from "./CardWithTexts";
import { ButtonV2 } from "../../ButtonV2";
export const CardDeckWithTextsAndButtons = ({ title, subTitle, cards, primaryButtonLabel, primaryButtonLink, secondaryButtonLabel, secondaryButtonLink, backgroundColor = "#fff", orText = "or" }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "card-deck-with-texts-buttons-root" },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "card-deck-with-texts-buttons-root__textsWrapper" },
                    React.createElement(Heading, { design: "h3-semi-bold-small" }, title),
                    React.createElement("p", { dangerouslySetInnerHTML: {
                            __html: subTitle || "",
                        } })),
                React.createElement("div", { className: "card-deck-with-texts-buttons-root__cardsWrapper" }, cards.length > 0 && cards.map((card, index) => (React.createElement(CardWithTexts, { cardText: card.cardText, cardIndex: index + 1, key: `unique${card.cardText}` })))),
                React.createElement("div", { className: "card-deck-with-texts-buttons-root__buttonsWrapper" },
                    React.createElement(ButtonV2, { design: "cta-large-wbg", href: primaryButtonLink }, primaryButtonLabel),
                    React.createElement("span", null, orText),
                    React.createElement(ButtonV2, { design: "ghost-large-wbg", href: secondaryButtonLink }, secondaryButtonLabel))))));
};
