import { Dropdown } from "../Dropdown";
import { ContainerFW, Container } from "../global";
import React, { useMemo, useState, useCallback } from "react";
export const TradingScenariosMultiple = ({ title, subtitle, examples }) => {
    const [exampleIndex, setExampleIndex] = useState(0);
    const handleRadioClick = useCallback((event) => {
        const index = parseInt(event.currentTarget.dataset.index, 10);
        setExampleIndex(index);
    }, [setExampleIndex]);
    const handleDropdownClick = useCallback((event) => {
        setExampleIndex(examples.findIndex((example) => example.radioSelector.title === event));
    }, [setExampleIndex, examples]);
    const summaryToTraverse = useMemo(() => {
        const { summary } = examples[exampleIndex];
        return summary;
    }, [exampleIndex, examples]);
    const summaryFragment = useMemo(() => (React.createElement("div", { className: "trading-scenarios-multiple__summary" }, summaryToTraverse.map((item, i) => React.createElement("div", { key: i },
        React.createElement("div", { className: 'caption-bold color-dark-80' },
            React.createElement("span", { dangerouslySetInnerHTML: { __html: item.title } })),
        React.createElement("h5", { className: 'h5-semi-bold color-dark-default' },
            React.createElement("span", { dangerouslySetInnerHTML: { __html: item.text } })))))), [summaryToTraverse]);
    const dropdownFragment = useMemo(() => (React.createElement(Dropdown, { onSelect: handleDropdownClick, classes: "hide-large-ndb hide-medium-ndb", options: examples.map((e) => e.radioSelector.title) })), [examples, handleDropdownClick]);
    const radioFragment = useMemo(() => (React.createElement("div", { className: "trading-scenarios-multiple__radio-container" },
        React.createElement("div", { className: "centered-container trading-scenarios-multiple__radio-selector-container" },
            React.createElement("div", { className: "trading-scenarios-multiple__radio centered-container bg-color-pure-white hide-small-ndb" }, examples.map((example, i) => React.createElement("div", { key: i, onClick: handleRadioClick, "data-index": i, className: `trading-scenarios__radio-item caption centered-container ${i === exampleIndex ? "bg-color-dark-default caption-bold color-pure-white" : ""}` }, example.radioSelector.title)))),
        React.createElement("div", { className: "centered-container" },
            React.createElement("div", { className: "trading-scenarios-multiple__radio-title", dangerouslySetInnerHTML: { __html: examples[exampleIndex]?.radioSelector.radioText } })))), [exampleIndex, examples, handleRadioClick]);
    const exampleFragment = useMemo(() => (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("div", { className: "trading-scenarios-multiple__two-scenarios-div" }, examples[exampleIndex].scenarios.map((scenario, i) => React.createElement("div", { key: i, className: "trading-scenarios-multiple__scenario" },
                React.createElement("span", { className: "caption-bold" },
                    " ",
                    scenario.title),
                React.createElement("div", { className: "trading-scenarios-multiple__scenario-div bg-color-pure-white" },
                    React.createElement("div", { className: "flex trading-scenarios-multiple__two-samples" },
                        scenario.htmlContent1 && React.createElement("div", { dangerouslySetInnerHTML: { __html: scenario.htmlContent1 } }),
                        scenario.htmlContent2 && React.createElement("div", { dangerouslySetInnerHTML: { __html: scenario.htmlContent2 } })),
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: scenario.htmlContent3 } })))))))), [exampleIndex, examples]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "trading-scenarios-multiple__title centered-container" },
                React.createElement("h2", null, title),
                subtitle && React.createElement("h5", { className: "h5-semi-bold" }, subtitle)),
            React.createElement("div", { className: "trading-scenarios-multiple bg-color-neutral-accent-background" },
                React.createElement("div", { className: "trading-scenarios-multiple__dropdown-selector-container hide-large hide-medium" }, dropdownFragment),
                React.createElement("div", { className: "trading-scenarios-multiple__summary-container" }, summaryFragment),
                React.createElement("div", { className: "trading-scenarios-multiple__scenarios-container" },
                    React.createElement("div", { className: "trading-scenarios-multiple__radio-selector" }, radioFragment),
                    React.createElement("div", { className: "trading-scenarios-multiple__scenarios" }, exampleFragment))))));
};
