import React, { useState, useEffect } from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import { usePageViewStyle } from "../../hooks";
export const WhatWeOffer = (props) => {
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const [isSmallValue, setIsSmallValue] = useState(false);
    const [isMediumValue, setIsMediumValue] = useState(false);
    const [isLargeValue, setIsLargeValue] = useState(false);
    useEffect(() => {
        setIsSmallValue(isSmall);
        setIsMediumValue(isMedium);
        setIsLargeValue(isLarge);
    }, [isSmall, isMedium, isLarge]);
    const card = (i, className) => (React.createElement("div", { className: className },
        isLargeValue && React.createElement(Img, { src: props.cards[i].desktopImage, alt: props.cards[i].imageAlt }),
        isMediumValue && React.createElement(Img, { src: props.cards[i].tabletImage, alt: props.cards[i].imageAlt }),
        isSmallValue && React.createElement(Img, { src: props.cards[i].phoneImage, alt: props.cards[i].imageAlt })));
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "what-we-offer" },
                React.createElement("div", { className: "what-we-offer__title-container" },
                    React.createElement("h2", null, props.title)),
                React.createElement("div", { className: "what-we-offer__cards-container" },
                    React.createElement("div", { className: "what-we-offer__left-container" },
                        React.createElement("div", { className: "what-we-offer__up-container" },
                            React.createElement("div", { className: "what-we-offer__up-container-text" },
                                React.createElement("div", { className: "what-we-offer__up-container-text-title" },
                                    React.createElement("h4", null, props.cards[0].title)),
                                React.createElement("div", { className: "what-we-offer__up-container-text-description" },
                                    React.createElement("p", null, props.cards[0].description))),
                            card(0, "what-we-offer__up-container-img")),
                        React.createElement("div", { className: "what-we-offer__down-container" },
                            React.createElement("div", { className: "what-we-offer__down-container-left" },
                                React.createElement("div", { className: "what-we-offer__down-container-left-text" },
                                    React.createElement("div", { className: "what-we-offer__down-container-left-text-title" },
                                        React.createElement("h4", null, props.cards[1].title)),
                                    React.createElement("div", { className: "what-we-offer__down-container-left-text-description" },
                                        React.createElement("p", null, props.cards[1].description))),
                                card(1, "what-we-offer__down-container-left-img")),
                            React.createElement("div", { className: "what-we-offer__down-container-right" },
                                React.createElement("div", { className: "what-we-offer__down-container-right-text" },
                                    React.createElement("div", { className: "what-we-offer__down-container-right-text-title" },
                                        React.createElement("h4", null, props.cards[2].title)),
                                    React.createElement("div", { className: "what-we-offer__down-container-right-text-description" },
                                        React.createElement("p", null, props.cards[2].description))),
                                card(2, "what-we-offer__down-container-right-img")))),
                    React.createElement("div", { className: "what-we-offer__right-container" },
                        React.createElement("div", { className: "what-we-offer__right-container-text" },
                            React.createElement("div", { className: "what-we-offer__right-container-text-title" },
                                React.createElement("h4", null, props.cards[3].title)),
                            React.createElement("div", { className: "what-we-offer__right-container-text-description" },
                                React.createElement("p", null, props.cards[3].description))),
                        card(3, "what-we-offer__right-container-img")))))));
};
