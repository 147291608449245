import React from "react";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { AnimatedCard } from "./AnimatedCard";
import { AnimatedBigCard } from "./AnimatedBigCard";
export const SectionWithTwoAnimatedCards = ({ title, cards, backgroundColor, textAlignment }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "section-with-two-cards-animated-root", style: { backgroundColor: backgroundColor || "inherit" } },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement(Heading, { style: { "--text-alignment": `${textAlignment}` }, design: "h2-regular" }, title),
            React.createElement("div", { className: "section-with-two-cards-animated-root__bigCardWrapper" }, cards.length > 0 && cards.map((card) => card.bigCard === true && React.createElement(AnimatedBigCard, { key: `unique${card.title}`, ...card }))),
            React.createElement("div", { className: "section-with-two-cards-animated-root__cardsWrapper" }, cards.length > 0 && cards.map((card) => card.bigCard === false && React.createElement(AnimatedCard, { key: `unique${card.title}`, ...card })))))));
