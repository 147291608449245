import React, { useState, useEffect } from "react";
import { pageViewStyle } from "../../../pageViewStyle/pageViewStyle";
import { NoResults } from "../NoResults/NoResults";
import { SearchResultItem, stripHTML } from "./SearchResultItem";
import { JobBoardPagination } from "../../JobBoard/JobBoardPagination/JobBoardPagination";
import { getHighlightedText } from "../../AlphabetList";
import { SearchInput } from "../SearchInput";
const groomResultSummary = (summary, searchValue) => {
    const plainText = stripHTML(summary);
    return getHighlightedText(plainText, searchValue);
};
const calculateResultsStats = (totalResults, currentPage, resultsPerPage) => {
    const previousResultsCount = currentPage * resultsPerPage;
    const remainingResultsCount = totalResults - previousResultsCount;
    const firstResultNum = previousResultsCount + 1;
    const pageResultsCount = remainingResultsCount >= resultsPerPage ? resultsPerPage : remainingResultsCount;
    const lastResultNum = previousResultsCount + pageResultsCount;
    const totalPagesCount = Math.ceil(totalResults / resultsPerPage);
    return { firstResultNum, lastResultNum, pageResultsCount, totalPagesCount };
};
export const SearchResults = ({ title, subtitle, resultsList, onPageChange, totalResults, resultsPerPage, currentPage, searchValue, noResultsFoundText = "No results found", handleSearch, onSearchChange, inputSearchValue, searchPlaceholder, searchResultsFor }) => {
    const [view, setView] = useState(pageViewStyle.getView());
    const onViewUpdate = (view) => {
        setView(view);
    };
    useEffect(() => {
        pageViewStyle.subscribe(onViewUpdate);
        return () => {
            pageViewStyle.unsubscribe(onViewUpdate);
        };
    }, []);
    const { totalPagesCount } = calculateResultsStats(totalResults, currentPage, resultsPerPage);
    const hasResults = resultsList && resultsList.length > 0;
    return (React.createElement("div", { className: "search-results" },
        React.createElement("h1", { className: "search-results__title" }, title),
        React.createElement("div", { className: "global-search__input" },
            React.createElement("div", { className: "global-search__input-container" },
                React.createElement(SearchInput, { onSearch: handleSearch, onChange: onSearchChange, searchValue: inputSearchValue, searchPlaceholder: searchPlaceholder }))),
        React.createElement("p", { className: "search-results__subtitle" }, `${subtitle} ${totalResults} ${searchResultsFor} "${searchValue}"`),
        React.createElement("div", { className: "search-results__container" },
            React.createElement("div", { className: "search-results__list" }, hasResults ? resultsList.map(({ title, content, url }, index) => React.createElement("div", { key: index, className: "search-results__result" },
                React.createElement(SearchResultItem, { title: stripHTML(title), summary: groomResultSummary(content, searchValue), url: url }))) : React.createElement(NoResults, { noResults: noResultsFoundText })),
            totalPagesCount > 1 && React.createElement("div", { className: "search-results__pagination" },
                React.createElement(JobBoardPagination, { numOfPages: totalPagesCount, currentPage: currentPage, onPageChange: onPageChange, isSmallScreen: view !== "large" })))));
};
