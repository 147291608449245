import React from "react";
import { Img } from "../../Img";
export const InstrumentSliderCard = (props) => (React.createElement("div", { className: "instrument-slider-card" },
    React.createElement("div", { className: "instrument-slider-card__header" },
        React.createElement("div", { className: "instrument-slider-card__text" },
            React.createElement("h6", { className: "instrument-slider-card__title text-weight-bold" }, props.title),
            React.createElement("p", { className: "instrument-slider-card__description footnote" }, props.description)),
        React.createElement(Img, { className: "instrument-slider-card__img", src: props.icon, alt: props.title })),
    React.createElement("div", { className: "instrument-slider-card__footer" },
        React.createElement("a", { href: "https://web.thinktrader.com/", target: "_blank", rel: "noreferrer", className: `instrument-slider-card__price ${props.price1status}` }, props.price1),
        React.createElement("a", { href: "https://web.thinktrader.com/", target: "_blank", rel: "noreferrer", className: `instrument-slider-card__price ${props.price2status}` }, props.price2))));
