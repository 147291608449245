import React, { useState, useCallback } from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../../Components/Img";
import { IconChevronUpp } from "../svgIcons/IconChevronUpp";
import { ExternalLink } from "../svgIcons/externalLink";
export const AccessThinkPortalToday = (props) => {
    const [expandedIndex, setExpandedIndex] = useState(false);
    const [expandedIndexDesktop, setExpandedIndexDesktop] = useState(false);
    const onClickHandler = useCallback(function () {
        setExpandedIndex(!expandedIndex);
    }, [expandedIndex]);
    const onClickHandlerDesktop = useCallback(function () {
        setExpandedIndexDesktop(!expandedIndexDesktop);
    }, [expandedIndexDesktop]);
    const mobileButtonIcon = (index) => (React.createElement("div", { className: index ? "access-think-portal-today__expandableSection-open" : "access-think-portal-today__expandableSection" }, index ? React.createElement("div", { className: "access-think-portal-today__expandableSection-open-container" },
        React.createElement(Img, { src: props.mobileQRCode, alt: props.mobileQRCodeAlt }),
        React.createElement("p", null, props.buttonMobileText)) : ""));
    const desktopButtonIcon = (index) => (React.createElement("div", { className: index ? "access-think-portal-today__expandableSection-open-d" : "access-think-portal-today__expandableSection-d" }, index ? React.createElement("div", { className: "access-think-portal-today__expandableSection-open-d-buttons-container" },
        React.createElement("a", { rel: "noopener", href: props.buttonDesktopWindowsLink }, props.buttonDesktopWindowsText),
        React.createElement("a", { rel: "noopener", href: props.buttonDesktopMacLink }, props.buttonDesktopMacText)) : ""));
    const arrowIcon = (index) => (React.createElement("span", { className: index ? "access-think-portal-today__arrow-active" : "access-think-portal-today__arrow" }, IconChevronUpp()));
    const arrowIconDesktop = (index) => (React.createElement("span", { className: index ? "access-think-portal-today__arrow-active-d" : "access-think-portal-today__arrow-d" }, IconChevronUpp()));
    return (React.createElement("div", { className: "access-think-portal-today", id: "MTSection" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "access-think-portal-today__desktop" },
                    React.createElement("div", { className: "access-think-portal-today__desktop-container" },
                        React.createElement("div", { className: "access-think-portal-today__desktop-container-image" },
                            React.createElement(Img, { src: props.mainImageUrl, alt: props.mainImageAlt })),
                        React.createElement("div", { className: "access-think-portal-today__desktop-container-text" },
                            React.createElement("div", { className: "access-think-portal-today__desktop-container-title" },
                                React.createElement("h2", null, props.title)),
                            React.createElement("div", { className: "access-think-portal-today__desktop-container-description" },
                                React.createElement("p", null, props.description)),
                            React.createElement("div", { className: "access-think-portal-today__desktop-container-buttons" },
                                React.createElement("div", { className: "access-think-portal-today__desktop-container-button-m" },
                                    React.createElement("h6", { className: "h4-semi-bold", onClick: onClickHandler },
                                        React.createElement(Img, { src: props.mobileSVGIcon, alt: props.mobileSVGIconAlt }),
                                        props.buttonMobileTitle,
                                        arrowIcon(expandedIndex)),
                                    mobileButtonIcon(expandedIndex)),
                                React.createElement("div", { className: "access-think-portal-today__desktop-container-button-w" },
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.buttonWebLink },
                                        React.createElement("h6", { className: "h4-semi-bold" },
                                            React.createElement(Img, { src: props.browserSVGIcon, alt: props.browserSVGIconAlt }),
                                            props.buttonWebText,
                                            React.createElement("span", null, ExternalLink())))),
                                props.buttonDesktopText && React.createElement("div", { className: "access-think-portal-today__desktop-container-button-d" },
                                    React.createElement("h6", { className: "h4-semi-bold", onClick: onClickHandlerDesktop },
                                        React.createElement(Img, { src: props.desktopSVGIcon, alt: props.desktopSVGIconAlt }),
                                        props.buttonDesktopText,
                                        arrowIconDesktop(expandedIndexDesktop)),
                                    desktopButtonIcon(expandedIndexDesktop)))))),
                React.createElement("div", { className: "access-think-portal-today__mobile" },
                    React.createElement("div", { className: "access-think-portal-today__mobile-container" },
                        React.createElement("div", { className: "access-think-portal-today__mobile-container-text" },
                            React.createElement("h2", null, props.title),
                            React.createElement("p", null, props.description)),
                        React.createElement("div", { className: "access-think-portal-today__mobile-container-image" },
                            React.createElement(Img, { src: props.mainImageUrl, alt: props.mainImageAlt })),
                        React.createElement("div", { className: "access-think-portal-today__mobile-container-buttons" },
                            React.createElement("div", { className: "access-think-portal-today__mobile-container-button-m" },
                                React.createElement("div", { className: "access-think-portal-today__mobile-container-button-text" },
                                    React.createElement(Img, { src: props.mobileSVGIcon, alt: props.mobileSVGIconAlt }),
                                    React.createElement("h4", null, props.buttonMobileTitle)),
                                React.createElement("div", { className: "access-think-portal-today__mobile-container-button-logos" },
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.googlePlayUrl },
                                        React.createElement(Img, { src: props.googlePlayImageUrl, alt: props.googlePlayImageAlt })),
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.appStoreUrl },
                                        React.createElement(Img, { src: props.appStoreImageUrl, alt: props.appStoreImageAlt })))),
                            React.createElement("div", { className: "access-think-portal-today__mobile-container-button-w" },
                                React.createElement("h4", null,
                                    React.createElement(Img, { src: props.browserSVGIcon, alt: props.browserSVGIconAlt }),
                                    props.buttonWebText,
                                    React.createElement("div", { style: { alignSelf: "center" } },
                                        " ",
                                        ExternalLink())),
                                props.buttonWebMobileDescription && React.createElement("p", null, props.buttonWebMobileDescription)),
                            props.buttonDesktopText && React.createElement("div", { className: "access-think-portal-today__mobile-container-button-d" },
                                React.createElement("h4", null,
                                    React.createElement(Img, { src: props.desktopSVGIcon, alt: props.desktopSVGIconAlt }),
                                    props.buttonDesktopText),
                                React.createElement("p", null, props.buttonDesktopMobileDescription)))))))));
};
