import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, ContainerFW, Row } from "../../global";
import { WideRangeCard } from "./WideRangeCard/WideRangeCard";
export const WideRangeCardDeck = (props) => {
    const [cards, setCards] = useState([]);
    useEffect(() => {
        const newCards = [];
        props.cards.map((card, i) => newCards.push({ ...card, id: `${i}`, opened: false }));
        setCards(newCards);
    }, []);
    const toggleExpand = useCallback((id) => {
        setCards(cards.map(card => card.id === id ? { ...card, opened: !card.opened } : { ...card, opened: false }));
    }, [cards]);
    return (React.createElement("div", { className: "accordion-card__deck wide-range-card__deck" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("h2", { className: "accordion-card__deck-title", dangerouslySetInnerHTML: { __html: props.title } }),
                React.createElement(Row.Cols2, null, cards.map((card, i) => React.createElement(Col, { key: `accordion-card-${i}` },
                    React.createElement(WideRangeCard, { title: card.title, text: card.text, icon: card.icon, linkText: card.linkText, linkUrl: card.linkUrl, opensInNewTab: card.opensInNewTab, opened: card.opened, instruments: card.instruments, instrumentTitle: card.instrumentTitle, id: `${i}`, toggleExpand: toggleExpand }))))))));
};
