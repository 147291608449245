import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
import { getColors } from "../../../utils/getColors";
import { Heading } from "../../Heading";
export const ThinkTraderAccountBanner = ({ title, description, imageUrl, imageAlt, list, buttonLink, buttonText, openInNewTab, backgroundColor, linkButtonLabel, linkButtonUrl }) => {
    const { bgColor } = getColors(backgroundColor);
    const standardString = React.useCallback((value) => {
        if (value.includes("<br />")) {
            return value.replace("<br />", "<span class=\"special-symbol\">  |  </span>");
        }
        return value;
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "thinktrader-account-banner" },
                React.createElement("div", { className: "thinktrader-account-banner__text-wrapper" },
                    React.createElement("div", { className: "thinktrader-account-banner__h1-h5-wrapper" },
                        React.createElement(Heading, { design: "h1-regular" }, title),
                        React.createElement(Heading, { design: "h5-semi-bold" }, description)),
                    React.createElement("div", { className: "thinktrader-account-banner__options-wrapper" }, list && list.length > 0 && list.map((item, i) => (React.createElement("div", { key: i, className: "thinktrader-account-banner__option" },
                        React.createElement("p", { className: "thinktrader-account-banner__option__p-title" }, item.itemTitle),
                        React.createElement("div", { className: "thinktrader-account-banner__option__p-subtitle", dangerouslySetInnerHTML: {
                                __html: standardString(item.itemSubTitle) || ""
                            } }))))),
                    React.createElement("div", { className: "thinktrader-account-banner__button-wrapper" },
                        React.createElement(ButtonV2, { design: "tmx25-round-green-lightgreen", href: buttonLink, openInNewTab: openInNewTab, showArrowRight: true }, buttonText),
                        linkButtonLabel && linkButtonUrl && React.createElement("a", { className: "link-button", rel: "noreferrer", target: "_blank", href: linkButtonUrl }, linkButtonLabel))),
                React.createElement("div", { className: "thinktrader-account-banner__image-wrapper" },
                    React.createElement(ImgV2, { src: imageUrl, alt: imageAlt, loading: "eager" }))))));
};
