import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
export const SimpleTitleAndSubtitle = ({ title, subTitle, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "simple-title-subtitle-root" },
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("p", { className: "copy-lp", dangerouslySetInnerHTML: {
                        __html: subTitle || "",
                    } })))));
};
