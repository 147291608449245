import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { TheCard } from "./TheCard/TheCard";
import { getColors } from "../../../utils/getColors";
export const ThreeCardsLP = ({ title, content, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "three-cards-lp" },
                React.createElement("div", { className: "three-cards-lp__title-container" }, title && React.createElement("h1", null, title)),
                React.createElement("div", { className: "three-cards-lp__cards-container" }, content.map((card, i) => React.createElement(TheCard, { key: i, cardTitle: card.cardTitle, cardPlace: card.cardPlace, iconPlace: card.iconPlace, listItems: card.listItems })))))));
};
