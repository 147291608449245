import React from "react";
import { NumberedSwipeCard_AppDownload } from "./variants";
import { Img } from "../../../Img";
export var NumberedSwipeCardVariant;
(function (NumberedSwipeCardVariant) {
    NumberedSwipeCardVariant["APP_DOWNLOAD"] = "app-download";
})(NumberedSwipeCardVariant || (NumberedSwipeCardVariant = {}));
const cardVariantComponents = {
    [NumberedSwipeCardVariant.APP_DOWNLOAD]: NumberedSwipeCard_AppDownload,
};
export const NumberedSwipeCard = (props) => {
    const CardVariantComponent = props.cardVariant ? cardVariantComponents["app-download"] : undefined;
    return (React.createElement("section", { className: "numbered-swipe-card" },
        React.createElement("span", { className: "numbered-swipe-card__number" }, props.number),
        React.createElement("h2", null, props.title),
        React.createElement("p", { dangerouslySetInnerHTML: { __html: props.text } }),
        React.createElement(Img, { className: "numbered-swipe-card__image", src: props.imageUrl, alt: props.imageAlt }),
        CardVariantComponent && props.cardVariantProps?.[0] && React.createElement(CardVariantComponent, { ...props.cardVariantProps[0] })));
};
