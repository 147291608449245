import { useState } from "react";
export const usePagination = (items, itemsPerPage) => {
    const [currentPage, setCurrentPage] = useState(0);
    const currentPageItems = items.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const numOfPages = Math.ceil(items.length / itemsPerPage);
    return {
        itemsPerPage,
        currentPage,
        currentPageItems,
        numOfPages,
        setCurrentPage
    };
};
