import React, { useState, useEffect, useRef } from "react";
import { Container, ContainerFW } from "../../global";
import { pageViewStyle } from "../../../pageViewStyle";
import { SellBuyPriceCard } from "./SellBuyPriceCard/SellBuyPriceCard";
import { getColors } from "../../../utils";
import pako from "pako";
export const SellBuyPriceCardsDeck = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    const [instruments, setInstruments] = useState([]);
    const [view, setView] = useState(pageViewStyle.getView());
    const prevAskPrices = useRef(new Map());
    const prevBidPrices = useRef(new Map());
    useEffect(() => {
        !view && setView(pageViewStyle.getView());
        const websocket = new WebSocket(`${props.wsUrl}/price-feed/${props.session}`);
        websocket.binaryType = "arraybuffer";
        const pingRequest = new TextEncoder().encode("ping");
        let interval;
        websocket.onopen = () => {
            interval = setInterval(() => {
                websocket.send(pingRequest);
            }, 1000);
        };
        websocket.onmessage = (event) => {
            const data = event.data;
            const message = pako.inflate(data, { to: "string" });
            updatePriceFeed(message);
        };
        return () => {
            clearInterval(interval);
            websocket.close();
        };
    }, []);
    const updatePriceFeed = (message) => {
        const dict = JSON.parse(message);
        const instruments = [];
        for (const key in dict) {
            if (key.includes("quote")) {
                const group = key.split("_")[0];
                const parsedValues = JSON.parse(dict[key]);
                const n = JSON.parse(dict[`${group}_marketItem_${key.split("_")[2]}`]).Name;
                const dailyChange = calcDailyChange(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict);
                const prevBid = prevBidPrices.current.get(parsedValues.Code) ?? 0;
                const prevAsk = prevAskPrices.current.get(parsedValues.Code) ?? 0;
                const instrument = {
                    name: n,
                    code: parsedValues.Code,
                    groupCode: group,
                    bid: parsedValues.Bid,
                    bidStyle: bidAskStyle(parsedValues.Bid, prevBid),
                    bidIcon: "",
                    ask: parsedValues.Ask,
                    askStyle: bidAskStyle(parsedValues.Ask, prevAsk),
                    askIcon: "",
                    spread: calcSpread(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict),
                    dailyChange,
                    dailyChangeStyle: dailyChangeStyle(+dailyChange),
                    dailyChangeIcon: "",
                };
                prevAskPrices.current.set(parsedValues.Code, parsedValues.Ask);
                prevBidPrices.current.set(parsedValues.Code, parsedValues.Bid);
                instruments.push(instrument);
            }
        }
        const newInstruments = instruments.filter(instrument => props.instrumentsToShow.map(el => el.title).includes(instrument.code)).filter((v, i, a) => a.findIndex(v2 => (v2.code === v.code)) === i);
        setInstruments(newInstruments);
    };
    const calcSpread = (group, code, bid, ask, dict) => {
        const marketItem = JSON.parse(dict[`${group}_marketItem_${code}`]);
        let decimalPrecision = 0;
        let tickSize = 0;
        if (dict[`${group}_marketItemInfo_${code}`]) {
            const marketItemInfo = JSON.parse(dict[`${group}_marketItemInfo_${code}`]);
            decimalPrecision =
                marketItemInfo.DecimalPrecision ?? marketItem.DecimalPrecision;
            tickSize = marketItemInfo.TickSize ?? marketItem.TickSize;
        }
        else {
            decimalPrecision = marketItem.DecimalPrecision;
            tickSize = marketItem.TickSize;
        }
        let decimal = tickSize >= 1.0 ? 0 : decimalPrecision;
        decimal = decimal > 2 ? decimal - 1 : decimal;
        const spread = ((ask - bid) * Math.pow(10, decimal)).toFixed(1);
        return spread;
    };
    const calcDailyChange = (group, code, bid, ask, dict) => {
        if (dict[`${group}_prevDayClosePrice_${code}`]) {
            const parsedValues = JSON.parse(dict[`${group}_prevDayClosePrice_${code}`]);
            const prevClose = parsedValues.PrevClose ?? 0;
            const averageCurrentPrice = (ask + bid) / 2;
            const dailyValue = averageCurrentPrice - prevClose;
            const dailyPercent = ((dailyValue / prevClose) * 100).toFixed(2);
            return `${dailyPercent}%`;
        }
        return "N/A";
    };
    const bidAskStyle = (currentPrice, previousPrice) => {
        if (currentPrice >= previousPrice) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    const dailyChangeStyle = (value) => {
        if (value >= 0) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: "sell-buy-price-cards-deck" },
                React.createElement("div", { className: "sell-buy-price-cards-deck__text-wrapper" },
                    props.title && React.createElement("h2", null, props.title),
                    props.subtitle && React.createElement("p", { className: "sell-buy-price-cards-deck__text-subtitle", dangerouslySetInnerHTML: { __html: props.subtitle } })),
                React.createElement("div", { className: "sell-buy-price-cards-deck__cards-wrapper" }, instruments.length ? instruments.map((item, i) => React.createElement(SellBuyPriceCard, { key: `instrument-${i}`, title: item.code, icon: props.instrumentsToShow.find(el => el.title === item.code)?.icon || "", alt: item.code, price1: `${item.bid}`, price2: `${item.ask}`, price1status: item.bidStyle, price2status: item.askStyle, buyLabel: props.instrumentsToShow[i].buyLabel, sellLabel: props.instrumentsToShow[i].sellLabel, instrumentUrl: "https://web.thinktrader.com/account/login" })) : ""),
                props.nodeText && React.createElement("div", { className: "sell-buy-price-cards-deck__free-node" },
                    React.createElement("p", null, props.nodeText))))));
};
