import * as React from "react";
import { pageViewStyle } from "../../pageViewStyle";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
export const PopularQuestions = (props) => {
    const view = pageViewStyle.getView();
    return (React.createElement("div", { className: "popularQuestions" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "popularQuestions__container" },
                    React.createElement("div", { className: "popularQuestions__text" },
                        React.createElement("div", { className: "popularQuestions__title" },
                            React.createElement("h2", null, props.title)),
                        view === "small" || view === "medium" && React.createElement("div", { className: "popularQuestions__image" },
                            React.createElement(Img, { src: props.imageUrl, alt: props.imageAlt })),
                        props.children ? props.children :
                            React.createElement("div", { className: "popularQuestions__list-items" },
                                React.createElement("ul", null, props.items.map((item, index) => (React.createElement("li", { key: index, className: "popularQuestions__list-item" },
                                    React.createElement("a", { className: "popularQuestions__list-item-link", href: item.itemLink }, item.item))))))),
                    view === "large" && React.createElement("div", { className: "popularQuestions__image" },
                        React.createElement(Img, { src: props.imageUrl, alt: props.imageAlt })))))));
};
