import * as React from "react";
import { caretIcon } from "../Cards/AccordionCardDeck/AccordionCard";
import { arrowIcon } from "../Cards/InformationCardDarkDeck/InformationCardDark";
import { ContainerFW } from "../global";
import { Container } from "../global";
export const SupportSection = (props) => {
    const [activeMenuItem, setActiveMenuItem] = React.useState();
    const activeMenuItemHandler = (title) => React.useCallback(() => {
        if (activeMenuItem === title) {
            setActiveMenuItem(undefined);
        }
        else {
            setActiveMenuItem(title);
        }
    }, [activeMenuItem]);
    React.useEffect(() => {
        const activeItem = props.sidebar.map(el => el.links.filter(link => link.title === props.title).length ? el : undefined).filter(e => e !== undefined)[0]?.title;
        setActiveMenuItem(activeItem);
    }, []);
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "support-section" },
            React.createElement(Container, null,
                React.createElement("div", { className: "support-section__tab" },
                    React.createElement("div", { className: "support-section__content" },
                        props.backLinkText && React.createElement("a", { href: props.backLinkUrl, className: "faq-accordion__link support-section__link" },
                            arrowIcon(),
                            " ",
                            props.backLinkText,
                            " "),
                        props.children ? props.children : React.createElement(React.Fragment, null,
                            React.createElement("h2", { className: "support-section__title" }, props.title),
                            React.createElement("div", { className: "support-section__text", dangerouslySetInnerHTML: { __html: props.content } }))),
                    React.createElement("ul", { className: "support-section__navigation" }, props.sidebar.map((item, id) => (React.createElement("li", { key: `support-section__item-${id}`, className: `support-section__item ${activeMenuItem === item.title ? "active" : ""}` },
                        React.createElement("h5", { onClick: activeMenuItemHandler(item.title), className: "support-section__item-title" },
                            item.title,
                            " ",
                            caretIcon()),
                        React.createElement("div", { className: "support-section__list" }, item.links.map((listItem, i) => (React.createElement("p", { key: `support-section__list-item-${i}`, className: `support-section__list-item ${props.title === listItem.title ? "active" : ""}` },
                            React.createElement("a", { href: listItem.url }, listItem.title))))))))))))));
};
