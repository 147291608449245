import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
export const RibbonWithStatLine = ({ buttonHref, buttonLabel, opensInNewTab, subTitle, title, imageUrl, backgroundColor, buttonHoverColor, buttonHoverTextColor }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor ? backgroundColor : "inherit" },
    React.createElement("div", { className: "ribbon-with-stat-line-root" },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "ribbon-with-stat-line-root__contentWrapper" },
                React.createElement("div", { className: "ribbon-with-stat-line-root__contentWrapper__textWrapper" },
                    React.createElement(Heading, { design: "h3-bold" },
                        React.createElement("span", { dangerouslySetInnerHTML: {
                                __html: title,
                            } })),
                    React.createElement("div", { className: "ribbon-with-stat-line-root__contentWrapper__subtitle", dangerouslySetInnerHTML: {
                            __html: subTitle,
                        } })),
                React.createElement(ButtonV2, { href: buttonHref, design: "secondary-medium-wbg", style: { "--button-hover-color": `${buttonHoverColor}`, "--button-hover-text-color": `${buttonHoverTextColor}` }, openInNewTab: opensInNewTab }, buttonLabel)),
            React.createElement("div", { className: "ribbon-with-stat-line-root__bg-image", style: { "--background-image-url": `url(${imageUrl})` } })))));
