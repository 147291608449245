import React, { useState, useCallback } from "react";
import { NewsCardDeck } from "../Cards";
import { usePageViewStyle, usePagination } from "../../hooks";
import { Heading } from "../Heading";
import { Col, Container, ContainerFW } from "../global";
import { AcademyArticleCard } from "../Cards/AcademyArticleCardDeck/AcademyArticleCard/AcademyArticleCard";
import { JobBoardPagination } from "../JobBoard/JobBoardPagination/JobBoardPagination";
export const ProfileArticles = ({ academyCards, newsCards, filterOptions }) => {
    const [selectedFilterOption, setSelectedFilterOption] = useState(newsCards.length ? 0 : 1);
    const { viewStyle: view } = usePageViewStyle();
    const setSelectedFilterOptionCallback = useCallback((event) => {
        setSelectedFilterOption(event.currentTarget.dataset.option * 1);
        setCurrentPage(0);
    }, []);
    const renderFilterRadio = useCallback(() => (React.createElement(React.Fragment, null, (newsCards.length && academyCards.cards.length && filterOptions) ? React.createElement("div", { className: "academyArticleCardDeck__radio" }, filterOptions?.map((option, i) => (React.createElement("div", { key: i, className: `academyArticleCardDeck__radio-option centered-container p-regular ${selectedFilterOption === i ? "active bg-color-dark-default" : ""}`, onClick: setSelectedFilterOptionCallback, "data-option": i }, option)))) : "")), [filterOptions, selectedFilterOption, setSelectedFilterOptionCallback, newsCards, academyCards]);
    const { currentPage, currentPageItems, numOfPages, setCurrentPage } = usePagination(selectedFilterOption ? academyCards.cards : newsCards, 6);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "profile-articles" },
                React.createElement("div", { className: "profile-articles__header" },
                    React.createElement(Heading, { design: "h4-bold-small" },
                        filterOptions[selectedFilterOption],
                        " (",
                        selectedFilterOption === 0 ? newsCards.length : academyCards.cards.length,
                        ")"),
                    renderFilterRadio()),
                selectedFilterOption === 0 ?
                    React.createElement(NewsCardDeck, { noWidgets: true, isListView: true, currentPage: currentPage, currentPageItems: currentPageItems, numOfPages: numOfPages, setCurrentPage: setCurrentPage, ...newsCards }) :
                    React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "academyArticleCardDeck__cards-grid" }, currentPageItems.map((card, i) => (React.createElement(Col, { key: i },
                            React.createElement(AcademyArticleCard, { articleReadingTimeTimeUnit: academyCards.articleReadingTimeTimeUnit, categoryLinks: academyCards.categoryLinks, culture: academyCards.culture, ...card, isListView: academyCards.isListView, cultureCode: academyCards.cultureCode }))))),
                        React.createElement("div", { className: "jobBoard__pagination" },
                            React.createElement(JobBoardPagination, { currentPage: currentPage, numOfPages: numOfPages, setCurrentPage: setCurrentPage, isSmallScreen: view !== "large" })))))));
};
