import React from "react";
import { Img } from "../../../Img";
export const InformationCardDark = (props) => (React.createElement("div", { className: "informationCardDark" },
    React.createElement(Img, { className: "informationCardDark__img", src: props.backgroundImgURL, alt: props.alt }),
    React.createElement("div", { className: "informationCardDark__text" },
        React.createElement("h3", { className: "informationCardDark__title" }, props.title),
        React.createElement("div", { className: "informationCardDark__description" }, props.description)),
    React.createElement("div", { className: "informationCardDark__link" },
        React.createElement("div", { className: "informationCardDark__link--text" },
            React.createElement("a", { href: props.linkUrl }, props.linkText),
            React.createElement("div", { className: "informationCardDark__link--icon" }, arrowIcon())))));
export const arrowIcon = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "#fff", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#6cv15a3sra)" },
        React.createElement("path", { d: "m16.172 11-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2h12.172z", fill: "inherit" })),
    React.createElement("defs", null,
        React.createElement("clipPath", null,
            React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
