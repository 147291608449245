import React, { useCallback } from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils";
import { Heading } from "../Heading";
import { webIcon } from "../svgIcons/webIcon";
import { mobileIcon } from "../svgIcons/mobileIcon";
export const CardsGridWithFullWidthCenteredImagesAndTags = ({ title, cards, designStyles, columns }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    const renderCard = useCallback((card) => (React.createElement("div", { className: `${columns === "4" ? "cards-grid-with-full-width-centered-images-and-tags__card col-4-card" : "cards-grid-with-full-width-centered-images-and-tags__card col-3-card"}` },
        React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags__top-end mb-20" },
            React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags__image-container", style: { backgroundColor: card.cardBackgroundColor } },
                card.tags && React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags__tags-strip" },
                    React.createElement("span", { className: "cards-grid-with-full-width-centered-images-and-tags__text-befor-tags" }, card.textBeforeTags),
                    card.tags.split("|")?.map((tag, i) => (React.createElement("div", { key: i, className: "cards-grid-with-full-width-centered-images-and-tags__tag centered-container" },
                        tag === "Web" ? webIcon : mobileIcon,
                        tag)))),
                React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags__image-container__imageWrapper" },
                    React.createElement("img", { className: "cards-grid-with-full-width-centered-images-and-tags__image-container__imageWrapper__image", src: card.imageUrl, alt: card.imageAlt })))),
        React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags__bottom-end" },
            React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags__generic-title-description" },
                React.createElement("h5", { className: "h5-lp mb-12" }, card.title),
                React.createElement("p", { className: "copy-lp" }, card.description))))), []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { style: { color: textColor }, className: "cards-grid-with-full-width-centered-images-and-tags" },
                React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags__title-container mb-40" },
                    React.createElement(Heading, { design: designStyles?.titleDesign }, title)),
                React.createElement("div", { className: `${columns === "4" ? "cards-grid-with-full-width-centered-images-and-tags__cards-container col-4" : "cards-grid-with-full-width-centered-images-and-tags__cards-container col-3"}` }, cards.map(c => renderCard(c)))))));
};
