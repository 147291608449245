import * as React from "react";
import DatePicker from "react-datepicker";
export const CheckboxesDatepicker = (props) => {
    const [options, setOptions] = React.useState("");
    const [checked, setChecked] = React.useState({});
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    React.useEffect(() => {
        props.handler(props.title.replaceAll(" ", ""), mapStringsToDates(options));
    }, [options]);
    React.useEffect(() => {
        toggleAll(false);
    }, [props.reset]);
    const mapStringsToDates = (str) => {
        let q = "";
        const today = new Date();
        switch (str.replace(",", "")) {
            case "Today":
                q = `startdate-${today.toISOString().slice(0, 10)},enddate-${new Date().toISOString().slice(0, 10)}`;
                break;
            case "Yesterday":
                q = `startdate-${new Date(today.setDate(today.getDate() - 1)).toISOString().slice(0, 10)},enddate-${new Date().toISOString().slice(0, 10)}`;
                break;
            case "This week":
                q = `startdate-${new Date(today.setDate(today.getDate() - 7)).toISOString().slice(0, 10)},enddate-${new Date().toISOString().slice(0, 10)}`;
                break;
            case "This month":
                q = `startdate-${new Date(today.setDate(today.getDate() - 30)).toISOString().slice(0, 10)},enddate-${new Date().toISOString().slice(0, 10)}`;
                break;
            case "All time":
                q = "startdate-,enddate-";
                break;
            default:
                q = str;
        }
        return q;
    };
    function updateCheckedValues(obj, updater) {
        const objectForUpdate = obj;
        for (const key in objectForUpdate) {
            if (objectForUpdate.hasOwnProperty(key)) {
                objectForUpdate[key] = updater(objectForUpdate[key]);
            }
        }
        return objectForUpdate;
    }
    function dateString(date, prefix) {
        return `${prefix}${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    }
    const toggleAll = (val) => {
        const initialCheckedState = {};
        props.items.forEach(item => initialCheckedState[item.categoryId] = val);
        setChecked(initialCheckedState);
        setOptions("");
    };
    const fieldHandler = (val, itemChecked) => React.useCallback(() => {
        const updateAll = updateCheckedValues(checked, (e) => e = false);
        const updatedChecked = itemChecked ? updateAll : { ...updateAll, [val]: true };
        setChecked(prevChecked => ({ ...prevChecked, ...updatedChecked }));
        val === "Customised" ?
            setOptions(prevCheckboxes => prevCheckboxes.includes("date") ? "" : `startdate-${new Date().toISOString().slice(0, 10)},enddate-${new Date().toISOString().slice(0, 10)}`)
            : setOptions(prevCheckboxes => prevCheckboxes.includes(`${val}`) ? prevCheckboxes.replace(`${val},`, "") : `${val},`);
    }, [options, checked]);
    const dateHandler = (type) => React.useCallback((date) => {
        const startPrefix = "startdate-";
        const endPrefix = "enddate-";
        if (type === "start") {
            setStartDate(date);
            setOptions(`${dateString(date, startPrefix)},${dateString(endDate, endPrefix)},`);
        }
        else {
            setOptions(`${dateString(startDate, startPrefix)},${dateString(date, endPrefix)},`);
            setEndDate(date);
        }
    }, [startDate, endDate, options]);
    const inlineClass = props.inlineLayout ? "inline" : "";
    return (React.createElement("div", { className: `filters__field-radios ${inlineClass}` }, props.items.map((item, id) => (React.createElement("div", { key: `radio-${props.id}-${id}`, className: `filters__radio ${inlineClass} ${item.categoryTitle === "Customised" ? "datepicker-box" : ""}` },
        React.createElement("input", { checked: checked[item.categoryId] || false, onChange: fieldHandler(item.categoryId, checked[item.categoryId]), type: "radio", name: item.categoryId, id: `radio-${props.id}-${id}` }),
        React.createElement("label", { htmlFor: `radio-${props.id}-${id}` }),
        item.categoryTitle,
        item.categoryTitle === "Customised" &&
            React.createElement("div", { className: "datepickers" },
                React.createElement(DatePicker, { disabled: !checked["Customised"], selected: startDate, onChange: dateHandler("start"), selectsStart: true, startDate: startDate, maxDate: endDate }),
                React.createElement(DatePicker, { disabled: !checked["Customised"], selected: endDate, onChange: dateHandler("end"), selectsEnd: true, startDate: endDate, minDate: startDate })))))));
};
