import React from "react";
import { Container, ContainerFW } from "../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import { TabWithInstrumentsAndTable } from "./TabWithInstrumentsAndTable";
export const TabsWithInstrumentsAndTable = ({ tabs, instrumentsSectionTitle }) => {
    const renderTabControls = (index, className) => `<li class="${className}">${tabs[index].tabName}</li>`;
    return (React.createElement(ContainerFW, { className: "tabs-with-instruments-and-table__container" },
        React.createElement(Container, { className: "tabs-with-instruments-and-table" },
            React.createElement("ul", { className: "tabs-with-instruments-and-table__tab-controls" }),
            React.createElement(Swiper, { pagination: {
                    el: ".tabs-with-instruments-and-table__tab-controls",
                    clickable: true,
                    renderBullet: renderTabControls
                }, modules: [EffectFade], className: "careerTabsSlider__swiper", autoHeight: true, allowTouchMove: false }, Object.values(tabs).map((tabData, index) => React.createElement(SwiperSlide, { key: index },
                React.createElement(TabWithInstrumentsAndTable, { key: index, instruments: tabData.instruments, tableData: tabData.tableData, instrumentsSectionTitle: instrumentsSectionTitle })))))));
};
