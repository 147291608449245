import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
export const SectionWithGradientAndImage = ({ title, subTitle, backGroundImage, buttonImage, backGroundColor }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "section-with-gradient-and-image__root" },
                React.createElement("div", { className: "section-with-gradient-and-image__root__gradient-background", style: { "--background-image-url": `url(${backGroundImage})` } }),
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("div", { className: "p-regular", dangerouslySetInnerHTML: {
                        __html: subTitle || "",
                    } }),
                React.createElement("div", { className: "section-with-gradient-and-image__root__gradient-image" },
                    React.createElement(ImgV2, { src: buttonImage }))))));
};
