import React, { useCallback, useState } from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { SearchInput } from "../GlobalSearch/SearchInput";
import { getColors } from "../../utils/getColors";
import { IconChevronRightSupport } from "../svgIcons/chevronRight-support";
export const TitleSubtitleAndSearch = ({ subTitle, title, placeholder, iconImg, withFiltersActive, withOrWithoutFilterButton, backgroundColor, breadcrumbs }) => {
    const [inputValue, setInputValue] = useState("");
    const handleSearchOnChange = useCallback((event) => {
        setInputValue(event.target.value);
    }, []);
    const handleOnSearch = useCallback((event) => {
        if (event.key === "Enter") {
            setInputValue("");
        }
    }, []);
    const handleOnClick = useCallback((event) => {
        event.preventDefault();
    }, []);
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "title-subtitle-and-search-root" },
            breadcrumbs && React.createElement("div", null,
                React.createElement(Container, null,
                    React.createElement("div", { className: "title-subtitle-and-search-root__breadcrumbs" },
                        React.createElement("a", { href: "/" }, homeIcon()),
                        breadcrumbs.map((item, index) => {
                            if (index + 1 === breadcrumbs?.length) {
                                return (React.createElement("span", { key: index, className: "title-subtitle-and-search-root__breadcrumb" },
                                    IconChevronRightSupport,
                                    " ",
                                    item.breadcrumbText));
                            }
                            else {
                                return (React.createElement("a", { key: index, className: "title-subtitle-and-search-root__breadcrumb", href: item.breadcrumbLink },
                                    IconChevronRightSupport,
                                    " ",
                                    item.breadcrumbText));
                            }
                        })))),
            React.createElement(Container, null,
                React.createElement("div", { className: "title-subtitle-and-search-root__titlesWrapper" },
                    title && React.createElement("h1", null, title),
                    subTitle && React.createElement("h5", null, subTitle)),
                React.createElement("div", { className: "title-subtitle-and-search-root__inputsWrapper" },
                    React.createElement(SearchInput, { searchValue: inputValue, onChange: handleSearchOnChange, onSearch: handleOnSearch, searchPlaceholder: placeholder }),
                    withOrWithoutFilterButton && React.createElement("div", { className: "title-subtitle-and-search-root__inputsWrapper__buttonWrapper" },
                        React.createElement(ButtonV2, { icon: iconImg, design: "ghost-medium-wbg", onClick: handleOnClick },
                            React.createElement("p", { className: `${withFiltersActive && "with-filters-mark"}` }, "Filters"))))))));
};
const homeIcon = () => (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.33741 3.2295C7.72697 2.93131 8.27305 2.93131 8.66261 3.2295L8.6632 3.22995L13.5399 6.97995C13.9127 7.26676 14.0587 7.74653 13.9052 8.19265C13.7532 8.63446 13.3485 8.93086 12.8767 8.93086H12.7167V11.7909C12.7167 12.455 12.1774 12.9942 11.5133 12.9942H9.08001C8.71182 12.9942 8.41334 12.6957 8.41334 12.3275V10.9442H7.49668V12.3275C7.49668 12.6957 7.1982 12.9942 6.83001 12.9942H4.61668C3.95258 12.9942 3.41334 12.455 3.41334 11.7909V8.93086H3.12334C2.65202 8.93086 2.24364 8.64116 2.09412 8.19062C1.94175 7.74508 2.08782 7.26636 2.46011 6.97998L7.33682 3.22995L7.33741 3.2295ZM3.25509 7.79753L7.99834 4.15011L12.7416 7.79753H11.58V11.7909C11.58 11.8301 11.5492 11.8609 11.51 11.8609H9.54668V10.7875C9.54668 10.2501 9.10744 9.81086 8.57001 9.81086H7.34001C6.80258 9.81086 6.36334 10.2501 6.36334 10.7875V11.8609H4.61668C4.57744 11.8609 4.54668 11.7909 4.54668 11.7909L4.55 7.79753H3.25509Z", fill: "#0E1D31" })));
