import * as React from "react";
import { ArticleBanner } from "../Banners";
import { Container, ContainerFW } from "../global";
import { FaqAccordionList } from "../FaqAccordionList";
export const Article = (props) => {
    const [h2Tags, setH2tags] = React.useState();
    const [loaded, setLoaded] = React.useState(false);
    const articleContainerRef = React.useRef(null);
    const [scrollTop, setScrollTop] = React.useState(0);
    const onScroll = () => {
        if (articleContainerRef && articleContainerRef.current) {
            const winScroll = document.documentElement.scrollTop;
            const height = articleContainerRef.current.scrollHeight - document.documentElement.clientHeight;
            const scrolled = (winScroll / height) * 100;
            setScrollTop(scrolled);
        }
    };
    const getMonth = function (d) {
        const locale = document.documentElement.lang, month = d.toLocaleString(locale, { month: "long" });
        return month;
    };
    React.useEffect(() => {
        const htmlToParse = props.faqTitle
            ? `${props.content}<h2>${props.faqTitle}</h2>`
            : `${props.content}`;
        const dom = parseHTML(htmlToParse);
        dom.querySelectorAll("h2");
        setH2tags([...dom.querySelectorAll("h2")]);
        setLoaded(true);
        window.addEventListener("scroll", onScroll);
    }, [props.content, props.faqTitle]);
    const accordion = () => props.faqItems.length ? (React.createElement(FaqAccordionList, { title: props.faqTitle, iconAlt: props.faqLinkText, iconImage: props.iconImage, moreQuestions: props.moreQuestions, items: props.faqItems, moreQuestionsLinkText: props.faqLinkText, moreQuestionsLinkUrl: props.faqLinkUrl })) : "";
    const date = new Date(props.publishDate);
    const published = () => React.createElement(React.Fragment, null,
        props.publishedOn,
        " ",
        getMonth(date),
        " ",
        date.getDate(),
        ", ",
        date.getFullYear(),
        " ",
        props.by,
        " ",
        props.authorUrl ? React.createElement("a", { href: props.authorUrl, className: "color-dark-60" }, props.author) : props.author);
    return (React.createElement(ContainerFW, null,
        scrollTop <= 100 && React.createElement("div", { className: "progressbar" },
            React.createElement("div", { className: "progressbar__marker", style: { width: `${scrollTop}%` } })),
        React.createElement("div", { className: "article" },
            React.createElement(ArticleBanner, { image: props.imageUrl, title: props.title, breadcrumbs: props.breadcrumbs, categories: props.category.split("|"), categoryLinks: props.categoryLinks, culture: props.culture, difficulty: props.difficulty, difficultyColor: props.difficultyColor }, published()),
            React.createElement(Container, null,
                React.createElement("div", { className: "article__container", ref: articleContainerRef },
                    (h2Tags && h2Tags.length >= 3) && (React.createElement("div", { className: "contents-table" },
                        React.createElement("h4", { className: "h4-bold" }, props.tableOfContentsTitle),
                        React.createElement("ul", null, h2Tags.map((item, i) => (React.createElement(SidebarItem, { key: `contents-table-item-${i}`, title: item.innerText })))))),
                    React.createElement("div", { className: "article__content", dangerouslySetInnerHTML: {
                            __html: loaded ? props.content : "",
                        } }),
                    accordion(),
                    props.disclaimerText && React.createElement("section", { className: "article__disclaimerText" }, props.disclaimerText))))));
};
const SidebarItem = React.forwardRef((props, ref) => {
    const scrollToHeading = React.useCallback(() => {
        [...document.querySelectorAll(".article h2")].find(el => el.textContent?.includes(props.title))?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [props.title]);
    return (React.createElement("li", { onClick: scrollToHeading }, props.title));
});
SidebarItem.displayName = "SidebarItem";
function parseHTML(html) {
    const t = document.createElement("template");
    t.innerHTML = html;
    return t.content;
}
