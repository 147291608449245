import React from "react";
export const MultiplesSectorsIcon = ({ fill = "none", height = "48", width = "48" }) => (React.createElement("svg", { height: height, width: width, fill: fill, viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_21472_55574)" },
        React.createElement("path", { d: "M36.0002 6.00134H32.3082C28.9947 6.00134 26.3086 8.68744 26.3086 12.0009V15.693C26.3086 19.0064 28.9947 21.6925 32.3082 21.6925H36.0002C39.3137 21.6925 41.9998 19.0064 41.9998 15.693V12.0009C41.9998 8.68744 39.3137 6.00134 36.0002 6.00134Z", fill: "#CFD2D6" }),
        React.createElement("path", { d: "M36.0002 26.3076H32.3082C28.9947 26.3076 26.3086 28.9937 26.3086 32.3072V35.9992C26.3086 39.3127 28.9947 41.9988 32.3082 41.9988H36.0002C39.3137 41.9988 41.9998 39.3127 41.9998 35.9992V32.3072C41.9998 28.9937 39.3137 26.3076 36.0002 26.3076Z", fill: "#CFD2D6" }),
        React.createElement("path", { d: "M15.6936 6.00134H12.0015C8.68805 6.00134 6.00195 8.68744 6.00195 12.0009V15.693C6.00195 19.0064 8.68805 21.6925 12.0015 21.6925H15.6936C19.007 21.6925 21.6931 19.0064 21.6931 15.693V12.0009C21.6931 8.68744 19.007 6.00134 15.6936 6.00134Z", fill: "#CFD2D6" }),
        React.createElement("path", { d: "M14.7707 26.3075C10.9495 26.3075 7.84815 29.4088 7.84815 33.2301C7.84815 34.6331 8.27273 35.9438 8.99268 37.0329L6.38979 39.6358C5.85444 40.1711 5.85444 41.0572 6.38979 41.5926C6.66669 41.8695 7.01744 41.9987 7.36818 41.9987C7.71893 41.9987 8.06967 41.8695 8.34657 41.5926L10.9495 38.9897C12.0386 39.7096 13.3493 40.1342 14.7523 40.1342C18.5735 40.1342 21.6748 37.0329 21.6748 33.2116C21.6748 29.3904 18.5735 26.2891 14.7523 26.2891L14.7707 26.3075Z", fill: "#0E1D31" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_21472_55574" },
            React.createElement("rect", { height: "36", width: "36", fill: "white", transform: "translate(6 6)" })))));
