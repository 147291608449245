import React from "react";
import { Container, ContainerFW } from "../global";
import { TrustpilotCard } from "../TrustpilotSlider/TrustpilotCard";
import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ButtonV2 } from "../ButtonV2";
import { Img } from "../Img";
export const TrustPilotOpinionLP = ({ cards, mainTitle, buttonHref, buttonLabel, buttonImage, backgroundColor, openInNewTabButton, buttonHref2, buttonLabel2, buttonImage2, openInNewTabButton2 }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor ? backgroundColor : "inherit" },
    React.createElement("div", { className: "trust-pilot-opinion-LP-root" },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("h3", null, mainTitle),
            React.createElement("div", { className: "trust-pilot-opinion-LP-root__swiperWrapper" },
                React.createElement(Swiper, { modules: [Mousewheel], mousewheel: true, slidesPerView: 1.2, spaceBetween: 0, breakpoints: {
                        [600]: {
                            slidesPerView: 3,
                            spaceBetween: 15
                        },
                    } }, cards.length > 0 && cards.map((card, index) => React.createElement(SwiperSlide, { key: `trustpilot-slide-${index}` }, React.createElement(TrustpilotCard, { ...card }))))),
            React.createElement("div", { className: "trust-pilot-opinion-LP-root__buttons-wrapper" },
                buttonHref && React.createElement(ButtonV2, { design: "secondary-large-wbg", href: buttonHref, openInNewTab: openInNewTabButton },
                    React.createElement("p", null, buttonLabel),
                    React.createElement(Img, { src: buttonImage, alt: buttonLabel })),
                buttonHref2 && React.createElement(ButtonV2, { design: "secondary-large-wbg", href: buttonHref2, openInNewTab: openInNewTabButton2 },
                    React.createElement("p", null, buttonLabel2),
                    React.createElement(Img, { src: buttonImage2, alt: buttonLabel2 })))))));
