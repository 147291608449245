import React from "react";
import { Heading } from "../../Heading";
import { Img } from "../../Img";
export const Contacts = ({ mainTitle, subTitle, chatLabel, emailLabel, phoneNumberLabel, chatIcon, emailIcon, phoneNumberIcon, chatCallFunction, onClickFn }) => (React.createElement("div", { className: 'contacts' },
    React.createElement(Heading, { design: 'h4-bold' }, mainTitle),
    React.createElement("p", { dangerouslySetInnerHTML: {
            __html: subTitle || "",
        } }),
    React.createElement("div", { className: 'contacts__options' },
        React.createElement("div", null,
            React.createElement(Img, { src: chatIcon }),
            React.createElement("a", { className: "", onClick: chatCallFunction }, chatLabel)),
        React.createElement("div", null,
            React.createElement(Img, { src: emailIcon }),
            React.createElement("p", null, emailLabel)),
        React.createElement("div", null,
            React.createElement(Img, { src: phoneNumberIcon }),
            React.createElement("p", null, phoneNumberLabel)))));
