import React, { useCallback, useEffect, useState } from "react";
import { Container, ContainerFW } from "../global";
import { IconPlayButton } from "../svgIcons/playButton";
import { Img } from "../Img";
import { YouTubeVideoEmbed } from "../Cards/VideoCardDeck/YouTubeVideoEmbed";
import { IconClose } from "../svgIcons";
import { pageViewStyle } from "../../pageViewStyle";
import { useInView } from "react-intersection-observer";
export const ManagePortfolioWithEase = ({ mainSubTitle, mainTitle, mobileLinkLabel, mobileLink, webLinkLabel, webLink, imageUrlDesktop, imageUrlMobile, imageAlt }) => {
    const [currentUrlVideo, setCurrentUrlVideo] = useState();
    const [showVideo, setShowVideo] = useState();
    const [view, setView] = useState(pageViewStyle.getView());
    const { ref, inView } = useInView({
        threshold: 0,
    });
    const handleCurrentVideo = useCallback((videoUrl) => () => {
        setCurrentUrlVideo(videoUrl);
        setShowVideo(prev => !prev);
    }, []);
    const handleCloseVideo = useCallback(() => (setShowVideo(prev => !prev)), []);
    const handleDeviceImage = () => {
        if (view === "small") {
            return React.createElement(Img, { src: imageUrlMobile, alt: imageAlt });
        }
        return React.createElement(Img, { src: imageUrlDesktop, alt: imageAlt });
    };
    useEffect(() => {
        !view && setView(pageViewStyle.getView());
    }, [view]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement("div", { className: "managePortfolioWithEase-root", ref: ref },
            React.createElement(Container, null,
                React.createElement("div", { className: `managePortfolioWithEaseWrapper ${inView ? "managePortfolioWithEaseWrapper--animated" : ""}` },
                    React.createElement("div", { className: "managePortfolioWithEaseWrapper__textsWrapper" },
                        React.createElement("h2", null, mainTitle),
                        React.createElement("h5", null, mainSubTitle)),
                    React.createElement("div", { className: "managePortfolioWithEaseWrapper__links-wrapper" },
                        React.createElement("span", { className: "managePortfolioWithEaseWrapper__links-wrapper__webLinkWrapper", onClick: handleCurrentVideo(webLink) },
                            IconPlayButton,
                            React.createElement("span", null, webLinkLabel)),
                        React.createElement("span", { className: "managePortfolioWithEaseWrapper__links-wrapper__mobileLinkWrapper", onClick: handleCurrentVideo(mobileLink) },
                            IconPlayButton,
                            React.createElement("span", null, mobileLinkLabel))),
                    handleDeviceImage(),
                    (showVideo && currentUrlVideo) &&
                        React.createElement("div", { className: "managePortfolioWithEaseWrapper__modal" }, currentUrlVideo && React.createElement("div", { className: "managePortfolioWithEaseWrapper__modal__videoWrapper" },
                            React.createElement("span", { onClick: handleCloseVideo, className: "iconWrapper" }, IconClose()),
                            React.createElement(YouTubeVideoEmbed, { videoUrl: currentUrlVideo }))))))));
};
