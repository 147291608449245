import React, { Fragment } from "react";
import { ButtonV2 } from "../../../ButtonV2";
import { Img } from "../../../Img";
export const VPSProviderCard = ({ logoUrl, logoAlt, keyFeatures, specifications, linkText, linkUrl, warning, openInNewTab }) => (React.createElement("div", { className: "vps-provider-card" },
    React.createElement("div", { className: "vps-provider-card__header" },
        React.createElement("div", { className: "vps-provider-card__logo" },
            React.createElement(Img, { src: logoUrl, alt: logoAlt })),
        keyFeatures && React.createElement("div", { className: "vps-provider-card__key-features" }, keyFeatures.map(({ featureName, featureValue }, index) => (React.createElement(Fragment, { key: index },
            React.createElement("div", { className: "vps-provider-card__key-features-name" }, featureName),
            React.createElement("div", { className: "vps-provider-card__key-features-value", dangerouslySetInnerHTML: { __html: featureValue } })))))),
    specifications && specifications.length > 0 && React.createElement("div", { className: "vps-provider-card__specifications" },
        React.createElement("ul", null, specifications.map((item, index) => (React.createElement("li", { key: index }, item))))),
    React.createElement("div", { className: "vps-provider-card__footer" },
        linkText && (React.createElement(ButtonV2, { design: "ghost-medium-wbg", href: linkUrl, openInNewTab: openInNewTab }, linkText)),
        React.createElement("div", { className: "vps-provider-card__warning", dangerouslySetInnerHTML: { __html: warning } }))));
