import React, { useState, useEffect } from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { Button } from "../../Button";
export const OptionalButtonSectionBanner = ({ title, description, imageDesktop, imageMobile, alt, imagePosition, buttonText, buttonUrl, openInNewTabButton = false }) => {
    const [imageDesktopValue, setImageDesktopValue] = useState("");
    const [altValue, setAltValue] = useState("");
    useEffect(() => {
        setImageDesktopValue(imageDesktop);
        setAltValue(alt);
    }, [imageDesktop, alt]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "optional-button-section-banner" },
                React.createElement("div", { className: `optional-button-section-banner__grid-outer bg-color-neutral-accent-background ${imagePosition === "left" ? "" : "optional-button-section-banner__img-right"}` },
                    React.createElement("div", { className: "optional-button-section-banner__image-container centered-container bg-color-neutral-accent-default hide-medium hide-small" },
                        React.createElement(ImgV2, { largeUrl: imageDesktopValue, alt: altValue, strictRender: true, className: "optional-button-section-banner__image" })),
                    React.createElement("div", { className: "optional-button-section-banner__flex-middle" },
                        React.createElement("div", { className: "optional-button-section-banner__image-container-mobile bg-color-neutral-accent-default hide-large centered-container" },
                            React.createElement(ImgV2, { mediumUrl: imageDesktopValue, alt: altValue, smallUrl: imageMobile, strictRender: true, className: "optional-button-section-banner__image" })),
                        React.createElement("div", { className: "optional-button-section-banner__title-container" },
                            React.createElement("h3", { dangerouslySetInnerHTML: {
                                    __html: title,
                                } })),
                        React.createElement("div", { className: "optional-button-section-banner__description-container", dangerouslySetInnerHTML: {
                                __html: description,
                            } }),
                        buttonText && React.createElement("div", { className: "optional-button-section-banner__button-container" },
                            React.createElement(Button, { label: buttonText, href: buttonUrl, openinnewtab: openInNewTabButton.toString() }))))))));
};
