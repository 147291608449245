import React, { useCallback } from "react";
import { ButtonSquare } from "../ButtonSquare";
import { Img } from "../Img";
import { Container, ContainerFW } from "../global";
import { ButtonV2 } from "../ButtonV2";
import { ImgV2 } from "../ImgV2";
export const OtherAccounts = ({ mainSubTitle, mainTitle, sectionItems, blackCard, buttonUrl, buttonLabel, cardBackground, tradingViewCard }) => {
    const handleVisibleButton = useCallback(() => {
        if (buttonUrl && buttonLabel) {
            return React.createElement("div", { className: "otherAccountWrapper__buttonWrapper" },
                React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonUrl }, buttonLabel));
        }
        return React.createElement(React.Fragment, null);
    }, [buttonLabel, buttonUrl]);
    const mainTitleSection = () => (mainTitle && React.createElement("h2", { className: "otherAccountWrapper__mainTitle" }, mainTitle));
    const mainSubTitleSection = () => (mainSubTitle && React.createElement("h5", { className: "otherAccountWrapper__mainSubTitle" }, mainSubTitle));
    const tradingViewComponent = useCallback(() => {
        if (tradingViewCard !== undefined) {
            return (React.createElement("div", { className: "otherAccountWrapper__sectionWrapper__section trading-view-card" },
                React.createElement("div", { className: "trading-view-card__img" },
                    React.createElement(ImgV2, { src: tradingViewCard.image, alt: tradingViewCard.title })),
                React.createElement("h5", { className: "trading-view-card__title" }, tradingViewCard.title),
                React.createElement("div", { className: "trading-view-card__icon" },
                    React.createElement(ImgV2, { src: tradingViewCard.icon, alt: tradingViewCard.title })),
                React.createElement("div", { className: "trading-view-card__subtitle", dangerouslySetInnerHTML: {
                        __html: tradingViewCard.subtitle,
                    } })));
        }
        return React.createElement(React.Fragment, null);
    }, [tradingViewCard]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement("div", { className: "otherAccountWrapper-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: `${blackCard?.title ? "otherAccountWrapperWithBlackCard" : "otherAccountWrapper"}` },
                    mainTitleSection(),
                    mainSubTitleSection(),
                    React.createElement("div", { className: "otherAccountWrapper__sectionWrapper" },
                        sectionItems.length > 0 && sectionItems.map((a) => (React.createElement("div", { className: "otherAccountWrapper__sectionWrapper__section", style: { backgroundColor: cardBackground || "#F2F6F6" }, key: `unique${a.title}` },
                            React.createElement(Img, { className: "otherAccountWrapper__sectionWrapper__section__image", src: a.imageUrl, alt: a.imageAlt }),
                            React.createElement("h5", { className: "otherAccountWrapper__sectionWrapper__section__title" }, a.title),
                            React.createElement("div", { className: "otherAccountWrapper__sectionWrapper__section__subtitle", dangerouslySetInnerHTML: {
                                    __html: a.subTitle,
                                } }),
                            (a.buttonLabel && !blackCard?.buttonLabel) && React.createElement(ButtonSquare, { label: a.buttonLabel, href: a.buttonLink })))),
                        blackCard?.title && (React.createElement("div", { className: "otherAccountWrapper__sectionWrapper__section blackCard__section", key: `unique${blackCard.title}` },
                            React.createElement("h5", { className: "otherAccountWrapper__sectionWrapper__section__title blackCard__title" }, blackCard.title),
                            blackCard.subTitle && React.createElement("p", { className: "otherAccountWrapper__sectionWrapper__section__subtitle blackCard__subtitle" }, blackCard.subTitle),
                            blackCard?.buttonLabel && React.createElement(ButtonSquare, { label: blackCard.buttonLabel, href: blackCard.buttonLink }),
                            React.createElement("div", { className: "otherAccountWrapper-root__bg-image", style: { "--background-image-url": `url(${blackCard.imageUrl})` } }))),
                        tradingViewComponent()),
                    handleVisibleButton())))));
};
