import React, { useCallback, useEffect, useState } from "react";
import { usePageViewStyle } from "../../hooks";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { getColors } from "../../utils";
export const SimpleHeaderBanner = ({ title, subTitle, desktopAnimation, mobileAnimation, backgroundColor }) => {
    const { isLarge } = usePageViewStyle();
    const { bgColor } = getColors(backgroundColor);
    const [lottieSize, setLottieSize] = useState({
        width: 652,
        height: 438,
    });
    useEffect(() => {
        if (isLarge) {
            setLottieSize({
                width: 1128,
                height: 350,
            });
            return;
        }
        setLottieSize({
            width: 652,
            height: 438,
        });
    }, [isLarge]);
    const handleLottieAnimation = useCallback(() => {
        if (isLarge) {
            return desktopAnimation;
        }
        return mobileAnimation;
    }, [desktopAnimation, isLarge, mobileAnimation]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "simple-header-banner__root" },
                React.createElement(Heading, { design: "h1-regular" }, title),
                React.createElement("p", { className: "p-regular" }, subTitle),
                React.createElement(DotLottieReact, { src: handleLottieAnimation(), loop: true, autoplay: true, width: lottieSize.width, height: lottieSize.height })))));
};
