import React, { useEffect, useState } from "react";
export const AnimatedRollingNumber = ({ rollTo, classes, rollFrom = 0, duration = 1000, step = 5 }) => {
    const [currentNumber, setCurrentNumber] = useState(rollFrom);
    const validStep = Math.max(1, Math.floor(step || 1));
    useEffect(() => {
        let isMounted = true;
        const incrementTime = duration / Math.ceil((rollTo - rollFrom) / validStep);
        const interval = setInterval(() => {
            if (isMounted) {
                setCurrentNumber((prevNumber) => {
                    if (prevNumber < rollTo) {
                        return prevNumber + validStep;
                    }
                    else {
                        clearInterval(interval);
                        return prevNumber;
                    }
                });
            }
        }, incrementTime);
        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [rollFrom, rollTo, duration, validStep]);
    return (React.createElement("div", { style: { marginRight: "-10px" }, className: "animated-rolling-number" },
        React.createElement("div", { className: classes, style: { textAlign: "left" } }, currentNumber),
        React.createElement("div", { className: classes, style: { visibility: "hidden", height: 0, marginRight: "10px" } }, rollTo)));
};
