import React from "react";
import { Img } from "../../../Img";
export const SellBuyPriceCard = (props) => (React.createElement("div", { className: "sell-buy-price-card" },
    React.createElement(Img, { className: "sell-buy-price-card__img", src: props.icon, alt: props.alt }),
    React.createElement("h5", null, props.title),
    React.createElement("div", { className: "sell-buy-price-card__prices" },
        React.createElement("div", { className: "sell-buy-price-card__buy-price-wrapper" },
            React.createElement("p", { className: "sell-buy-price-card__price-label" }, props.buyLabel),
            React.createElement("a", { className: `${props.price1status === "price-feed__negative" ? "sell-buy-price-card__buy-price-container" : "sell-buy-price-card__sell-price-container"}`, href: props.instrumentUrl, target: "_blank", rel: "noopener noreferrer" },
                React.createElement("p", { className: "sell-buy-price-card__buy-price" }, props.price1))),
        React.createElement("div", { className: "sell-buy-price-card__sell-price-wrapper" },
            React.createElement("p", { className: "sell-buy-price-card__price-label" }, props.sellLabel),
            React.createElement("a", { className: `${props.price2status === "price-feed__negative" ? "sell-buy-price-card__buy-price-container" : "sell-buy-price-card__sell-price-container"}`, href: props.instrumentUrl, target: "_blank", rel: "noopener noreferrer" },
                React.createElement("p", { className: "sell-buy-price-card__sell-price" }, props.price2))))));
