import React, { useEffect, useState, useCallback, useMemo } from "react";
import { IconChevronDown } from "../../svgIcons";
function getIncrementingArr(arrayLength) {
    return Array.from(Array(arrayLength).keys());
}
export const JobBoardPagination = ({ numOfPages, currentPage, setCurrentPage, onPageChange, isSmallScreen, linkPagination, scrollTopOnPageChange = true }) => {
    const [btnPageIndexArr, setBtnPageIndexArr] = useState([]);
    const nextPage = useCallback(function () {
        if (currentPage < numOfPages - 1) {
            const argumentPageNext = linkPagination ? currentPage + 1 : (prev) => prev + 1;
            setCurrentPage && setCurrentPage(argumentPageNext);
            onPageChange && onPageChange(currentPage + 1);
            scrollTopOnPageChange && window.scrollTo({ top: 0, left: 0 });
        }
    }, [currentPage, linkPagination, numOfPages, onPageChange, scrollTopOnPageChange, setCurrentPage]);
    const previousPage = useCallback(function () {
        if (currentPage > 0) {
            const argumentPagePrev = linkPagination ? currentPage - 1 : (prev) => prev - 1;
            setCurrentPage && setCurrentPage(argumentPagePrev);
            onPageChange && onPageChange(currentPage - 1);
            scrollTopOnPageChange && window.scrollTo({ top: 0, left: 0 });
        }
    }, [currentPage, linkPagination, onPageChange, scrollTopOnPageChange, setCurrentPage]);
    const changePage = useCallback(function (index) {
        return (e) => {
            e.preventDefault();
            setCurrentPage && setCurrentPage(index);
            onPageChange && onPageChange(index);
            scrollTopOnPageChange && window.scrollTo({ top: 0, left: 0 });
        };
    }, [onPageChange, scrollTopOnPageChange, setCurrentPage]);
    const numOfSlots = useMemo(() => (isSmallScreen ? 4 : 7), [isSmallScreen]);
    useEffect(() => {
        const incremntingArr = getIncrementingArr(numOfPages);
        let dotsLeftShow = false;
        let dotsRightShow = false;
        if (numOfPages > numOfSlots) {
            const halfNumOfSlots = Math.floor(numOfSlots / 2);
            if (currentPage >= halfNumOfSlots) {
                dotsLeftShow = true;
            }
            if (currentPage <= numOfPages - halfNumOfSlots - 1) {
                dotsRightShow = true;
            }
            const maxSlotsWithoutCurrentPage = numOfSlots - 1 - (dotsLeftShow ? 1 : 0) - (dotsRightShow ? 1 : 0);
            let leftSideSlots = 0;
            let rightSideSlots = 0;
            let i = 1;
            let y = 0;
            while (y < maxSlotsWithoutCurrentPage) {
                if (incremntingArr[currentPage - i] ||
                    incremntingArr[currentPage - i] === 0) {
                    leftSideSlots++;
                    y++;
                }
                if (incremntingArr[currentPage + i]) {
                    rightSideSlots++;
                    y++;
                }
                i++;
            }
            incremntingArr.splice(0, currentPage - leftSideSlots);
            const currentPageIndex = incremntingArr.findIndex((val) => val === currentPage);
            incremntingArr.splice(currentPageIndex + rightSideSlots + 1, incremntingArr.length - 1);
        }
        setBtnPageIndexArr(incremntingArr);
    }, [numOfPages, numOfSlots, currentPage, linkPagination]);
    return (React.createElement("div", { className: "jobBoard__paginationBtns" },
        React.createElement("span", { className: `jobBoard__paginationArrow ${currentPage <= 0 && "jobBoard__paginationArrow--disable"}`, onClick: previousPage }, IconChevronDown({})),
        btnPageIndexArr.map((pageIndexValue) => {
            if (linkPagination) {
                return React.createElement("a", { href: `?page=${pageIndexValue + 1}`, className: `jobBoard__paginationBtn ${currentPage === pageIndexValue && "jobBoard__paginationBtn--active"}`, key: pageIndexValue, onClick: changePage(pageIndexValue) }, pageIndexValue + 1);
            }
            return React.createElement("button", { className: `jobBoard__paginationBtn ${currentPage === pageIndexValue && "jobBoard__paginationBtn--active"}`, key: pageIndexValue, onClick: changePage(pageIndexValue) }, pageIndexValue + 1);
        }),
        React.createElement("span", { className: `jobBoard__nextPaginationArrow ${currentPage >= numOfPages - 1 && "jobBoard__paginationArrow--disable"}`, onClick: nextPage }, IconChevronDown({}))));
};
