import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
import { getColors } from "../../../utils/getColors";
export const TradingViewBanner = ({ title, buttonTitle, orString, secondButtonLabel, secondButtonLink, subTitle, buttonLabel, buttonLink, opensInNewTab, imageUrl, imageAlt, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "trading-view-banner" },
                React.createElement("div", { className: "trading-view-banner__text-and-button-container" },
                    React.createElement("div", { className: "trading-view-banner__text-wrapper" },
                        React.createElement("h1", null, title),
                        React.createElement("h5", null, subTitle)),
                    React.createElement("div", { className: "trading-view-banner__button-wrapper" },
                        buttonTitle && React.createElement("p", { className: "trading-view-banner__button-title" }, buttonTitle),
                        React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonLink, openInNewTab: opensInNewTab }, buttonLabel),
                        orString && React.createElement("span", { className: "trading-view-banner__button-or" }, orString),
                        secondButtonLabel && React.createElement(ButtonV2, { design: "ghost-large-wbg", href: secondButtonLink, openInNewTab: opensInNewTab }, secondButtonLabel))),
                React.createElement("div", { className: "trading-view-banner__image-container" },
                    React.createElement(ImgV2, { src: imageUrl, alt: imageAlt, loading: "eager" }))))));
};
