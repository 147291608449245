import { ContainerFW, Container } from "../global";
import React, { useMemo, useState, useCallback } from "react";
export const TradingScenarios = ({ title, summary, radioSelector, scenarios }) => {
    const [long, setLong] = useState(true);
    const toggleRadioLong = useCallback(() => {
        setLong(true);
    }, []);
    const toggleRadioShort = useCallback(() => {
        setLong(false);
    }, []);
    const summaryFragment = useMemo(() => (React.createElement("div", { className: "trading-scenarios__summary" }, summary.map((item) => React.createElement("div", { key: item.text + item.title },
        React.createElement("div", { className: 'caption-bold color-dark-80' },
            React.createElement("span", { dangerouslySetInnerHTML: { __html: item.title } })),
        React.createElement("h5", { className: 'h5-semi-bold color-dark-default' },
            React.createElement("span", { dangerouslySetInnerHTML: { __html: item.text } })))))), [summary]);
    const radioFragment = useMemo(() => (React.createElement("div", { className: "trading-scenarios__radio-container" },
        React.createElement("div", { className: "centered-container" },
            React.createElement("div", { className: "trading-scenarios__radio centered-container a bg-color-pure-white" },
                React.createElement("div", { onClick: toggleRadioLong, className: `trading-scenarios__radio-item caption centered-container ${long ? "bg-color-dark-default caption-bold color-pure-white" : ""}` }, radioSelector.longPosition.radioText),
                React.createElement("div", { onClick: toggleRadioShort, className: `trading-scenarios__radio-item caption centered-container ${!long ? "bg-color-dark-default caption-bold color-pure-white" : ""}` }, radioSelector.shortPosition.radioText))),
        React.createElement("div", { className: "centered-container" },
            long && React.createElement("p", { dangerouslySetInnerHTML: { __html: radioSelector.longPosition.title } }),
            !long && React.createElement("p", { dangerouslySetInnerHTML: { __html: radioSelector.shortPosition.title } })))), [long]);
    const renderScenario = useCallback((sc) => (React.createElement("div", null,
        React.createElement("div", { className: "trading-scenarios__scenario" },
            React.createElement("span", { className: "caption-bold" }, sc.title),
            React.createElement("div", { className: "trading-scenarios__scenario-div bg-color-pure-white" },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: sc.htmlContent } }))))), [long, scenarios]);
    const scenariosFragment = useMemo(() => (React.createElement("div", null,
        long && React.createElement("div", { className: "trading-scenarios__two-scenarios-div" },
            renderScenario(scenarios.longPosition.scenarioA),
            renderScenario(scenarios.longPosition.scenarioB)),
        !long && React.createElement("div", { className: "trading-scenarios__two-scenarios-div" },
            renderScenario(scenarios.shortPosition.scenarioA),
            renderScenario(scenarios.shortPosition.scenarioB)))), [long, scenarios]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "trading-scenarios__title centered-container" },
                React.createElement("h2", null, title)),
            React.createElement("div", { className: "trading-scenarios bg-color-neutral-accent-background" },
                React.createElement("div", { className: "trading-scenarios__summary-container" }, summaryFragment),
                React.createElement("div", null,
                    React.createElement("div", { className: "trading-scenarios__radio-selector" }, radioFragment),
                    React.createElement("div", { className: "trading-scenarios__scenarios" }, scenariosFragment))))));
};
