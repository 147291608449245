import React from "react";
import { Container, ContainerFW } from "../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { CategoriesWithIconSwiperCard } from "./CategoriesWithIconSwiperCard";
import { Mousewheel } from "swiper";
import { getColors } from "../../utils";
export const CategoriesWithIconSwiper = ({ title, cardsContent, titlePosition, backgroundColor }) => {
    const colors = getColors(backgroundColor);
    if (!backgroundColor || backgroundColor === "dark") {
        colors.textColor = "#CFD2D6";
    }
    return (React.createElement(ContainerFW, { backgroundColor: colors.bgColor },
        React.createElement("div", { className: "categories-with-icon-swiper-root" },
            React.createElement(Container, null,
                React.createElement("div", { style: { color: colors.textColor }, className: `categories-with-icon-swiper-root__titleWrapper ${titlePosition === "left" ? "leftTitle" : "centerTitle"}` },
                    React.createElement("p", null, title)),
                React.createElement("div", { style: { color: colors.textColor }, className: "categories-with-icon-swiper-root__swiperWrapper" },
                    React.createElement(Container, null,
                        React.createElement(Swiper, { modules: [Mousewheel], mousewheel: true, slidesPerView: "auto" }, cardsContent.length && cardsContent.map((card, i) => React.createElement(SwiperSlide, { key: `cardsContent-slide-${i}` }, React.createElement("span", null,
                            React.createElement("span", { className: "categories-with-icon-swiper-root__card", style: { color: colors.textColor } },
                                React.createElement(CategoriesWithIconSwiperCard, { ...card }))))))))))));
};
