import React from "react";
import { Heading } from "../../Heading";
import { ButtonV2 } from "../../ButtonV2";
import { Img } from "../../Img";
import { getColors } from "../../../utils";
export const WideCardWithButton = ({ imgUrl, imgAlt, subTitle, title, buttonText, buttonUrl, backgroundColor }) => {
    const { bgColor, textColor } = getColors(backgroundColor || "neutralaccentbackground");
    const getContainerStyle = () => ({ backgroundColor: bgColor || undefined, color: textColor || undefined });
    return (React.createElement("div", { className: "wide-card-with-button", style: getContainerStyle() },
        React.createElement(Img, { src: imgUrl, alt: imgAlt, className: "wide-card-with-button__image" }),
        React.createElement(Heading, { design: "h4-semi-bold" }, title),
        React.createElement("p", null, subTitle),
        React.createElement(ButtonV2, { design: "ghost-medium-wbg", href: buttonUrl, classesWrapper: "wide-card-with-button__button" }, buttonText)));
};
