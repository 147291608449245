import { useEffect } from "react";
import { TimescaleEnum } from "../instrumentData.types";
export const useInstrumentPriceHistory = ({ inputs, callbacks, config }) => {
    useEffect(() => {
        let historyRequestCounter = 0;
        setTimeout(async () => {
            const reqHistory = async () => {
                await fetchHistory();
            };
            const reqWeek52 = async () => {
                await fetchWeek52Range();
            };
            await reqHistory();
            await reqWeek52();
        });
        const historyRequestInterval = setInterval(async () => {
            if (historyRequestCounter < config.MAX_REQUEST_ATTEMPTS) {
                historyRequestCounter++;
                await fetchHistory();
            }
        }, config.REQUEST_INTERVAL);
        const fetchHistory = async () => {
            try {
                const response = await fetch(`${inputs.apiUrl}/history/${inputs.session}/${inputs.instrument}/${inputs.timescale}`);
                const historyTicks = await response.json();
                if (response.status === 200) {
                    clearInterval(historyRequestInterval);
                    if (historyTicks[historyTicks.length - 1]) {
                        if (inputs.timescale === TimescaleEnum.TS_1DAY) {
                            callbacks.setDayRange(`${historyTicks[historyTicks.length - 1]?.Low.toFixed(config.DECIMAL_PRECISION)} - ${historyTicks[historyTicks.length - 1]?.High.toFixed(config.DECIMAL_PRECISION)}`);
                            callbacks.setOpen(+historyTicks[historyTicks.length - 1].Open.toFixed(config.DECIMAL_PRECISION));
                            callbacks.setPrevClose(+historyTicks[historyTicks.length - 1].Close.toFixed(config.DECIMAL_PRECISION));
                        }
                    }
                }
                else {
                    if (historyRequestCounter === config.MAX_REQUEST_ATTEMPTS) {
                        clearInterval(historyRequestInterval);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        };
        let week52RequestCounter = 0;
        const week52RequestInterval = setInterval(async () => {
            if (week52RequestCounter < config.MAX_REQUEST_ATTEMPTS) {
                week52RequestCounter++;
                await fetchWeek52Range();
            }
        }, config.REQUEST_INTERVAL);
        const fetchWeek52Range = async () => {
            try {
                const response = await fetch(`${inputs.apiUrl}/week-52/${inputs.session}/${inputs.instrument}`);
                const week52Range = await response.json();
                if (response.status === 200) {
                    clearInterval(week52RequestInterval);
                    const notExists = [-1, 0];
                    if (notExists.includes(week52Range.High) ||
                        notExists.includes(week52Range.Low)) {
                        callbacks.setYearRange("");
                    }
                    else {
                        callbacks.setYearRange(`${week52Range.Low.toFixed(config.DECIMAL_PRECISION)} - ${week52Range.High.toFixed(config.DECIMAL_PRECISION)}`);
                    }
                }
                else {
                    if (week52RequestCounter === config.MAX_REQUEST_ATTEMPTS) {
                        clearInterval(week52RequestInterval);
                        callbacks.setYearRange("");
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        };
        return () => {
            clearInterval(historyRequestInterval);
            clearInterval(week52RequestInterval);
        };
    }, [callbacks, config.DECIMAL_PRECISION, config.MAX_REQUEST_ATTEMPTS, config.REQUEST_INTERVAL, inputs.apiUrl, inputs.instrument, inputs.session, inputs.timescale]);
};
