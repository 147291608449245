import { widgetMock } from "../NavigationWidgets/NavigationWidgets.props.mock";
export const navigationMock = {
    navigationItems: [
        {
            title: "Markets",
            type: "text",
            isCurrentPage: false,
            sections: [
                {
                    title: "TRADING",
                    type: "wide",
                    sectionItems: [
                        {
                            title: "CFD trading",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Find out how CFD trading works and its benefits",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Forex",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Choose from major, minor and exotic currency pairs",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Indices",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Choose from a wide selection of global indices",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Commodities",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Trade CFDs on gold, other metals and energy",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Cryptocurrencies",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Go long or go short on the most popular cryptocurrencies",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Stocks",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Trade on stocks without buying company shares",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "ETFs",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Speculate on the price movements of ETFs",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Futures",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Open CFD trades on the most popular futures contracts",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Contract specifications",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Check out spreads, contract size and other details",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                    ],
                },
            ],
        },
        {
            title: "Platforms",
            type: "text",
            isCurrentPage: false,
            sections: [
                {
                    title: "TRADING",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Platform overview",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Check out what our platforms have to offer",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Platform comparison",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Find the platform that suits your trading style",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "ThinkTrader",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "The award-winning proprietary CFD trading platform",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        }, {
                            title: "ThinkMarkets MT4",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "The industry-standard trading platform",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "ThinkMarkets MT5",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "The latest MetaTrader with advanced features",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        }, {
                            title: "ThinkCopy",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "The copy trading app for beginners and signal providers",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                    ],
                },
                {
                    title: "ACCOUNT INFORMATION",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Account types",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Choose from Standard, ThinkZero and other accounts",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Account management",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Explore ThinkPortal – our sophisticated trading hub",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Deposit & withdraw",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Move your funds in and out of your account with ease",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                    ],
                },
                {
                    title: "TOOLS",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Signal Centre",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Choose from Standard, ThinkZero and other accounts",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "VPS hosting",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Explore ThinkPortal – our sophisticated trading hub",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Traders' Gym",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Move your funds in and out of your account with ease",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                    ],
                },
            ],
        },
        {
            title: "Learning",
            type: "text",
            isCurrentPage: false,
            sections: [
                {
                    title: "TRADING GUIDES",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Academy",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Master your trading knowledge with our guides",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Glossary",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Familiarise yourself with the most used trading terms",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Videos",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Grasp the trading basics with our videos",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        }
                    ],
                },
                {
                    title: "MARKET OVERVIEW",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Market news",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Stay up to date with the latest market news and insights",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Economic calendar",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Keep an eye on economic events to plan your trades",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        }
                    ],
                }
            ],
        },
        {
            title: "Company",
            type: "text",
            isCurrentPage: false,
            sections: [
                {
                    title: "THINKMARKETS",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Who we are",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Learn about our values, expertise and licenses",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Careers",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Join our team to make a difference in the trading world",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Announcements",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Check out the latest in-house updates and developments",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Legal documents",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Understand your legal relationship with us before you start trading.",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Sponsorship",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Discover all our new and existing sponsorship agreements.",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Security",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "See how your trading account is protected with us",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        }
                    ],
                },
                {
                    title: "SUPPORT",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Contact us",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Get in touch with our client support team 24/7",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "Help center",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Find answers to the most frequently asked questions",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        },
                        {
                            title: "System status",
                            linkUrl: "#",
                            linkMoreText: "",
                            linkMoreUrl: "",
                            description: "Uncover any planned or unplanned outages to our services.",
                            imageUrl: "./images/Header/navigationBar/trade.svg",
                        }
                    ],
                },
            ],
        },
        {
            title: "Log in",
            type: "button-login",
            isCurrentPage: false,
            sections: [
                {
                    title: "TRADING PLATFORMS",
                    type: "default",
                    sectionItems: [
                        {
                            title: "ThinkTrader Web",
                            linkUrl: "",
                            linkMoreText: "Login",
                            linkMoreUrl: "#",
                            description: "Turn your browser into a high-performance trading tool",
                            type: "login",
                            imageUrl: "./images/Header/navigationBar/ThinkTrader.svg",
                        },
                        {
                            title: "ThinkMarkets MT4",
                            linkUrl: "",
                            linkMoreText: "Login",
                            linkMoreUrl: "#",
                            description: "Established, secure and equipped with a supreme set of features.",
                            type: "login",
                            imageUrl: "./images/Header/navigationBar/ThinkTrader.svg",
                        },
                        {
                            title: "ThinkMarkets MT5",
                            linkUrl: "",
                            linkMoreText: "Login",
                            linkMoreUrl: "#",
                            description: "Upgraded version of MT4 with a wide selection of instruments.",
                            type: "login",
                            imageUrl: "./images/Header/navigationBar/ThinkTrader.svg",
                        }
                    ],
                },
                {
                    title: "ACCOUNT MANAGEMENT",
                    type: "default",
                    sectionItems: [
                        {
                            title: "ThinkPortal",
                            linkUrl: "",
                            linkMoreText: "Login",
                            linkMoreUrl: "#",
                            description: "Access and manage all your accounts in one place.",
                            type: "login",
                            imageUrl: "./images/Header/navigationBar/AccountManagement.svg",
                        },
                    ],
                },
            ],
        },
        {
            title: "Sign up",
            type: "button-register",
            isCurrentPage: false,
            sections: [
                {
                    title: "",
                    type: "default",
                    sectionItems: [
                        {
                            title: "Demo account",
                            linkUrl: "",
                            linkMoreText: "Sign up",
                            linkMoreUrl: "#",
                            description: "Explore the markets, test your skills and practice using trading tools in a risk-free environment.",
                            imageUrl: "./images/Header/navigationBar/NewAccountUser.svg",
                            type: "register",
                        },
                        {
                            title: "Live account",
                            linkUrl: "",
                            linkMoreText: "Create account",
                            linkMoreUrl: "#",
                            description: "Catch trading opportunities on thousands of instruments and put your trading knowledge to work.",
                            imageUrl: "./images/Header/navigationBar/NewAccountUser.svg",
                            type: "register",
                        }
                    ],
                },
            ],
        },
    ],
    searchPlaceholder: "Search for...",
    data: [
        {
            title: "Support", href: "#",
            sections: [
                {
                    title: "Affiliates",
                    type: "wide",
                    sectionItems: [
                        {
                            title: "",
                            linkUrl: "",
                            linkMoreText: "Explore affiliates",
                            linkMoreUrl: "#",
                            description: "Receive a one-time affiliate fee for every eligible trader you refer.",
                        },
                    ],
                },
                {
                    title: "Introducing brokers",
                    type: "wide",
                    sectionItems: [
                        {
                            title: "",
                            linkUrl: "",
                            linkMoreText: "Explore introducing brokers",
                            linkMoreUrl: "#",
                            description: "Get unlimited commissions without maximum payout when your clients trade. ",
                        },
                    ],
                },
                {
                    title: "Money managers",
                    type: "wide",
                    sectionItems: [
                        {
                            title: "",
                            linkUrl: "",
                            linkMoreText: "Explore money managers",
                            linkMoreUrl: "#",
                            description: "Manage investor funds and get compensated with both fees and commissions. ",
                        },
                    ],
                },
            ],
        },
        { title: "Deposit", href: "#", sections: [] },
    ],
    widgets: widgetMock,
    menuTitle: "Menu",
    searchWidgetProps: { searchItems: { title: "Search Site", sections: [], type: "widget", currentCulture: "EN", currentIcon: "emptyValueForMock" } },
    logoHref: "~/en"
};
