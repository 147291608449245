import React, { useEffect, useState } from "react";
import { AlphabetsSearch } from "../AlphabetsSearch";
import { CardsGridIconAndTitle } from "../CardsGridIconAndTitle";
import { Gap } from "../Gaps/Gap";
import { SearchRibbon } from "../SearchRibbon";
import { TradingGlossary } from "../TradingGlossary/TradingGlossary";
export const TradingGlossaryLanding = (props) => {
    const [data, setData] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const bg = props.backgroundColor ? props.backgroundColor : "inherit";
    useEffect(() => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        };
        const filteredQuery = searchQuery.replaceAll(" ", "").replace(/[^a-zA-Z0-9 ]/gm, "");
        filteredQuery === "" && setData({ content: [] });
        filteredQuery !== "" && fetch(`/${props.culture}/api/GlossarySearch?token=${props.token}&terms=${searchQuery}`, requestOptions)
            .then((response) => response.json())
            .then((responseData) => setData({ ...data, content: responseData }))
            .catch(err => console.warn(err));
    }, [searchQuery]);
    return (React.createElement("div", { className: "trading-glossary-landing" },
        React.createElement(SearchRibbon, { search: setSearchQuery, searchText: props.searchText, backgrounColor: bg }),
        searchQuery ?
            data && React.createElement(TradingGlossary, { alphabetContent: props.alphabetContent, noResultsText: props.noResultsText, searchResultsText: props.searchResultsText, searchQuery: searchQuery, list: data, categories: props.categoryContent })
            :
                React.createElement(React.Fragment, null,
                    React.createElement(Gap, { desktopGap: "40px", mobileGap: "40px", gapColor: bg }),
                    React.createElement(AlphabetsSearch, { ...props.alphabetContent, noWrappers: false }),
                    React.createElement(Gap, { desktopGap: "40px", mobileGap: "40px", gapColor: bg }),
                    React.createElement(CardsGridIconAndTitle, { ...props.categoryContent, noWrappers: false }))));
};
