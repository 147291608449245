import React from "react";
import { Img } from "../../../Img";
export const GetHelpCard = (props) => (React.createElement("div", { className: "getHelpCard" },
    React.createElement("div", { className: "getHelpCard__icon-title" },
        React.createElement("div", { className: "getHelpCard__card-icon" },
            React.createElement(Img, { src: props.imageUrl, alt: props.imageAlt })),
        React.createElement("div", { className: "getHelpCard__card-title" },
            React.createElement("p", null, props.title))),
    React.createElement("div", { className: "getHelpCard__card-items" }, props.links.map((card, index) => (React.createElement("div", { key: index, className: "getHelpCard__card-item" },
        React.createElement("a", { href: card.linkURL, target: "_blank", rel: "noopener noreferrer" }, card.linkText)))))));
