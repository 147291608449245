import React, { useEffect } from "react";
import { Container, ContainerFW } from "../../global";
import { AccountCard } from "./AccountCard/AccountCard";
export const AccountCardDeck = (props) => {
    useEffect(() => {
        const col1Items = document.querySelectorAll(".account-card")[0].querySelectorAll(".account-card__section-item");
        const col2Items = document.querySelectorAll(".account-card")[1].querySelectorAll(".account-card__section-item");
        for (let i = 0; i < col1Items.length; i++) {
            const col1Height = col1Items[i].offsetHeight;
            const col2Height = col2Items[i].offsetHeight;
            const maxHeight = Math.max(col1Height, col2Height);
            if (col1Height < maxHeight) {
                col1Items[i].style.height = `${maxHeight}px`;
            }
            if (col2Height < maxHeight) {
                col2Items[i].style.height = `${maxHeight}px`;
            }
        }
    }, []);
    return (React.createElement("div", { className: "information-card__deck account-card__deck" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("h2", { className: "information-card__deck-title", dangerouslySetInnerHTML: { __html: props.title } }),
                React.createElement("div", { className: "information-card__deck-cards" }, props.cards.map((card, i) => React.createElement(AccountCard, { key: `information-card-${i}`, ...card }))),
                props.legend && React.createElement("p", { className: "information-card__legend", dangerouslySetInnerHTML: { __html: props.legend } })))));
};
