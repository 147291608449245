export const getWindowLocationPage = () => {
    const pageNumericInUrl = new URLSearchParams(location.search).get("page");
    if (pageNumericInUrl !== null) {
        const pageNumberInUrl = Number(pageNumericInUrl);
        if (isNaN(pageNumberInUrl)) {
            return undefined;
        }
        if (pageNumberInUrl <= 0) {
            return undefined;
        }
        return pageNumberInUrl;
    }
    return undefined;
};
export const setWindowLocationPage = (currentPage) => {
    if (!currentPage) {
        return;
    }
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", currentPage.toString());
    const stringSearchParams = searchParams.toString();
    window.history.replaceState("null", "null", `?${stringSearchParams}`);
};
export const getWindowLocationDifficulty = () => {
    const difficultyInUrl = new URLSearchParams(location.search).get("difficulty");
    if (difficultyInUrl !== null) {
        return difficultyInUrl;
    }
    return undefined;
};
