const COMMANDS = {
    play: "{\"event\":\"command\",\"func\":\"playVideo\",\"args\":\"\"}",
    stop: "{\"event\":\"command\",\"func\":\"stopVideo\",\"args\":\"\"}",
    pause: "{\"event\":\"command\",\"func\":\"pauseVideo\",\"args\":\"\"}"
};
export const controlIframedVideo = (videoIframe, command) => {
    if (!videoIframe || !Object.keys(COMMANDS).includes(command)) {
        return;
    }
    const messageContent = COMMANDS[command];
    try {
        videoIframe?.contentWindow?.postMessage(messageContent, "*");
    }
    catch (e) {
        console.debug("postMessage to embedded Youtube video fails with error: ", e);
    }
};
