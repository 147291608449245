import * as React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { usePageViewStyle } from "../../hooks";
import { getColors } from "../../utils";
import { Breadcrumbs } from "../Breadcrumbs";
import { Gap } from "../Gaps/Gap";
export const Announcements = (props) => {
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const { bgColor } = getColors(props.backgroundColor);
    const [contentToShow, setContentToShow] = React.useState([]);
    let charNumbers = 0;
    isSmall ? charNumbers = 83 : (isMedium ? charNumbers = 130 : (isLarge ? charNumbers = 88 : 0));
    const [itemSubtitleValue, setItemSubtitleValue] = React.useState("");
    const depContent0ItemSubtitle = props.content[0].itemSubtitle;
    React.useEffect(() => {
        setItemSubtitleValue(`${depContent0ItemSubtitle.slice(0, charNumbers)}...`);
    }, [depContent0ItemSubtitle, charNumbers]);
    React.useEffect(() => {
        if (props.content.length > 0) {
            setContentToShow(props.content.slice(0, 20));
        }
    }, [props.content]);
    const handleLoadMoreContent = React.useCallback((event) => {
        event.preventDefault();
        if (props.content.length > contentToShow.length) {
            const newContent = props.content.slice(contentToShow.length, contentToShow.length + 10);
            setContentToShow((prev) => ([...prev, ...newContent]));
        }
    }, [contentToShow, props.content]);
    const optionalBreadCrumb = React.useCallback(() => {
        if (props.breadcrumbs !== undefined) {
            return React.createElement(React.Fragment, null,
                React.createElement(Breadcrumbs, { breadcrumbs: props.breadcrumbs, lightMode: props.backgroundColor === "white" }),
                React.createElement(Gap, { desktopGap: "20px", mobileGap: "10px" }));
        }
        return React.createElement(React.Fragment, null);
    }, [props.backgroundColor, props.breadcrumbs]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            optionalBreadCrumb(),
            React.createElement("div", { className: "announcements" },
                React.createElement("div", { className: "announcements__container" },
                    React.createElement("div", { className: "announcements__text" },
                        React.createElement("div", { className: "announcements__main-title" },
                            React.createElement("h1", null, props.title)),
                        React.createElement("div", { className: "announcements__main-subtitle" },
                            React.createElement("h5", null, props.subTitle))),
                    React.createElement("div", { className: "announcements__content" },
                        React.createElement("div", { className: "announcements__last-announcement" },
                            React.createElement("div", { className: "announcements__last-announcement-date" },
                                React.createElement("p", null, new Date(props.content[0].itemUploadDate ? props.content[0].itemUploadDate : "").toDateString().slice(4)),
                                React.createElement("span", null, "NEW")),
                            React.createElement("div", { className: "announcements__last-announcement-text-image" },
                                React.createElement("div", { className: "announcements__last-announcement-text" },
                                    React.createElement("a", { href: props.content[0].itemLink },
                                        React.createElement("h3", null, props.content[0].itemTitle),
                                        React.createElement("p", null, itemSubtitleValue),
                                        " ")),
                                React.createElement("div", { className: "announcements__last-announcement-image" },
                                    React.createElement("a", { href: props.content[0].itemLink },
                                        React.createElement(Img, { src: props.content[0].itemImage, alt: props.content[0].itemImageAlt }))))),
                        React.createElement("div", { className: "announcements__list-announcements" }, contentToShow.map((item, i) => {
                            if (i > 0) {
                                return (React.createElement("div", { key: i, className: "announcements__list-announcements-item" },
                                    React.createElement("div", { className: "announcements__list-announcements-item-date" },
                                        React.createElement("p", null, new Date(item.itemUploadDate ? item.itemUploadDate : "").toDateString().slice(4))),
                                    React.createElement("div", { className: "announcements__list-announcements-item-title" },
                                        React.createElement("a", { href: item.itemLink },
                                            React.createElement("h4", null, item.itemTitle)))));
                            }
                            return "";
                        }))),
                    React.createElement("div", { className: "announcements__button-container" }, props.content.length > contentToShow.length && React.createElement(ButtonV2, { onClick: handleLoadMoreContent, design: "ghost-medium-wbg", href: props.buttonLink }, props.buttonLabel)))))));
};
