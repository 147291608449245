import React from "react";
import { ContainerFW } from "../global";
import { Container } from "../global";
import { SimpleLightbox } from "../Cards/VideoCardDeck/SimpleLightbox/SimpleLightbox";
import { YouTubeVideoEmbed } from "../Cards/VideoCardDeck/YouTubeVideoEmbed";
import timeClock from "../svgIcons/timeClock";
import { ButtonV2 } from "../ButtonV2";
export const TrendingVideos = (props) => (React.createElement(ContainerFW, { backgroundColor: props.backgroundColor ? props.backgroundColor : "inherit" },
    React.createElement(Container, null,
        React.createElement("div", { className: "trending-videos" },
            React.createElement("div", { className: "trending-videos__title" },
                React.createElement("h2", null, props.title)),
            props.videosList.length &&
                React.createElement("div", { className: "trending-videos__container" },
                    React.createElement("div", { className: "trending-videos__main" },
                        React.createElement("div", { className: "trending-videos__main-img" },
                            React.createElement(SimpleLightbox, { className: "trending-videos__lightbox-trigger" },
                                React.createElement(YouTubeVideoEmbed, { videoUrl: props.videosList[0].videoURL, webSiteRootUrl: props.videosList[0].videoURL }))),
                        React.createElement("div", { className: "trending-videos__main-text" },
                            React.createElement("div", { className: "trending-videos__upload-time" },
                                React.createElement("p", null,
                                    React.createElement("span", null, timeClock({})),
                                    props.videosList[0].videoUploadTime)),
                            React.createElement("div", { className: "trending-videos__upload-title" },
                                React.createElement("h4", null, props.videosList[0].videoTitle)))),
                    React.createElement("div", { className: "trending-videos__list" }, props.videosList.slice(1).map((item, i) => (React.createElement("div", { key: i, className: `trending-videos__list-item-${i}` },
                        React.createElement("div", { className: "trending-videos__list-item-img" },
                            React.createElement(SimpleLightbox, { className: `trending-videos__list-lightbox-trigger-${i}` },
                                React.createElement(YouTubeVideoEmbed, { videoUrl: item.videoURL, webSiteRootUrl: item.videoURL }))),
                        React.createElement("div", { className: "trending-videos__list-item-text" },
                            React.createElement("div", { className: "trending-videos__list-upload-time" },
                                React.createElement("p", null,
                                    React.createElement("span", null, timeClock({})),
                                    item.videoUploadTime)),
                            React.createElement("div", { className: "trending-videos__list-title" },
                                React.createElement("h5", null, item.videoTitle)))))))),
            props.buttonUrl && React.createElement(ButtonV2, { design: "ghost-medium-wbg", href: props.buttonUrl }, props.buttonText)))));
