import * as React from "react";
import { Img } from "../../Img";
import { Container, ContainerFW } from "../../global";
import { Heading } from "../../Heading";
export const ArticleBanner = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "staticBanner articleBanner staticBanner--short" },
        React.createElement("div", { className: "staticBanner__content" },
            props.breadcrumbs && React.createElement("div", null,
                React.createElement(Container, null,
                    React.createElement("div", { className: "staticBanner__breadcrumbs" },
                        React.createElement("a", { href: "/" }, homeIcon()),
                        props.breadcrumbs.map((item, index) => {
                            if (index + 1 === props.breadcrumbs?.length) {
                                return (React.createElement("span", { key: index, className: "staticBanner__breadcrumb" },
                                    " ",
                                    " / ",
                                    item.breadcrumbText,
                                    " "));
                            }
                            else {
                                return (React.createElement("a", { key: index, className: "staticBanner__breadcrumb", href: item.breadcrumbLink },
                                    " ",
                                    " / ",
                                    item.breadcrumbText,
                                    " "));
                            }
                        })))),
            React.createElement("div", null,
                React.createElement(Container, null,
                    React.createElement("div", { className: "articleBanner__content" },
                        React.createElement("span", { className: `articleBanner__tag articleBanner__tag--${props.difficultyColor}` }, props.difficulty),
                        React.createElement(Heading, { design: "h1-regular-small" }, props.title),
                        React.createElement("div", { className: "articleBanner__categories" }, props.categories.map((category, i) => React.createElement("a", { key: `articleBanner__category-${i}`, href: `/${props.culture}${props.categoryLinks.find(el => el.title.toLowerCase().includes(category.toLowerCase()))?.urlSuffix}` }, category))),
                        React.createElement("div", { className: "caption-bold color-dark-60" }, props.children),
                        React.createElement(Img, { src: props.image, alt: props.title, className: "articleBanner__image" }))))))));
const homeIcon = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 2 46 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M34.76 38.1725H26.47V32.5525C26.47 32.2625 26.23 32.0225 25.94 32.0225H22.25C21.96 32.0225 21.72 32.2625 21.72 32.5525V38.1725H14.08C12.64 38.1725 11.47 37.0025 11.47 35.5625V25.9825H9.60001C8.61001 25.9825 7.77001 25.3825 7.46001 24.4425C7.14001 23.5125 7.44001 22.5225 8.22001 21.9225L22.85 10.6725C23.66 10.0525 24.8 10.0525 25.61 10.6725L40.24 21.9225C41.02 22.5225 41.32 23.5125 41 24.4425C40.68 25.3725 39.84 25.9825 38.86 25.9825H37.38V35.5625C37.38 37.0025 36.21 38.1725 34.77 38.1725H34.76ZM27.87 36.7725H34.76C35.43 36.7725 35.97 36.2325 35.97 35.5625V25.5325C35.97 25.5325 35.97 25.4525 35.96 25.4125L35.84 24.6125L36.64 24.5825H38.85C39.42 24.5825 39.62 24.1325 39.67 23.9925C39.72 23.8525 39.83 23.3825 39.38 23.0325L24.75 11.7825C24.44 11.5425 24.01 11.5425 23.7 11.7825L9.07001 23.0325C8.61001 23.3825 8.73001 23.8625 8.78001 23.9925C8.83001 24.1325 9.02001 24.5825 9.60001 24.5825H13L12.89 25.3825C12.88 25.4525 12.87 25.4925 12.87 25.5325V35.5625C12.87 36.2325 13.41 36.7725 14.08 36.7725H20.32V32.5525C20.32 31.4925 21.19 30.6225 22.25 30.6225H25.94C27 30.6225 27.87 31.4925 27.87 32.5525V36.7725Z", fill: "#CFD2D6", stroke: "#CFD2D6", strokeWidth: "0.6" })));
