import React from "react";
import { Button } from "../../../Button";
import { Img } from "../../../Img";
export const AccountCard = (props) => (React.createElement("div", { className: "account-card" },
    React.createElement("h3", { className: "account-card__title" }, props.title),
    props.sections.map((section, i) => (React.createElement("div", { key: `account-card__section-${i}`, className: "account-card__section" },
        React.createElement("p", { className: "account-card__section-title" }, section.title),
        section.sectionItems.map((item, id) => (React.createElement("div", { key: `account-card__section-item-${id}`, className: "account-card__section-item" },
            React.createElement("p", { className: "account-card__section-item-title" }, item.title),
            item.text && (React.createElement("p", { className: "account-card__section-item-text" }, item.text)),
            item.icon && (React.createElement(Img, { src: item.icon, className: "account-card__section-item-icon" })),
            item.linkText && (React.createElement("a", { href: item.linkUrl, className: "account-card__section-item-link information-card__link" },
                item.linkText,
                " ",
                arrowIcon())),
            item.images && (React.createElement("div", { className: "account-card__section-item-images" }, item.images.map((img, index) => (React.createElement("div", { key: `account-card__section-item-image-${index}`, className: "account-card__section-item-image-container" },
                React.createElement("p", { className: "account-card__section-item-text-image-title" }, img.title),
                React.createElement(Img, { src: img.imageUrl, className: "account-card__section-item-image" })))))))))))),
    props.buttonText && (React.createElement(Button, { label: props.buttonText, href: props.buttonUrl, style: { margin: "auto" }, primary: true }))));
const arrowIcon = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#6cv15a3sra)" },
        React.createElement("path", { d: "m16.172 11-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2h12.172z", fill: "inherit" })),
    React.createElement("defs", null,
        React.createElement("clipPath", null,
            React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
