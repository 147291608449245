import React, { useCallback } from "react";
export const AlphabetsSearchCard = ({ title, linkURL, onClick, icon }) => {
    const handleClick = useCallback((e) => {
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    }, [onClick]);
    const activeClass = window.location.pathname === linkURL ? "alphabets-search-card__title--active" : "";
    return (React.createElement("a", { href: linkURL || "#", className: "alphabets-search-card", onClick: handleClick },
        React.createElement("div", { className: `alphabets-search-card__title centered-container ${activeClass}` },
            React.createElement("span", { className: "alphabets-search-card__text p-regular" }, icon || title))));
};
