import { useEffect } from "react";
import pako from "pako";
export const useInstrumentPriceWS = (inputs, handleWebSocketMessage) => {
    useEffect(() => {
        let websocket;
        let interval;
        const startWebsocket = () => {
            websocket = new WebSocket(`${inputs.wsUrl}/bid-ask/${inputs.session}/${inputs.instrument}`);
            websocket.binaryType = "arraybuffer";
            const pingRequest = new TextEncoder().encode("ping");
            websocket.onopen = () => {
                interval = setInterval(() => {
                    websocket.send(pingRequest);
                }, 1000);
            };
            websocket.onmessage = (event) => {
                const data = event.data;
                const message = pako.inflate(data, { to: "string" });
                if (message) {
                    const priceQuote = JSON.parse(message);
                    handleWebSocketMessage(priceQuote);
                }
            };
        };
        startWebsocket();
        setTimeout(() => {
            if (!websocket.OPEN) {
                startWebsocket();
            }
        }, 5000);
        return () => {
            clearInterval(interval);
            websocket.close();
        };
    }, [handleWebSocketMessage, inputs.instrument, inputs.session, inputs.wsUrl]);
};
