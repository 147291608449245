import React from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
export const InnovationBlockDemo = (props) => (React.createElement(ContainerFW, null,
    React.createElement(Container, null,
        React.createElement("div", { className: "innovation-block innovation-block--demo" },
            React.createElement("div", { className: "innovation-block__content" },
                React.createElement("h2", { className: "innovation-block__title", dangerouslySetInnerHTML: { __html: props.title } }),
                React.createElement(Img, { src: props.imageUrl, alt: props.title, className: "innovation-block__image--mobile" }),
                React.createElement("p", { className: "innovation-block__paragraph" }, props.paragraph),
                React.createElement("ul", { className: "innovation-block__list" }, props.list.map((item, i) => (React.createElement("li", { key: `innovation-block__list-item-${i}`, className: "innovation-block__item" },
                    React.createElement("div", { className: "innovation-block__item-checkmark" }, checkmark()),
                    React.createElement("div", { className: "innovation-block__item-content" },
                        React.createElement("strong", { className: "innovation-block__item-title" }, item.title),
                        React.createElement("p", { className: "innovation-block__item-text" }, item.subtitle))))))),
            React.createElement(Img, { src: props.imageUrl, alt: props.title, className: "innovation-block__image" })))));
const checkmark = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "m8.568 18.742.006.005a.863.863 0 0 0 .553.203h.005a.857.857 0 0 0 .626-.274l.006-.007 10.96-12.2a.849.849 0 0 0-.063-1.201.849.849 0 0 0-1.2.063h-.001L9.06 16.91l-4.59-4.005h.001l-.002-.003a.854.854 0 0 0-1.194.08l-.007.008c-.302.35-.27.89.086 1.198l.002.002 5.212 4.553z", fill: "#70AA1E", stroke: "#70AA1E" })));
