export const instrumentGroupsAndSubgroups = {
    Forex: ["Forex"],
    Indices: ["Indices"],
    Commodities: ["Commodities", "Energy", "Metals"],
    ETFs: ["ETFs", "ETFs TT & MT5"],
    Shares: ["Shares", "Shares TT & MT5", "Shares MT4"],
    Futures: ["Futures"],
    Crypto: ["Crypto"]
};
export const propertyReassignMap = {
    "en-AU": [
        {
            groupOrSubgroup: "Forex",
            instrumentCodes: ["AUD/CAD", "AUD/CHF", "AUD/JPY", "AUD/USD", "EUR/AUD", "GBP/AUD"],
            propertyName: "positionQuality",
            newValue: "Major"
        },
    ]
};
