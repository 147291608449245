import * as React from "react";
import { Container, ContainerFW, Col, Row } from "../../global";
import { Img } from "../../Img";
export const ContactsBanner = (props) => {
    const mouseClickEvents = ["mousedown", "click", "mouseup"];
    function simulateMouseClick(element) {
        mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })));
    }
    const simulateMouseClickOnZendeskChatIcon = React.useCallback(() => {
        const element = document.querySelector("a[class=\"chat-toggle\"]");
        simulateMouseClick(element);
    }, []);
    return (React.createElement("div", { className: "contacts-banner" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "contacts-banner__container" },
                    React.createElement(Row.Cols4, null, props.contacts.map((item, index) => (React.createElement(Col, { key: index },
                        React.createElement("div", { onClick: index === 0 ? simulateMouseClickOnZendeskChatIcon : undefined, className: "contacts-banner__item", style: { cursor: `${index === 0 ? "pointer" : "unset"}` } },
                            React.createElement(Img, { className: "contacts-banner__item-img", src: item.iconURL, alt: item.iconAlt }),
                            React.createElement("a", { href: index === 0 ? undefined : item.linkUrl, className: `contacts-banner__item-text ${index >= 2 ? "blueLagoon" : ""}` },
                                React.createElement("span", { className: `${item.additionalText
                                        ? "circle"
                                        : "no-circle"}` }),
                                item.text),
                            React.createElement("p", { className: "contacts-banner__item-additional-text" }, item.additionalText)))))))))));
};
