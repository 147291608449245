import React from "react";
import { getColors } from "../../utils/getColors";
import { Container, ContainerFW } from "../global";
import { CustomLottie } from "../CustomLottie";
import { webIcon } from "../svgIcons/webIcon";
import { mobileIcon } from "../svgIcons/mobileIcon";
export const CardDeckWithTitleAndAnimatinOrImage = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    const haveSvgIcon1 = (props.cards[0].cardAnimation.substr(props.cards[0].cardAnimation.length - 3) === "svg" ? 1 : 0);
    const haveSvgIcon2 = (props.cards[1].cardAnimation.substr(props.cards[1].cardAnimation.length - 3) === "svg" ? 1 : 0);
    const imgMarginBottom = ((haveSvgIcon1 + haveSvgIcon2) === 2 ? "20px" : "40px");
    const labels = (haveLabels) => (haveLabels && React.createElement("div", { className: "card-deck-with-title-and-animation-or-image__labels" },
        React.createElement("span", { className: "card-deck-with-title-and-animation-or-image__label-item" },
            webIcon,
            " ",
            React.createElement("p", null, "Web")),
        React.createElement("span", { className: "card-deck-with-title-and-animation-or-image__label-item" },
            mobileIcon,
            " ",
            React.createElement("p", null, "Mobile"))));
    const subtitle = (haveSubtitle) => (haveSubtitle && props.subtitle && React.createElement("p", { className: `${props.subtitlePosition === undefined || props.subtitlePosition === "Center" ? "margin-auto" : ""}`, style: { textAlign: props.subtitlePosition === undefined ? "center" : props.subtitlePosition.toLowerCase() } }, props.subtitle));
    const cardItem = () => (props.cards.length > 0 && props.cards.map((card, index) => (index < 2 ? (React.createElement("div", { key: index, className: "card-deck-with-title-and-animation-or-image__left-card", style: { backgroundColor: props.cardsBackgroundColor } },
        React.createElement("div", { className: "card-deck-with-title-and-animation-or-image__left-card-image", style: { marginBottom: imgMarginBottom } },
            React.createElement(CustomLottie, { animation: card.cardAnimation })),
        React.createElement("div", { className: "card-deck-with-title-and-animation-or-image__left-card-text" },
            React.createElement("h5", null, card.cardTitle),
            React.createElement("p", { dangerouslySetInnerHTML: { __html: card.cardSubTitle } })))) : "")));
    const rightWraper = () => (React.createElement("div", { className: "card-deck-with-title-and-animation-or-image__right-wrapper", style: { flexDirection: props.imagePosition === "bottom" ? "column-reverse" : "column", padding: props.imagePosition === "bottom" ? "40px 0px 0px 0px" : "30px 0px 30px 0px", backgroundColor: props.cardsBackgroundColor } },
        React.createElement("div", { className: `card-deck-with-title-and-animation-or-image__right-card-image ${props.imagePosition === "bottom" ? "imageBottom" : "centered-image"}` },
            React.createElement(CustomLottie, { animation: props.cards[2].cardAnimation })),
        React.createElement("div", { className: "card-deck-with-title-and-animation-or-image__right-card-text" },
            React.createElement("h5", null, props.cards[2].cardTitle),
            React.createElement("p", { dangerouslySetInnerHTML: { __html: props.cards[2].cardSubTitle } }),
            labels(props.haveLabels))));
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: "card-deck-with-title-and-animation-or-image" },
                React.createElement("div", { className: "card-deck-with-title-and-animation-or-image__text-wrapper" },
                    React.createElement("h2", { style: { textAlign: props.titlePosition === undefined ? "center" : props.titlePosition.toLowerCase() } }, props.title),
                    subtitle(props.subtitle)),
                React.createElement("div", { className: `card-deck-with-title-and-animation-or-image__cards-wrapper ${props.bigCardPosition === "right" ? "mobile-image-bottom" : "mobile-image-top"}` },
                    React.createElement("div", { className: "card-deck-with-title-and-animation-or-image__left-wrapper" }, cardItem()),
                    rightWraper())))));
};
