export const fetchData = async (url, limit, setJobs, setErrorMessage) => fetch(`${url}&per_page=${limit.toString()}`)
    .then(response => {
    if (response.ok) {
        return response.json();
    }
    else {
        return Promise.reject();
    }
})
    .then((data) => {
    setJobs(data.jobs);
})
    .catch(() => {
    setErrorMessage("Something went wrong! Please try again!");
});
