import * as React from "react";
import { Container, ContainerFW } from "../global";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { IconChevronDown } from "../svgIcons";
import { getColors } from "../../utils/getColors";
export const BigTextSection = ({ title, description, openButtonLabel, linkUrl, openInNewTab = true, backgroundColor }) => {
    const [descriptionToShow, setDescriptionToShow] = React.useState(description);
    const { bgColor } = getColors(backgroundColor);
    React.useEffect(() => {
        setDescriptionToShow(description);
    }, [description]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "big-text-section" },
                React.createElement("div", { className: "big-text-section__title-container" },
                    React.createElement("h2", null, title)),
                React.createElement("div", { className: "big-text-section__description-container", dangerouslySetInnerHTML: {
                        __html: descriptionToShow,
                    } }),
                React.createElement("div", { className: "big-text-section__button-container" },
                    React.createElement(ButtonV2, { href: linkUrl, design: "ghost-medium-wbg", openInNewTab: openInNewTab },
                        openButtonLabel,
                        " ",
                        React.createElement("span", { className: "chevronUp" }, IconChevronDown({}))))))));
};
