import React from "react";
import { Container, ContainerFW } from "../global";
import { CardItem } from "./CardItem";
import { getColors } from "../../utils";
export const PopularInstrumentsByGroups = ({ title, instruments, linkLabel, linkUrl, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "popular-instruments-by-groups-root" },
            React.createElement(Container, null,
                React.createElement("h2", { className: "popular-instruments-by-groups-root__title" }, title),
                React.createElement("div", { className: "popular-instruments-by-groups-root__cards-container" }, instruments.length > 0 && instruments.map((a) => (React.createElement("div", { key: `unique${a.groupName}`, className: "popular-instruments-by-groups-root__cards-container__cardItem" },
                    React.createElement(CardItem, { groupName: a.groupName, instrumentItems: a.instrumentItems }))))),
                React.createElement("a", { className: "popular-instruments-by-groups-root__link", href: linkUrl }, linkLabel)))));
};
