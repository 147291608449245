import * as React from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils/getColors";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
export const TextAndImageLPCentered = ({ imageAlt, imageUrl, text, title, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "text-and-image-lp-centered_root" },
            React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
                React.createElement("div", { className: "text-and-image-lp-centered_root__image-container" },
                    React.createElement(ImgV2, { src: imageUrl, alt: imageAlt })),
                React.createElement("div", { className: "text-and-image-lp-centered_root__text-container" },
                    React.createElement(Heading, { design: "h2-regular" }, title),
                    React.createElement("p", { className: "text-and-image-lp-centered_root__text-description copy-LP", dangerouslySetInnerHTML: {
                            __html: text || ""
                        } }))))));
};
