import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { Img } from "../../Img";
import { Breadcrumbs } from "../../Breadcrumbs";
const getRootClassName = (props) => {
    let rootClassName = "staticBanner ";
    if (props.isSmallBanner) {
        rootClassName += "staticBanner--small ";
    }
    if (!props.primaryLinkText && !props.secondaryLinkText) {
        rootClassName += "staticBanner--no-cta ";
    }
    return rootClassName;
};
export const StaticBanner = (props) => {
    const rootClassName = getRootClassName(props);
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: rootClassName },
            React.createElement(Img, { className: "staticBanner__bg", src: props.imageUrl, alt: props.imageAlt, loading: "eager" }),
            React.createElement("div", { className: "staticBanner__content" },
                React.createElement("div", null, props.breadcrumbs && React.createElement(Breadcrumbs, { breadcrumbs: props.breadcrumbs })),
                React.createElement("div", null,
                    React.createElement(Container, null,
                        React.createElement("h1", null, props.title),
                        React.createElement("p", { className: "staticBanner__text", dangerouslySetInnerHTML: {
                                __html: props.content,
                            } }),
                        React.createElement("div", { className: "staticBanner__cta" },
                            props.primaryLinkText && (React.createElement("a", { href: props.primaryLinkUrl, className: "staticBanner__btn" }, props.primaryLinkText)),
                            props.secondaryLinkText && (React.createElement("a", { href: props.secondaryLinkUrl, className: "staticBanner__link" }, props.secondaryLinkText)))))))));
};
