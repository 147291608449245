import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
import { getColors } from "../../../utils";
import { useInView } from "react-intersection-observer";
const getButtonDesign = (buttonType) => buttonType === "primary" ? "cta-medium-wbg" : "ghost-medium-wbg";
export const OptionalButtonBanner = ({ title, description, note, imageDesktop, imageMobile, alt, imagePosition, buttonText, buttonUrl, backgroundColor, buttonType, hasBigImage, linkText, linkURL, openInNewTabButton }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const [bigImage, setBigImage] = React.useState("");
    const [smallImage, setSmallImage] = React.useState("");
    const titleSection = () => (title && React.createElement("div", { className: "optional-button-banner__title-container" },
        React.createElement("h2", { dangerouslySetInnerHTML: { __html: title } })));
    const buttonSection = () => (buttonText && React.createElement(ButtonV2, { design: getButtonDesign(buttonType), href: buttonUrl, openInNewTab: openInNewTabButton }, buttonText));
    const linkSection = () => (linkText && React.createElement("a", { className: "optional-button-banner__link-wrapper", href: linkURL }, linkText));
    React.useEffect(() => {
        setBigImage(imageDesktop);
    }, [imageDesktop]);
    React.useEffect(() => {
        setSmallImage(imageMobile);
    }, [imageMobile]);
    const leftDesktopImageSection = () => (React.createElement("div", { className: "optional-button-banner__image-container-left hide-medium hide-small" }, imagePosition.toLowerCase() === "left" && React.createElement(ImgV2, { largeUrl: bigImage, strictRender: true, alt: alt })));
    const rightDesktopImageSection = () => (React.createElement("div", { className: "optional-button-banner__image-container-right hide-medium hide-small" },
        imagePosition.toLowerCase() !== "left" && React.createElement(ImgV2, { largeUrl: bigImage, strictRender: true, alt: alt }),
        note && React.createElement("div", { className: "footnote" }, note)));
    const mobileImageSection = () => (React.createElement("div", { className: "optional-button-banner__image-container-mobile hide-large" },
        React.createElement(ImgV2, { mediumUrl: bigImage, smallUrl: smallImage, strictRender: true, alt: alt }),
        note && React.createElement("div", { className: "footnote" }, note)));
    return (React.createElement("div", { className: "option-button-banner__container" },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, null,
                React.createElement("div", { style: { color: textColor }, className: `optional-button-banner ${hasBigImage ? "optional-button-banner--big-image" : ""} ${inView ? "optional-button-banner--animated" : ""}`, ref: ref },
                    React.createElement("div", { className: "optional-button-banner__flex-outer" },
                        leftDesktopImageSection(),
                        React.createElement("div", { className: "optional-button-banner__flex-middle" },
                            titleSection(),
                            mobileImageSection(),
                            React.createElement("div", { className: "optional-button-banner__description-container" },
                                React.createElement("div", { dangerouslySetInnerHTML: { __html: description } })),
                            React.createElement("div", { className: "optional-button-banner__button-container" },
                                buttonSection(),
                                linkSection())),
                        rightDesktopImageSection()))))));
};
