import * as React from "react";
import { Container, ContainerFW } from "../../global";
export const RibbonBanner2 = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "ribbonBanner2" },
        React.createElement(Container, null,
            React.createElement("div", { className: "ribbonBanner2__inner" },
                React.createElement("div", { className: "ribbonBanner2__text" },
                    React.createElement("h3", null, props.largeText),
                    React.createElement("p", null, props.smallText)),
                React.createElement("a", { href: props.buttonUrl, className: "ribbonBanner2__cta" }, props.buttonText))))));
