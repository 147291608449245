import React from "react";
import { SimpleLightbox } from "../SimpleLightbox/SimpleLightbox";
import { YouTubeVideoEmbed } from "../YouTubeVideoEmbed";
export const VideoCard = (props) => {
    const isLightboxed = typeof props.hasLightbox === "undefined" || props.hasLightbox === true;
    if (!props.videoUrl) {
        return null;
    }
    return (React.createElement("div", { className: "videoCard" },
        React.createElement("div", { className: "videoCard__embedded" },
            React.createElement(VideoEmbedHelper, { videoUrl: props.videoUrl, hasLightbox: isLightboxed, webSiteRootUrl: props.webSiteRootUrl })),
        React.createElement("p", null, props.title),
        (props.author && props.created) && React.createElement("h6", { className: "videoCard__authorCreated" },
            props.author,
            " \u2022 ",
            props.created),
        props.category && React.createElement("h6", { className: "videoCard__category" },
            iconBook(),
            props.category)));
};
export const VideoEmbedHelper = (props) => props.hasLightbox ? React.createElement(SimpleLightbox, { className: "videoCard__lightbox-trigger" },
    React.createElement(YouTubeVideoEmbed, { videoUrl: props.videoUrl, webSiteRootUrl: props.webSiteRootUrl })) : React.createElement(YouTubeVideoEmbed, { videoUrl: props.videoUrl, webSiteRootUrl: props.webSiteRootUrl });
const iconBook = () => (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 12.737a.227.227 0 0 1-.174-.077c-.666-.747-1.483-1.097-2.566-1.097-.747 0-1.494.25-2.217.744a.232.232 0 0 1-.363-.193V4.28c0-.077.04-.15.103-.193.803-.547 1.637-.824 2.48-.824 1.21 0 2.163.41 2.917 1.254a.24.24 0 0 1 .06.156v7.83c0 .097-.06.184-.15.217a.247.247 0 0 1-.084.017H8zm-2.74-1.64c1 0 1.826.28 2.506.853V4.763C7.11 4.06 6.31 3.733 5.26 3.733c-.714 0-1.424.227-2.117.674v7.283c.69-.397 1.4-.597 2.117-.597v.004z", fill: "#007C8C" }),
    React.createElement("path", { d: "M8 12.737a.247.247 0 0 1-.082-.017.231.231 0 0 1-.15-.217V4.67a.23.23 0 0 1 .06-.157c.75-.843 1.703-1.253 2.916-1.253.844 0 1.677.277 2.48.823a.237.237 0 0 1 .104.194v7.83a.232.232 0 0 1-.364.193c-.723-.493-1.47-.743-2.216-.743-1.084 0-1.9.346-2.567 1.096a.238.238 0 0 1-.173.077L8 12.737zm.234-7.974v7.187c.68-.573 1.507-.853 2.507-.853.717 0 1.427.2 2.117.596V4.407c-.694-.447-1.404-.674-2.117-.674-1.053 0-1.853.327-2.507 1.03z", fill: "#007C8C" })));
