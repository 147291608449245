import * as React from "react";
import { ButtonV2 } from "../ButtonV2";
import { ContainerFW, Container } from "../global";
import { IconClose } from "../svgIcons";
import { Checkboxes } from "./Checkboxes/Checkboxes";
import { CheckboxesDatepicker } from "./Checkboxes/CheckboxesDatepicker";
import { Range } from "./Range/Range";
import { getFilterItemsType } from "./utils";
export const Filters = ({ resetText, applyText, fields, data, setData, reset, setReset, submit, filterToggle, areAnyFiltersSet, checkAll, }) => {
    const [hasValuesToClear, setHasValuesToClear] = React.useState(false);
    const getCurrentlySelectedItem = (filterField, data) => {
        const dataKey = filterField.title.replaceAll(" ", "");
        const dataValue = data[dataKey];
        if (filterField.type === "range") {
            return "";
        }
        if (filterField.type === "checkbox") {
            const values = (dataValue || "").split(",");
            return values;
        }
        if (filterField.type === "checkbox-date") {
            return dataValue;
        }
        return "";
    };
    const extendedFields = fields.map((filterField) => ({
        ...filterField,
        currentValue: getCurrentlySelectedItem(filterField, data)
    }));
    const resetFilters = React.useCallback(() => {
        const newData = {};
        fields.forEach((field) => {
            newData[field.title.replaceAll(" ", "")] = "";
        });
        setData(newData);
    }, [fields, setData]);
    const resetHandler = React.useCallback((e) => {
        e.preventDefault();
        setReset(e);
        resetFilters();
        filterToggle(false);
    }, [filterToggle, resetFilters, setReset]);
    const applyFilters = React.useCallback((e) => {
        e.preventDefault();
        let q = "";
        Object.keys(data).map(k => q += `${data[k].title}=${data[k].value}`);
        submit(q);
        filterToggle(false);
    }, [data, filterToggle, submit]);
    React.useEffect(() => {
        if (!areAnyFiltersSet) {
            resetFilters();
        }
    }, [areAnyFiltersSet, resetFilters]);
    const fieldHandler = React.useCallback((title, v) => {
        setData(prevData => {
            const copiedData = { ...prevData };
            return {
                ...copiedData,
                [title]: v
            };
        });
    }, [setData]);
    const closeFilters = React.useCallback(() => filterToggle(false), [filterToggle]);
    React.useEffect(() => {
        const dataValues = Object.values(data);
        const validValues = dataValues.filter((a) => a !== "");
        if (validValues.length > 0) {
            setHasValuesToClear(true);
        }
        if (validValues.length === 0) {
            setHasValuesToClear(false);
        }
    }, [data]);
    const processFilterItems = React.useCallback((index) => {
        const fieldType = fields[index].type;
        const itemsType = getFilterItemsType(fields[index]);
        const filterItems = fields[index].items;
        if (itemsType === "string") {
            if (fieldType === "checkbox-date") {
                return filterItems.map((item) => ({
                    categoryId: item,
                    categoryTitle: item
                }));
            }
            return filterItems.map(item => item);
        }
        if (itemsType === "object") {
            return filterItems.map((a) => a.categoryTitle);
        }
        return [];
    }, [fields]);
    const getFieldDisplayText = (field) => field.displayText || field.title;
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "filters" },
            React.createElement(Container, null,
                React.createElement("h4", { className: "filters__header" },
                    "Filters",
                    React.createElement("span", { onClick: closeFilters, className: "filters__close" }, IconClose())),
                React.createElement("div", { className: "filters__wrap" },
                    React.createElement("div", { className: "filters__container" }, extendedFields.map((field, i) => (React.createElement("div", { key: `filter-${i}`, className: `filters__field filters__field-${field.type}` },
                        React.createElement("div", { className: "filters__field-title caption-bold" },
                            getFieldDisplayText(field),
                            " ",
                            field.type === "checkbox" && React.createElement("a", { href: "#", onClick: checkAll(field.title) }, "Select all")),
                        field.type === "range" && React.createElement(Range, { reset: reset, title: field.title, handler: fieldHandler, data: fields, value: 5, min: field.min, max: field.max, id: i }),
                        (field.type === "checkbox" || field.type === "tab") &&
                            React.createElement(Checkboxes, { type: field.type, checkAll: checkAll[field.title], handler: fieldHandler, data: fields, filtersData: data, inlineLayout: field.inlineLayout, title: field.title, items: processFilterItems(i), id: i, currentValue: field.currentValue }),
                        field.type === "checkbox-date" &&
                            React.createElement(CheckboxesDatepicker, { reset: reset, handler: fieldHandler, data: fields, inlineLayout: field.inlineLayout, title: field.title, items: processFilterItems(i), id: i })))))),
                React.createElement("div", { className: "filters__actions" },
                    (hasValuesToClear) && React.createElement("a", { href: "#", onClick: resetHandler, className: "filters__reset" }, resetText),
                    React.createElement(ButtonV2, { onClick: applyFilters, design: "ghost-medium-wbg" }, applyText))))));
};
