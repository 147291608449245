import React from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils";
import { Heading } from "../Heading";
export const CardsGridTitleDescriptionIcon = ({ title, description, designStyles, cards }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { style: { color: textColor }, className: "cards-grid-title-description-icon" },
                React.createElement("div", { className: "cards-grid-title-description-icon__top-end" },
                    React.createElement(Heading, { design: designStyles?.titleDesign }, title),
                    React.createElement("p", { className: "copy-lp" }, description)),
                React.createElement("div", { className: "cards-grid-title-description-icon__cards-container" }, cards?.map((c, i) => (React.createElement("div", { key: i, className: "cards-grid-title-description-icon__card color-dark-90" },
                    React.createElement("div", { className: "cards-grid-title-description-icon__card-top-strip" },
                        React.createElement(Heading, { design: "h3-bold-small" }, c.title),
                        React.createElement("img", { src: c.imageUrl, alt: c.imageAlt })),
                    React.createElement("div", { className: "cards-grid-title-description-icon__card-bottom-strip" },
                        React.createElement("p", { className: "copy-lp" }, c.description))))))))));
};
