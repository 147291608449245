import React, { useState, useEffect, useCallback, useMemo } from "react";
import { IconChevronDown } from "../svgIcons";
import { ImgV2 } from "../ImgV2/ImgV2";
const TabFaqAccordionCard = ({ id, question, icon, answer, setActiveCardId, activeCardId }) => {
    const handleExpand = useCallback(() => {
        if (setActiveCardId && id === activeCardId) {
            setActiveCardId(-1);
        }
        else if (setActiveCardId && id !== undefined) {
            setActiveCardId(id);
        }
    }, [activeCardId, id, setActiveCardId]);
    const [answerValue, setAnswerValue] = useState("");
    useEffect(() => {
        setAnswerValue(answer);
    }, [answer]);
    return (React.createElement("div", { itemScope: true, itemProp: "mainEntity", itemType: "https://schema.org/Question", className: `faq-accordion-list__faq-card ${id === activeCardId ? "active" : ""}` },
        React.createElement("div", { className: "faq-accordion-list__faq-card-question", onClick: handleExpand },
            React.createElement("div", { className: "flex faq-accordion-list__plus-minus-text" },
                React.createElement("h5", { itemProp: "name", className: "faq-accordion-list__title" },
                    icon && React.createElement(ImgV2, { width: 38, height: 38, src: icon, alt: question }),
                    question),
                React.createElement("span", { className: "faq-accordion-list__icon-close" }, IconChevronDown({})))),
        React.createElement("div", { className: "flex faq-accordion-list__plus-minus-text" },
            React.createElement("div", { itemScope: true, itemProp: "acceptedAnswer", itemType: "https://schema.org/Answer" },
                React.createElement("div", { itemProp: "text", className: "faq-accordion-list__faq-card-answer", dangerouslySetInnerHTML: { __html: answerValue } })))));
};
export const TabWithAccordion = ({ title, subTitle, items }) => {
    const [activeCardId, setActiveCardId] = React.useState(-1);
    const accordion = useMemo(() => (React.createElement("div", { className: "faq-accordion-list__deck" }, items.map((item, i) => React.createElement(TabFaqAccordionCard, { key: `faq-accordion-list__card-${i}`, id: i, question: item.question, answer: item.answer, icon: item.icon, setActiveCardId: setActiveCardId, activeCardId: activeCardId })))), [items, setActiveCardId, activeCardId]);
    return (React.createElement("div", { className: "faq-accordion-list" },
        React.createElement("div", { itemScope: true, itemType: "https://schema.org/FAQPage", className: "faq-accordion-list__column" },
            React.createElement("h3", { className: "faq-accordion-list__title" }, title),
            subTitle && React.createElement("div", { className: "faq-accordion-list__content", dangerouslySetInnerHTML: { __html: subTitle } }),
            accordion)));
};
