import * as React from "react";
import { ImgV2 } from "../../../ImgV2/ImgV2";
import { GreenCheckIcon } from "../../../svgIcons";
export const TheCard = ({ cardTitle, cardPlace, iconPlace, listItems }) => {
    const separatedArray = [...listItems.split("|")];
    return (React.createElement("div", { className: "the-card" },
        React.createElement("div", { className: "the-card__place-title-wrappers" },
            React.createElement("div", { className: "the-card__place-wrapper" },
                React.createElement("span", null,
                    iconPlace && React.createElement(ImgV2, { src: iconPlace, alt: cardTitle }),
                    cardPlace)),
            React.createElement("div", { className: "the-card__title-wrapper" },
                React.createElement("h4", null, cardTitle))),
        React.createElement("div", { className: "the-card__list-wrapper" }, separatedArray && separatedArray.map((item, i) => (React.createElement("div", { key: i, className: "the-card__list-wrapper__list-item" },
            GreenCheckIcon(iconPlace ? "#5EE15A" : "#4BB448"),
            " ",
            React.createElement("span", null, item)))))));
};
