import { useEffect, useRef } from "react";
import { getWindowLocationDifficulty, getWindowLocationPage, setWindowLocationPage } from "../utils";
export const useHandleWindowLocationPage = (currentPage, setCurrentPageFunc, skipDifficultyPages = false) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (skipDifficultyPages) {
            if (getWindowLocationDifficulty()) {
                return;
            }
        }
        if (!isFirstRender.current) {
            setWindowLocationPage(currentPage);
            return;
        }
        const pageInUrl = getWindowLocationPage();
        if (pageInUrl) {
            if (pageInUrl !== currentPage) {
                setCurrentPageFunc(pageInUrl);
            }
        }
        else {
            setWindowLocationPage(currentPage);
        }
    }, [currentPage, setCurrentPageFunc, skipDifficultyPages]);
    useEffect(() => {
        isFirstRender.current = false;
    }, []);
};
