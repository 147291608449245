import React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { getColors } from "../../utils";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { Heading } from "../Heading";
export const ItemDeckWithTitle = ({ cards, backgroundColor = "white", title, numColumns = 3, buttonUrl, buttonText, openInNewTabButton }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: `${backgroundColor === "dark" ? "itemDeckWithTitle-dark-wrapper" : "itemDeckWithTitle-wrapper"} itemDeckWithTitle-wrapper--${numColumns}-cols` },
            React.createElement(Container, null,
                React.createElement("div", { className: "itemDeckWithTitle-wrapper__grid-wrapper" },
                    title && React.createElement(Heading, { design: "h2-regular-small" }, title),
                    React.createElement("div", { className: "itemDeckWithTitle-wrapper__grid-wrapper__cards-root" }, cards.length > 0 && cards.map((a) => (React.createElement("div", { key: `unique${a.title}`, className: "itemDeckWithTitle-wrapper__grid-wrapper__cards-root__cards-wrapper" },
                        React.createElement(Img, { src: a.imageUrl, alt: a.imageAlt }),
                        React.createElement("span", { className: "itemDeckWithTitle-wrapper__grid-wrapper__cards-root__cards-wrapper__texts" },
                            React.createElement(Heading, { design: "h2-regular-small" }, a.title),
                            React.createElement("p", { dangerouslySetInnerHTML: {
                                    __html: a.comment,
                                } })))))),
                    buttonText && React.createElement("div", { className: "itemDeckWithTitle-wrapper__button-wrapper" },
                        React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonUrl, openInNewTab: openInNewTabButton }, buttonText)))))));
};
