import React from "react";
import { Img } from "../../Img";
export const CardWithImage = ({ bgCardPicture, bgCardPictureAlt, subTitle, title }) => (React.createElement("div", { className: "card-with-image" },
    React.createElement("div", { className: "card-with-image__imageContainer" },
        React.createElement(Img, { src: bgCardPicture, alt: bgCardPictureAlt })),
    React.createElement("h4", { dangerouslySetInnerHTML: {
            __html: title
        } }),
    React.createElement("p", { dangerouslySetInnerHTML: {
            __html: subTitle
        } })));
