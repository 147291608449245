import React from "react";
export const supportMaintenanceIcon = (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", "aria-label": "Maintenance" },
    React.createElement("g", { clipPath: "url(#clip0_17165_362958)" },
        React.createElement("mask", { id: "mask0_17165_362958", style: { maskType: "luminance" }, maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "16", height: "16" },
            React.createElement("path", { d: "M16 0H0V16H16V0Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0_17165_362958)" },
            React.createElement("path", { d: "M6.70234 7.0705L1.9883 11.7846C1.4676 12.3052 1.4676 13.1494 1.9883 13.6702C2.50899 14.1908 3.35321 14.1908 3.87391 13.6702L8.58794 8.9561", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M6.70428 7.07045C6.14173 5.6351 6.2517 3.75178 7.41141 2.59211C8.57108 1.43243 10.7112 1.17789 11.8897 1.885L9.86261 3.91211L9.67421 5.98614L11.7482 5.79773L13.7753 3.77061C14.4825 4.94913 14.2279 7.08931 13.0682 8.24898C11.9085 9.40865 10.0252 9.51865 8.58988 8.95605", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }))),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_17165_362958" },
            React.createElement("rect", { width: "16", height: "16", fill: "white" })))));
