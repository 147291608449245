import { useState, useEffect } from "react";
export function getMarketSentiment(symbols, securityKey) {
    return fetch(`https://api.thinkmarkets.com/tm/getBBI?${securityKey ? `securityKey=${securityKey}` : ""}${symbols ? `&symbols=${symbols}` : ""}`);
}
export function useMarketSentiment(symbols = "USDJPY,USDCAD,EURUSD,US30,GER30,XAUUSD,GBPUSD,AUDUSD", securityKey = "zzz9bbb59df534b077c0e9a7e425ea97ffc") {
    const [marketSentiments, setMarketSentiments] = useState([]);
    const [error, setError] = useState(undefined);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMarketSentiment(symbols, securityKey);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}, statusText: ${response.statusText}`);
                }
                const data = await response.json();
                setMarketSentiments(data);
            }
            catch (error) {
                setError(error);
            }
        };
        void fetchData();
    }, [symbols, securityKey]);
    return {
        marketSentiments,
        error
    };
}
