import * as React from "react";
import { ContainerFW, Container } from "../global";
import { Heading } from "../Heading";
import { ButtonV2 } from "../ButtonV2";
import { accountTypesTick } from "../svgIcons";
import { ImgV2 } from "../ImgV2";
import { getColors } from "../../utils/getColors";
const AccountCardV2 = ({ mostPopularTitle, title, titleIconUrl, titleIconAlt, description, spec1, spec2, spec3, availableLabel, platformsIcons1, platformsIcons2, buttonLearnMoreText, buttonLearnMoreUrl, buttonLearnMoreDesign, buttonLearnMoreOpenInNewTab, featuresBulletsSplit, buttonSeeAllFeaturesText }) => {
    const featuresBullets = featuresBulletsSplit?.split("%%%");
    const [expandedFeatures, setExpandedFeatures] = React.useState(false);
    const handleToggleFeatures = React.useCallback(() => {
        setExpandedFeatures(prev => !prev);
    }, []);
    const renderSpec = React.useCallback((spec) => (React.createElement(React.Fragment, null, spec && React.createElement("div", { className: "account-types-v2__spec-row" },
        React.createElement("div", { className: "account-types-v2__spec-row-left" }, spec?.split("###")[0]),
        React.createElement("div", { className: "p-bold" }, spec?.split("###")[1])))), []);
    return (React.createElement("div", { className: `account-types-v2__card ${mostPopularTitle ? "account-types-v2__card__popular" : ""}` },
        React.createElement("div", { className: "account-types-v2__card-header" },
            React.createElement("span", { className: "hide-small" },
                React.createElement(Heading, { design: "h2-regular-small" }, title)),
            React.createElement("span", { className: "hide-medium hide-large" },
                React.createElement(Heading, { design: "h1-regular-small" }, title)),
            React.createElement(ImgV2, { src: titleIconUrl, alt: titleIconAlt, className: "account-types-v2__titleIcon" })),
        React.createElement("p", { className: "account-types-v2__description caption2-regular" }, description),
        React.createElement("div", { className: "account-types-v2__separator-line" }),
        React.createElement("div", { className: "account-types-v2__specs" },
            renderSpec(spec1),
            renderSpec(spec2),
            renderSpec(spec3)),
        React.createElement("div", { className: "account-types-v2__separator-line" }),
        React.createElement("div", null,
            React.createElement("p", { className: "account-types-v2__available-label caption-risk-warning-bold color-dark-60" },
                " ",
                availableLabel),
            platformsIcons1 && (React.createElement("div", { className: "account-types-v2__card-platforms-icons-container" },
                React.createElement(ImgV2, { src: platformsIcons1, alt: "Platform Icon", className: "account-types-v22__platformIcon" }),
                React.createElement(ImgV2, { src: platformsIcons2, alt: "Platform Icon", className: "account-types-v22__platformIcon" })))),
        React.createElement(ButtonV2, { href: buttonLearnMoreUrl, openInNewTab: buttonLearnMoreOpenInNewTab, design: buttonLearnMoreDesign, showArrowRight: true }, buttonLearnMoreText),
        React.createElement("div", { className: "account-types-v2__expandable " },
            React.createElement("div", null, featuresBullets && (React.createElement("div", { className: "account-types-v2__features-bullets" }, (expandedFeatures ? featuresBullets : featuresBullets?.slice(0, 3)).map((feature, index) => (React.createElement("div", { key: index, className: "flex" },
                React.createElement("div", { className: "account-types-v2__features-bullet-tick" }, accountTypesTick),
                React.createElement("div", { className: "caption2-regular" },
                    " ",
                    feature))))))),
            React.createElement(ButtonV2, { design: "tmx25-square-white-lightblue", showArrowDown: !expandedFeatures, showArrowUp: expandedFeatures, onClick: handleToggleFeatures }, buttonSeeAllFeaturesText))));
};
export const AccountTypesV2 = ({ title, description, backgroundColor, cards }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "account-types-v2" },
                title &&
                    (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "centered-container" },
                            React.createElement(Heading, { design: "h1-regular" }, title)),
                        React.createElement("div", { className: "centered-container color-dark-80" },
                            React.createElement(Heading, { design: "h5-semi-bold" }, description)),
                        React.createElement("div", null),
                        React.createElement("div", null))),
                React.createElement("div", { className: "account-types-v2__cards-container" }, cards?.map((item, i) => React.createElement("div", { key: `key-${i}` },
                    React.createElement("div", { className: `account-types-v2__col ${item.mostPopularTitle ? "mostpopular" : ""}`, key: `key-pbody-${i}` },
                        item.mostPopularTitle && React.createElement("div", { className: "centered-container account-types-v2__most-popular-label account-types-v2__most-popular-text" }, item.mostPopularTitle),
                        !item.mostPopularTitle && React.createElement("div", { className: "centered-container account-types-v2__most-popular-label account-types-v2__most-popular-text visibility-hidden" }, "Account"),
                        React.createElement(AccountCardV2, { ...item })))))))));
};
