import * as React from "react";
export const Range = (props) => {
    const [range, setRangeValue] = React.useState(props.value ? props.value : 0);
    const [step, setStep] = React.useState(0);
    const ref = React.useRef(undefined);
    React.useEffect(() => {
        setRange();
        if (ref.current !== undefined) {
            const rangeLinePadding = 16;
            const calcStep = (ref.current.offsetWidth - rangeLinePadding) / (props.max - props.min);
            setStep(calcStep);
        }
    }, []);
    React.useEffect(() => {
        setRangeValue(props.max / 2),
            setTimeout(() => setRange());
    }, [props.reset]);
    const onRangeInput = (element) => {
        {
            const valPercent = (element.valueAsNumber - parseInt(element.min)) / (parseInt(element.max) - parseInt(element.min));
            const style = `background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${valPercent}, #0E1D31), color-stop(${valPercent}, #f2f4f6))`;
            element.setAttribute("style", style);
        }
    };
    const setRange = () => {
        document?.querySelectorAll(".range")?.forEach(function (el) {
            const element = el;
            onRangeInput(element);
        });
    };
    React.useEffect(() => {
        props.handler(props.title.replaceAll(" ", ""), range);
    }, [range]);
    const fieldHandler = React.useCallback((e) => {
        setRangeValue(e.target.value);
        setRange();
    }, [range, props.data]);
    return (React.createElement("div", { className: "range__container" },
        React.createElement("input", { ref: ref, onInput: fieldHandler, className: "range", id: `range-${props.id}`, type: "range", value: range, min: props.min, max: props.max }),
        React.createElement("label", { htmlFor: "range", style: {
                transform: `translateX(${range * step - step * props.min}px)`,
            } },
            React.createElement("sub", null,
                " ",
                range * 1 !== props.max ? `${range}min.` : `${range}min+`,
                " ")),
        React.createElement("div", { className: "range__options" }, [...Array(10).keys()].filter(item => item % (props.max / 5) === 0).map((el, i) => React.createElement("sub", { key: `range__option-${i}`, className: "range__option" }, i !== 4 ? `${el + 1} min.` : `${props.max} min+`)))));
};
