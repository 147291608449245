import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils";
export const SectionWithThreeColumns = ({ backgroundColor, content }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "section-with-three-columns" },
                React.createElement("div", { className: "section-with-three-columns__cards-wrapper" }, content.map((item, i) => React.createElement("div", { className: "section-with-three-columns__card-box", key: `card-${i}` },
                    React.createElement("img", { src: item.iconUrl, alt: item.iconAlt }),
                    React.createElement("h5", null, item.title),
                    React.createElement("p", { dangerouslySetInnerHTML: { __html: item.text } }))))))));
};
