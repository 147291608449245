import React from "react";
import { CustomLottie } from "../CustomLottie/CustomLottie";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
export const CardWithAnimation = ({ animation, subTitle, title, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "card-with-animation-root" },
            React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
                React.createElement("h2", { dangerouslySetInnerHTML: {
                        __html: title,
                    }, className: "h2-regular-small" }),
                React.createElement(CustomLottie, { animation: animation, className: "card-with-animation-root__animation" }),
                React.createElement("p", null, subTitle)))));
};
