import React from "react";
import { Img } from "../../Img";
import { ButtonSquare } from "../../ButtonSquare";
export const TradeMiniAccountCard = ({ title, description, buttonLabel, buttonHref, mobileImg }) => (React.createElement("div", { className: "tradeMiniAccountCardWrapper" },
    React.createElement("div", { className: "tradeMiniAccountCardWrapper__imageWrapper" },
        React.createElement(Img, { src: mobileImg, alt: `unique${title}` })),
    React.createElement("div", { className: "tradeMiniAccountCardWrapper__comment__section-wrapper" },
        React.createElement("h4", null, title),
        React.createElement("p", { dangerouslySetInnerHTML: { __html: description } }),
        React.createElement(ButtonSquare, { label: buttonLabel, href: buttonHref }))));
