import React from "react";
import { Container, ContainerFW } from "../global";
import { CustomLottie } from "../CustomLottie/CustomLottie";
import { getColors } from "../../utils/getColors";
import { Heading } from "../Heading";
export const AnimatedViewWithTitle = ({ title, subtitle, animation, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "animated-view-with-title-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "animated-view-with-title-root__contentWrapper" },
                    React.createElement(CustomLottie, { animation: animation }),
                    React.createElement("div", { className: "animated-view-with-title-root__contentWrapper__textWrapper" },
                        React.createElement(Heading, { design: "h2-lp" }, title),
                        React.createElement("p", null, subtitle)))))));
};
