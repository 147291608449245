import React from "react";
import { ContainerFW } from "../global";
import { Container } from "../global";
import timeClockDark from "../svgIcons/timeClockDark";
import VideoClock from "../svgIcons/VideoClock";
import { ButtonV2 } from "../ButtonV2";
import { ImgV2 } from "../ImgV2";
export const TrendingVideosV2 = (props) => (React.createElement(ContainerFW, { backgroundColor: props.backgroundColor ? props.backgroundColor : "inherit" },
    React.createElement(Container, null,
        React.createElement("div", { className: "trending-videos-v2" },
            React.createElement("div", { className: "trending-videos-v2__title" },
                React.createElement("h2", null, props.title)),
            props.videosList && props.videosList.length > 0 &&
                React.createElement("div", { className: "trending-videos-v2__container" },
                    React.createElement("div", { className: "trending-videos-v2__main" },
                        props.videosList[0].linkURL &&
                            React.createElement("a", { className: "trending-videos-v2__main-img", target: "_blank", href: props.videosList[0].linkURL, rel: "noreferrer" },
                                React.createElement(ImgV2, { className: "trending-videos-v2__main-img-img", src: props.videosList[0].imageURL })),
                        React.createElement("div", { className: "trending-videos-v2__main-text" },
                            React.createElement("div", { className: "trending-videos-v2__upload-time" },
                                React.createElement("p", null,
                                    React.createElement("span", null, timeClockDark({})),
                                    props.videosList[0].videoUploadTime)),
                            React.createElement("div", { className: "trending-videos-v2__upload-title" },
                                React.createElement("a", { className: "trending-videos-v2__main-img", href: props.videosList[0].linkURL },
                                    React.createElement("h4", null, props.videosList[0].videoTitle))))),
                    React.createElement("div", { className: "trending-videos-v2__list" }, props.videosList.slice(1).map((item, i) => (React.createElement("div", { key: i, className: `trending-videos-v2__list-item-${i} trending-videos-v2__list-item ${item.linkURL && "trending-videos-v2__coming-soon trending-videos-v2__auto-cursor"}` },
                        React.createElement("div", { className: "trending-videos-v2__list-item-img" },
                            item.linkURL &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("a", { className: "trending-videos-v2__main-img hide-small-ndb", href: item.linkURL },
                                        React.createElement(ImgV2, { className: "trending-videos-v2__main-img-img-side", src: item.imageURL }))),
                            !item.linkURL &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: "trending-videos-v2__main-img hide-small-ndb" }, VideoClock()))),
                        React.createElement("div", { className: "trending-videos-v2__list-item-text-link" }, item.linkURL ? (React.createElement("a", { href: item.linkURL },
                            React.createElement("div", { className: "trending-videos-v2__list-upload-time" },
                                React.createElement("p", null,
                                    React.createElement("span", null, timeClockDark({})),
                                    item.videoUploadTime)),
                            React.createElement("div", { className: "trending-videos-v2__list-title" },
                                React.createElement("h5", null, item.videoTitle)))) : (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "trending-videos-v2__list-upload-time" },
                                React.createElement("p", { className: "color-complimentary2" }, item.videoUploadTime)),
                            React.createElement("div", { className: "trending-videos-v2__list-title" },
                                React.createElement("h5", null, item.videoTitle)))))))))),
            props.buttonUrl && React.createElement(ButtonV2, { design: "ghost-medium-wbg", href: props.buttonUrl }, props.buttonText)))));
