import React from "react";
import { Img } from "../../Img";
import Addplus from "../../svgIcons/Addplus";
import Substract from "../../svgIcons/Substract";
export const ExpandableCard = ({ cardComments, cardImage, cardImageAlt, cardSubtitle, cardTitle, currenciesTitle, currenciesValues, feeLabel, feeValue, isOpen = false, onPlusIconClick, cardId, showLoadMore = false, showLoadMoreFunc, loadMoreLabel }) => (React.createElement("div", { key: cardId, className: `${isOpen === false ? "ExpandableCardWrapperClosed" : "ExpandableCardWrapper"}` },
    React.createElement("div", { className: "ExpandableCardWrapper__visibleHeader" },
        React.createElement("div", { className: "ExpandableCardWrapper__visibleHeader__topCardInfo" },
            React.createElement("div", null,
                React.createElement(Img, { src: cardImage, alt: cardImageAlt, className: "ExpandableCardWrapper__visibleHeader__topCardInfo__image" }),
                React.createElement("h4", { className: "ExpandableCardWrapper__visibleHeader__topCardInfo__cardTitle" }, cardTitle)),
            React.createElement("span", { className: "ExpandableCardWrapper__visibleHeader__topCardInfo__signsWrapper", onClick: onPlusIconClick && onPlusIconClick(cardId) }, isOpen === false ? Addplus({}) : Substract({}))),
        React.createElement("p", { className: "ExpandableCardWrapper__visibleHeader__cardSubTitle" }, cardSubtitle)),
    React.createElement("div", { className: "ExpandableCardWrapper__cardBody" },
        React.createElement("p", { className: "ExpandableCardWrapper__cardBody__cardComments" }, cardComments),
        React.createElement("p", { className: "ExpandableCardWrapper__cardBody__feeLabel" }, feeLabel),
        React.createElement("p", { className: "ExpandableCardWrapper__cardBody__feeValue" }, feeValue),
        React.createElement("p", { className: "ExpandableCardWrapper__cardBody__currenciesTitle" }, currenciesTitle),
        React.createElement("div", { className: "ExpandableCardWrapper__cardBody__currenciesCurrenciesWrapper" },
            React.createElement("p", { className: "ExpandableCardWrapper__cardBody__currenciesCurrenciesWrapper__currenciesValues" },
                currenciesValues,
                showLoadMore && React.createElement("span", { className: "ExpandableCardWrapper__cardBody__currenciesCurrenciesWrapper__loadMore", onClick: showLoadMoreFunc && showLoadMoreFunc(cardId) }, loadMoreLabel))))));
