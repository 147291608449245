import React from "react";
import { checkDoesDataHaveColumn, checkIsValueDisplayable, getNormalizedColumnName, getValueWithSpecialCase, getTranslatedColumnName } from "../";
import { stringHtmlFromString } from "../../../utils/stripHtmlFromString";
const translateFieldValue = (originalValue, fieldName, translateFieldFunction) => translateFieldFunction ? translateFieldFunction(fieldName, originalValue) : originalValue;
const handleImageError = (event) => {
    event.target.classList.add("hide-alt-text");
};
export const ContractSpecsTable = ({ data, columnInfo, className, columnsThatAllowHtml, columnsTranslations, translateFieldFunction }) => (React.createElement("table", { className: className },
    React.createElement("thead", null,
        React.createElement("tr", null, columnInfo.map((column, columnIndex) => React.createElement("th", { key: columnIndex, "data-field-name": column.dataColumnName },
            React.createElement("div", null, getTranslatedColumnName(column.dataColumnName, columnsTranslations))))),
        React.createElement("tr", null, columnInfo.map((_, columnIndex) => React.createElement("td", { key: columnIndex })))),
    React.createElement("tbody", null, data.map((row, rowIndex) => React.createElement("tr", { key: rowIndex, "data-id": rowIndex }, columnInfo.map((column, columnIndex) => {
        const normalizedColumnName = getNormalizedColumnName(column.dataColumnName);
        const value = getValueWithSpecialCase(normalizedColumnName, column, row);
        const isStringifiableData = typeof value === "string" || typeof value === "number";
        const dataField = isStringifiableData ? String(value) : "";
        const translatedDataField = translateFieldValue(dataField, normalizedColumnName, translateFieldFunction);
        const urlSuffix = row.urlSuffix;
        const instrumentGroup = row.instrumentGroup;
        const isInstrumentCell = column.dataColumnName === "Instrument";
        const cellTitle = column.columnName.toUpperCase();
        { }
        const rowHasThisField = checkDoesDataHaveColumn(row, normalizedColumnName);
        const isValueDisplayable = checkIsValueDisplayable(dataField);
        const isCellEmpty = !rowHasThisField || !isValueDisplayable;
        const isHtmlAllowed = columnsThatAllowHtml?.includes(column.dataColumnName);
        const altText = stringHtmlFromString(translatedDataField);
        return React.createElement(ContractsSpecsTableCell, { key: `${rowIndex}-${columnIndex}`, cellFieldName: column.dataColumnName, cellTitle: cellTitle },
            React.createElement("div", { className: "price-feed-cs__table-instrument" },
                isInstrumentCell && row.iconOrFlag && React.createElement("img", { src: row.iconOrFlag, alt: altText, onError: handleImageError }),
                React.createElement(ContractsSpecsTableCellContent, { index: columnIndex, urlSuffix: urlSuffix, instrumentGroup: instrumentGroup, value: translatedDataField, isCellEmpty: isCellEmpty, isHtmlAllowed: isHtmlAllowed })));
    }))))));
const ContractsSpecsTableCell = ({ cellFieldName, cellTitle, children }) => React.createElement("td", { "data-field-name": cellFieldName, title: cellTitle }, children);
const ContractsSpecsTableCellContent = ({ value, isCellEmpty, isHtmlAllowed }) => {
    if (isHtmlAllowed) {
        return React.createElement("div", { className: "price-feed-cs__table-instrument-name", dangerouslySetInnerHTML: { __html: value } });
    }
    return React.createElement(React.Fragment, null, isCellEmpty ? "" : React.createElement("div", { className: "price-feed-cs__table-instrument-name" }, value));
};
