import React, { useCallback, useEffect, useState } from "react";
import { ExpandableCard } from "./ExpandableCard/ExpandableCard";
import { usePageViewStyle } from "../../hooks";
import { ComponentHeader } from "./ComponentHeader";
export const ExpandableCardsContainer = ({ componentMainTitle, componentSubTitleTitle, cards, }) => {
    const [readyCards, setReadyCards] = useState([]);
    const { isSmall } = usePageViewStyle();
    const handleCardOpingCard = useCallback((id) => () => {
        const result = readyCards.map((a) => {
            if (a.cardId !== id) {
                return { ...a, isOpen: false };
            }
            return { ...a, isOpen: !a.isOpen };
        });
        setReadyCards(result);
    }, [readyCards]);
    useEffect(() => {
        if (cards.length > 0) {
            const value = cards.map((a) => {
                if (isSmall) {
                    return { ...a, showLoadMore: false };
                }
                return ({ ...a, showLoadMore: (a.currenciesValues.length > 60) });
            });
            setReadyCards(value);
        }
    }, [cards, isSmall]);
    const handleCurrencyLength = useCallback((id) => {
        const value = readyCards.filter((a) => a.cardId === id)[0];
        if (value.showLoadMore === true) {
            return value.currenciesValues.slice(0, 45).trim().concat("...");
        }
        return value.currenciesValues;
    }, [readyCards]);
    const handleShowLoadMoreFunc = useCallback((id) => () => {
        const result = readyCards.map((a) => {
            if (a.cardId === id) {
                return { ...a, showLoadMore: false };
            }
            return a;
        });
        setReadyCards(result);
    }, [readyCards]);
    const handleCardSubtitle = useCallback((value) => {
        const result = value.replace(",", " | ");
        return result;
    }, []);
    return (React.createElement("div", { className: "ExpandableCardsContainer" },
        React.createElement(ComponentHeader, { mainTitle: componentMainTitle, subTitle: componentSubTitleTitle }),
        React.createElement("div", { className: "ExpandableCardsContainer__cardsWrapper" }, readyCards.length > 0 &&
            readyCards.map((a) => (React.createElement(ExpandableCard, { cardId: a.cardId, key: a.cardId, cardComments: a.cardComments, cardImage: a.cardImage, cardImageAlt: a.cardImageAlt, cardSubtitle: handleCardSubtitle(a.cardSubtitle), cardTitle: a.cardTitle, feeLabel: a.feeLabel, feeValue: a.feeValue, currenciesTitle: a.currenciesTitle, isOpen: a.isOpen, showLoadMore: a.showLoadMore, currenciesValues: handleCurrencyLength(a.cardId), onPlusIconClick: handleCardOpingCard, showLoadMoreFunc: handleShowLoadMoreFunc, loadMoreLabel: a.loadMoreLabel }))))));
};
