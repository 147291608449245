import * as React from "react";
import { Container } from "../global";
import { PlatformCardHeader, PlatformCardBody } from "./PlatformCard/PlatformCard";
export const PlatformComparison = (props) => (React.createElement("div", { className: "platformComparison" },
    React.createElement(Container, null,
        React.createElement("div", { className: "platformComparison__wrapper" },
            React.createElement("div", { className: "platformComparison__inner" },
                React.createElement("div", { className: "platformComparison__row" }, props.items.map((item, index) => React.createElement("div", { key: `key-phead-${index}`, className: "platformComparison__col" },
                    React.createElement(PlatformCardHeader, { ...item }))))),
            React.createElement("div", { className: "platformComparison__card-bodies-wrapper" },
                React.createElement("div", { className: "platformComparison__row platformComparison__card-bodies" }, props.items.map((item, i) => React.createElement("div", { className: "platformComparison__col", key: `key-pbody-${i}` },
                    React.createElement(PlatformCardBody, { ...item })))))))));
