import React from "react";
import { AnimatedBanner } from "../../Components/Banners/AnimatedBanner/AnimatedBanner";
import { ManagePortfolioWithEase } from "../../Components/ManagePortfolioWithEase/ManagePortfolioWithEase";
import { ClickAndPlayVideoCard } from "../../Components/Cards/ClickAndPlayVideoCard/ClickAndPlayVideoCard";
import { ThreeImagesCards } from "../../Components/ThreeImagesCards/ThreeImagesCards";
import { OptionalButtonBanner } from "../../Components/Banners/OptionalButtonBanner/OptionalButtonBanner";
import { InstrumentCategoryDeck } from "../../Components/Cards/InstrumentCategoryDeck/InstrumentCategoryDeck";
import { AccessThinkPortalToday } from "../../Components/AccessThinkPortalToday/AccessThinkPortalToday";
import { Gap } from "../../Components/Gaps/Gap";
import { AnimatedBoxGrid } from "../../Components";
export const ThinkTrader = (props) => (React.createElement(React.Fragment, null,
    React.createElement(Gap, { mobileGap: "16px", desktopGap: "32px", gapColor: "#fff" }),
    React.createElement(AnimatedBanner, { ...props.animatedBanner }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "64px", gapColor: "#fff" }),
    React.createElement(ManagePortfolioWithEase, { ...props.managePortfolioWithEase }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(ClickAndPlayVideoCard, { ...props.clickAndPlayVideoCard }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(ThreeImagesCards, { ...props.threeImagesCard }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(OptionalButtonBanner, { ...props.optionalButtonBanner }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(AnimatedBoxGrid, { ...props.animatedBoxGrid }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(InstrumentCategoryDeck, { ...props.instrumentCategoryDeck }),
    React.createElement(Gap, { mobileGap: "32px", desktopGap: "80px", gapColor: "#fff" }),
    React.createElement(AccessThinkPortalToday, { ...props.accessThinkPortalToday })));
