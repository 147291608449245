import React, { useEffect, useState, useCallback } from "react";
import { Img } from "../Img";
import { pageViewStyle } from "../../pageViewStyle/pageViewStyle";
export const InstrumentSlider = ({ title, items, onMarketClick, currentMarketTab, children, }) => {
    const [activeMarketIndex, setActiveMarketIndex] = useState(items.findIndex((i) => i.title === currentMarketTab));
    const [view, setView] = useState(pageViewStyle.getView());
    const onViewUpdate = (view) => {
        setView(view);
    };
    useEffect(() => {
        pageViewStyle.subscribe(onViewUpdate);
        return () => {
            pageViewStyle.unsubscribe(onViewUpdate);
        };
    }, [view]);
    const handleMarketClick = useCallback((event) => {
        if (view === "large") {
            event.preventDefault();
            const index = parseInt(event.currentTarget.getAttribute("data-id") ?? "0");
            setActiveMarketIndex(index);
            onMarketClick(items[index].title);
        }
    }, []);
    const handleOnTouchStart = useCallback((event) => {
        if (event.touches.length > 1) {
            setActiveMarketIndex(-1);
        }
        if (view !== "large" && event.touches.length <= 1) {
            event.preventDefault();
            const index = parseInt(event.currentTarget.getAttribute("data-id") ?? "0");
            setActiveMarketIndex(index);
            onMarketClick(items[index].title);
        }
    }, []);
    return (React.createElement("div", { className: "instrument-slider" },
        title && (React.createElement("h2", { className: "instrument-slider__title", dangerouslySetInnerHTML: { __html: title } })),
        React.createElement("div", { className: "instrument-slider__items" }, items.map((item, i) => (React.createElement("div", { key: `instrument-slider-item-${i}`, className: `instrument-slider__item  ${activeMarketIndex === i &&
                "instrument-slider__highlighted"}`, "data-id": i, onClick: handleMarketClick, onTouchStart: handleOnTouchStart },
            React.createElement(Img, { className: "div instrument-slider__item-image", src: item.imageUrl, alt: item.imageAlt }),
            React.createElement("div", { className: "instrument-slider__item-title" }, item.title))))),
        children));
};
