import React from "react";
import { Container, ContainerFW } from "../global";
import { ButtonV2 } from "../ButtonV2";
import { getColors } from "../../utils/getColors";
export const HeaderWithTitleSubTitleAndButton = ({ title, subTitle, buttonLabel, buttonHref, openInNewTabButton, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "header-with-title-subTitle-button" },
                React.createElement("h2", { className: "h2-regular", dangerouslySetInnerHTML: {
                        __html: title || "",
                    } }),
                React.createElement("p", { className: "copy-lp" }, subTitle),
                buttonHref && buttonLabel && React.createElement(ButtonV2, { design: "secondary-large-dbg", href: buttonHref, openInNewTab: openInNewTabButton }, buttonLabel)))));
};
