import React from "react";
export const BidAskQuote = ({ title, children, className, linkUrl }) => {
    if (linkUrl) {
        return React.createElement("a", { href: linkUrl, className: `bid-ask-quote__link ${className}`, target: "_blank", rel: "noreferrer" },
            React.createElement(BidAskQuoteBase, { title: title }, children));
    }
    return React.createElement("div", { className: className },
        React.createElement(BidAskQuoteBase, { title: title }, children));
};
export const BidAskQuoteBase = ({ title, children }) => React.createElement(React.Fragment, null,
    React.createElement("div", null, title),
    React.createElement("div", null, children));
