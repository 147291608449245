import React, { useCallback, useState } from "react";
export const CustomSelect = ({ options, onSelect, selectOptionText }) => {
    const [selectedOption, setSelectedOption] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onSelect(option);
        setIsOpen(false);
    };
    const optionClickHandler = (option) => () => handleOptionClick(option);
    const toggleHandler = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    return (React.createElement("div", { className: "custom-select" },
        React.createElement("div", { className: "select-toggle", onClick: toggleHandler }, selectedOption ? selectedOption.label : selectOptionText),
        isOpen && (React.createElement("div", { className: "select-menu" }, options.map((option) => (React.createElement("div", { key: option.value, className: "select-option", onClick: optionClickHandler(option) }, option.label)))))));
};
