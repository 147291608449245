export const widgetMock = {
    language: {
        title: "Select your language/country",
        type: "widget",
        sections: [
            {
                title: "International",
                type: "default",
                sectionItems: [
                    {
                        title: "English",
                        icon: "./images/languages/English.svg",
                        code: "EN",
                        selected: false,
                        linkUrl: "/en",
                    },
                ],
            },
            {
                title: "LATAM",
                type: "default",
                sectionItems: [
                    {
                        title: "Brazil",
                        icon: "./images/languages/English.svg",
                        code: "BRA",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/pt/",
                    },
                    {
                        title: "Latin America",
                        icon: "./images/languages/English.svg",
                        code: "ES",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/latam/",
                    }
                ],
            },
            {
                title: "Asia Pacific",
                type: "default",
                sectionItems: [
                    {
                        title: "China",
                        icon: "./images/languages/English.svg",
                        code: "AU",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/ch/",
                    },
                    {
                        title: "Japan",
                        icon: "./images/languages/English.svg",
                        code: "JP",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/jp/",
                    },
                    {
                        title: "Vietnam",
                        icon: "./images/languages/English.svg",
                        code: "IN",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/id/",
                    },
                    {
                        title: "Taiwan",
                        icon: "./images/languages/English.svg",
                        code: "NZ",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/nz/",
                    },
                    {
                        title: "Indonesia",
                        icon: "./images/languages/English.svg",
                        code: "Thai",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/th/",
                    },
                    {
                        title: "Thailand",
                        icon: "./images/languages/English.svg",
                        code: "Vietnam",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/vn/",
                    },
                    {
                        title: "Australia",
                        icon: "./images/languages/English.svg",
                        code: "AU",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/au/",
                    },
                    {
                        title: "Australia",
                        icon: "./images/languages/English.svg",
                        code: "AU",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/au/",
                    }
                ],
            },
            {
                title: "Africa, Middle East",
                type: "default",
                sectionItems: [
                    {
                        title: "UAE",
                        icon: "./images/languages/English.svg",
                        code: "UAE",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/ar/",
                    },
                    {
                        title: "Middle East",
                        icon: "./images/languages/English.svg",
                        code: "UAE",
                        selected: false,
                        linkUrl: "/en",
                    },
                    {
                        title: "South Africa",
                        icon: "./images/languages/English.svg",
                        code: "ZA",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/za/",
                    },
                ],
            },
            {
                title: "Europe",
                type: "default",
                sectionItems: [
                    {
                        title: "United Kingdom",
                        icon: "./images/languages/English.svg",
                        code: "BG",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/eu/",
                    },
                    {
                        title: "Poland",
                        icon: "./images/languages/English.svg",
                        code: "DE",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/de/",
                    },
                    {
                        title: "Spain",
                        icon: "./images/languages/English.svg",
                        code: "IT",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/it/",
                    },
                    {
                        title: "Czech Republic",
                        icon: "./images/languages/English.svg",
                        code: "PL",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/pl/",
                    },
                    {
                        title: "Germany",
                        icon: "./images/languages/English.svg",
                        code: "SP",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/es/",
                    },
                    {
                        title: "Greece",
                        icon: "./images/languages/English.svg",
                        code: "UK",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/uk/",
                    },
                    {
                        title: "Italy",
                        icon: "./images/languages/English.svg",
                        code: "EU",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/eu/",
                    },
                    {
                        title: "Europe",
                        icon: "./images/languages/English.svg",
                        code: "EU",
                        selected: false,
                        linkUrl: "https://www.thinkmarkets.com/eu/",
                    }
                ],
            },
        ],
        currentCulture: "EN",
        currentIcon: "/images/Cards/TMTradingCardDeck/global.png",
    },
};
