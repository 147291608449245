import React, { useState } from "react";
import { arrowIcon } from "../Cards/InformationCardDarkDeck/InformationCardDark";
import { ContainerFW, Container } from "../global";
import { FaqAccordionCard } from "./FaqAccordionCard";
import { Img } from "../Img";
export const FaqAccordion = (props) => {
    const [activeCardId, setActiveCardId] = useState(-1);
    return (React.createElement("div", { className: `faq-accordion ${props.imageUrl ? "" : "faq-accordion--no-image"}` },
        React.createElement(Img, { className: "faq-accordion__bkgr", src: props.imageUrl, alt: "FAQ section background image" }),
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "faq-accordion__deck" },
                    React.createElement("h2", { className: "faq-accordion__title" }, props.title),
                    props.items.map((item, i) => React.createElement(FaqAccordionCard, { key: `faq-accordion__card-${i}`, id: i, question: item.question, answer: item.answer, setActiveCardId: setActiveCardId, activeCardId: activeCardId })),
                    React.createElement("a", { href: props.linkUrl, className: "faq-accordion__link" },
                        props.linkText,
                        " ",
                        arrowIcon()))))));
};
