import React from "react";
import { Img } from "../../../Img";
export const AccordionCard = (props) => {
    const toggle = React.useCallback(() => props.toggleExpand?.(props.id || "0"), []);
    return (React.createElement("div", { className: `accordion-card ${props.opened ? "accordion-card--opened" : ""}` },
        React.createElement("h4", { className: "accordion-card__title" },
            props.title,
            " ",
            React.createElement("div", { className: "accordion-card__trigger", onClick: toggle }, caretIcon())),
        React.createElement("div", { className: "accordion-card__content" },
            React.createElement("div", { className: "accordion-card__text" }, props.text),
            React.createElement("a", { href: props.linkUrl, target: props.opensInNewTab ? "_blank" : "_self", rel: "noreferrer", className: "accordion-card__link" },
                props.linkText,
                " ",
                arrowIcon()),
            React.createElement(Img, { src: props.icon, alt: props.title, className: "accordion-card__icon" }))));
};
export const arrowIcon = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "#007c8c", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#6cv15a3sra)" },
        React.createElement("path", { d: "m16.172 11-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2h12.172z", fill: "inherit" })),
    React.createElement("defs", null,
        React.createElement("clipPath", null,
            React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
export const caretIcon = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M17.29 11.29a.996.996 0 0 0-1.41 0L12 15.17l-3.88-3.88a.997.997 0 1 0-1.41 1.41l4.59 4.59a.994.994 0 0 0 1.41 0l4.59-4.59c.38-.38.38-1.02-.01-1.41z", fill: "#0e1d31" })));
