import React from "react";
export const PlatformInformationCard = (props) => (React.createElement("div", { className: "information-card information-card--platform" },
    React.createElement("div", { className: "information-card__content" },
        React.createElement("h4", { className: "information-card__title" }, props.title),
        React.createElement("p", { className: "information-card__text" }, props.text)),
    React.createElement("a", { href: props.linkUrl, target: props.opensInNewTab ? "_blank" : "_self", rel: "noreferrer", className: "information-card__link" },
        props.linkText,
        " ",
        arrowIcon())));
const arrowIcon = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#6cv15a3sra)" },
        React.createElement("path", { d: "m16.172 11-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2h12.172z", fill: "inherit" })),
    React.createElement("defs", null,
        React.createElement("clipPath", null,
            React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
