import React from "react";
import { Img } from "../../Img";
import { Container, ContainerFW } from "../../global";
import { ButtonWrapper } from "./ButtonWrapper/ButtonWrapper";
import { Breadcrumbs } from "../../Breadcrumbs";
import { Heading } from "../../Heading";
const getBackgroundColor = (backgroundColor) => backgroundColor === "dark" ? "#0E1D31" : "#fff";
const checkHasComponentButton = ({ buttonHref, buttonLabel, linkHref, linkLabel }) => (buttonHref && buttonLabel) || (linkHref && linkLabel);
const getClassName = (backgroundColor) => backgroundColor === "dark" ? "tradeWithComponentBlackBackground-dark" : "tradeWithComponentBlackBackground";
const getTitle = (title, h2Ttile) => {
    if (title) {
        return !h2Ttile ? React.createElement("h1", { className: "tradeWithComponent-mainTitle", dangerouslySetInnerHTML: { __html: title } }) : React.createElement("h2", { className: "tradeWithComponent-mainTitle", dangerouslySetInnerHTML: { __html: title } });
    }
    else {
        return "";
    }
};
export const TradeWithComponentBlackBackground = ({ mainTitle, h2title, subMainTitle, sectionItems, buttonHref, buttonLabel, linkLabel, linkHref, backgroundColor, breadcrumbs, openInNewTabButton, linkOpenInNewTab }) => {
    const correctedBgColor = getBackgroundColor(backgroundColor);
    const hasButton = checkHasComponentButton({ buttonHref, buttonLabel, linkHref, linkLabel });
    const className = getClassName(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: `${correctedBgColor}` },
        React.createElement("div", { className: `${className}` },
            breadcrumbs && React.createElement("div", { className: "breadcrumbs__container" },
                React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs })),
            React.createElement(Container, null,
                getTitle(mainTitle, h2title),
                subMainTitle && React.createElement("p", { className: "tradeWithComponent-subTitle" }, subMainTitle),
                React.createElement("div", { className: `${sectionItems.length <= 3 ? "tradeWithComponent-sectionWrapper" : "tradeWithComponent-sectionWrapper--larger"} ` }, sectionItems.length > 0 && sectionItems?.map((a) => (React.createElement("span", { key: `unique${a.title}`, className: "tradeWithComponent-sectionWrapper__section" },
                    React.createElement(Img, { src: a.imageUrl, className: "tradeWithComponent-sectionWrapper__section__image", alt: a.imageAlt }),
                    React.createElement(Heading, { design: "h4-semi-bold" }, a.title),
                    React.createElement("div", { className: "tradeWithComponent-sectionWrapper__section__subTitle", dangerouslySetInnerHTML: { __html: a.subTitle } }))))),
                hasButton && React.createElement(ButtonWrapper, { buttonHref: buttonHref, buttonLabel: buttonLabel, linkHref: linkHref, linkLabel: linkLabel, openInNewTabButton: openInNewTabButton, linkOpenInNewTab: linkOpenInNewTab })))));
};
