import React from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
import { Img } from "../../Components/Img";
import { Heading } from "../Heading";
export const MarketsAndInstruments = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement("div", { className: "markets-and-instruments" },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "markets-and-instruments__container" },
                    React.createElement("div", { className: "markets-and-instruments__left-wrapper" },
                        React.createElement("div", { className: "markets-and-instruments__left-wrapper__title-wrapper" },
                            React.createElement(Heading, { design: "h3-bold" }, props.title)),
                        React.createElement("div", { className: "markets-and-instruments__left-wrapper__desc-wrapper", dangerouslySetInnerHTML: { __html: props.desc } }),
                        React.createElement("div", { className: "markets-and-instruments__left-wrapper__img-wrapper" },
                            React.createElement(Img, { src: props.imageURL, alt: props.imageAlt }))),
                    React.createElement("div", { className: "markets-and-instruments__right-wrapper" }, props.instruments.map((el, i) => (React.createElement("div", { key: i, className: "markets-and-instruments__right-wrapper__el" },
                        React.createElement("div", { className: "markets-and-instruments__right-wrapper__el-icon" },
                            React.createElement(Img, { src: el.iconURL, alt: el.iconAlt })),
                        React.createElement("div", { className: "markets-and-instruments__right-wrapper__el-title" },
                            React.createElement(Heading, { design: "h4-bold" }, el.title)),
                        React.createElement("div", { className: "markets-and-instruments__right-wrapper__el-desc", dangerouslySetInnerHTML: { __html: el.description } }))))))))));
};
