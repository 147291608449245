import React, { useEffect, useState } from "react";
import { ButtonV2 } from "../../../ButtonV2";
import { ImgV2 } from "../../../ImgV2/ImgV2";
import { usePageViewStyle } from "../../../../hooks";
export const CardWithImageAndButton = ({ title, description, mobileDescription, buttonURL, buttonLabel, openInNewTabButton, imageUrl, smallImageUrl, imageAlt }) => {
    const { isSmall, isMedium, isLarge } = usePageViewStyle();
    const [currentImage, setCurrentImage] = useState(imageUrl);
    const [descriptionText, setDescriptionText] = useState(description);
    useEffect(() => {
        if (isSmall) {
            setCurrentImage(smallImageUrl);
            setDescriptionText(mobileDescription);
        }
        else {
            setCurrentImage(imageUrl);
            setDescriptionText(description);
        }
    }, [isSmall, isMedium, isLarge, descriptionText]);
    const renderImage = () => (React.createElement(ImgV2, { src: currentImage, alt: imageAlt, loading: "eager" }));
    return (React.createElement("div", { className: "card-with-image-and-button" },
        React.createElement("div", { className: "card-with-image-and-button__text-and-button-wrapper" },
            React.createElement("h4", { dangerouslySetInnerHTML: {
                    __html: title
                } }),
            React.createElement("p", { dangerouslySetInnerHTML: {
                    __html: descriptionText
                } }),
            React.createElement(ButtonV2, { href: buttonURL, design: "secondary-large-wbg", openInNewTab: openInNewTabButton }, buttonLabel)),
        React.createElement("div", { className: "card-with-image-and-button__image-wrapper" }, renderImage())));
};
