import React from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils";
export const CardsGridImageAndDescription = ({ title, designStyles, cards }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { style: { color: textColor }, className: "cards-grid-image-and-description" },
                React.createElement("div", { className: "caption-bold" }, title),
                React.createElement("div", { className: "cards-grid-image-and-description__cards-grid" }, cards.map((card, i) => React.createElement("a", { key: `cards-grid-image-and-description-${i}`, href: card.link, className: "cards-grid-image-and-description__card-container" },
                    React.createElement("div", { className: "cards-grid-image-and-description__card-image-container" },
                        React.createElement("img", { className: "cards-grid-image-and-description__card-image", src: card.imageUrl, alt: card.imageAlt })),
                    React.createElement("div", { className: "cards-grid-image-and-description__text-container" },
                        React.createElement("h5", { className: "h5-semi-bold" }, card.text)))))))));
};
