import * as utils from "./tabInitialization.utils";
export class ContractSpecsTabObj {
    constructor(contextInfo) {
        this.culture = contextInfo.culture;
        this.instrumentGroup = contextInfo.instrumentGroupName;
        this.instrumentSubgroup = contextInfo.instrumentSubgroupName;
        this.pageConfig = contextInfo.pageConfig;
        this.cultureConfig = utils.getCultureConfigData(this.culture, this.pageConfig);
        if (!this.cultureConfig) {
            console.info("No contract specs config found for this culture");
            return;
        }
        this.instrumentGroupConfig = utils.getInstrumentGroupConfigData({
            instrumentGroupName: this.instrumentGroup,
            instrumentSubgroupName: this.instrumentSubgroup
        }, this.cultureConfig);
        this.filters = utils.getRelevantFilters(this.instrumentGroupConfig);
        this.accountTypeIds = utils.getAccountTypeIds(this.cultureConfig);
        this.columnsPerLeverageType = utils.getColumnsPerLeverageType(this.cultureConfig, this.instrumentGroupConfig);
    }
    setProps(objectProps) {
        Object.entries(objectProps).forEach(([key, value]) => this[key] = value);
    }
    getRelevantData(inputData) {
        return utils.getRelevantData(this.culture, this.instrumentGroup, this.instrumentSubgroup, this.instrumentGroupConfig, inputData);
    }
    getFilters() {
        return this.filters;
    }
    getDefaultFilterValues() {
        return utils.getDefaultFilterValues(this.instrumentGroupConfig);
    }
    getAutoExecutedHandlers() {
        return utils.getAutoExecutedHandlers(this.instrumentGroupConfig);
    }
    getColumnsThatAllowHtml() {
        return utils.getColumnsThatAllowHtml(this.cultureConfig, this.instrumentGroupConfig);
    }
    getAdditionaInfo(localInfoKey, additionalInfo) {
        return utils.getAdditionaInfo(this.cultureConfig, localInfoKey, additionalInfo);
    }
    checkColumnExistsForAccountType(accountTypes, accountTypeName) {
        if (typeof this.accountTypeIds === "undefined") {
            return true;
        }
        return utils.checkColumnExistsForAccountType(accountTypes, accountTypeName, this.accountTypeIds);
    }
    checkIsCorrectLeverageColumn(columnName, leverageType, isStrictCheck = false) {
        if (typeof this.columnsPerLeverageType === "undefined") {
            return true;
        }
        return utils.checkIsCorrectLeverageColumn({ columnName, leverageType, columnsPerLeverageType: this.columnsPerLeverageType, culture: this.culture, leverageDataGroups: this.cultureConfig?.leverageDataGroups, isStrictCheck });
    }
    getEnhancedTableInfo(tableColumns, leverageType) {
        if (typeof this.columnsPerLeverageType === "undefined") {
            return tableColumns;
        }
        return utils.getEnhancedTableInfo({ tableColumns, leverageType, columnsPerLeverageType: this.columnsPerLeverageType, culture: this.culture, leverageDataGroups: this.cultureConfig?.leverageDataGroups });
    }
    getSpecialCaseLeverageColumn(leverageType) {
        if (typeof this.columnsPerLeverageType === "undefined") {
            return true;
        }
        return utils.getSpecialCaseLeverageColumn({ leverageType, columnsPerLeverageType: this.columnsPerLeverageType, culture: this.culture, leverageDataGroups: this.cultureConfig?.leverageDataGroups });
    }
    getTabFiltersTranslations(filterTranslations) {
        if (!this.filters) {
            return;
        }
        return utils.getGroupFiltersTranslations(this.filters, filterTranslations);
    }
    getPossibleReassignMap(reassignMapConfig, instrumentGroupOrSubgroups) {
        return utils.getPossibleReassignMap(reassignMapConfig, instrumentGroupOrSubgroups, this.culture, this.instrumentSubgroup || "");
    }
    getGroomedData(data, reassignMapConfig, instrumentGroupOrSubgroups) {
        return utils.getGroomedData(data, reassignMapConfig, instrumentGroupOrSubgroups, this.culture, this.instrumentSubgroup || this.instrumentGroup || "");
    }
}
