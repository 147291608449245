import { UIDataRangeStatusEnum } from "../instrumentData.types";
export const calcRangeBarProps = (rangeStart, rangeEnd, currentValue) => {
    const checkAreInputsUndefined = () => typeof rangeStart === "undefined" || typeof rangeEnd === "undefined" || typeof currentValue === "undefined";
    if (checkAreInputsUndefined()) {
        return {
            status: UIDataRangeStatusEnum.UNDEFINED_INPUTS,
            offset: undefined
        };
    }
    const rangeDiff = rangeEnd - rangeStart;
    if (rangeDiff < 0) {
        return {
            status: UIDataRangeStatusEnum.INCALCULABLE_INPUTS,
            offset: undefined
        };
    }
    if (rangeDiff === 0) {
        return {
            status: UIDataRangeStatusEnum.ZERO_RANGE,
            offset: undefined
        };
    }
    if (rangeDiff > 0) {
        return {
            status: UIDataRangeStatusEnum.REGULAR_RANGE,
            offset: (currentValue - rangeStart) / rangeDiff * 100
        };
    }
    return {
        status: UIDataRangeStatusEnum.UKNOWN,
        offset: undefined
    };
};
