import React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { getColors } from "../../utils";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { Heading } from "../Heading";
export const ItemDeckWithTitleHorizontal = ({ cards, backgroundColor = "white", title, buttonUrl, buttonText }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: `${backgroundColor === "dark" ? "itemDeckWithTitle-dark-wrapperHorizontal" : "itemDeckWithTitle-wrapperHorizontal"}` },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "itemDeckWithTitle-wrapperHorizontal__grid-wrapper" },
                    title && React.createElement(Heading, { design: "h2-regular-small" }, title),
                    React.createElement("div", { className: "itemDeckWithTitle-wrapperHorizontal__grid-wrapper__cards-root" }, cards.length > 0 && cards.map((a) => (React.createElement("div", { key: `unique${a.title}`, className: "itemDeckWithTitle-wrapperHorizontal__grid-wrapper__cards-root__cards-wrapper" },
                        React.createElement(Img, { src: a.imageUrl, alt: a.imageAlt }),
                        React.createElement("span", { className: "itemDeckWithTitle-wrapperHorizontal__grid-wrapper__cards-root__cards-wrapper__texts" },
                            React.createElement(Heading, { design: "h2-regular-small" }, a.title),
                            React.createElement("p", null, a.comment)))))),
                    buttonText && React.createElement("div", { className: "itemDeckWithTitle-wrapperHorizontal__button-wrapper" },
                        React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonUrl }, buttonText)))))));
};
