import * as React from "react";
import { Breadcrumbs } from "../../Breadcrumbs";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
export const BasicBannerWithChangingTextPosition = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    const h5Heading = function () {
        if (props.subtitle) {
            return (React.createElement("div", { className: "basic-banner-with-changing-text-position__subtitle-container" },
                React.createElement("h5", { style: { color: bgColor === "#FFFFFF" ? "#0E1D31" : (bgColor === "#0E1D31" ? "#CFD2D6" : "#5EE15A"), textAlign: props.titlePosition === undefined ? "center".toLowerCase() : props.titlePosition.toLowerCase() } }, props.subtitle)));
        }
        else {
            return "";
        }
    };
    const h1Heading = function () {
        if (props.title) {
            return (React.createElement("div", { className: "basic-banner-with-changing-text-position__title-container" },
                React.createElement("h1", { style: { color: bgColor === "#FFFFFF" ? "#0E1D31" : (bgColor === "#0E1D31" ? "#FFFFFF" : "#5EE15A"), textAlign: props.titlePosition === undefined ? "center".toLowerCase() : props.titlePosition.toLowerCase() } }, props.title)));
        }
        else {
            return "";
        }
    };
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "basic-banner-with-changing-text-position" },
                props.breadcrumbs && React.createElement(Breadcrumbs, { ...props.breadcrumbs }),
                React.createElement("div", { className: "basic-banner-with-changing-text-position__text-container", style: {
                        alignItems: props.titlePosition === undefined ? "center" : props.titlePosition.toLowerCase()
                    } },
                    React.createElement("div", { className: "basic-banner-with-changing-text-position__wrapper", style: {
                            maxWidth: props.titlePosition !== "center" ? "unset" : ""
                        } },
                        h1Heading(),
                        h5Heading()))))));
};
