import React from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
import { Logo } from "../Logo/Logo";
export const LogoLP = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement(Logo, { imageUrl: props.imageUrl, imageUrlReverse: props.imageUrlReverse, alt: props.alt }))));
};
