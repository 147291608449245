import React, { useCallback } from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils";
import { Heading } from "../Heading/Heading";
import { supportUpIcon } from "../svgIcons/supportUpIcon";
import { supportDownIcon } from "../svgIcons/supportDownIcon";
import { supportMaintenanceIcon } from "../svgIcons/supportMaintenanceIcon";
export const StatusCardsGrid = ({ title, cards, designStyles }) => {
    const { bgColor, textColor } = getColors(designStyles.backgroundColor);
    const renderUp = useCallback(({ statusDescription }) => React.createElement("div", { className: "centered-container status-cards-grid__status-label color-tm-green-120" },
        supportUpIcon,
        React.createElement("div", { className: "status-cards-grid__text-up caption-bold" }, statusDescription)), []);
    const renderDown = useCallback(({ statusDescription }) => React.createElement("div", { className: "centered-container status-cards-grid__status-label color-support-down" },
        supportDownIcon,
        React.createElement("div", { className: "status-cards-grid__text-down caption-bold" }, statusDescription)), []);
    const renderMaintenance = useCallback(({ statusDescription }) => React.createElement("div", { className: "centered-container status-cards-grid__status-label color-support-maintenance" },
        supportMaintenanceIcon,
        React.createElement("div", { className: "status-cards-grid__text-maintenance caption-bold" }, statusDescription)), []);
    const renderCard = useCallback((card) => (React.createElement("div", { className: "status-cards-grid__card" },
        card.serviceTitle,
        card.statusCode === "up" && renderUp(card),
        card.statusCode === "down" && renderDown(card),
        card.statusCode === "maintenance" && renderMaintenance(card))), [renderUp, renderDown, renderMaintenance]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "status-cards-grid__root" },
            React.createElement(Container, null,
                React.createElement("div", { style: { color: textColor }, className: "status-cards-grid" },
                    React.createElement(Heading, { design: designStyles.titleDesign }, title),
                    React.createElement("br", null),
                    React.createElement("div", { className: "status-cards-grid__grid" }, cards?.map((card) => (React.createElement("div", { key: `unique${card.serviceTitle}` }, renderCard(card))))))))));
};
