import React from "react";
const getDefaultIconUrlVar = (buttonFunction) => {
    switch (buttonFunction) {
        case "expand":
            return "var(--expand-button-icon-url)";
        case "collapse":
            return "var(--collapse-button-icon-url)";
        case "toggle":
        default:
            return "var(--toggle-button-icon-url)";
    }
};
const getDefaultText = (buttonFunction) => {
    switch (buttonFunction) {
        case "expand":
            return "Show";
        case "collapse":
            return "Hide";
        case "toggle":
        default:
            return "Toggle";
    }
};
export const ToggleExpandCollapseButton = ({ buttonText, buttonIconUrl, onToggle, buttonFunction = "toggle" }) => {
    const iconUrlVar = `${buttonIconUrl ? `url(${buttonIconUrl})` : getDefaultIconUrlVar(buttonFunction)}`;
    const styleObj = { "--toggle-button-icon-url": `${iconUrlVar}` };
    return (React.createElement("button", { className: `toggle-expand-collapse-button toggle-expand-collapse-button--${buttonFunction}`, onClick: onToggle, style: styleObj }, buttonText || getDefaultText(buttonFunction)));
};
