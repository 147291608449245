import React from "react";
import { IconChevronDown } from "../svgIcons";
import { handleLinkTarget } from "../../utils";
export const Button = ({ primary = false, label, hasArrow, ...props }) => {
    const openInNewTabValue = props.openinnewtab === "true" ? true : false;
    const hrefTarget = handleLinkTarget(openInNewTabValue);
    return (React.createElement("a", { href: props.href ? props.href : "#", onClick: props.onClick, className: `button button--${primary ? "primary" : "secondary"} ${props.classes ? props.classes : ""}`, style: { ...props.style }, ...props, target: hrefTarget },
        label,
        hasArrow ? IconChevronDown({}) : ""));
};
