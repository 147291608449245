import React from "react";
import { Container, ContainerFW, Row, Col } from "../../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { VideoCard } from "./VideoCard/VideoCard";
export const VideoCardDeck = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: `videoCardDeck ${props.isSliderOnMobile ? "videoCardDeck__slider" : ""}` },
        React.createElement(Container, null,
            React.createElement("div", { className: "videoCardDeck__titleAndLink" },
                React.createElement("h2", null, props.title),
                props.linkText && React.createElement("a", { href: props.linkUrl },
                    props.linkText,
                    " ",
                    arrow())),
            props.isSliderOnMobile &&
                React.createElement(React.Fragment, null,
                    React.createElement(Swiper, { modules: [Navigation], slidesPerView: "auto", navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        } }, props.cards.map((item, i) => (React.createElement(SwiperSlide, { key: i },
                        React.createElement(VideoCard, { webSiteRootUrl: props.webSiteRootUrl, hasLightbox: false, ...item }))))),
                    React.createElement(Container, null,
                        React.createElement("div", { className: "videoCardDeck__pagination-container" },
                            React.createElement("svg", { className: "swiper-button-prev", width: "56", height: "56", viewBox: "0 0 56 56", xmlns: "http://www.w3.org/2000/svg" },
                                React.createElement("path", { d: "M19.18 29.68a1.75 1.75 0 0 1-.513-1.237v-.886c.005-.463.189-.906.513-1.237l11.993-11.97a1.167 1.167 0 0 1 1.657 0l1.657 1.657a1.143 1.143 0 0 1 0 1.633L24.103 28l10.384 10.36a1.166 1.166 0 0 1 0 1.657L32.83 41.65a1.167 1.167 0 0 1-1.657 0L19.18 29.68z", fill: "currentColor" })),
                            React.createElement("svg", { className: "swiper-button-next", width: "56", height: "56", viewBox: "0 0 56 56", xmlns: "http://www.w3.org/2000/svg" },
                                React.createElement("path", { d: "M36.82 26.32c.328.328.513.773.513 1.237v.886c-.005.463-.189.906-.513 1.237L24.827 41.65a1.167 1.167 0 0 1-1.657 0l-1.657-1.657a1.143 1.143 0 0 1 0-1.633L31.897 28 21.513 17.64a1.166 1.166 0 0 1 0-1.657l1.657-1.633a1.167 1.167 0 0 1 1.657 0L36.82 26.32z", fill: "currentColor" }))))),
            props.isSliderOnMobile || React.createElement(Row.Cols3, null, props.cards.map((card, i) => (React.createElement(Col, { key: i },
                React.createElement(VideoCard, { webSiteRootUrl: props.webSiteRootUrl, ...card })))))))));
const arrow = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "#007c8c", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#6cv15a3sra)" },
        React.createElement("path", { d: "m16.172 11-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2h12.172z", fill: "inherit" })),
    React.createElement("defs", null,
        React.createElement("clipPath", null,
            React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
