import * as React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../../Components/Img";
import { Heading } from "../../Components/Heading";
export const ProfileSection = ({ title, subTitle, content, image }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement(Container, null,
        React.createElement("div", { className: "profile-section" },
            React.createElement(Img, { src: image }),
            React.createElement("div", { className: "profile-section__content" },
                React.createElement(Heading, { design: "h1-regular-small" }, title),
                React.createElement(Heading, { design: "h4-bold" }, subTitle),
                React.createElement("div", { className: "p-regular", dangerouslySetInnerHTML: { __html: content } }))))));
