import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
import { Img } from "../../Img";
import { ButtonV2 } from "../../ButtonV2";
import { Breadcrumbs } from "../../Breadcrumbs";
import { Gap } from "../../../Components/Gaps/Gap";
export const AffiliateBanner = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "affiliate-banner" },
                props.breadcrumbs && React.createElement(Breadcrumbs, { ...props.breadcrumbs }),
                React.createElement(Gap, { desktopGap: "40px", mobileGap: "40px" }),
                React.createElement("div", { className: "affiliate-banner__wrapper" },
                    React.createElement("div", { className: "affiliate-banner__text-and-button-section" },
                        React.createElement("div", { className: "affiliate-banner__title-and-subtitle-container" },
                            React.createElement("h1", null, props.title),
                            React.createElement("h5", null, props.subtitle)),
                        React.createElement("div", { className: "affiliate-banner__numbers-container" }, props.numbers.map((item, i) => React.createElement("div", { key: i, className: `affiliate-banner__number-and-title-wrapper ${i === 1 ? "border-class" : ""}` },
                            React.createElement("div", { className: "affiliate-banner__number-wrapper" },
                                React.createElement("h3", null, item.number)),
                            React.createElement("div", { className: "affiliate-banner__title-wrapper" },
                                React.createElement("p", null, item.title))))),
                        React.createElement("div", { className: "affiliate-banner__buttons-container" },
                            React.createElement(ButtonV2, { design: "cta-large-wbg", href: props.firstButtonLink, openInNewTab: props.openInNewTabButton }, props.firstButtonLabel),
                            React.createElement(ButtonV2, { design: "ghost-large-wbg", href: props.secondButtonLink, openInNewTab: props.openInNewTabButton2 }, props.secondButtonLabel))),
                    React.createElement("div", { className: "affiliate-banner__image-section" },
                        React.createElement(Img, { className: "affiliate-banner__image", src: props.imageURL, alt: props.imageAlt })))))));
};
