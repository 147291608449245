import React, { useCallback, useEffect, useState } from "react";
import { ButtonSquare } from "../../ButtonSquare";
import { Img } from "../../Img";
import { handleLinkTarget } from "../../../utils";
export const HeroBannerWithImages = ({ slideTitles, stats, buttonLabel, buttonHref, linkLabel, changingInterval, linkHref, linkOpenInNewTab, buttonOpenInNewTab }) => {
    const [value, setValue] = useState(0);
    const linkHrefTarget = handleLinkTarget(linkOpenInNewTab);
    const handleFadeInAndFadeOut = useCallback((index) => () => {
        setValue(index);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            if (value < slideTitles.length - 1) {
                setValue(value + 1);
            }
            else {
                setValue(0);
            }
        }, changingInterval ?? 5000);
        return () => clearInterval(interval);
    }, [value, changingInterval, slideTitles.length]);
    return (React.createElement("div", { className: "HeroBannerWithImagesWrapper" },
        React.createElement("section", { className: "heroRootWrapperGridContainer" },
            slideTitles.map((slide, index) => (React.createElement("div", { className: `titleWrapper ${value === index ? "elementToFadeIn" : "elementToFadeOut"}`, key: `unique${slide.mainTitle}` },
                React.createElement("div", { className: "titleWrapper__mainTitle", dangerouslySetInnerHTML: {
                        __html: slide.mainTitle,
                    } }),
                React.createElement("div", { className: "titleWrapper__mainSubTitle", dangerouslySetInnerHTML: {
                        __html: slide.mainSubTitle,
                    } })))),
            React.createElement("div", { className: "imageWrapper" }, slideTitles.map((a, index) => (React.createElement(Img, { key: `images${a.mainImage}`, className: `${value === index ? "elementToFadeIn" : "elementToFadeOut"}`, src: slideTitles[value].mainImage, alt: slideTitles[value].mainImageAlt })))),
            React.createElement("div", { className: "buttonsWrapper" },
                React.createElement(ButtonSquare, { label: buttonLabel, href: buttonHref, openinnewtab: buttonOpenInNewTab.toString() }),
                linkLabel && React.createElement("a", { href: linkHref, target: linkHrefTarget }, linkLabel)),
            React.createElement("div", { className: "statsWrapper" }, stats.map((a) => (React.createElement("span", { key: `statElement${a.title}` },
                React.createElement("p", { className: "statsWrapper__statTitle" }, a.title),
                React.createElement("p", { className: "statsWrapper__statSubTitle" }, a.subTitle))))),
            React.createElement("div", { className: "sliderButtonsWrapper" }, slideTitles.map((a, index) => (React.createElement("span", { className: "sliderButtonsWrapper__sliderButtonsItemContainer", key: `unique${a.mainTitle}`, onClick: handleFadeInAndFadeOut(index) },
                React.createElement("div", { className: `sliderButtonsWrapper__sliderButtonsItem ${index === value ? "sliderButtonsWrapper__active" : ""}`, style: { backgroundColor: `${index < value ? "#5ee15a" : "unset"}` } },
                    React.createElement("span", { className: "sliderButtonsWrapper__progres-scale" })))))))));
};
