export const INSTRUMENT_CODE_CORRECTIONS = {
    apple: "aapl",
    amazon: "amzn",
    airbnb: "abnb",
    visa: "v",
    alibaba: "baba",
    tesla: "tsla",
    microsoft: "msft",
    goldman: "gs",
    alphabet: "googl"
};
