import React, { useEffect, useRef, memo } from "react";
const EconomicCalendar = () => {
    const container = useRef(null);
    useEffect(() => {
        const script = document.createElement("script");
        script.src =
            "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
        "width": "100%",
        "height": "100%",
        "colorTheme": "light",
        "isTransparent": false,
        "locale": "en",
        "importanceFilter": "-1,0,1",
        "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
        }`;
        if (container.current) {
            container.current.appendChild(script);
        }
    }, []);
    return (React.createElement("div", { className: "economic-calendar-container" },
        React.createElement("div", { className: "tradingview-widget-container", ref: container },
            React.createElement("div", { className: "tradingview-widget-container__widget" }),
            React.createElement("div", { className: "tradingview-widget-copyright" },
                React.createElement("a", { href: "https://www.tradingview.com/", rel: "noopener nofollow noreferrer", target: "_blank" },
                    React.createElement("span", { className: "blue-text" }, "Track all markets on TradingView"))))));
};
export const EconomicCalendarMemoized = memo(EconomicCalendar);
