import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
import { getColors } from "../../../utils/getColors";
import { usePageViewStyle } from "../../../hooks";
import { Heading } from "../../Heading";
export const ThinkTraderMainBanner = ({ title, subtitle, desktopImageUrl, mobileImageUrl, imageAlt, appStoreButtonLabel, appStoreButtonLink, appStoreButtonIcon, googlePlayButtonLabel, googlePlayButtonLink, googlePlayButtonIcon, webButtonLabel, webButtonLink, webButtonIcon, openInNewTabWebRutton, backgroundColor, haveBottomButtons = true, topButtonLink, openInNewTabTopRutton = true, topButtonLabel, topButtonIcon, textBetweenButtons = "or" }) => {
    const { bgColor } = getColors(backgroundColor);
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const renderButtonSection = () => (haveBottomButtons && React.createElement("div", { className: "thinktrader-main-banner__buttons-container" },
        React.createElement("div", { className: "thinktrader-main-banner__buttons-container__mobile-app" },
            React.createElement(ButtonV2, { design: "ghost-large-wbg", href: appStoreButtonLink, openInNewTab: true, icon: appStoreButtonIcon }, appStoreButtonLabel),
            React.createElement(ButtonV2, { design: "ghost-large-wbg", href: googlePlayButtonLink, openInNewTab: true, icon: googlePlayButtonIcon }, googlePlayButtonLabel)),
        isLarge && React.createElement("span", { className: "thinktrader-main-banner__buttons-container__text-between-buttons" }, textBetweenButtons),
        isLarge && React.createElement("div", { className: "thinktrader-main-banner__buttons-container__web-platform" },
            React.createElement(ButtonV2, { design: "secondary-large-dbg", href: webButtonLink, openInNewTab: openInNewTabWebRutton, icon: webButtonIcon }, webButtonLabel))));
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "thinktrader-main-banner" },
                React.createElement("div", { className: "thinktrader-main-banner__text-container" },
                    React.createElement("div", { className: "thinktrader-main-banner__text-container__title-wrapper" },
                        React.createElement(Heading, { design: "text-jumbo" }, title)),
                    React.createElement("div", { className: "thinktrader-main-banner__text-container__subtitle-wrapper" },
                        React.createElement("p", { dangerouslySetInnerHTML: {
                                __html: subtitle,
                            } })),
                    topButtonLabel && React.createElement("div", { className: "thinktrader-main-banner__text-container__button-wrapper" },
                        React.createElement(ButtonV2, { design: "cta-large-wbg", href: topButtonLink, openInNewTab: openInNewTabTopRutton, icon: topButtonIcon }, topButtonLabel))),
                React.createElement("div", { className: "thinktrader-main-banner__image-container" },
                    (isLarge || isMedium) && React.createElement(ImgV2, { src: desktopImageUrl, alt: imageAlt, loading: "eager" }),
                    isSmall && React.createElement(ImgV2, { src: mobileImageUrl, alt: imageAlt, loading: "eager" })),
                renderButtonSection()))));
};
