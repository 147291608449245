import React from "react";
import { Img } from "../../Img";
import { Container, ContainerFW } from "../../global";
export const HeroBannerWithImagesAndTitle = ({ imageUrl, imageAlt, items, mainTitle, subTitle }) => (React.createElement(ContainerFW, { backgroundColor: "#0E1D31" },
    React.createElement("div", { className: "heroBannerWithImagesAndTitle-root" },
        React.createElement(Container, null,
            React.createElement("h1", null, mainTitle),
            React.createElement(Img, { src: imageUrl, alt: imageAlt, className: "heroBannerWithImagesAndTitle-root-image" }),
            React.createElement("h5", { className: "heroBannerWithImagesAndTitle-root-subTitle" }, subTitle),
            React.createElement("div", { className: "itemsWrapper" }, items.length > 0 && items.map((a) => (React.createElement("div", { key: `unique${a.label}`, className: "itemsWrapper__section" },
                React.createElement(Img, { src: a.imageUrl, alt: a.imageAlt }),
                React.createElement("h5", { className: "itemsWrapper__section__label" }, a.label)))))))));
