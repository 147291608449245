import React from "react";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { Category } from "./Categories/Category";
export const Mt5DownloadSection = ({ title, categories, titleStyles }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "mt5-download-section__root", id: "MTSection" },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: `${titleStyles}` },
                React.createElement(Heading, { design: "h2-regular" }, title)),
            React.createElement("div", { className: `mt5-download-section__categoriesWrapper ${titleStyles}` }, categories.length > 0 && categories.map((a) => (React.createElement(Category, { key: `unique${a.categoryTitle}`, ...a }))))))));
