import { useEffect, useState } from "react";
export const useExecuteTriggerCallback = (callbackFunc) => {
    const getTimestamp = () => Date.now();
    const [, setRefreshTime] = useState(() => getTimestamp());
    const triggerRefresh = () => {
        setRefreshTime(getTimestamp());
    };
    useEffect(() => {
        callbackFunc();
    });
    return {
        triggerRefresh
    };
};
