import * as React from "react";
import "./";
import { Container, ContainerFW } from "../../global";
export const BellyBanner = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "bellyBanner" },
        React.createElement(Container, null,
            React.createElement("div", { className: "bellyBanner__Inner" },
                props.title && React.createElement("h5", null,
                    " ",
                    props.title,
                    " "),
                React.createElement("div", { className: "bellyBanner__text" }, props.items.map((item, index) => (React.createElement("span", { key: index, dangerouslySetInnerHTML: { __html: item } })))),
                React.createElement("a", { href: props.buttonUrl, className: "bellyBanner__Inner__cta" }, props.buttonText))))));
