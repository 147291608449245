import React, { useEffect, useRef, memo } from "react";
import { getInstrumentCodeCorrection } from "./utils";
import { INSTRUMENT_CODE_CORRECTIONS } from "./config";
const InstrumentChart = ({ instrumentCode }) => {
    const container = useRef(null);
    const chartInstrumentCode = getInstrumentCodeCorrection(instrumentCode, INSTRUMENT_CODE_CORRECTIONS) || instrumentCode;
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
          "autosize": true,
          "symbol": "THINKMARKETS:${chartInstrumentCode}",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
        if (container.current) {
            container.current.appendChild(script);
        }
    }, [chartInstrumentCode]);
    return (React.createElement("div", { className: "tradingview-widget-container", ref: container, style: { height: "100%", width: "100%" } },
        React.createElement("div", { className: "tradingview-widget-container__widget", style: { height: "calc(100% - 32px)", width: "100%" } })));
};
export const InstrumentChartMemoized = memo(InstrumentChart);
