import React, { Fragment, useCallback } from "react";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ButtonV2 } from "../ButtonV2";
import { handleLinkTarget } from "../../utils";
import { GrayCheckMark } from "../svgIcons";
import { getColors } from "../../utils/getColors";
export const CardsDeckWithTitleAndSubV2 = ({ title, cards, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    const renderCard = useCallback((card) => {
        const linkHrefTarget = handleLinkTarget(card.linkOpenInNewTab);
        return (React.createElement("div", { className: `cards-deck-with-title-and-sub-v2-root__card ${card.backgroundImage ? "cards-deck-with-title-and-sub-v2-root__card-darkbg" : ""}`, style: { backgroundImage: `url('${card.backgroundImage}'), ${card.linearGradient?.split("###")[0]}` } },
            React.createElement("div", null,
                React.createElement(Heading, { design: "h4-semi-bold" }, card.title),
                React.createElement("span", { className: "cards-deck-with-title-and-sub-v2-root__description-container", dangerouslySetInnerHTML: { __html: card.description } }),
                typeof card.bulletPoints === "string" && card.bulletPoints.length > 2 && card.bulletPoints?.split("###")?.map((b, i) => React.createElement("div", { key: i },
                    React.createElement("div", { className: "cards-deck-with-title-and-sub-v2-root__bullet-container" },
                        GrayCheckMark,
                        React.createElement("div", { className: "caption2-regular" }, b))))),
            card.buttonText && React.createElement(ButtonV2, { design: card.linearGradient?.split("###")[1], href: card.buttonUrls, openInNewTab: true },
                " ",
                React.createElement("span", { className: "text-nav" }, card.buttonText)),
            card.linkText && React.createElement("a", { target: linkHrefTarget, className: "cards-deck-with-title-and-sub-v2-root__link text-nav", href: card.linkUrl },
                " ",
                card.linkText)));
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "cards-deck-with-title-and-sub-v2-root" },
                title && React.createElement(Heading, { design: "h2-regular-small" }, title),
                React.createElement("div", { className: "cards-deck-with-title-and-sub-v2-root__cards-wrapper" },
                    React.createElement("div", { className: "cards-deck-with-title-and-sub-v2-root__first-card-container" }, cards.length > 0 && renderCard(cards[0])),
                    React.createElement("div", { className: "cards-deck-with-title-and-sub-v2-root__wrapper-right" }, cards.length > 1 && cards.slice(1).map((card, i) => React.createElement(Fragment, { key: i }, renderCard(card)))))))));
};
