import React from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils";
import { ButtonLink } from "../ButtonLink/ButtonLink";
import { Heading } from "../Heading";
export const TitleWithSubtitleAndThreeSimpleCards = ({ title, description, buttons, designStyles }) => {
    const { bgColor, textColor } = getColors(designStyles.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { style: { color: textColor }, className: "title-with-subtitle-and-cards" },
                React.createElement("div", { className: "flex title-with-subtitle-and-cards__title-container" },
                    React.createElement("div", { className: "centered-container" },
                        React.createElement(Heading, { design: designStyles.titleDesign }, title)),
                    React.createElement("div", { className: "centered-container" },
                        React.createElement("div", { className: "h5-semi-bold" },
                            " ",
                            description))),
                React.createElement("br", null),
                React.createElement("div", { className: "grid title-with-subtitle-and-cards__buttons-container" }, buttons.map((b, i) => React.createElement("div", { key: i },
                    React.createElement(ButtonLink, { style: { width: "100%" }, href: b.url },
                        React.createElement("span", { className: "h4-semi-bold" }, b.title)))))))));
};
