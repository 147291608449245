import * as React from "react";
export const Checkboxes = (props) => {
    const [tabActive, setTabActive] = React.useState("");
    const fieldHandler = (title, val) => React.useCallback(() => {
        const dataTitle = title.replaceAll(" ", "");
        const prevValue = props.filtersData[dataTitle] || "";
        const value = prevValue.includes(`${val},`) ? prevValue.replace(`${val},`, "") : `${prevValue}${val},`;
        const tabValueAll = prevValue.includes(props.items.join(",").replace("All,", "")) ? "" : `${props.items.join(",").replace("All,", "")},`;
        val === "All" ? props.handler(dataTitle, tabValueAll) : props.handler(dataTitle, value);
    }, [props.filtersData]);
    const inlineClass = props.inlineLayout ? "inline" : "";
    React.useEffect(() => {
        if (props.type === "tab") {
            Object.keys(props.filtersData).length && setTabActive(props.filtersData[props.title].includes(props.items.join(",").replace("All,", "")) ? props.items.join(",") : props.filtersData[props.title]);
        }
    }, [props.filtersData]);
    return (props.type === "checkbox" ?
        React.createElement("div", { className: `filters__field-checkboxes ${inlineClass}` }, props.items.map((item, id) => React.createElement("div", { key: `checkbox-${props.id}-${id}`, className: `filters__checkbox ${inlineClass}` },
            React.createElement("input", { checked: props.currentValue.includes(item) || false, onChange: fieldHandler(props.title, item), type: "checkbox", name: item, id: `checkbox-${props.id}-${id}` }),
            React.createElement("label", { htmlFor: `checkbox-${props.id}-${id}` }),
            item))) : React.createElement("div", { className: "filters__tabs-container" },
        React.createElement("div", { className: "filters__field-tabs" }, props.items.map((item, id) => React.createElement("div", { key: `filters-tab-${props.id}-${id}`, onClick: fieldHandler(props.title, item), className: `filters__tab ${inlineClass} ${tabActive.includes(item) ? "filters__tab--active" : ""}` }, item)))));
};
