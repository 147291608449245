import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { SectionItem } from "./SectionItem/SectionItem";
export const SectionWithTwoLists = ({ columns, backGroundColor }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "section-with-two-lists" }, columns.length > 0 && columns.map((a) => (React.createElement("div", { key: `unique${a.title}`, className: "section-with-two-lists__itemsWrapper" },
                React.createElement(Heading, { design: "h3-semi-bold-small" }, a.title),
                a.items.map((item) => (React.createElement(SectionItem, { key: `unique${item.content}`, ...item }))))))))));
};
