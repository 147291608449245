import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
export const AnimatedBigCard = ({ buttonHref, buttonLabel, imgUrlDesktop, imgUrlTablet, imgUrlPhone, cardContent, title }) => (React.createElement("div", { className: "animated-big-card-root" },
    React.createElement("div", { className: "animated-big-card-root__textWrapper" },
        React.createElement(Heading, { design: "h4-bold" }, title),
        React.createElement("p", null, cardContent),
        React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonHref }, buttonLabel)),
    React.createElement("div", { className: "animated-big-card-root__imageWrapper" },
        React.createElement(ImgV2, { largeUrl: imgUrlDesktop, mediumUrl: imgUrlTablet, smallUrl: imgUrlPhone, alt: title, loading: "eager" }))));
