import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { Gap } from "../../Gaps/Gap";
import { clock } from "../../svgIcons/clock";
import { ImgV2 } from "../../ImgV2";
import { getColors } from "../../../utils";
import { Heading } from "../../../Components/Heading";
export const PodcastEpisodeBanner = ({ titleBig, timeToReadString, linkUrl, richTitleSide, richSubtitle1, richSubtitle2, imageUrl, imageAlt, backgroundColor, headingDesign }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "podcast-episode-banner" },
                React.createElement("div", { className: "podcast-episode-banner__title" },
                    React.createElement(Heading, { design: headingDesign }, titleBig)),
                React.createElement(Gap, { desktopGap: "33px", mobileGap: "25px" }),
                React.createElement("div", { className: "podcast-episode-banner__image-and-content-container" },
                    React.createElement("a", { href: linkUrl, target: "_blank", rel: "noopener noreferrer" },
                        React.createElement("div", { className: "centered-container" },
                            React.createElement(ImgV2, { className: "podcast-episode-banner__responsive-image", src: imageUrl, alt: imageAlt }))),
                    React.createElement("div", null,
                        timeToReadString && React.createElement("span", { className: "color-dark-80" },
                            clock(),
                            " ",
                            timeToReadString),
                        React.createElement(Gap, { desktopGap: "10px", mobileGap: "17px" }),
                        richTitleSide && React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: richTitleSide,
                            } }),
                        React.createElement(Gap, { desktopGap: "10px", mobileGap: "17px" }),
                        richSubtitle1 && React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: richSubtitle1,
                            } }),
                        React.createElement(Gap, { desktopGap: "10px", mobileGap: "17px" }),
                        richSubtitle2 && React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: richSubtitle2,
                            } })))))));
};
