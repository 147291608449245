export const getInstrumentCodeCorrection = (instrumentCode, correctionsMap) => Object.entries(correctionsMap).find(([key,]) => key.toLowerCase() === instrumentCode.toLowerCase())?.[1];
export const INSTRUMENT_CODE_CORRECTIONS = {
    amzn: "amzn",
    arbnb: "abnb",
    v: "v",
    alibaba: "baba",
    tsla: "tsla",
    msft: "msft",
    gs: "gs"
};
getInstrumentCodeCorrection;
