import React, { Fragment, useMemo } from "react";
import { IconNoResults } from "../svgIcons";
import { AlphabetListItem } from "./AlphabetListItem/AlphabetListItem";
export const AlphabetList = (props) => {
    const handleGlossaryTitle = useMemo(() => {
        if (props.title) {
            return (props.title?.charAt(0).toUpperCase() + props.title?.slice(1));
        }
        return "";
    }, [props.title]);
    const [data, setData] = React.useState({});
    const [selectedType, setSelectedType] = React.useState();
    React.useEffect(() => {
        const letters = [...new Set([...props.content].map(l => l.letter).sort())];
        const obj = {};
        letters.map((l) => obj[l ? l : ""] = []);
        props.content.map(item => { obj[`${item.letter}`] = [...obj[`${item.letter}`], { term: item.term, definition: item.definition }]; });
        setData(obj);
        const parts = window.location.href.split("/");
        const lastSegment = parts.pop() || parts.pop();
        setSelectedType(lastSegment && lastSegment);
    }, [props]);
    const resultsTitle = () => Object.keys(data).length ?
        React.createElement("h5", { className: "alphabet-list__title search-title h5-semi-bold" },
            props.searchResultsText,
            " \u201C",
            props.searchQuery,
            "\u201C")
        : React.createElement("div", { className: "alphabet-list__noresults" },
            IconNoResults,
            props.noResultsText);
    return (React.createElement("div", { className: "alphabet-list" },
        (selectedType && selectedType.length > 1 && !props.searchQuery) && React.createElement("h2", { className: "alphabet-list__title" }, handleGlossaryTitle),
        props.searchQuery && React.createElement("div", { className: "search-title__container" }, resultsTitle()),
        React.createElement("div", { className: "alphabet-list__container" }, data && Object.keys(data).map((item, i) => (React.createElement("div", { key: i, className: "alphabet-list__content" },
            React.createElement("div", { className: "alphabet-list__letter" },
                React.createElement("h3", null, item)),
            React.createElement("div", { className: "alphabet-list__letter-block" }, data[item].map((el, i) => React.createElement(AlphabetListItem, { key: `alphabet-list-${i}`, ...el, definition: getHighlightedText(el.definition, props.searchQuery ? props.searchQuery : ""), term: getHighlightedText(el.term, props.searchQuery ? props.searchQuery : "") })))))))));
};
export const getHighlightedText = (text, highlightedText, highLightColor = "#BEF3BD") => {
    const parts = text.split(new RegExp(`(${highlightedText})`, "gi"));
    return React.createElement(React.Fragment, null,
        " ",
        parts.map((part, i) => {
            const isPartHighlighted = part.toLowerCase() === highlightedText.toLowerCase();
            const key = `${text.toLowerCase().replace(" ", "")}-${i}`;
            return React.createElement(Fragment, { key: key }, isPartHighlighted ?
                React.createElement("mark", { style: { backgroundColor: highLightColor } }, part) : React.createElement(React.Fragment, null, part));
        }),
        " ");
};
