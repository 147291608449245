import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { HeroBanner } from "./HeroBanner";
import { ContainerFW, Container } from "../../global";
import { viewSizes } from "../../../pageViewStyle";
import { Img } from "../../Img";
export const HeroBannerSlider = (props) => (React.createElement("div", { className: "hero-banner-slider" },
    React.createElement(ContainerFW, null,
        React.createElement(Swiper, { className: "hero-slider", modules: [Navigation], loop: true, navigation: {
                nextEl: ".hero-banner-slider__navigation",
            }, breakpoints: {
                [viewSizes.mediumMaxWidth]: {
                    navigation: false,
                },
            } }, props.items.map((item, i) => (React.createElement(SwiperSlide, { key: `banner-item-${i}` },
            React.createElement(HeroBanner, { imageUrl: item.imageUrl, mobileImageUrl: item.mobileImageUrl, darkTheme: item.darkTheme, title: item.title, text: item.text, buttonHref: item.buttonHref, buttonTitle: item.buttonTitle, linkTitle: item.linkTitle, linkHref: item.linkHref }))))),
        React.createElement("div", { className: "hero-banner-slider__navigation" },
            React.createElement(Container, null,
                React.createElement(Img, { src: props.navigationImageUrl, className: "hero-banner-slider__navigation-image", alt: props.navigationText, loading: "eager" }),
                React.createElement("h4", { className: "hero-banner-slider__navigation-title" }, props.navigationText))))));
