import React from "react";
import { Button } from "../Button";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
export const TradersGym = (props) => (React.createElement("div", { className: "traders-gym" },
    React.createElement(ContainerFW, null,
        React.createElement(Container, null,
            React.createElement("div", { className: "traders-gym__inner" },
                React.createElement("div", { className: "traders-gym__desktop" },
                    React.createElement("div", { className: "traders-gym__content" },
                        React.createElement("h1", { dangerouslySetInnerHTML: {
                                __html: props.title,
                            } }),
                        React.createElement("div", { className: "traders-gym__content-inner" },
                            React.createElement("p", null, props.text),
                            React.createElement(Button, { label: props.linkText, href: props.linkUrl, primary: true }))),
                    React.createElement(Img, { src: props.imageUrl, alt: props.imageAlt })),
                React.createElement("div", { className: "traders-gym__mobile" },
                    React.createElement("h1", { dangerouslySetInnerHTML: { __html: props.title } }),
                    React.createElement("p", null, props.text),
                    React.createElement(Img, { src: props.imageUrl, alt: props.imageAlt }),
                    React.createElement(Button, { label: props.linkText, href: props.linkUrl, primary: true })))))));
