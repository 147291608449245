import React from "react";
import { ContainerFW, Container } from "../../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { BrokerRegulationCard } from "./BrokerRegulationCard/BrokerRegulationCard";
export const BrokerRegulationCardDeck = ({ title, content }) => (React.createElement("div", { className: "broker-regulation-card-deck" },
    React.createElement(ContainerFW, null,
        React.createElement("h2", { className: "broker-regulation-card-deck__title", dangerouslySetInnerHTML: { __html: title } }),
        React.createElement(Container, null,
            React.createElement("div", { className: "broker-regulation-card-deck__table-view" }, content.map((item, i) => (React.createElement(BrokerRegulationCard, { key: i, title: item.title, text: item.text }))))),
        React.createElement("div", { className: "broker-regulation-card-deck__swiper-view" },
            React.createElement(Swiper, { modules: [Navigation, Pagination], spaceBetween: 16, slidesPerView: "auto", navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }, pagination: {
                    el: ".broker-regulation-card-deck__pagination",
                    clickable: true,
                    renderBullet: () => ("<span class='broker-regulation-card-deck__bullet swiper-pagination-bullet'></span>"),
                } }, content.map((item, i) => React.createElement(SwiperSlide, { key: `broker-regulation-card-deck-slide-${i}` },
                React.createElement(BrokerRegulationCard, { title: item.title, text: item.text })))),
            React.createElement("div", { className: "broker-regulation-card-deck__pagination" })))));
