import React from "react";
import { getColors } from "../../utils";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
import { CardInfo } from "./CardInfo";
export const TradeWithExpertInsights = ({ buttonLink, buttonText, cardInfoSet, imageUrl, subTitle, title, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "trade-with-expert-insights-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "trade-with-expert-insights-root__textContainer" },
                    React.createElement(Heading, { design: "h2-lp" }, title),
                    React.createElement("p", { className: "trade-with-expert-insights-root__subtitle", dangerouslySetInnerHTML: {
                            __html: subTitle || "",
                        } })),
                React.createElement("div", { className: "trade-with-expert-insights-root__image-container" },
                    React.createElement("div", { className: "trade-with-expert-insights-root__image-container__imageWrapper" },
                        React.createElement(ImgV2, { src: imageUrl, alt: imageUrl })),
                    React.createElement("div", { className: "trade-with-expert-insights-root__image-container__cardsInfoWrapper" }, cardInfoSet.length > 0 && cardInfoSet.map((card) => (React.createElement(CardInfo, { key: `unique${card.cardText}`, cardImg: card.cardImg, cardText: card.cardText }))))),
                React.createElement("div", { className: "trade-with-expert-insights-root__image-container__buttonWrapper" },
                    React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonLink }, buttonText))))));
};
