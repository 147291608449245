import React, { useEffect, useState } from "react";
import { JobBoardPagination } from "../../..//Components/JobBoard/JobBoardPagination/JobBoardPagination";
import { pageViewStyle } from "../../../pageViewStyle";
import { Col, Row, Container, ContainerFW } from "../../global";
import { VideoCard } from "../VideoCardDeck/VideoCard";
export const VideoCardDeckWithPagination = (props) => {
    const [view, setView] = useState(pageViewStyle.getView());
    const [currentPage, setCurrentPage] = useState(0);
    const cardsPerPage = 6;
    const onViewUpdate = (view) => {
        setView(view);
    };
    useEffect(() => {
        pageViewStyle.subscribe(onViewUpdate);
        return () => {
            pageViewStyle.unsubscribe(onViewUpdate);
        };
    }, [view]);
    const selectedCards = props.cards.slice(currentPage * cardsPerPage, (currentPage + 1) * cardsPerPage);
    const cardsLength = props.cards.length;
    const numOfPages = Math.ceil(cardsLength / cardsPerPage);
    const itemsShown = () => {
        let itemsString = "";
        currentPage === 0 ?
            itemsString = `${cardsLength > cardsPerPage ? cardsPerPage : cardsLength}`
            : (currentPage + 1) === numOfPages ?
                itemsString = `${(numOfPages - 1) * cardsPerPage + 1}-${cardsLength}`
                : itemsString = `${(currentPage) * cardsPerPage + 1}-${(currentPage + 1) * cardsPerPage}`;
        return itemsString;
    };
    const thisComponentRef = React.useRef(null);
    const scrollToThisComponentRef = React.useCallback(() => {
        thisComponentRef.current?.scrollIntoView({ behavior: "auto" });
        if (thisComponentRef.current) {
            window.scrollBy(0, -105);
        }
    }, []);
    const mounted = React.useRef(false);
    React.useEffect(() => {
        if (mounted.current) {
            scrollToThisComponentRef();
        }
        else {
            mounted.current = true;
        }
    }, [currentPage]);
    return (React.createElement(ContainerFW, null,
        props.filtersRibbonElement,
        props.filtersElement,
        React.createElement("div", { ref: thisComponentRef, className: "videoCardDeckWithPagination" },
            React.createElement(Container, null,
                React.createElement("h2", null, props.title),
                props.children,
                React.createElement(Row.Cols3, null, selectedCards.map((card, i) => (React.createElement(Col, { key: i },
                    React.createElement(VideoCard, { webSiteRootUrl: props.webSiteRootUrl, ...card }))))),
                React.createElement("div", { className: "jobBoard__pagination" },
                    React.createElement(JobBoardPagination, { currentPage: currentPage, numOfPages: numOfPages, setCurrentPage: setCurrentPage, isSmallScreen: view !== "large" })),
                React.createElement("p", { className: "videoCardDeckWithPagination__display" }, `Displaying ${itemsShown()} of ${props.cards.length} videos`)))));
};
