import React, { useEffect, useState } from "react";
import { Img } from "../../../Img";
export const PhonesGridContainer = ({ mainTitle, subTitle, upperTitle, phonesImageUrl }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (currentImageIndex < phonesImageUrl.length - 1) {
                setCurrentImageIndex(currentImageIndex + 1);
            }
            else {
                setCurrentImageIndex(0);
            }
        }, 2000);
        return () => clearInterval(interval);
    }, [currentImageIndex, phonesImageUrl.length]);
    return (React.createElement("div", { className: "phonesGridContainerWrapper" },
        React.createElement("p", { className: "phonesGridContainerWrapper__upperTitle" }, upperTitle),
        React.createElement("h3", { className: "phonesGridContainerWrapper__mainTitle" }, mainTitle),
        React.createElement("p", { className: "phonesGridContainerWrapper__mainSubTItle" }, subTitle),
        React.createElement("div", { className: "phonesGridContainerWrapper__phoneImagesWrapper" }, phonesImageUrl.length > 0 && phonesImageUrl.map((a, index) => (React.createElement(Img, { key: `unique${a}`, src: phonesImageUrl[index], alt: mainTitle, className: `${currentImageIndex === index ? "elementToFadeIn" : "elementToFadeOut"}` }))))));
};
