import React from "react";
import { Container, ContainerFW } from "../global";
import { TradeMiniAccountCard } from "./TradeMiniAccountCard/TradeMiniAccountCard";
export const TradeMiniAccount = ({ mainTitle, subTitle, cards, cardOrientation = "horizontal" }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement("div", { className: "tradeMiniAccount-root" },
        React.createElement(Container, null,
            React.createElement("div", { className: "tradeMiniAccount" },
                React.createElement("div", { className: "tradeMiniAccount__text-container" },
                    React.createElement("h2", null, mainTitle),
                    React.createElement("p", null, subTitle)),
                React.createElement("div", { className: `${cardOrientation === "horizontal" ? "tradeMiniAccount-cards-wrapper" : "tradeMiniAccount-cards-wrapperVertical"}` }, cards.length > 0 && cards.map((a) => (React.createElement(TradeMiniAccountCard, { key: `unique${a.title}`, title: a.title, description: a.description, buttonLabel: a.buttonLabel, buttonHref: a.buttonHref, mobileImg: a.mobileImg, tabletImg: a.tabletImg, desktopImg: a.desktopImg })))))))));
