import React from "react";
import { InstrumentHeader } from "../InstrumentHeader";
import { InstrumentChartMemoized } from "../InstrumentChart";
import { Container, ContainerFW } from "../global";
export const InstrumentInfo = ({ instrumentName, instrumentCode, bidTitle, askTitle, openTitle, prevCloseTitle, dayRangeTitle, week52RangeTitle, session, instrument, apiUrl, wsUrl, currencyCode, currencyIconUrl, instrumentDescription, bidAskLinksUrl }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement(Container, { className: "instrument-header-container" },
        React.createElement(InstrumentHeader, { instrumentName: instrumentName, instrumentCode: instrumentCode, bidTitle: bidTitle, askTitle: askTitle, openTitle: openTitle, prevCloseTitle: prevCloseTitle, dayRangeTitle: dayRangeTitle, week52RangeTitle: week52RangeTitle, session: session, instrument: instrument, apiUrl: apiUrl, wsUrl: wsUrl, currencyCode: currencyCode, currencyIconUrl: currencyIconUrl, bidAskLinksUrl: bidAskLinksUrl }),
        React.createElement("div", { className: "instrument-info__chart" },
            React.createElement(InstrumentChartMemoized, { instrumentCode: instrumentCode })),
        instrumentDescription && React.createElement("div", { className: "instrument-info__description", dangerouslySetInnerHTML: {
                __html: instrumentDescription,
            } }))));
