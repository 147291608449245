import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
import { Listitem } from "./ListItem/Listitem";
import { getColors } from "../../utils/getColors";
export const SectionWithListVersioned = ({ title, buttonHref, buttonLabel, imageUrl, list, openInNewTabButton, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "section-with-list-versioned__root" },
                React.createElement("div", { className: "section-with-list-versioned__root__textsWrapper" },
                    React.createElement(Heading, { design: "h3-bold" }, title),
                    Array.isArray(list) && list.length > 0 && list.map((a) => (React.createElement(Listitem, { key: `unique${a.title}`, ...a }))),
                    React.createElement(ButtonV2, { openInNewTab: openInNewTabButton, design: "cta-large-wbg", href: buttonHref }, buttonLabel)),
                React.createElement("div", { className: "section-with-list-versioned__root__imageWrapper" },
                    React.createElement(ImgV2, { src: imageUrl }))))));
};
