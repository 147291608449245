import React, { useState, useEffect, useRef } from "react";
import { getColors } from "../../utils";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { usePageViewStyle } from "../../hooks";
export const RichTextDescriptionCtaAndImageBanner = ({ richTitle, description, buttonUrl, buttonText, designStyles, imgUrlDesktop, imgUrlMobile }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    const { isLarge } = usePageViewStyle();
    const videoRef = useRef(null);
    const [animationPath, setAnimationPath] = useState("");
    useEffect(() => {
        if (isLarge) {
            setAnimationPath(imgUrlDesktop);
        }
        else {
            setAnimationPath(imgUrlMobile);
        }
    });
    if (videoRef.current) {
        videoRef.current.load();
    }
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: "rich-text-description-cta-and-image-banner", style: { color: textColor } },
                React.createElement("div", { className: "rich-text-description-cta-and-image-banner__top-end-container" },
                    React.createElement("div", { style: { backgroundColor: bgColor }, className: "rich-text-description-cta-and-image-banner__title-container" },
                        React.createElement("span", { className: "h1-lp", style: {
                                color: designStyles.backgroundColor === "dark" ? "#FFFFFF" : ""
                            }, dangerouslySetInnerHTML: {
                                __html: richTitle
                            } })),
                    React.createElement("div", { className: "rich-text-description-cta-and-image-banner__button-and-description-container" },
                        React.createElement("p", { style: {
                                color: designStyles.backgroundColor === "dark" ? "#FFFFFF" : ""
                            }, className: "color-dark-80 h5-semi-bold" }, description),
                        React.createElement("div", { className: "rich-text-description-cta-and-image-banner__button-container" },
                            React.createElement(ButtonV2, { href: buttonUrl, design: designStyles.buttonDesign }, buttonText)))),
                React.createElement("br", null),
                React.createElement("div", { className: "rich-text-description-cta-and-image-banner__image-container" },
                    React.createElement("video", { ref: videoRef, width: "100%", loop: true, autoPlay: true, muted: true, playsInline: true },
                        React.createElement("source", { src: animationPath, type: "video/mp4" })))))));
};
