import React, { useCallback } from "react";
import { Img } from "../../Img";
import { ButtonSquare } from "../../ButtonSquare";
import { Container, ContainerFW } from "../../global";
import { IconPDF } from "../../svgIcons/pdf";
import { getColors } from "../../../utils";
import { Heading } from "../../Heading";
import { ButtonV2 } from "../../ButtonV2";
export const TradeWithComponent = ({ imageUrl, imageAlt, h2title, backgroundColor, informationItems, mainSubTitle, mainTitle, buttonLabel, downloadButton, buttonHref, imagePosition = "left", buttonOpenInNewTab, titleFontSize, tabletDesign, greenButton }) => {
    const { bgColor } = getColors(backgroundColor);
    const renderButton = useCallback(() => {
        const buttonOpenInNewTabValue = (buttonOpenInNewTab === true ? true : false);
        if (buttonLabel && downloadButton) {
            return React.createElement("div", { style: { marginTop: "32px" } },
                React.createElement("a", { href: buttonHref, className: "button button--secondary WideCardWithList__link", download: true },
                    IconPDF,
                    buttonLabel));
        }
        else if (buttonLabel) {
            return greenButton ? React.createElement(ButtonV2, { href: buttonHref, design: "cta-large-wbg", openInNewTab: buttonOpenInNewTabValue }, buttonLabel) : React.createElement(ButtonSquare, { label: buttonLabel, href: buttonHref, openinnewtab: buttonOpenInNewTabValue.toString() });
        }
        else {
            return "";
        }
    }, [downloadButton, buttonLabel, buttonHref, buttonOpenInNewTab, greenButton]);
    const getTitle = useCallback(() => {
        if (mainTitle) {
            return h2title ? React.createElement(Heading, { design: titleFontSize === "h2-regular-small" ? "h2-regular-small" : "h2-regular" }, mainTitle) : React.createElement(Heading, { design: "h1-regular" }, mainTitle);
        }
        else {
            return "";
        }
    }, [mainTitle, h2title, titleFontSize]);
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor ? bgColor : "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: `${imagePosition === "left" ? "TradeWithComponentWrapper" : "TradeWithComponentWrapperRight"} ${tabletDesign === "col-2" ? "col-2" : ""}` },
                getTitle(),
                React.createElement("p", { className: "TradeWithComponentWrapper__mainSubTitle", dangerouslySetInnerHTML: {
                        __html: mainSubTitle ? mainSubTitle : "",
                    } }),
                React.createElement(Img, { src: imageUrl, className: "TradeWithComponentWrapper__image", alt: imageAlt }),
                informationItems.length > 0 && React.createElement("div", { className: "TradeWithComponentWrapper__sectionWrapper" }, informationItems.map((a, index) => (React.createElement("div", { key: index, className: "TradeWithComponentWrapper__sectionWrapper__section" },
                    React.createElement(Img, { src: a.icon, alt: a.iconAlt, className: "TradeWithComponentWrapper__sectionWrapper__section__image" }),
                    React.createElement("span", { className: "TradeWithComponentWrapper__sectionWrapper__section__titleWrapper" },
                        React.createElement(Heading, { design: "h5-bold" }, a.title),
                        a.subTitle && React.createElement("p", { className: "TradeWithComponentWrapper__sectionWrapper__section__titleWrapper__subTitle", dangerouslySetInnerHTML: { __html: a.subTitle } })))))),
                renderButton()))));
};
