import React from "react";
import { ImgV2 } from "../ImgV2";
import { Heading } from "../Heading";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { ContainerFW, Container } from "../global";
export const CardsDeckWithTitleSimple = ({ title, items, buttonLink, buttonText, openInNewTab }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement(Container, { className: "g-container--tablet-margins" },
        React.createElement("div", { className: "cards-deck-with-title-simple" },
            React.createElement("div", { className: "cards-deck-with-title-simple__title-container centered-container" },
                React.createElement(Heading, { design: "h3-bold" }, title)),
            React.createElement("div", { className: "cards-deck-with-title-simple__cards-wrapper" }, items && items.map((a, index) => (React.createElement("div", { className: "cards-deck-with-title-simple__card", key: `card-${index}` },
                React.createElement("div", { className: "cards-deck-with-title-simple__image-container" },
                    React.createElement(ImgV2, { className: "cards-deck-with-title-simple__section-image", src: a.imageUrl, alt: a.imageAlt })),
                React.createElement("div", { className: "cards-deck-with-title-simple__text-container" },
                    React.createElement("h5", { className: "h5-bold", dangerouslySetInnerHTML: {
                            __html: a.title
                        } }),
                    React.createElement("p", { className: "p-regular", dangerouslySetInnerHTML: {
                            __html: a.text
                        } })))))),
            buttonLink && React.createElement("div", { className: "cards-deck-with-title-simple__buttons-wrapper" },
                React.createElement(ButtonV2, { design: "tmx25-round-green-lightgreen", href: buttonLink, openInNewTab: openInNewTab, showArrowRight: true }, buttonText))))));
