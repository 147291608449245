import { CULTURES_TO_LOCALE_MAP } from "../../../i18n";
import * as utils from "./instrumentTranslations.utils";
export class InstrumentFieldsTranslator {
    constructor(contextInfo) {
        this.culture = contextInfo.culture;
        this.instrumentGroupName = contextInfo.instrumentGroupName;
        this.instrumentSubgroupName = contextInfo.instrumentSubgroupName;
        this.instrFieldsTranslateConfig = contextInfo.instrFieldsTranslateConfig;
        this.translationFunction = contextInfo.translationFunction;
        this.getTranslatedValue = this.getTranslatedValue.bind(this);
        this.cultureConfig = utils.getCultureTranslateConfigData(this.culture, this.instrFieldsTranslateConfig);
        if (!this.cultureConfig) {
            console.info("No contract specs translation config found for this culture");
            this.instrumentGroupConfig = undefined;
            return;
        }
        if (!CULTURES_TO_LOCALE_MAP[this.culture]) {
            console.info("No locale mapping found for this culture");
            this.instrumentGroupConfig = undefined;
            return;
        }
        this.instrumentGroupConfig = utils.getInstrGroupTranslateConfigData(this.instrumentGroupName, this.instrumentSubgroupName, this.cultureConfig);
    }
    getTranslatedValue(fieldName, originalValue) {
        if (!this.instrumentGroupConfig) {
            return originalValue;
        }
        return utils.getTranslatedValue({ fieldName, originalValue, instrumentGroupConfig: this.instrumentGroupConfig, translationFunction: this.translationFunction });
    }
}
