import React, { useMemo } from "react";
import { ContactItemContent } from "./ContactItemContent";
import { determineContactItemType, handleCardClick, simulateMouseClickOnZendeskChatIcon, } from "./contactUs.utils";
export const StillHaveQuestionItem = ({ imgUrl, imgAlt, title, linkUrl, cardUrl, }) => {
    const handleClick = useMemo(() => cardUrl && handleCardClick
        ? handleCardClick(cardUrl)
        : simulateMouseClickOnZendeskChatIcon, [cardUrl]);
    const handleKeyPress = useMemo(() => cardUrl && handleCardClick
        ? handleCardClick(cardUrl)
        : simulateMouseClickOnZendeskChatIcon, [cardUrl]);
    const contactItemType = determineContactItemType(linkUrl);
    if (contactItemType === "tel") {
        return (React.createElement(React.Fragment, null,
            React.createElement("a", { className: "still-have-questions-item still-have-questions-item--mobile", href: linkUrl, dir: "ltr" },
                React.createElement(ContactItemContent, { title: title, imgUrl: imgUrl, imgAlt: imgAlt })),
            React.createElement("a", { className: "still-have-questions-item still-have-questions-item--non-mobile", onClick: handleClick, onKeyPress: handleKeyPress, dir: "ltr" },
                React.createElement(ContactItemContent, { title: title, imgUrl: imgUrl, imgAlt: imgAlt }))));
    }
    if (contactItemType === "mailto") {
        return (React.createElement("a", { className: "still-have-questions-item", href: linkUrl },
            React.createElement(ContactItemContent, { title: title, imgUrl: imgUrl, imgAlt: imgAlt })));
    }
    return (React.createElement("a", { className: "still-have-questions-item", onClick: handleClick, onKeyPress: handleKeyPress },
        React.createElement(ContactItemContent, { title: title, imgUrl: imgUrl, imgAlt: imgAlt })));
};
