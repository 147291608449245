import React, { useState, useEffect } from "react";
import { ContainerFW } from "../global";
import { Container } from "../global";
import { Img } from "../Img";
import { usePageViewStyle } from "../../hooks";
export const MeetOurCEO = (props) => {
    const { isLarge, isMedium } = usePageViewStyle();
    const [isMediumValue, setIsMediumValue] = useState(false);
    const [isLargeValue, setIsLargeValue] = useState(false);
    useEffect(() => {
        setIsMediumValue(isMedium);
        setIsLargeValue(isLarge);
    }, [isMedium, isLarge]);
    return (React.createElement(React.Fragment, null, (isLargeValue || isMediumValue) && React.createElement(ContainerFW, null,
        React.createElement("div", { className: "meetOurCEO" },
            React.createElement(Container, null,
                React.createElement("div", { className: "meetOurCEO__content" },
                    React.createElement(Img, { className: "meetOurCEO__img1", src: props.imageUrl, alt: props.imageAlt }),
                    React.createElement("div", { className: "meetOurCEO__text" },
                        React.createElement("h2", null, props.title),
                        React.createElement("p", { className: "meetOurCEO__comment", dangerouslySetInnerHTML: { __html: props.text } }),
                        React.createElement("h4", null, props.name),
                        React.createElement("p", { className: "meetOurCEO__position" }, props.position))))))));
};
