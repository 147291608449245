import React from "react";
import { getColors } from "../../utils/getColors";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
export const Mt5GradientBanner = ({ bannerImage, title, subTitle, ctaUrl, ctaLabel, gradientImage, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "Mt5-gradient-banner__root" },
            React.createElement("div", { className: "Mt5-gradient-banner__bgGradient", style: { "--background-image-url": `url(${gradientImage})` } }),
            React.createElement(Container, null,
                React.createElement("div", { className: "Mt5-gradient-banner__bannerImgWrapper" },
                    React.createElement(ImgV2, { src: bannerImage })),
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("p", { className: "Mt5-gradient-banner__subTitle p-regular" }, subTitle),
                React.createElement(ButtonV2, { design: "cta-large-dbg", href: ctaUrl }, ctaLabel)))));
};
