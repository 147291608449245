import * as React from "react";
import { Container, ContainerFW } from "../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination } from "swiper";
import { ContractSpecsTab } from "./ContractSpecsTab";
import { usePageViewStyle } from "../../hooks";
import { getCultureConfigData } from "./utils";
import { DEFAULT_CS_PAGE_CONFIG } from "./config";
import { Suspense } from "react";
import { LoadingSpinner } from "../LoadingSpinner";
const listOfPossibleFilters = ["positionQuality", "country", "currency", "accountType", "leverageType", "instrumentSubtype"];
export const ContractSpecsTabs = (props) => {
    const swiperRef = React.useRef();
    const { viewStyle: view } = usePageViewStyle();
    const swiperOnBeforeInit = React.useCallback((swiper) => {
        swiperRef.current = swiper;
    }, []);
    const handleSwiperHeightUpdate = React.useCallback(() => {
        swiperRef.current?.update();
    }, []);
    const renderBulletFunction = (index, className) => {
        const { title, iconUrl, iconActiveUrl } = props.items[index];
        const iconActiveUrlAdjusted = iconActiveUrl || iconUrl || "";
        const navItemInlineStyle = `--icon-idle-url: url(${iconUrl}); --icon-active-url: url(${iconActiveUrlAdjusted});`;
        return `<div class="careerTabsSlider__bullets swiper-pagination-bullet ${className}" style="${navItemInlineStyle}">
            <h2 data-label="${title}">${title}</h2>
        </div>`;
    };
    React.useEffect(() => {
        swiperRef.current?.slideTo(props.activeTabIndex);
    }, [props.activeTabIndex]);
    React.useEffect(() => {
        window.addEventListener("load", handleSwiperHeightUpdate);
        return () => {
            window.removeEventListener("load", handleSwiperHeightUpdate);
        };
    }, [handleSwiperHeightUpdate]);
    const pageConfigInfo = props.pageConfig || DEFAULT_CS_PAGE_CONFIG(props.culture);
    if (!getCultureConfigData(props.culture, pageConfigInfo)) {
        return (React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "contract-specs-tabs__error" }, "Error loading contract specs: no config data found for current culture"))));
    }
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "careerTabsSlider contract-specs-tabs" },
            React.createElement("div", { className: "careerTabsSlider__sliderWrap" },
                React.createElement("div", { className: "pagination-container" },
                    React.createElement(Container, null,
                        React.createElement("div", { className: "careerTabsSlider__customPagination" }))),
                React.createElement(Suspense, { fallback: React.createElement("div", { className: "spinner-wrapper" },
                        React.createElement(LoadingSpinner, null)) },
                    React.createElement(Swiper, { onBeforeInit: swiperOnBeforeInit, effect: view === "large" ? "fade" : undefined, pagination: {
                            el: ".careerTabsSlider__customPagination",
                            clickable: true,
                            renderBullet: renderBulletFunction
                        }, modules: [EffectFade, Pagination], className: "careerTabsSlider__swiper", autoHeight: true, allowTouchMove: false }, Object.values(props.items).map((child, index) => (React.createElement(SwiperSlide, { key: index },
                        React.createElement(ContractSpecsTab, { listOfPossibleFilters: listOfPossibleFilters, title: child.title, group: child.group, subGroup: child.subGroup, subGroupAdditionalInfo: child.subGroupAdditionalInfo, data: props.data, culture: props.culture, pageConfig: pageConfigInfo, tableInfos: props.tableInfo, additionalInfos: props.additionalInfo, swiperHandler: handleSwiperHeightUpdate, areFiltersExpanded: false, filtersTranslations: props.filtersTranslations, columnsTranslations: props.columnsTranslations }))))))))));
};
