import * as React from "react";
import { pageViewStyle } from "../../../pageViewStyle";
import { Img } from "../../Img";
export const Hello = (props) => {
    const [view, setView] = React.useState(pageViewStyle.getView());
    const onViewUpdate = (view) => setView(view);
    React.useEffect(() => {
        pageViewStyle.subscribe(onViewUpdate);
        return () => {
            pageViewStyle.unsubscribe(onViewUpdate);
        };
    }, [view]);
    return (React.createElement("h1", { className: "hello" },
        " ",
        "Hello, ",
        React.createElement("span", { className: "hello__name" }, props.name),
        ", current view is: ",
        view,
        React.createElement("br", null),
        props.imageURL && (React.createElement("div", null,
            React.createElement(Img, { src: props.imageURL })))));
};
