import * as React from "react";
import { Img } from "../../Img";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
export const AccountCardHeader = ({ titleText, imageUrl, alt, description, buttonHeaderText, buttonHeaderUrl }) => (React.createElement("div", { className: "account-card__fixed-logo account-card__account-card" },
    React.createElement("div", { className: "h4-semi-bold" }, titleText),
    React.createElement("div", { className: "centered-container account-card__header-image-container" },
        React.createElement(Img, { loading: "eager", className: "account-card__img-logo", src: imageUrl, alt: alt }),
        React.createElement("div", { className: "caption2-regular" }, description)),
    React.createElement(ButtonV2, { design: "secondary-medium-wbg", href: buttonHeaderUrl }, buttonHeaderText)));
export const AccountCardBody = ({ content, buttonTitle, buttonHref }) => (React.createElement("div", { className: "account-card__inner" },
    React.createElement("div", { className: "account-card__list-items" },
        React.createElement("div", { className: "account-card__container-scrollabe" }, content.map((item, index) => (React.createElement("div", { key: index, className: `account-card__item centered-container ${item.imageUrl ? "account-card__item-with-image" : ""}`, style: { marginTop: `${item.marginTop}px` } },
            React.createElement("div", { className: "account-card__item-title text-weight-medium centered-container" },
                item.imageUrl && React.createElement(Img, { className: "account-card__item-image", src: item.imageUrl, alt: item.imageAlt }),
                item.title && React.createElement("div", { className: "account-card__title-upper color-dark-60 caption-bold" }, item.title),
                React.createElement("div", { className: "h5-bold account-card__description color-dark-default" }, item.description),
                item.title2 && React.createElement("div", { className: "account-card__title-upper color-dark-60 caption-bold account-card__bottom-title" }, item.title2)))))),
        React.createElement("div", { className: "account-card__button-separator" }),
        buttonTitle && React.createElement("div", { className: "account-card__button-container centered-container" },
            React.createElement(ButtonV2, { design: "ghost-large-wbg", href: buttonHref, openInNewTab: true }, buttonTitle)))));
