import * as React from "react";
import { getColors } from "../../utils";
import { ButtonV2 } from "../ButtonV2";
import { Heading } from "../Heading";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
export const HeroBannerWithTitleSubtitleImageButton = ({ title, subtitle, buttonText, buttonUrl, imageUrl, imageAlt, designStyles, openInNewTabButton }) => {
    const { textColor, bgColor } = getColors(designStyles?.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "hero-banner-with-title-subtitle-image-button", style: { color: textColor } },
                React.createElement("div", { className: "hero-banner-with-title-subtitle-image-button__main-flex" },
                    React.createElement("div", { className: "hero-banner-with-title-subtitle-image-button__title-button-group" },
                        React.createElement(Heading, { design: designStyles.titleDesign }, title),
                        React.createElement("h5", { className: "h5-semi-bold color-dark-20" }, subtitle),
                        React.createElement(ButtonV2, { href: buttonUrl, design: designStyles.buttonDesign, openInNewTab: openInNewTabButton },
                            buttonText,
                            " ")),
                    React.createElement("div", { className: "hero-banner-with-title-subtitle-image-button__image" },
                        React.createElement("a", { href: buttonUrl, target: "_blank", style: { width: "100%" }, rel: "noopener noreferrer" },
                            React.createElement(Img, { src: imageUrl, alt: imageAlt, style: { width: "100%", height: "auto", objectFit: "contain" } }))))))));
};
