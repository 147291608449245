import React from "react";
import { ContainerFW, Container, Row, Col } from "../../global";
import { DarkNumberedCard } from "./DarkNumberedCard/DarkNumberedCard";
export const DarkNumberedCardDeck = ({ title, subTitle, titleSubtitlePosition, cards, primaryLinkText, primaryLinkUrl, secondaryLinkText, secondaryLinkUrl }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: `dark-numbered-card-deck title-position-${titleSubtitlePosition}` },
        React.createElement(Container, null,
            React.createElement("h2", { className: "dark-numbered-card-deck__title", style: { textAlign: titleSubtitlePosition } }, title),
            subTitle && React.createElement("p", { className: "dark-numbered-card-deck__subTitle", style: { textAlign: titleSubtitlePosition } }, subTitle)),
        React.createElement("div", { className: "dark-numbered-card-deck__cards" },
            React.createElement(Container, null,
                React.createElement(Row.Cols3, null, cards.map((card, i) => React.createElement(Col, { key: `key-DarkNumberedCard-${i}` },
                    React.createElement(DarkNumberedCard, { index: `0${i + 1}`, title: card.title, content: card.content })))))),
        React.createElement(Container, null, primaryLinkText && React.createElement("div", { className: "dark-numbered-card-deck__links" },
            React.createElement("a", { className: "dark-numbered-card-deck__link dark-numbered-card-deck__primaryLink", href: primaryLinkUrl }, primaryLinkText),
            secondaryLinkText && React.createElement("a", { className: "dark-numbered-card-deck__link dark-numbered-card-deck__secondaryLink", href: secondaryLinkUrl }, secondaryLinkText))))));
