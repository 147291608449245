import React from "react";
import { AlphabetList } from "../AlphabetList/AlphabetList";
import { AlphabetsSearch } from "../AlphabetsSearch";
import { CardsGridIconAndTitle } from "../CardsGridIconAndTitle";
import { Gap } from "../Gaps/Gap";
export const TradingGlossary = (props) => (React.createElement("div", { className: "trading-glossary" },
    React.createElement("div", { className: "trading-glossary__main" },
        React.createElement(AlphabetList, { ...props.list, noResultsText: props.noResultsText, searchResultsText: props.searchResultsText, searchQuery: props.searchQuery })),
    React.createElement("div", { className: "trading-glossary__side-bar" },
        React.createElement(AlphabetsSearch, { ...props.alphabetContent }),
        React.createElement(Gap, { desktopGap: "40px", mobileGap: "40px" }),
        React.createElement(CardsGridIconAndTitle, { ...props.categories, activeCardValue: props.list.title }))));
