import React, { useEffect, useState } from "react";
import { SearchRibbon } from "../SearchRibbon";
import { TradingGlossary } from "../TradingGlossary/TradingGlossary";
export const TradingGlossarySearch = (props) => {
    const [data, setData] = useState(props.list);
    const [searchQuery, setSearchQuery] = useState("");
    useEffect(() => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        };
        const filteredQuery = searchQuery.replace(/[^a-zA-Z0-9 ]/gm, "");
        searchQuery === "" ? setData(props.list) : fetch(`/${props.culture}/api/GlossarySearch?token=${props.token}&terms=${filteredQuery}`, requestOptions)
            .then((response) => response.json())
            .then((responseData) => setData({ ...data, content: responseData }))
            .catch(err => {
            setData({ content: [] });
            console.warn(err);
        });
    }, [searchQuery]);
    return (React.createElement("div", { className: "trading-glossary-search" },
        React.createElement(SearchRibbon, { backgrounColor: "#fff", search: setSearchQuery, searchText: props.searchText }),
        React.createElement(TradingGlossary, { categories: props.categoriesProps, alphabetContent: props.alphabetProps, noResultsText: "result not found", searchResultsText: "Search result of", searchQuery: searchQuery, list: data })));
};
