import * as React from "react";
export const LayoutOptions = ({ viewHandler, view, }) => {
    const handleGridViewClick = React.useCallback(() => {
        viewHandler(false);
    }, [viewHandler]);
    const handleListViewClick = React.useCallback(() => {
        viewHandler(true);
    }, [viewHandler]);
    return (React.createElement("div", { className: "subscribeRibbon__layout-options" },
        React.createElement("div", { className: "subscribeRibbon__layout-optionsInner" },
            React.createElement("div", { onClick: handleGridViewClick, className: `subscribeRibbon__layout ${view ? "" : "active"}` }, gridViewIcon()),
            React.createElement("div", { onClick: handleListViewClick, className: `subscribeRibbon__layout ${view ? "active" : ""}` }, listViewIcon()))));
};
const gridViewIcon = () => (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M13.847 7.74v6.113H7.733V7.74h6.114zm0-.933H7.733a.936.936 0 0 0-.933.933v6.113c0 .514.42.934.933.934h6.114c.513 0 .933-.42.933-.934V7.74a.936.936 0 0 0-.933-.933zM24.266 7.74v6.113h-6.113V7.74h6.113zm0-.933h-6.113a.936.936 0 0 0-.933.933v6.113c0 .514.42.934.933.934h6.113c.514 0 .934-.42.934-.934V7.74a.936.936 0 0 0-.934-.933zM13.847 18.147v6.113H7.733v-6.113h6.114zm0-.934H7.733a.936.936 0 0 0-.933.934v6.113c0 .513.42.933.933.933h6.114c.513 0 .933-.42.933-.933v-6.113a.936.936 0 0 0-.933-.934zM24.266 18.147v6.113h-6.113v-6.113h6.113zm0-.934h-6.113a.936.936 0 0 0-.933.934v6.113c0 .513.42.933.933.933h6.113c.514 0 .934-.42.934-.933v-6.113a.936.936 0 0 0-.934-.934z", fill: "#fff" })));
const listViewIcon = () => (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M22.967 8.953H12.12a.464.464 0 0 1-.467-.466c0-.26.207-.467.467-.467h10.847c.26 0 .466.207.466.467s-.206.466-.466.466zM22.967 13.96H12.12a.464.464 0 0 1-.467-.467c0-.26.207-.466.467-.466h10.847c.26 0 .466.206.466.466s-.206.467-.466.467zM22.967 18.96H12.12a.464.464 0 0 1-.467-.467c0-.26.207-.466.467-.466h10.847c.26 0 .466.206.466.466s-.206.467-.466.467zM9.36 9.28a.791.791 0 0 1-.794-.793c0-.44.354-.794.794-.794.44 0 .793.354.793.794 0 .44-.353.793-.793.793zM9.36 14.287a.791.791 0 0 1-.794-.794.79.79 0 0 1 .794-.793c.44 0 .793.353.793.793 0 .44-.353.794-.793.794zM9.36 19.287a.791.791 0 0 1-.794-.794.79.79 0 0 1 .794-.793c.44 0 .793.353.793.793 0 .44-.353.794-.793.794zM22.967 23.98H12.12a.464.464 0 0 1-.467-.467c0-.26.207-.466.467-.466h10.847c.26 0 .466.206.466.466s-.206.467-.466.467zM9.36 24.307a.791.791 0 0 1-.794-.794.79.79 0 0 1 .794-.793c.44 0 .793.353.793.793 0 .44-.353.794-.793.794z", fill: "#fff" })));
