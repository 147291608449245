import React from "react";
import { ContainerFW, Container } from "../global";
import { Heading } from "../Heading";
import { Img } from "../../Components/Img";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { getColors } from "../../utils";
export const ContactUsBannerWithButton = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "contact-us-banner-with-button" },
                React.createElement("div", { className: "contact-us-banner-with-button__text-container" },
                    React.createElement(Heading, { design: "h2-regular-small" }, props.title),
                    React.createElement("div", { className: "contact-us-banner-with-button__description", dangerouslySetInnerHTML: {
                            __html: props.description,
                        } }),
                    props.buttonLabel && React.createElement("div", { className: "contact-us-banner-with-button__button-container" },
                        React.createElement(ButtonV2, { design: "ghost-medium-wbg", href: props.buttonURL }, props.buttonLabel))),
                React.createElement("div", { className: "contact-us-banner-with-button__image-container" },
                    React.createElement(Img, { src: props.imageURL, alt: props.imageAlt }))))));
};
