import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { Img } from "../Img";
export const CustomLottie = ({ animation, className, loop = true, animationAlt }) => {
    const [image, setImage] = useState();
    useEffect(() => {
        animation.includes("json") ? fetch(animation)
            .then(response => response.json())
            .then((jsonData) => {
            setImage(jsonData);
        })
            .catch((error) => {
            console.error(error);
        }) : setImage(animation);
    }, [animation]);
    return (React.createElement("div", { className: className }, typeof (image) === "object" ? React.createElement(Lottie, { animationData: image, loop: loop }) : React.createElement(Img, { src: animation, alt: animationAlt })));
};
