import React, { useState, useEffect } from "react";
import { Container, ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { ButtonV2 } from "../../ButtonV2";
import { Heading } from "../../Heading";
import { getColors } from "../../../utils";
export const OptionalButtonSectionBanner2 = ({ title, description, imageDesktop, imageMobile, alt, imagePosition, buttonText, buttonUrl, designStyles, openInNewTabButton = false }) => {
    const [imageDesktopValue, setImageDesktopValue] = useState("");
    const [altValue, setAltValue] = useState("");
    useEffect(() => {
        setImageDesktopValue(imageDesktop);
        setAltValue(alt);
    }, [imageDesktop, alt]);
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor || "neutralaccentbackground");
    return (React.createElement(ContainerFW, { backgroundColor: textColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "optional-button-section-banner2" },
                React.createElement("div", { className: `optional-button-section-banner2__flex-outer ${imagePosition === "left" ? "" : "optional-button-section-banner2__img-right"}` },
                    React.createElement("div", { style: { backgroundColor: bgColor }, className: "optional-button-section-banner2__image-container centered-container hide-medium hide-small" },
                        React.createElement(ImgV2, { largeUrl: imageDesktopValue, alt: altValue, strictRender: true, className: "optional-button-section-banner2__image" })),
                    React.createElement("div", { className: "centered-container optional-button-section-banner2__flex-middle-container" },
                        React.createElement("div", { className: "optional-button-section-banner2__flex-middle" },
                            React.createElement("div", { style: { backgroundColor: bgColor }, className: "optional-button-section-banner2__image-container-mobile hide-large centered-container" },
                                React.createElement(ImgV2, { mediumUrl: imageDesktopValue, alt: altValue, smallUrl: imageMobile, strictRender: true, className: "optional-button-section-banner2__image" })),
                            React.createElement("div", { className: "optional-button-section-banner2__title-container" },
                                React.createElement(Heading, { design: designStyles?.titleDesign },
                                    React.createElement("span", { dangerouslySetInnerHTML: {
                                            __html: title,
                                        } }))),
                            React.createElement("div", { className: "optional-button-section-banner2__description-container copy-lp", dangerouslySetInnerHTML: {
                                    __html: description,
                                } }),
                            buttonText && React.createElement("div", { className: "optional-button-section-banner2__button-container" },
                                React.createElement(ButtonV2, { href: buttonUrl, design: designStyles?.buttonDesign || "cta-large-wbg", openInNewTab: openInNewTabButton },
                                    " ",
                                    buttonText,
                                    " ")))))))));
};
