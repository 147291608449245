const compareColumnPositions = (column1, column2) => {
    const firstColumnPosition = Number(column1.columnPosition);
    const secondColumnPosition = Number(column2.columnPosition);
    if (firstColumnPosition < secondColumnPosition) {
        return -1;
    }
    if (firstColumnPosition > secondColumnPosition) {
        return 1;
    }
    return 0;
};
export const getOrderedColumns = (columns) => [...columns].sort(compareColumnPositions);
export const getNormalizedColumnName = (columnName) => columnName.charAt(0).toLowerCase() + columnName.slice(1);
export const checkDoesDataHaveColumn = (dataRow, columnName) => Object.keys(dataRow).map(key => key.toLowerCase()).includes(columnName.toLowerCase());
export const checkIsValueDisplayable = (value) => value !== -1;
export const isInstrumentIncludedInMini = (instrumentData) => instrumentData.minTradeSizeMini !== -1
    && instrumentData.maxTradeSizeMini !== -1
    && instrumentData.targetSpreadMini !== ""
    && instrumentData.maxLeverageMini !== "";
const checkIsMatchingSearchString = (item, searchString) => searchString
    ? item.instrument?.toLowerCase().includes(searchString.toLowerCase())
    : true;
export const checkIsMatchingPositionQuality = (item, positionQuality) => (positionQuality && positionQuality !== "All")
    ? item.positionQuality === positionQuality
    : true;
export const checkIsMatchingCountry = (item, country) => country
    ? item.country === country
    : true;
export const checkIsMatchingCurrency = (item, currency) => currency
    ? item.currency === currency
    : true;
export const getSearchResults = (data, searchString) => data.filter((item) => checkIsMatchingSearchString(item, searchString));
export const checkIsMetalInAccountType = (metal, accountType) => {
    if (!accountType) {
        return true;
    }
    const isMetalInMiniAccount = metal.maxTradeSizeMini !== -1 && Boolean(metal.maxTradeSizeMini);
    const isMetalInThinkzeroAccount = metal.maxTradeSizeThinkZero !== -1 && Boolean(metal.maxTradeSizeThinkZero);
    const isMetalInAccountType = {
        mini: isMetalInMiniAccount,
        thinkzero: isMetalInThinkzeroAccount,
        standard: !isMetalInMiniAccount && !isMetalInThinkzeroAccount,
        thinktrader: !isMetalInMiniAccount && !isMetalInThinkzeroAccount,
    };
    return Boolean(isMetalInAccountType[accountType?.toLowerCase()]);
};
export const getDataPerAccountType = ({ searchString, accountType, instrumentGroup, positionQuality, instrumentSubtype, country, currency, culture }, data) => {
    const GROUP_FUNCTIONS_OBJ__MINI = {
        forex: (data) => data?.filter(item => (item.instrumentGroup === instrumentGroup
            && isInstrumentIncludedInMini(item)
            && checkIsMatchingPositionQuality(item, positionQuality)
            && checkIsMatchingCountry(item, country)
            && checkIsMatchingCurrency(item, currency))),
        indices: (data) => data.filter(item => (item.instrumentGroup === instrumentGroup
            && isInstrumentIncludedInMini(item)
            && checkIsMatchingCountry(item, country)
            && checkIsMatchingCurrency(item, currency))),
        commodities: (data) => data.filter(item => (item.instrumentGroup === instrumentSubtype
            && isInstrumentIncludedInMini(item)
            && checkIsMatchingCountry(item, country)
            && checkIsMatchingCurrency(item, currency)
            && (instrumentSubtype?.toLowerCase() === "metals" ? checkIsMetalInAccountType(item, accountType) : true))),
        crypto: (data) => data.filter(item => (item.instrumentGroup === instrumentGroup
            && isInstrumentIncludedInMini(item)
            && checkIsMatchingCountry(item, country)
            && checkIsMatchingCurrency(item, currency)))
    };
    const GROUP_FUNCTIONS_OBJ__GENERAL = {
        forex: (data) => data.filter(item => (item.instrumentGroup === instrumentGroup
            && checkIsMatchingPositionQuality(item, positionQuality)
            && checkIsMatchingCountry(item, country)
            && checkIsMatchingCurrency(item, currency))),
        commodities: (data) => data.filter(item => (item.instrumentGroup === instrumentSubtype
            && checkIsMatchingCountry(item, country)
            && checkIsMatchingCurrency(item, currency)
            && (instrumentSubtype?.toLowerCase() === "metals" ? checkIsMetalInAccountType(item, accountType) : true)))
    };
    const applyCommonFilteringRules = (data) => data.filter((item) => checkIsMatchingSearchString(item, searchString)
        && checkIsMatchingCountry(item, country)
        && checkIsMatchingCurrency(item, currency));
    const lcInstrGroupName = instrumentGroup?.toLowerCase() || "";
    const lcAccountType = accountType?.toLowerCase();
    const functionMappingObject = lcAccountType === "mini" ? GROUP_FUNCTIONS_OBJ__MINI : GROUP_FUNCTIONS_OBJ__GENERAL;
    const groupFunctionsMap = new Map(Object.entries(functionMappingObject));
    const filteringFunction = groupFunctionsMap.get(lcInstrGroupName);
    if (typeof filteringFunction === "function") {
        return applyCommonFilteringRules(filteringFunction(data));
    }
    return applyCommonFilteringRules(data);
};
export const getTranslatedColumnName = (columnName, columnsTranslations) => {
    const normalizedColumnName = getNormalizedColumnName(columnName);
    const translatedColumnName = columnsTranslations?.[normalizedColumnName];
    return translatedColumnName || columnName;
};
export const getValueWithSpecialCase = (normalizedColumnName, columnInfo, dataRow) => {
    const specialCaseValue = columnInfo.specialCaseColumn && dataRow[getNormalizedColumnName(columnInfo.specialCaseColumn)];
    if (typeof specialCaseValue !== "undefined" && specialCaseValue !== "") {
        return specialCaseValue;
    }
    return dataRow[normalizedColumnName];
};
