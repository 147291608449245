import * as React from "react";
import { ContainerFW } from "../global";
import { FiltersRibbon } from "../FiltersRibbon";
import { NewsCardDeck, VideoCardDeck, VideoCardDeckWithPagination } from "../Cards";
import { Filters } from "../Filters";
import { Gap } from "../Gaps/Gap";
import { LatestMarketNews } from "../LatestMarketNews";
import { InstrumentsBuySellCardsBar } from "../InstrumentsBuySellCardsBar";
import { getFilterItemsType } from "../Filters/utils";
import { usePagination } from "../../hooks";
function isVideoCardArr(containsVideos, cards) {
    return containsVideos;
}
export const FilterCards = (props) => {
    const [listView, setListView] = React.useState(false);
    const [filtersActive, setFiltersActive] = React.useState(false);
    const [filtersData, setFiltersData] = React.useState({});
    const [resetFilters, setResetFilters] = React.useState(0);
    const [cards, setCards] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [isFilterOrSearchApplied, setIsFilterOrSearchApplied] = React.useState(false);
    const [hasFiltersApplied, setHasFiltersApplied] = React.useState(false);
    const getSlicedCards = ((cards) => cards.slice(props.dropInitialCards || 0));
    const getAdjustedCards = (cards) => {
        if (props.containsVideos && searchQuery) {
            return cards.filter((card) => card.title.toLowerCase().includes(searchQuery.toLocaleLowerCase()));
        }
        return cards;
    };
    const adjustedCards = getAdjustedCards(getSlicedCards(cards));
    const { currentPage, currentPageItems, numOfPages, setCurrentPage } = usePagination(adjustedCards, 10);
    const getConditionalRenderingContext = () => {
        const hasCards = currentPageItems.length > 0;
        const areAnyFiltersSet = Object.values(filtersData).filter(el => el !== "" && el !== 5).length > 0;
        const isSearch = searchQuery !== "";
        const isFilteredResult = isFilterOrSearchApplied && searchQuery === "";
        const shouldShowLatestNews = hasCards && !isFilterOrSearchApplied;
        return {
            hasCards,
            areAnyFiltersSet,
            isFilteredResult,
            isSearch,
            shouldShowLatestNews,
        };
    };
    const { areAnyFiltersSet, isSearch, isFilteredResult, shouldShowLatestNews, } = getConditionalRenderingContext();
    const getInitialCheckedAllState = React.useCallback(() => props.fields.reduce((acc, item) => {
        acc[item.title] = false;
        return acc;
    }, {}), [props.fields]);
    const [checkedAll, setCheckedAll] = React.useState(() => getInitialCheckedAllState());
    const getDataKeyForFilterTitle = (filterTitle) => filterTitle.replaceAll(" ", "");
    const checkAll = React.useCallback((item) => (e) => {
        e.preventDefault();
        const field = props.fields.find(field => field.title === item);
        if (!field || field.type !== "checkbox" || typeof field.items === "undefined") {
            return;
        }
        const itemsType = getFilterItemsType(field);
        const getNewValue = () => {
            if (itemsType === "string") {
                return field.items;
            }
            if (itemsType === "object") {
                return field.items.map((a) => a.categoryTitle);
            }
            return [];
        };
        const newValue = getNewValue();
        const mustCheckAll = !checkedAll[item];
        const fieldItems = newValue;
        const allValues = mustCheckAll ? fieldItems.join(",") : "";
        const dataKey = getDataKeyForFilterTitle(item);
        setFiltersData(prevFilterData => ({
            ...prevFilterData,
            [dataKey]: allValues,
        }));
        setCheckedAll({ ...checkedAll, [item]: !checkedAll[item] });
    }, [checkedAll, props.fields]);
    const handlerHasFiltersApplied = React.useCallback((value) => {
        if (value.includes("Difficulty") || value.includes("Category") || value.includes("Readingtime")) {
            setHasFiltersApplied(true);
            return;
        }
        setHasFiltersApplied(false);
    }, []);
    const fetchArticles = React.useCallback((q) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ culture: props.culture, ...q })
        };
        handlerHasFiltersApplied(requestOptions.body);
        const endpoint = props.allArticles ? "FilterAcademyArticles" : (props.containsVideos ? "FilterVideos" : "FilterArticles");
        fetch(`/api/${endpoint}?token=${props.token}`, requestOptions)
            .then((response) => response.json())
            .then((responseData) => {
            setCards(responseData);
            setCurrentPage(0);
        })
            .catch(err => console.warn(err));
    }, [handlerHasFiltersApplied, props.allArticles, props.containsVideos, props.culture, props.token, setCurrentPage]);
    React.useEffect(() => {
        fetchArticles({});
        setIsFilterOrSearchApplied(false);
    }, [fetchArticles]);
    const handleSearchQuery = React.useCallback((searchText) => {
        fetchArticles({ searchString: searchText });
        setSearchQuery(searchText);
        setFiltersData({});
        setResetFilters(Math.random() * 100);
    }, [fetchArticles]);
    const dataHandler = React.useCallback((d) => {
        setFiltersData(d);
    }, []);
    const filterHandier = React.useCallback(() => {
        const o = Object.fromEntries(Object.entries(filtersData).filter(([, v]) => v !== ""));
        Object.keys(o).map((el) => {
            const type = props.fields.filter(f => f.title.replaceAll(" ", "") === el)[0].type;
            if ((type === "range")) {
                o[el] = o[el] * 1;
            }
            else if (type === "checkbox-date") {
                const startDate = o[el].split(",").filter((v) => v !== "")[0]?.replace("startdate-", "");
                const endDate = o[el].split(",").filter((v) => v !== "")[1]?.replace("enddate-", "");
                if (startDate) {
                    o["Date"] = startDate;
                }
                if (endDate) {
                    o["EndDate"] = endDate;
                }
                delete o[el];
            }
            else {
                o[el] = o[el].split(",").filter((v) => v !== "").filter((v) => v !== "All");
                Object.keys(o).map(el => (!o[el].length && typeof (o[el]) !== "number") ? delete o[el] : o[el]);
            }
        });
        fetchArticles(o);
        setSearchQuery("");
        setIsFilterOrSearchApplied(true);
    }, [fetchArticles, filtersData, props.fields]);
    const resetHandler = React.useCallback((e) => {
        e.preventDefault();
        setResetFilters(Math.random() * 100);
        const newFilters = {};
        Object.keys(filtersData).map(e => newFilters[e] = "");
        setFiltersData(newFilters);
        setCheckedAll(getInitialCheckedAllState());
        fetchArticles({});
        setIsFilterOrSearchApplied(false);
        setSearchQuery("");
        setHasFiltersApplied(false);
    }, [filtersData, getInitialCheckedAllState, fetchArticles]);
    const filtersRibbonElement = React.createElement(FiltersRibbon, { subscriptionButtonText: props.subscriptionButtonText, subscriptionText: props.subscriptionText, filterText: props.filterText, type: "search", filterToggle: setFiltersActive, filters: filtersActive, viewToggle: setListView, view: listView, searchQuery: searchQuery, setSearchQuery: handleSearchQuery, searchText: props.searchText, hasFiltersApplied: hasFiltersApplied });
    const filtersElement = React.createElement(Filters, { filterToggle: setFiltersActive, submit: filterHandier, reset: resetFilters, setReset: resetHandler, fields: props.fields, data: filtersData, setData: dataHandler, resetText: props.resetText, applyText: props.applyText, areAnyFiltersSet: areAnyFiltersSet, checkedAll: checkedAll, checkAll: checkAll });
    const mostTradedContent = (props.mostTradedIntruments && props.mostTradedIntrumentsTitle && props.securityKey) && React.createElement(InstrumentsBuySellCardsBar, { title: props.mostTradedIntrumentsTitle, symbols: props.mostTradedIntruments, securityKey: props.securityKey, startTradingUrl: props.startTradingUrl, startTradingDescription: props.startTradingDescription });
    const showClearFilters = React.useMemo(() => (isFilteredResult || searchQuery), [isFilteredResult, searchQuery]);
    const renderNewsContent = (cards) => {
        let deckTitle = "All news";
        if (isSearch) {
            deckTitle = "Search results";
        }
        else if (isFilteredResult) {
            deckTitle = "Filter results";
        }
        return (React.createElement(React.Fragment, null,
            shouldShowLatestNews && React.createElement(LatestMarketNews, { news: cards?.slice(0, 5), title: props.title || "" }),
            React.createElement(Gap, { mobileGap: "24px", desktopGap: "48px", gapColor: "#fff" }),
            mostTradedContent,
            React.createElement(Gap, { mobileGap: "24px", desktopGap: "48px", gapColor: "#fff" }),
            React.createElement(NewsCardDeck, { currentPage: currentPage, currentPageItems: currentPageItems, numOfPages: numOfPages, setCurrentPage: setCurrentPage, title: deckTitle, isListView: listView, filtersToggle: setFiltersActive, areFiltersDisplayed: filtersActive, filtersRibbonElement: filtersRibbonElement, filtersElement: filtersElement },
                React.createElement("p", { className: "filter-cards__results-count" },
                    adjustedCards.length,
                    " results found",
                    " ",
                    (showClearFilters) && React.createElement("a", { href: "#", onClick: resetHandler, className: "filters__reset" }, props.resetText)))));
    };
    const renderContent = () => {
        const videoCards = (c) => {
            if (Object.values(filtersData).filter(el => el !== "").length || searchQuery) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Gap, { mobileGap: "32px", desktopGap: "40px" }),
                    React.createElement(VideoCardDeckWithPagination, { cards: c, title: "Filter results", filtersRibbonElement: filtersRibbonElement, filtersElement: filtersElement },
                        React.createElement("p", { className: "filter-cards__results-count" },
                            c.length,
                            " results found",
                            " ",
                            React.createElement("a", { href: "#", onClick: resetHandler, className: "filters__reset" }, props.resetText)))));
            }
            const latestVideoCards = c?.slice(0, 3);
            const pastVideoCards = c?.slice(3);
            return (React.createElement(React.Fragment, null,
                filtersRibbonElement,
                filtersElement,
                React.createElement(Gap, { mobileGap: "32px", desktopGap: "40px" }),
                React.createElement(VideoCardDeck, { cards: latestVideoCards, title: "Trending videos", isSliderOnMobile: false, webSiteRootUrl: props.webSiteRootUrl }),
                React.createElement(Gap, { mobileGap: "64px", desktopGap: "80px" }),
                React.createElement(VideoCardDeckWithPagination, { cards: pastVideoCards, title: "All videos", webSiteRootUrl: props.webSiteRootUrl })));
        };
        if (props.allArticles) {
            return React.createElement(React.Fragment, null);
        }
        else {
            if (isVideoCardArr(props.containsVideos, currentPageItems)) {
                return videoCards(currentPageItems);
            }
            return renderNewsContent(currentPageItems);
        }
    };
    React.useEffect(() => {
        document.body.classList.toggle("body-scroll-disabled", filtersActive);
    }, [filtersActive]);
    return (React.createElement("div", { className: "filter-cards__container" },
        React.createElement(ContainerFW, { backgroundColor: "#fff" },
            React.createElement("div", { className: `filter-cards ${filtersActive ? "active-filters" : ""}` },
                React.createElement("div", { className: "filters-overlay" }),
                renderContent()))));
};
