import * as React from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
import { AlphabetsSearchCard } from "./AlphabetsSearchCard/AlphabetsSearchCard";
import { IconHome } from "../svgIcons/homeIcon";
export const AlphabetsSearch = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    const homelink = window.location.pathname.split("/").slice(0, -1).join("/");
    const content = props.noWrappers ?
        React.createElement("div", { className: "alphabets-search" },
            React.createElement("div", { className: "alphabets-search__title-container" },
                React.createElement("p", null, props.title)),
            React.createElement("div", { className: "alphabets-search__container" },
                React.createElement(AlphabetsSearchCard, { title: "", icon: IconHome, linkURL: homelink }),
                props.content.map((item, i) => React.createElement(AlphabetsSearchCard, { key: `alphabets-search-${i}`, ...item })))) :
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, null,
                React.createElement("div", { className: "alphabets-search" },
                    React.createElement("div", { className: "alphabets-search__title-container" },
                        React.createElement("p", null, props.title)),
                    React.createElement("div", { className: "alphabets-search__container" },
                        React.createElement(AlphabetsSearchCard, { title: "", icon: IconHome, linkURL: homelink }),
                        props.content.map((item, i) => React.createElement(AlphabetsSearchCard, { key: `alphabets-search-${i}`, ...item }))))));
    return content;
};
