import React from "react";
import { Container, ContainerFW } from "../../global";
import { pageViewStyle } from "../../../pageViewStyle";
import { Swiper, SwiperSlide, } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { InformationCardDark } from "./InformationCardDark/InformationCardDark";
import { getColors } from "../../../utils";
export const InformationCardDarkDeck = (props) => {
    const [view, setView] = React.useState(pageViewStyle.getView());
    const [swiper, setSwiper] = React.useState();
    const [showSwiper, setShowSwiper] = React.useState(true);
    const setSwiperInstance = React.useCallback((s) => setSwiper(s), []);
    const { bgColor } = getColors(props.backgroundColor || "neutralaccentbackground");
    const onViewUpdate = (view) => {
        setView(view);
    };
    React.useEffect(() => {
        if (swiper && props.content.length > 3 && view === "large") {
            setShowSwiper(false);
            swiper.destroy();
        }
    }, [swiper, view]);
    React.useEffect(() => {
        pageViewStyle.subscribe(onViewUpdate);
        return () => {
            pageViewStyle.unsubscribe(onViewUpdate);
        };
    }, [view]);
    return (React.createElement("div", { className: `information-card-dark-deck ${showSwiper ? "" : "swiper-off"}` },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, null,
                React.createElement("div", { className: "information-card-dark-deck__title-container" },
                    React.createElement("h2", null, props.title)),
                React.createElement("div", { className: "information-card-dark-deck__description-container" },
                    React.createElement("p", null, props.description)),
                React.createElement(Swiper, { onSwiper: setSwiperInstance, modules: [Navigation, Pagination], spaceBetween: 24, slidesPerView: "auto", navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }, pagination: {
                        el: ".information-card-dark-deck__pagination",
                        clickable: true,
                        renderBullet: () => ("<span class='information-card-dark-deck__bullet swiper-pagination-bullet'></span>"),
                    } }, props.content.map((item, i) => React.createElement(SwiperSlide, { key: `information-card-dark-deck-slide-${i}` },
                    React.createElement(InformationCardDark, { title: item.title, description: item.description, linkUrl: item.linkUrl, linkText: item.linkText, backgroundImgURL: item.backgroundImgURL, alt: item.alt })))),
                React.createElement("div", { className: "information-card-dark-deck__pagination" })))));
};
