import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils";
const getClassForCard = (bigCard) => `card-deck-with-different-size__${bigCard ? "big-card" : "small-card"}`;
const renderCardContent = (bigCard, alignRight, card) => (React.createElement("div", { className: `card-deck-with-different-size__title-and-description card-deck-with-different-size__${(bigCard && alignRight) ? "align-right" : ""} ${bigCard ? "" : "card-deck-with-different-size__title-and-description-small-card"}` },
    React.createElement("div", { className: `card-deck-with-different-size__title-and-description-inner-container card-deck-with-different-size__${(bigCard && alignRight) ? "align-right" : ""}` },
        React.createElement("h4", null,
            " ",
            card.title),
        React.createElement("p", null, card.description))));
const RenderCard = ({ card, bigCard, alignRight }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: `card-deck-with-different-size__card hide-medium-ndb hide-large-ndb ${getClassForCard(bigCard)}`, style: {
            backgroundImage: `url('${card.imgsmall}')`.replace("~", ""),
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
        } }, renderCardContent(bigCard, alignRight, card)),
    React.createElement("div", { className: `card-deck-with-different-size__card hide-large-ndb hide-small-ndb ${getClassForCard(bigCard)}`, style: {
            backgroundImage: `url('${card.imgmedium}')`.replace("~", ""),
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        } }, renderCardContent(bigCard, alignRight, card)),
    React.createElement("div", { className: `card-deck-with-different-size__card hide-small-ndb hide-medium-ndb  ${getClassForCard(bigCard)}`, style: {
            backgroundImage: `url('${card.imglarge}')`.replace("~", ""),
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        } }, renderCardContent(bigCard, alignRight, card))));
export const CardDeckWithDifferentSize = ({ title, cards, backgroundColor }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { style: { color: textColor }, className: "card-deck-with-different-size" },
                React.createElement("div", { className: "centered-container card-deck-with-different-size__title-container" },
                    React.createElement("h2", null, title)),
                React.createElement("div", { className: "card-deck-with-different-size__flex-container card-deck-with-different-size__gap flex" },
                    cards[0] && React.createElement("div", { className: "card-deck-with-different-size__one-card centered-container" },
                        React.createElement(RenderCard, { card: cards[0], bigCard: true, alignRight: false })),
                    cards[2] && React.createElement("div", { className: "card-deck-with-different-size__two-cards card-deck-with-different-size__gap centered-container" },
                        React.createElement(RenderCard, { card: cards[1], bigCard: false, alignRight: false }),
                        React.createElement(RenderCard, { card: cards[2], bigCard: false, alignRight: false })),
                    cards[3] && React.createElement("div", { className: "card-deck-with-different-size__one-card centered-container" },
                        React.createElement(RenderCard, { card: cards[3], bigCard: true, alignRight: true })))))));
};
