import React, { useCallback, useState } from "react";
import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, ContainerFW } from "../global";
import { IconChevronDown } from "../svgIcons";
import { CardDescription } from "./CardDescription/CardDescription";
import { KeepTraderInOrderSwiperCard } from "./KeepTraderInOrderSwiperCard/KeepTraderInOrderSwiperCard";
import { getColors } from "../../utils";
export const KeepTraderInOrderSwiper = ({ mainTitle, mainDescription, cards, backgroundColor }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { bgColor } = getColors(backgroundColor);
    const handleCurrentIndex = useCallback((swiper) => {
        setCurrentIndex(swiper.realIndex);
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "keepTraderInOrderSwiper__container" },
            React.createElement(Container, null,
                React.createElement("div", { className: "keepTraderInOrderSwiper" },
                    React.createElement("div", { className: "description-container" },
                        React.createElement(CardDescription, { mainTitle: mainTitle, mainDescription: mainDescription }),
                        React.createElement("span", { className: "keepTraderInOrderSwiper__arrowsWrapper" },
                            React.createElement("a", { className: "arrow-prev-selector" }, IconChevronDown({})),
                            React.createElement("p", { className: "keepTraderInOrderSwiper__arrowsWrapper__currentPage" },
                                currentIndex + 1,
                                " of ",
                                cards.length),
                            React.createElement("a", { className: "arrow-next-selector" }, IconChevronDown({})))),
                    React.createElement("div", { className: "keepTraderInOrderSwiper__swiperWrapper" },
                        React.createElement(Container, null,
                            React.createElement(Swiper, { navigation: {
                                    nextEl: ".arrow-next-selector",
                                    prevEl: ".arrow-prev-selector"
                                }, modules: [Mousewheel], onRealIndexChange: handleCurrentIndex, loop: true, mousewheel: true, breakpoints: {
                                    [601]: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    [1025]: {
                                        slidesPerView: (cards.length > 2) ? 2 : 1,
                                        spaceBetween: (cards.length > 3) ? -160 : 0,
                                    }
                                } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `trader-slide-${i}` }, React.createElement(KeepTraderInOrderSwiperCard, { ...card })))))))))));
};
