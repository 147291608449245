import React from "react";
import { Footer } from "../../stories/Footer/Footer";
import { NavigationHeader } from "../Header";
import { navigationMock } from "../Header/Navigation/navigation.props.mock";
import { secondaryNavigationMock } from "../Header/SecondaryNavigation/secondaryNavigationMock.props.mock";
import { widgetMock } from "../Header/NavigationWidgets/NavigationWidgets.props.mock";
import { footerMock } from "../Footer/Footer.props.mock";
export const Layout = (props) => (React.createElement(React.Fragment, null,
    React.createElement(NavigationHeader, { navigationItems: navigationMock.navigationItems, secondaryNavigation: secondaryNavigationMock, widgets: widgetMock, menuTitle: navigationMock.menuTitle, searchWidgetProps: navigationMock.searchWidgetProps, logoHref: navigationMock.logoHref, searchPlaceholder: navigationMock.searchPlaceholder }),
    props.children,
    React.createElement(Footer, { socialSectionLogoImageUrl: footerMock.socialSectionLogoImageUrl, socialSectionLogoImageAlt: footerMock.socialSectionLogoImageAlt, socialSectionLiveChatText: footerMock.socialSectionLiveChatText, socialSectionIcons: footerMock.socialSectionIcons, sections: footerMock.sections, footerBannerImageUrl: footerMock.footerBannerImageUrl, footerBannerImageAlt: footerMock.footerBannerImageAlt, riskWarningText: footerMock.riskWarningText, logoHref: footerMock.logoHref })));
