import * as React from "react";
import { Container, ContainerFW, Row, Col } from "../../global";
import { GetHelpCard } from "./GetHelpCard/GetHelpCard";
export const GetHelpCardDeck = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "getHelpCardDeck" },
        React.createElement(Container, null,
            React.createElement("div", { className: "getHelpCardDeck__container" },
                React.createElement("div", { className: "getHelpCardDeck__title" },
                    React.createElement("h2", null, props.title)),
                React.createElement("div", { className: "getHelpCardDeck__cards-container" },
                    React.createElement(Row.Cols4, null, props.cards.map((card, index) => React.createElement(Col, { key: index },
                        React.createElement(GetHelpCard, { ...card }))))))))));
