import React from "react";
import { ContractSpecsFilterFactory } from "./ContractSpecsFilterFactory";
export const getConditionalValue = (preferredValue, fallbackValue) => preferredValue || fallbackValue;
const getAdjustedFilterOptions = (options, optionsTexts) => {
    if (!options) {
        return;
    }
    return options.map((option, index) => {
        if (typeof option === "string") {
            return {
                value: option,
                text: optionsTexts?.[index] || option
            };
        }
        return {
            value: option.value,
            text: optionsTexts?.[index] || option.text || option.value
        };
    });
};
export const ContractSpecsFilterSet = ({ positionQualities, countries, currencies, accountTypes, instrumentSubtypes, leverageTypes, activePositionQuality, activeCountry, activeCurrency, activeAccountType, activeInstrumentSubtype, activeLeverageType, onPositionQualityChange, onCountryChange, onCurrencyChange, onAccountTypeChange, onInstrumentSubtypeChange, onLeverageTypeChange, filtersTranslations }) => {
    const allPossibleFiltersOrdered = [
        {
            options: getAdjustedFilterOptions(accountTypes?.options, filtersTranslations?.accountTypes?.options),
            filterType: "dropdown",
            onSelect: onAccountTypeChange,
            selectedOption: activeAccountType,
            filterTitle: getConditionalValue(filtersTranslations?.accountTypes?.title, "Account Type"),
        },
        {
            options: getAdjustedFilterOptions(positionQualities?.options, filtersTranslations?.positionQualities?.options),
            filterType: "tabs",
            onSelect: onPositionQualityChange,
            selectedOption: activePositionQuality,
            filterTitle: getConditionalValue(filtersTranslations?.positionQualities?.title, "Type"),
        },
        {
            options: getAdjustedFilterOptions(instrumentSubtypes?.options, filtersTranslations?.instrumentSubtypes?.options),
            filterType: "tabs",
            onSelect: onInstrumentSubtypeChange,
            selectedOption: activeInstrumentSubtype,
            filterTitle: getConditionalValue(filtersTranslations?.instrumentSubtypes?.title, "Platform"),
        },
        {
            options: getAdjustedFilterOptions(countries?.options, filtersTranslations?.countries?.options),
            filterType: "dropdown",
            onSelect: onCountryChange,
            selectedOption: activeCountry,
            filterTitle: getConditionalValue(filtersTranslations?.countries?.title, "Country"),
            placeholder: "-"
        },
        {
            options: getAdjustedFilterOptions(currencies?.options, filtersTranslations?.currencies?.options),
            filterType: "dropdown",
            onSelect: onCurrencyChange,
            selectedOption: activeCurrency,
            filterTitle: getConditionalValue(filtersTranslations?.currencies?.title, "Currency"),
            placeholder: "-"
        },
        {
            options: getAdjustedFilterOptions(leverageTypes?.options, filtersTranslations?.leverageTypes?.options),
            filterType: "dropdown",
            onSelect: onLeverageTypeChange,
            selectedOption: activeLeverageType,
            filterTitle: getConditionalValue(filtersTranslations?.leverageTypes?.title, "Classification"),
        },
    ];
    return (React.createElement(React.Fragment, null, allPossibleFiltersOrdered.filter(filterDefinition => filterDefinition.options).map(({ options, filterType, onSelect, selectedOption, filterTitle, placeholder }, index) => {
        if (!options?.length) {
            return undefined;
        }
        return React.createElement(ContractSpecsFilterFactory, { key: index, filterType: filterType, options: options, onSelect: onSelect, selectedOption: selectedOption, filterTitle: filterTitle, placeholder: placeholder });
    })));
};
