import * as React from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import { getColors } from "../../utils/getColors";
export const TextAndImageLP = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: `text-and-image-lp ${props.imagePosition === ("right" || "center") ? "image-right" : "image-left"}` },
                React.createElement("div", { className: "text-and-image-lp__text-container" },
                    React.createElement("h2", null, props.title),
                    React.createElement("div", { className: "text-and-image-lp__text-description", dangerouslySetInnerHTML: {
                            __html: props.text
                        } })),
                React.createElement("div", { className: "text-and-image-lp__image-container" },
                    React.createElement(Img, { src: props.imageUrl, alt: props.imageAlt }))))));
};
