import React, { useCallback } from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils";
export const CardsGridIconAndTitle = ({ cards, backgroundColor, noWrappers, title, activeCardValue }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    const handleActiveCard = useCallback((index) => {
        if (activeCardValue === cards[index].description.toLocaleLowerCase()) {
            return "active-card";
        }
        return "";
    }, [activeCardValue, cards]);
    const card = useCallback((card, i) => React.createElement("a", { href: card.linkUrl, key: i, className: `cards-grid-icon-and-title__card bg-color-neutral-accent-background centered-container color-dark-default ${handleActiveCard(i)}` },
        React.createElement("div", { className: "cards-grid-icon-and-title__inner" },
            React.createElement("div", { className: "centered-container" },
                React.createElement("img", { width: "24px", src: card.imageUrl, alt: card.imageAlt })),
            React.createElement("div", { className: "p-bold cards-grid-icon-and-title__text-container centered-container" },
                React.createElement("p", { className: "p-bold cards-grid-icon-and-title__text-description centered-container" }, card.description)))), [handleActiveCard]);
    const content = noWrappers ?
        React.createElement("div", { style: { color: textColor }, className: "cards-grid-icon-and-title" },
            title &&
                React.createElement("div", { className: "alphabets-search__title-container" },
                    React.createElement("p", null, title)),
            React.createElement("div", { className: "cards-grid-icon-and-title__cards-grid" }, cards.map((c, i) => card(c, i)))) :
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, null,
                React.createElement("div", { style: { color: textColor }, className: "cards-grid-icon-and-title" },
                    React.createElement("div", { className: "cards-grid-icon-and-title__cards-grid" }, cards.map((c, i) => card(c, i))))));
    return content;
};
