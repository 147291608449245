import * as React from "react";
import { ButtonV2 } from "../ButtonV2";
import { EconomicCalendarMemoized } from "../EconomicCalendar";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import { ellipse } from "../svgIcons/ellipse";
export const MarketNews = ({ mainTitle, newsContent, backgroundColor, moreNewsLabel, moreNewsUrl }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
    React.createElement(Container, null,
        React.createElement("div", { className: "market-news" },
            React.createElement("div", { className: "market-news__title-container" },
                React.createElement("h2", null, mainTitle)),
            React.createElement("div", { className: "market-news__news-container" },
                React.createElement("div", { className: "market-news__main-news" },
                    newsContent.map((item, i) => (React.createElement("a", { href: item.newsLink, key: i, className: "market-news__main-news-item" },
                        React.createElement("div", { className: "market-news__main-image-container" },
                            React.createElement(Img, { className: "market-news__main-image", src: item.newsImg, alt: item.newsTitle })),
                        React.createElement("div", { className: "market-news__main-text" },
                            React.createElement("div", { className: "market-news__main-news-info" },
                                React.createElement("p", null,
                                    React.createElement("span", { className: "market-news__green-text" }, item.newsInstrumentCategory),
                                    ellipse(),
                                    React.createElement("span", { className: "market-news__category-text" }, item.newsCategory),
                                    ellipse(),
                                    React.createElement("span", { className: "market-news__date-text" }, new Date(item.newsUploadTime).toDateString().slice(4)))),
                            React.createElement("div", { className: "market-news__main-news-title" },
                                React.createElement("h4", null, item.newsTitle)),
                            React.createElement("div", { className: "market-news__main-news-author" },
                                React.createElement("p", null, item.newsAuthor)))))),
                    moreNewsLabel && React.createElement("div", { className: "market-news__more-button" },
                        React.createElement(ButtonV2, { design: "secondary-medium-wbg", href: moreNewsUrl }, moreNewsLabel))),
                React.createElement("div", { className: "market-news__calendar" },
                    React.createElement(EconomicCalendarMemoized, null)))))));
