import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { Button } from "../../Button";
import ".";
export const ErrorBanner = (props) => {
    const clickHandler = React.useCallback(() => {
        window.location.href = "/";
    }, []);
    return (React.createElement("div", { style: {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${props.backgroundUrl})`,
        } },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "main-error-container" },
                    React.createElement("div", { className: "main-error-container__error-items-container" },
                        React.createElement("p", null, props.errorMessage),
                        React.createElement("span", { className: "text-jumbo" }, props.title),
                        React.createElement("h5", null, props.description),
                        React.createElement("div", { className: "main-error-container__return-button" },
                            React.createElement(Button, { label: props.returnButtonText, primary: true, onClick: clickHandler })),
                        React.createElement("span", { className: "main-error-container__help-pages-link", dangerouslySetInnerHTML: {
                                __html: props.returnToHomepageHtml,
                            } })))))));
};
