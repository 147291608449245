export const getCultureTranslateConfigData = (culture, pageConfig) => pageConfig.find(cultureConfig => cultureConfig.cultures.map(cultureName => cultureName.toLowerCase()).includes(culture.toLowerCase()));
const getGroupConfigKeyName = (instrumentGroupName, instrumentSubgroupName) => instrumentSubgroupName?.toLowerCase() || instrumentGroupName.toLowerCase();
export const getInstrGroupTranslateConfigData = (instrumentGroupName, instrumentSubgroupName, cultureConfig) => {
    const instrumentGroupKey = getGroupConfigKeyName(instrumentGroupName, instrumentSubgroupName);
    const instrumentGroupConfigData = cultureConfig && Object.entries(cultureConfig.instrumentGroupsCfg)?.find(([instrumentGroupConfigKey,]) => instrumentGroupConfigKey.toLowerCase() === instrumentGroupKey.toLowerCase())?.[1];
    if (!instrumentGroupConfigData) {
        console.info(`No instrument group config data could be found for group/subgroup '${instrumentGroupName}'`);
    }
    return instrumentGroupConfigData;
};
export const getReplacementValue = ({ key, keyType, keyPath, originalValue, translationFunction }) => {
    let realKey;
    if (keyType === "dynamicOriginal") {
        realKey = `${keyPath}.${originalValue}`;
    }
    else {
        realKey = key;
    }
    if (typeof realKey === "undefined") {
        return originalValue;
    }
    const translatedValue = translationFunction(realKey, { value: originalValue });
    return translatedValue === realKey ? originalValue : translatedValue;
};
export const getTranslatedValue = ({ fieldName, originalValue, instrumentGroupConfig, translationFunction }) => {
    if (!instrumentGroupConfig) {
        return originalValue;
    }
    const fieldTranslationRules = instrumentGroupConfig.filter((translationRule) => translationRule.fieldName.toLowerCase() === fieldName.toLowerCase());
    if (!fieldTranslationRules.length) {
        return originalValue;
    }
    const translatedValue = fieldTranslationRules.reduce((valueSoFar, newRule) => {
        const targetString = newRule.targetString || valueSoFar;
        return valueSoFar.replace(targetString, getReplacementValue({
            key: newRule.translationKey,
            keyType: newRule.translationKeyType,
            keyPath: newRule.translationKeyPath,
            originalValue: valueSoFar,
            translationFunction
        }));
    }, originalValue);
    return translatedValue;
};
