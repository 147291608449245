import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { Img } from "../../Img";
import { ButtonV2 } from "../../ButtonV2";
import { getColors } from "../../../utils";
import { Heading } from "../../Heading";
export const AcademyBanner = ({ title, h1title, subtitle, image, alt, buttonUrl, buttonText, designStyles }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor || "#0E1D31" },
        React.createElement(Container, null,
            React.createElement("div", { style: { color: textColor }, className: "academyBanner" },
                React.createElement("div", { className: "academyBanner__content" },
                    h1title ?
                        React.createElement(Heading, { design: "h1-regular" }, title) :
                        React.createElement(Heading, { design: designStyles?.titleDesign || "h2-regular" }, title),
                    React.createElement("h5", { className: "h5-semi-bold" }, subtitle),
                    React.createElement(ButtonV2, { design: designStyles?.buttonDesign || "secondary-large-dbg", href: buttonUrl }, buttonText)),
                React.createElement("div", { className: "academyBanner__image" },
                    React.createElement(Img, { src: image, alt: alt }))))));
};
