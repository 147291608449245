export const footerMock = {
    socialSectionLogoImageUrl: "./images/ThinkMarkets-logo.svg",
    socialSectionLogoImageAlt: "Think Markets",
    socialSectionLiveChatText: "Need to get in touch? Contact our client support team via live chat.",
    socialSectionIcons: [
        { iconUrl: "./images/Footer/icon-facebook.svg", iconAlt: "", iconLink: "" },
        { iconUrl: "./images/Footer/icon-twitter.svg", iconAlt: "", iconLink: "" },
        { iconUrl: "./images/Footer/icon-youtube.svg", iconAlt: "", iconLink: "" },
        { iconUrl: "./images/Footer/icon-linkedin.svg", iconAlt: "", iconLink: "" },
        { iconUrl: "./images/Footer/icon-instagram.svg", iconAlt: "", iconLink: "" }
    ],
    footerBannerImageUrl: "./images/Footer/footer_banner.svg",
    footerBannerImageAlt: "footer banner",
    riskWarningText: `<p><strong>Risk Warning:</strong> Derivative products are leveraged products and can result in losses that exceed initial deposits. Please ensure you fully understand the risks and take care to manage your exposure and seek independent advice if necessary.</p>
    <br/>
    <p><strong>TF Global Markets Int Limited</strong> is authorised and regulated by the Financial Services Authority, Firm Reference Number SD060. Registered address: CT House, Office 9B, Providence, Mahe, Seychelles. Company number 8424818-1. Clients will engage with services offered by TF Global Markets Int Limited, unless otherwise stated, please see the legal documents, including the Terms & Conditions for specific details.</p>
    <br/>
    <p><strong>ThinkMarkets Group</strong> is a global financial services provider, ThinkMarkets is a registered trademark of the group, that operates among various entities. For more information please visit the About Us section.</p>
    <br/>
    <p><strong>Think Capital Limited</strong> is registered in Bermuda, Company number: 51879. Registered address: Clarendon House, 2 Church Street, Hamilton HM 11, Bermuda. Think Capital is not required to hold any financial services license or authorization in Bermuda to offer its products and services. For the avoidance of doubt; Think Capital is not licensed by the Bermuda Monetary Authority, and does not provide any products and services under the Digital Asset Business Act 2018.</p>
    <br/>
    <p><strong>TFG (Payments) Limited</strong> is registered in the United Kingdom, Company number 10537331. Registered address: Hana, 4th Floor, 70 St Mary Axe, London EC3A 8BE.</p>
    <br/>
    <p><strong>Think Capital Services UK</strong> Ltd is registered in the United Kingdom, Company number 11054653. Registered address: Hana, 4th Floor, 70 St Mary Axe, London, EC3A 8BE</p>
    <br/>
    <p>The information on this site is not directed to residents of the United States, Canada, the European Union, Australia, United Kingdom and Japan and is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.</p>
    <br/>
    <p>© 2022 This website is owned and operated by ThinkMarkets Group.</p>`,
    logoHref: "~/en",
    sections: [
        { title: "TRADE WITH US",
            section: [
                { label: "Trade CFDs", url: "#" },
                { label: "Social Trading", url: "#" },
                { label: "Trading tools", url: "#" },
                { label: "Pricing", url: "#" }
            ]
        },
        { title: "ACCOUNT INFORMATION",
            section: [
                { label: "Account Comparison", url: "#" },
                { label: "Demo Account", url: "#" },
                { label: "ThinkZero Account", url: "#" },
                { label: "Manage Account", url: "#" },
                { label: "Deposit & Withdraw", url: "#" }
            ]
        },
        { title: "POPULAR IN LEARNING",
            section: [
                { label: "Japanese Candlesticks", url: "#" },
                { label: "Glossary of The Day", url: "#" },
                { label: "Learn to Trade CFDs", url: "#" },
                { label: "Successful Trading Strategies", url: "#" }
            ]
        },
        { title: "COMPANY",
            section: [
                { label: "About ThinkMarkets", url: "#" },
                { label: "Press & Announcements", url: "#" },
                { label: "Careers", url: "#" }
            ]
        },
        { title: "SUPPORT",
            section: [
                { label: "How to place a trade?", url: "#" },
                { label: "How to create an account?", url: "#" },
                { label: "How to deposit?", url: "#" },
                { label: "How long does it take for account approval?", url: "#" },
                { label: "What types of accounts do you offer?", url: "#" }
            ]
        },
        { title: "LEGAL",
            section: [
                { label: "Terms & conditions", url: "#" },
                { label: "Privacy policy", url: "#" },
                { label: "Risk disclaimer", url: "#" },
                { label: "Regulations", url: "#" }
            ]
        },
    ],
};
