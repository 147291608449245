import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
import { CardWithHoverAnimation } from "./CardWithHoverAnimation";
export const CardsWithHoverAnimation = ({ title, backgroundColor, content }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "cards-with-hover-animation" },
                React.createElement("div", { className: "cards-with-hover-animation__title-wrapper" }, title && React.createElement("h2", null, title)),
                React.createElement("div", { className: "cards-with-hover-animation__cards-wrapper" }, content.map((card, i) => (React.createElement(CardWithHoverAnimation, { key: i, title: card.title, text: card.text, iconAlt: card.iconAlt, iconUrl: card.iconUrl, animationPath: card.animationPath }))))))));
};
