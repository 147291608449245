import * as React from "react";
import { ContainerFW } from "../global";
import { FiltersRibbon } from "../FiltersRibbon";
import { AcademyArticleCardDeckPool } from "../Cards";
import { Filters } from "../Filters";
import { serializeQuery, useFetchAcadArticlesOnMount, useHandleWindowLocationPage } from "./utils";
import { LoadingSpinner } from "../LoadingSpinner";
import { checkIsNumber } from "../../utils";
export const FilterAcademyCards = (props) => {
    const [listView, setListView] = React.useState(false);
    const [filtersActive, setFiltersActive] = React.useState(false);
    const [filtersData, setFiltersData] = React.useState({});
    const [resetFilters, setResetFilters] = React.useState(0);
    const [cards, setCards] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState(undefined);
    const [isFilterOrSearchApplied, setIsFilterOrSearchApplied] = React.useState(false);
    const [hasFiltersApplied, setHasFiltersApplied] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(true);
    const pageSize = 12;
    const [totalArticlesCount, setTotalArticlesCount] = React.useState(0);
    const abortController = React.useRef(undefined);
    const lastQuery = React.useRef(undefined);
    const categoryFilterItems = props.fields.find(filter => filter.title.toLowerCase() === "category")?.items || [];
    const [categoriesTitles, categoriesIds] = [
        categoryFilterItems.map((a) => a.categoryTitle),
        categoryFilterItems.map((a) => a.categoryId)
    ];
    const getConditionalRenderingContext = () => {
        const hasCards = cards.length > 0;
        const areAnyFiltersSet = Object.values(filtersData).filter(el => el !== "" && el !== 5).length > 0;
        const isSearch = searchQuery !== "";
        const isFilteredResult = isFilterOrSearchApplied && searchQuery === "";
        const shouldShowLatestNews = hasCards && !isFilterOrSearchApplied;
        return {
            hasCards,
            areAnyFiltersSet,
            isFilteredResult,
            isSearch,
            shouldShowLatestNews,
        };
    };
    const { areAnyFiltersSet } = getConditionalRenderingContext();
    const getInitialCheckedAllState = React.useCallback(() => props.fields.reduce((acc, item) => {
        acc[item.title] = false;
        return acc;
    }, {}), [props.fields]);
    const [checkedAll, setCheckedAll] = React.useState(() => getInitialCheckedAllState());
    const getDataKeyForFilterTitle = (filterTitle) => filterTitle.replaceAll(" ", "");
    const checkAll = React.useCallback((item) => (e) => {
        e.preventDefault();
        const field = props.fields.find(field => field.title === item);
        if (!field || field.type !== "checkbox" || typeof field.items === "undefined") {
            return;
        }
        const newValue = field.items.map((a) => a.categoryTitle);
        const mustCheckAll = !checkedAll[item];
        const fieldItems = newValue;
        const allValues = mustCheckAll ? fieldItems.join(",") : "";
        const dataKey = getDataKeyForFilterTitle(item);
        setFiltersData(prevFilterData => ({
            ...prevFilterData,
            [dataKey]: allValues,
        }));
        setCheckedAll({ ...checkedAll, [item]: !checkedAll[item] });
    }, [checkedAll, props.fields]);
    const handlerHasFiltersApplied = React.useCallback((value) => {
        if (value.includes("Difficulty") || value.includes("Category") || value.includes("Readingtime")) {
            setHasFiltersApplied(true);
            return;
        }
        setHasFiltersApplied(false);
    }, []);
    const getIndexOfSelectedElements = React.useCallback((word) => {
        if (categoriesTitles && categoriesTitles.length > 0) {
            return categoriesTitles.indexOf(word);
        }
        return undefined;
    }, [categoriesTitles]);
    const categoryTranslator = React.useCallback((value) => {
        if (value && value.length > 0) {
            const wordsArray = value.map((a) => getIndexOfSelectedElements(a)).filter(el => typeof el !== "undefined");
            const selectedOptions = categoriesIds
                .map((a, i) => (categoriesIds[wordsArray[i]]))
                .filter((a) => a !== undefined)
                .join();
            return selectedOptions.split(",");
        }
        return undefined;
    }, [categoriesIds, getIndexOfSelectedElements]);
    const handleCategoriesQuantity = React.useCallback((category) => {
        let fullCategoriesList = categoryTranslator(category);
        if (fullCategoriesList?.length === categoriesIds.length) {
            fullCategoriesList = undefined;
        }
        return fullCategoriesList;
    }, [categoriesIds.length, categoryTranslator]);
    const checkMustResetPageNumber = (lastQueryObject, newQueryObject) => serializeQuery(lastQueryObject, ["page"]) !== serializeQuery(newQueryObject, ["page"]);
    const fetchArticles = React.useCallback((q) => {
        setIsLoading(true);
        const { Difficulty, Category, page, searchString } = q;
        const getQueryPage = () => {
            if (checkIsNumber(page)) {
                return page;
            }
            if (checkIsNumber(currentPage)) {
                return currentPage;
            }
            return 0;
        };
        const getSearchString = () => {
            if (typeof searchString !== "undefined") {
                return searchString;
            }
            return searchQuery || "";
        };
        const getDifficulty = () => {
            if (typeof Difficulty !== "undefined") {
                return Difficulty;
            }
            if (!filtersData["Difficulty"]) {
                return undefined;
            }
            return filtersData["Difficulty"]?.split(",").filter(v => v);
        };
        const getCategory = () => {
            if (typeof Category !== "undefined") {
                return Category;
            }
            if (!filtersData["Category"]) {
                return undefined;
            }
            return filtersData["Category"]?.split(",").filter(v => v);
        };
        const getQueryCategory = () => {
            const category = getCategory();
            if (category) {
                return handleCategoriesQuantity(category);
            }
            return category;
        };
        const getQueryParamCandidates = () => ({
            culture: props.culture,
            page: getQueryPage(),
            searchString: getSearchString(),
            Difficulty: getDifficulty(),
            Category: getQueryCategory()
        });
        const translatedQueryParams = Object.entries(getQueryParamCandidates()).reduce((accumulatedParams, candidateEntry) => {
            const [paramKey, paramValue] = candidateEntry;
            if (paramValue !== null && typeof paramValue !== "undefined") {
                return {
                    ...accumulatedParams,
                    [paramKey]: paramValue,
                };
            }
            return accumulatedParams;
        }, {});
        if (lastQuery.current && checkMustResetPageNumber(lastQuery.current, translatedQueryParams)) {
            translatedQueryParams.page = 1;
            setCurrentPage(1);
        }
        const serializedQuery = serializeQuery(translatedQueryParams);
        lastQuery.current = translatedQueryParams;
        if (abortController.current) {
            abortController.current.abort();
        }
        const queryAbortController = abortController.current = new AbortController();
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: serializedQuery,
            signal: abortController.current.signal
        };
        handlerHasFiltersApplied(requestOptions.body);
        fetch(`/api/FilterAcademyArticles?token=${props.token}`, requestOptions)
            .then((response) => response.json())
            .then((responseData) => {
            setCards(responseData.articles);
            setCurrentPage(responseData.pageNumber);
            setTotalArticlesCount(responseData.totalArticlesCount);
        })
            .catch(err => console.warn(err))
            .finally(() => {
            setIsLoading(false);
            queryAbortController.abort();
        });
    }, [currentPage, filtersData, handleCategoriesQuantity, handlerHasFiltersApplied, props.culture, props.token, searchQuery]);
    const handlePaginationChange = React.useCallback((newPageNum) => {
        fetchArticles({
            page: newPageNum
        });
    }, [fetchArticles]);
    const handleSearchQuery = React.useCallback((searchText) => {
        fetchArticles({ searchString: searchText, Difficulty: null, Category: null });
        setSearchQuery(searchText);
        setFiltersData({});
    }, [fetchArticles]);
    useFetchAcadArticlesOnMount(currentPage, fetchArticles, setFiltersData);
    useHandleWindowLocationPage(currentPage, setCurrentPage, true);
    React.useEffect(() => {
        if (searchQuery === "") {
            setIsFilterOrSearchApplied(false);
            return;
        }
        setIsFilterOrSearchApplied(true);
    }, [fetchArticles, searchQuery]);
    const dataHandler = React.useCallback((d) => {
        setFiltersData(d);
    }, []);
    const filterHandier = React.useCallback(() => {
        const o = Object.fromEntries(Object.entries(filtersData).filter(([, v]) => v !== ""));
        Object.keys(o).map((el) => {
            const type = props.fields.filter(f => f.title.replaceAll(" ", "") === el)[0].type;
            if ((type === "range")) {
                o[el] = o[el] * 1;
            }
            else if (type === "checkbox-date") {
                const startDate = o[el].split(",").filter((v) => v !== "")[0]?.replace("startdate-", "");
                const endDate = o[el].split(",").filter((v) => v !== "")[1]?.replace("enddate-", "");
                if (startDate) {
                    o["Date"] = startDate;
                }
                if (endDate) {
                    o["EndDate"] = endDate;
                }
                delete o[el];
            }
            else {
                o[el] = o[el].split(",").filter((v) => v !== "").filter((v) => v !== "All");
                Object.keys(o).map(el => (!o[el].length && typeof (o[el]) !== "number") ? delete o[el] : o[el]);
            }
        });
        fetchArticles({
            ...o,
            searchString: ""
        });
        setSearchQuery("");
        setIsFilterOrSearchApplied(true);
    }, [fetchArticles, filtersData, props.fields]);
    const resetHandler = React.useCallback((e) => {
        e.preventDefault();
        setResetFilters(Math.random() * 100);
        const newFilters = {};
        Object.keys(filtersData).map(e => newFilters[e] = "");
        setFiltersData(newFilters);
        setCheckedAll(getInitialCheckedAllState());
        fetchArticles({});
        setIsFilterOrSearchApplied(false);
        setSearchQuery("");
        setHasFiltersApplied(false);
    }, [filtersData, getInitialCheckedAllState, fetchArticles]);
    const filtersRibbonElement = React.createElement(FiltersRibbon, { subscriptionButtonText: props.subscriptionButtonText, subscriptionText: props.subscriptionText, filterText: props.filterText, type: "search", filterToggle: setFiltersActive, filters: filtersActive, viewToggle: setListView, view: listView, searchQuery: searchQuery ?? "", setSearchQuery: handleSearchQuery, searchText: props.searchText, hasFiltersApplied: hasFiltersApplied });
    const filtersElement = React.createElement(Filters, { filterToggle: setFiltersActive, submit: filterHandier, reset: resetFilters, setReset: resetHandler, fields: props.fields, data: filtersData, setData: dataHandler, resetText: props.resetText, applyText: props.applyText, areAnyFiltersSet: areAnyFiltersSet, checkedAll: checkedAll, checkAll: checkAll });
    const renderContent = () => React.createElement(AcademyArticleCardDeckPool, { noResultsText: props.noResultsText, articlesString: props.articlesString, articleReadingTimeTimeUnit: props.articleReadingTimeTimeUnit || "", categoryLinks: props.categoryLinks, culture: props.cultureSuffix, cards: cards, isListView: listView, filtersRibbonElement: filtersRibbonElement, filtersElement: filtersElement, currentPage: currentPage, totalArticlesCount: totalArticlesCount, pageSize: pageSize, getPage: handlePaginationChange });
    React.useEffect(() => {
        document.body.classList.toggle("body-scroll-disabled", filtersActive);
    }, [filtersActive]);
    React.useEffect(() => () => {
        if (abortController.current) {
            abortController.current.abort();
        }
    }, []);
    return (React.createElement("div", { className: "filter-cards__container" },
        React.createElement(ContainerFW, { backgroundColor: "#fff" }, isLoading ? React.createElement("div", { className: "spinner-wrapper" },
            React.createElement(LoadingSpinner, null))
            : React.createElement("div", { className: `filter-cards ${filtersActive ? "active-filters" : ""}` },
                React.createElement("div", { className: "filters-overlay" }),
                renderContent()))));
};
