import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ContainerFW } from "../../global";
import { NumberedSwipeCard } from "./NumberedSwipeCard";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
const getButtonDesign = (buttonType) => buttonType === "primary" ? "cta-medium-wbg" : "ghost-medium-wbg";
export const NumberedSwipeCardDeck = ({ title, cards, backgroundColor, buttonTitle, buttonLink, buttonType = "primary", openInNewTabButton }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
    React.createElement("div", { className: "numbered-swipe-card-deck" },
        React.createElement("div", { className: "numbered-swipe-card-deck__title-container" },
            React.createElement("h2", null, title)),
        React.createElement(Swiper, { className: "hero-slider", slidesPerView: "auto" }, cards.map((item, i) => (React.createElement(SwiperSlide, { key: `swipe-card-${i}` },
            React.createElement(NumberedSwipeCard, { number: item.number, title: item.title, text: item.text, imageUrl: item.imageUrl, imageAlt: item.imageAlt, cardVariant: item.cardVariant, cardVariantProps: item.cardVariantProps }))))),
        buttonTitle && React.createElement("div", { className: "numbered-swipe-card-deck__button-container" },
            React.createElement(ButtonV2, { classes: "numbered-swipe-card-deck__button", design: getButtonDesign(buttonType), href: buttonLink, openInNewTab: openInNewTabButton }, buttonTitle)))));
