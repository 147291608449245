import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { TwoSectionWithTitleCard } from "./TwoSectionWithTitleCard";
export const TwoSectionWithTitle = ({ mainTitle, mainSubTitle, leftCardContent, rightCardContent, bgColor }) => {
    const { bgColor: backgroundColor } = getColors(bgColor);
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
        React.createElement("div", { className: "two-section-with-title-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "two-section-with-title" },
                    React.createElement("div", { className: "two-section-with-title__titleWrapper" },
                        React.createElement("h2", null, mainTitle),
                        React.createElement("h5", null, mainSubTitle)),
                    React.createElement("div", { className: "two-section-with-title__cardsWrapper" },
                        React.createElement(TwoSectionWithTitleCard, { title: leftCardContent.title, items: leftCardContent.items, iconType: "check" }),
                        React.createElement(TwoSectionWithTitleCard, { title: rightCardContent.title, items: rightCardContent.items, iconType: "alert" })))))));
};
