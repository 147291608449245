import React from "react";
import { Img } from "../Img";
import { IconChevronDown } from "../svgIcons";
export const CardItem = ({ groupName, instrumentItems }) => (React.createElement("div", { className: "cardItem-instrument-by-groups" },
    groupName && React.createElement("p", { className: "cardItem-instrument-by-groups__groupName" }, groupName),
    React.createElement("div", { className: "cardItem-instrument-by-groups__itemWrapper" }, instrumentItems.length > 0 && instrumentItems.map((a) => (React.createElement("a", { key: `unique${a.name}`, href: a.linkUrl, className: "cardItem-instrument-by-groups__link-container" },
        React.createElement(Img, { src: a.iconImg, alt: a.iconAlt || a.name }),
        React.createElement("span", { className: "cardItem-instrument-by-groups__link-container__texts-wrapper" },
            React.createElement("span", null,
                React.createElement("h5", { className: "cardItem-instrument-by-groups__link-container__texts-wrapper__name" }, a.name),
                React.createElement("p", { className: "cardItem-instrument-by-groups__link-container__texts-wrapper__description" }, a.description)),
            IconChevronDown({}))))))));
