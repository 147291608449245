import React from "react";
import { Img } from "../../../Img";
export const NotificationCard = ({ mainTitle, subTitle, imageUrl, elapsedTime }) => (React.createElement("div", { key: `unique${mainTitle}`, className: "notificationsGridContainerWrapper__notificationsWrapper__notificationCard" },
    React.createElement("div", { className: "notificationsGridContainerWrapper__notificationsWrapper__notificationCard__imageContainer" },
        React.createElement(Img, { src: imageUrl, alt: mainTitle })),
    React.createElement("div", { className: "notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts" },
        React.createElement("div", { className: "notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper" },
            React.createElement("p", { className: "notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-mainTitle" }, mainTitle),
            React.createElement("p", { className: "notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-elapsedTime" }, elapsedTime)),
        React.createElement("p", { className: "notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__notification-subTitle" }, subTitle))));
