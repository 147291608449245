import * as React from "react";
import { Container } from "../global";
import { AccountCardBody, AccountCardHeader } from "./AccountCard/AccountCard";
export const AccountTypes = (props) => (React.createElement("div", { className: "accountTypes" },
    React.createElement(Container, { className: "centered-container" },
        React.createElement("div", { className: "accountTypes__wrapper " },
            React.createElement("div", { className: "accountTypes__inner" },
                React.createElement("div", { className: "accountTypes__row" }, props.items.map((item, index) => React.createElement("div", { key: `key-phead-${index}`, className: "accountTypes__col" },
                    React.createElement(AccountCardHeader, { ...item }))))),
            React.createElement("div", { className: "accountTypes__card-bodies-wrapper" },
                React.createElement("div", { className: "accountTypes__row accountTypes__card-bodies" }, props.items.map((item, i) => React.createElement("div", { className: "accountTypes__col", key: `key-pbody-${i}` },
                    React.createElement(AccountCardBody, { ...item })))))))));
