import React, { useCallback } from "react";
import { caretIcon } from "../../Cards/AccordionCardDeck/AccordionCard";
export const FaqAccordionCard = (props) => {
    const handleExpand = useCallback(() => {
        if (props.setActiveCardId && props.id === props.activeCardId) {
            props.setActiveCardId(-1);
        }
        else if (props.setActiveCardId && props.id !== undefined) {
            props.setActiveCardId(props.id);
        }
    }, [props.activeCardId, props.id, props.setActiveCardId]);
    return (React.createElement("div", { className: `faq-card ${props.id === props.activeCardId ? "active" : ""}` },
        React.createElement("h3", { className: "faq-card__question", itemScope: true, itemProp: "mainEntity", itemType: "https://schema.org/Question", onClick: handleExpand },
            props.question,
            " ",
            caretIcon()),
        React.createElement("p", { className: "faq-card__answer", itemScope: true, itemProp: "acceptedAnswer", itemType: "https://schema.org/Answer", dangerouslySetInnerHTML: { __html: props.answer } })));
};
