import React, { useMemo } from "react";
import { ContainerFW } from "../global";
import { Heading } from "../Heading";
import { BottomTimeLine } from "./TimeLine/BottomTimeLine";
import { TopTimeLine } from "./TimeLine/TopTimeLine";
import { Draggable } from "./Draggable/Draggable";
export const TimelineWithText = ({ title, timelineData }) => {
    const TopTimeLineElements = useMemo(() => {
        const evenElements = timelineData.filter((a, i) => (i % 2 === 0 && a));
        return evenElements;
    }, [timelineData]);
    const bottomTimeLineElements = useMemo(() => {
        const oddElements = timelineData.filter((a, i) => (i % 2 !== 0 && a));
        return oddElements;
    }, [timelineData]);
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "timeline-with-text-wrapper" },
            React.createElement(Heading, { design: "h4-bold" }, title),
            React.createElement(Draggable, null,
                React.createElement("div", { className: "timeline-with-text-wrapper__container" },
                    React.createElement("div", { className: "timeline-with-text-wrapper__container__topLineWrapper" }, TopTimeLineElements.length > 0 && TopTimeLineElements.map((a) => (React.createElement("div", { key: `unique${a.date}` },
                        React.createElement(TopTimeLine, { ...a }))))),
                    React.createElement("div", { className: "timeline-with-text-wrapper__container__bottomLineWrapper" }, bottomTimeLineElements.length > 0 && bottomTimeLineElements.map((a) => (React.createElement("div", { key: `unique${a.date}` },
                        React.createElement(BottomTimeLine, { ...a }))))))))));
};
