import React from "react";
import { ImgV2 } from "../../../ImgV2";
import { Heading } from "../../../Heading";
export const SingleCard = ({ buttonLabel, buttonUrl, imageUrl, subTitle, title }) => (React.createElement("div", { className: 'single-card-root' },
    React.createElement("div", { className: 'single-card-root__imageWrapper' },
        React.createElement(ImgV2, { src: imageUrl })),
    React.createElement("div", { className: "single-card-root__textsWrapper" },
        React.createElement(Heading, { design: "h4-lp" }, title),
        React.createElement("p", { className: "single-card-root__textsWrapper__subtitle", dangerouslySetInnerHTML: {
                __html: subTitle || "",
            } })),
    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", className: "text-nav", href: buttonUrl }, buttonLabel)));
