import React from "react";
import { ContainerFW, Container, Row, Col } from "../../global";
import { NumberedCardDemo, } from "./NumberedCardDemo/NumberedCardDemo";
export const NumberedCardDemoDeck = ({ title, cards, primaryLinkText, primaryLinkUrl, }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "dark-numbered-card-deck dark-numbered-card-deck--demo" },
        React.createElement(Container, null,
            React.createElement("h2", { className: "dark-numbered-card-deck__title" }, title)),
        React.createElement("div", { className: "dark-numbered-card-deck__cards" },
            React.createElement(Container, null,
                React.createElement(Row.Cols3, null, cards.map((card, i) => (React.createElement(Col, { key: `key-NumberedCardDemo-${i}` },
                    React.createElement(NumberedCardDemo, { index: `0${i + 1}`, title: card.title, content: card.content }))))))),
        primaryLinkText && (React.createElement("div", { className: "dark-numbered-card-deck__cta" },
            React.createElement("a", { href: primaryLinkUrl }, primaryLinkText))))));
