import React from "react";
import { getColors } from "../../utils/getColors";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { CardsWithTitleAndSub } from "./CardsWithTitleAndSub";
export const CardsDeckWithTitleAndSub = ({ cardsWithTitleAndSubContent, mainTitle, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "cards-deck-with-title-and-sub-root" },
            React.createElement(Container, null,
                React.createElement(Heading, { design: "h2-regular-small" }, mainTitle),
                React.createElement("div", { className: "cards-deck-with-title-and-sub-root__cardsWrapper" }, cardsWithTitleAndSubContent.length > 0 && cardsWithTitleAndSubContent.map((card) => (React.createElement(CardsWithTitleAndSub, { ...card, key: `unique${card.title}` }))))))));
};
