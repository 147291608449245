import * as React from "react";
import Lottie from "lottie-react";
import cardsAnimation from "./cards-animation.json";
import { Container, ContainerFW } from "../../global";
import { Button } from "../../Button";
import { getColors } from "../../../utils";
import { useInView } from "react-intersection-observer";
export const OptionalButtonBannerWithAnimation = ({ title, description, imagePosition, buttonText, buttonUrl, backgroundColor, buttonType, hasBigImage }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    return (React.createElement("div", { className: "option-button-banner-with-animation__container" },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, null,
                React.createElement("div", { style: { color: textColor }, className: `optional-button-banner-with-animation ${hasBigImage ? "optional-button-banner-with-animation--big-image" : ""} ${inView ? "optional-button-banner-with-animation--animated" : ""}`, ref: ref },
                    React.createElement("div", { className: "optional-button-banner-with-animation__flex-outer" },
                        React.createElement("div", { className: "optional-button-banner-with-animation__image-container-left hide-medium hide-small" }, imagePosition === "left" && React.createElement(Lottie, { animationData: cardsAnimation, loop: true })),
                        React.createElement("div", { className: "optional-button-banner-with-animation__flex-middle" },
                            React.createElement("div", { className: "optional-button-banner-with-animation__title-container" },
                                React.createElement("h2", { dangerouslySetInnerHTML: { __html: title } })),
                            React.createElement("div", { className: "optional-button-banner-with-animation__image-container-mobile hide-large" },
                                React.createElement(Lottie, { animationData: cardsAnimation, loop: true })),
                            React.createElement("div", { className: "optional-button-banner-with-animation__description-container" },
                                React.createElement("div", { dangerouslySetInnerHTML: {
                                        __html: description
                                    } })),
                            React.createElement("div", { className: "optional-button-banner-with-animation__button-container" }, buttonText && React.createElement(Button, { primary: buttonType === "primary", label: buttonText, href: buttonUrl }))),
                        React.createElement("div", { className: "optional-button-banner-with-animation__image-container-right hide-medium hide-small" }, imagePosition !== "left" && React.createElement(Lottie, { animationData: cardsAnimation, loop: true }))))))));
};
