import React, { useState, useEffect } from "react";
import { ContainerFW, Container } from "../global";
import { usePageViewStyle } from "../../hooks";
import { TabWithAccordion } from "./TabWithAccordion";
export const TabsWithAccordion = ({ title, subTitle, backgroundColor, tabs }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { isSmall } = usePageViewStyle();
    const [smallView, setSmallView] = useState(false);
    useEffect(() => {
        setSmallView(isSmall);
    }, [isSmall]);
    const handleTabClick = (index) => () => {
        setActiveTab(index);
    };
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor ? backgroundColor : "white" },
        React.createElement(Container, null,
            React.createElement("div", { className: "tabs-with-accordion__header" },
                React.createElement("h2", null, title),
                subTitle && React.createElement("h5", { className: "tabs-with-accordion__header-subtitle", dangerouslySetInnerHTML: { __html: subTitle } })),
            React.createElement("div", { className: "tabs-with-accordion" }, !smallView ? (React.createElement(React.Fragment, null,
                React.createElement("ul", { className: "tabs-with-accordion__sidebar" }, tabs.map((tab, i) => (React.createElement("li", { className: `tabs-with-accordion__sidebar-item ${i === activeTab ? "sidebar-item-active" : ""}`, key: `tabs-with-accordion__tab-${i}`, onClick: handleTabClick(i) }, tab.title)))),
                React.createElement("div", { className: "tabs-with-accordion__content" },
                    React.createElement(TabWithAccordion, { ...tabs[activeTab] })))) : (tabs.map((tab, i) => React.createElement(TabWithAccordion, { key: i, ...tab })))))));
};
