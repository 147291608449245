import * as React from "react";
import { PopularQuestions } from "../PopularQuestions";
import { SupportSection } from "../SupportSection";
import { SearchBanner } from "../Banners/SearchBanner";
import { Gap } from "../Gaps/Gap";
export const SupportSearch = (props) => {
    const [items, setItems] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const contentRef = React.createRef();
    React.useEffect(() => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ culture: document.getElementsByTagName("html")[0].getAttribute("lang") })
        };
        searchQuery !== "" ? fetch(`/${props.culture}/api/FilterFAQs?token=${props.token}&query=${searchQuery}`, requestOptions)
            .then((response) => response.json())
            .then((responseData) => setItems(responseData))
            .catch(err => console.warn(err)) : setItems([]);
    }, [searchQuery]);
    React.useEffect(() => {
        contentRef.current && changeTextOfChildren(document.querySelector("div.support-search__container"), searchQuery);
    }, [searchQuery, contentRef]);
    const results = React.createElement("div", { ref: contentRef, className: "support-search__container" }, items.map((item, i) => React.createElement("div", { className: "support-search__item", key: `faq-${i}`, dangerouslySetInnerHTML: { __html: `<h5>${item.question}</h5><p>${item.answer}</p>` } })));
    return (React.createElement(React.Fragment, null,
        props.search && React.createElement(SearchBanner, { ...props.search, search: setSearchQuery, query: searchQuery }),
        props.popularQuestions && (React.createElement(React.Fragment, null,
            React.createElement(Gap, { desktopGap: "40px", mobileGap: "32px" }),
            React.createElement(PopularQuestions, { ...props.popularQuestions }, items.length ? results : ""))),
        props.supportSection && React.createElement(SupportSection, { ...props.supportSection }, items.length ? results : "")));
};
function changeTextOfChildren(element, query) {
    element.querySelectorAll("*").forEach((element) => {
        const regex = new RegExp(query, "gi");
        const nodes = element.childNodes;
        nodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const text = node.textContent;
                const replacedText = text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
                if (replacedText !== text) {
                    const tempElement = document.createElement("div");
                    tempElement.innerHTML = replacedText;
                    while (tempElement.firstChild) {
                        element.insertBefore(tempElement.firstChild, node);
                    }
                    element.removeChild(node);
                }
            }
        });
    });
}
