import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
import { CardWithIconTextCard } from "./CardsWithIconTextCard";
export const CardsWithIconText = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: "cards-with-icon-text" },
                React.createElement("div", { className: "cards-with-icon-text__text" },
                    React.createElement("h2", null, props.title),
                    React.createElement("p", null, props.subtitle)),
                React.createElement("div", { className: "cards-with-icon-text__cards" }, props.content.map((card, i) => (React.createElement(CardWithIconTextCard, { key: i, title: card.title, text: card.text, iconAlt: card.iconAlt, iconUrl: card.iconUrl, cardColor: card.cardColor }))))))));
};
