import React, { useState, useRef, useCallback } from "react";
import { BidAskQuote } from "./BidAskQuote";
import { calcRangeBarProps, checkIsStringRangeValid, } from "./utils";
import { useInstrumentPriceHistory, useInstrumentPriceWS } from "./hooks";
import { TimescaleEnum, UIDataRangeStatusEnum, } from "./instrumentData.types";
const MAX_REQUEST_ATTEMPTS = 15;
const REQUEST_INTERVAL = 2000;
const DECIMAL_PRECISION = 2;
const DEFAULT_TIMESCALE = TimescaleEnum.TS_1DAY;
export const InstrumentHeader = ({ instrumentName, instrumentCode, bidTitle, askTitle, openTitle, prevCloseTitle, dayRangeTitle, week52RangeTitle, session, instrument, apiUrl, wsUrl, currencyCode, currencyIconUrl, bidAskLinksUrl }) => {
    const prevBidPrice = useRef(undefined);
    const prevAskPrice = useRef(undefined);
    const [bidPrice, setBidPrice] = useState(undefined);
    const [askPrice, setAskPrice] = useState(undefined);
    const [open, setOpen] = useState(undefined);
    const [prevClose, setPrevClose] = useState(undefined);
    const [dayRange, setDayRange] = useState("");
    const [yearRange, setYearRange] = useState("");
    const calculatePercentageChange = () => {
        if (typeof prevClose === "undefined" ||
            typeof bidPrice === "undefined") {
            return undefined;
        }
        const value = ((prevClose - bidPrice) / bidPrice) * 100;
        if (!Number.isNaN(value) &&
            typeof value === "number" &&
            value !== Infinity) {
            return value;
        }
        return 0;
    };
    const checkIsChangeNegative = (currentValue, prevValue) => currentValue >= prevValue;
    const getValueChangeClass = (isValueNegative) => {
        if (isValueNegative) {
            return "instrument-header_change--positive";
        }
        else {
            return "instrument-header_change--negative";
        }
    };
    const handleWebSocketMessage = useCallback((priceQuote) => {
        setBidPrice(priceQuote.Bid ?? 0);
        setAskPrice(priceQuote.Ask ?? 0);
        prevBidPrice.current = priceQuote.Bid ?? 0;
        prevAskPrice.current = priceQuote.Ask ?? 0;
    }, []);
    useInstrumentPriceWS({
        wsUrl,
        session,
        instrument,
    }, handleWebSocketMessage);
    useInstrumentPriceHistory({
        inputs: {
            apiUrl,
            session,
            instrument,
            timescale: DEFAULT_TIMESCALE,
        },
        callbacks: {
            setDayRange,
            setOpen,
            setPrevClose,
            setYearRange,
        },
        config: {
            MAX_REQUEST_ATTEMPTS,
            REQUEST_INTERVAL,
            DECIMAL_PRECISION,
        },
    });
    const getRangeStats = () => {
        const [dayRangeFrom, dayRangeTo] = dayRange.replace(" ", "").split("-");
        const dayRangeStats = {
            from: dayRangeFrom || undefined,
            to: dayRangeTo || undefined,
        };
        const [yearRangeFrom, yearRangeTo] = yearRange
            .replace(" ", "")
            .split("-");
        const yearRangeStats = {
            from: yearRangeFrom || undefined,
            to: yearRangeTo || undefined,
        };
        return { dayRangeStats, yearRangeStats };
    };
    const getConditionaDataClasses = () => {
        const priceChangeClass = typeof changeValue !== "undefined"
            ? getValueChangeClass(changeValue !== Math.abs(changeValue))
            : getValueChangeClass(false);
        const bidQuoteClass = typeof bidPrice !== "undefined" &&
            typeof prevBidPrice.current !== "undefined"
            ? getValueChangeClass(checkIsChangeNegative(bidPrice, prevBidPrice.current))
            : getValueChangeClass(false);
        const askQuoteClass = typeof askPrice !== "undefined" &&
            typeof prevAskPrice.current !== "undefined"
            ? getValueChangeClass(checkIsChangeNegative(askPrice, prevAskPrice.current))
            : getValueChangeClass(false);
        return { priceChangeClass, bidQuoteClass, askQuoteClass };
    };
    const getRangeBarProps = (range) => {
        const FAIL_OFFSET = "-100vw";
        const CSS_CLASS_PREFFIX = "instrument-header__price-history-range-bar";
        if (!checkIsStringRangeValid(range)) {
            return {
                hasError: UIDataRangeStatusEnum.INVALID_RANGE_POINTS,
                pinPosition: FAIL_OFFSET,
                cssClass: "--disabled",
            };
        }
        const { status, offset } = calcRangeBarProps(Number(range.from), Number(range.to), open);
        const STATUS_TO_RETURN = {
            [UIDataRangeStatusEnum.INCALCULABLE_INPUTS]: {
                hasError: true,
                pinPosition: FAIL_OFFSET,
                cssClass: `${CSS_CLASS_PREFFIX}--disabled`,
            },
            [UIDataRangeStatusEnum.UNDEFINED_INPUTS]: {
                hasError: true,
                pinPosition: FAIL_OFFSET,
                cssClass: `${CSS_CLASS_PREFFIX}--disabled`,
            },
            [UIDataRangeStatusEnum.UKNOWN]: {
                hasError: true,
                pinPosition: FAIL_OFFSET,
                cssClass: `${CSS_CLASS_PREFFIX}--disabled`,
            },
            [UIDataRangeStatusEnum.ZERO_RANGE]: {
                hasError: false,
                pinPosition: FAIL_OFFSET,
                cssClass: `${CSS_CLASS_PREFFIX}--fullbar`,
            },
            [UIDataRangeStatusEnum.REGULAR_RANGE]: {
                hasError: false,
                pinPosition: `${Math.round(offset || 0)}%`,
                cssClass: "",
            },
        };
        const possiblePredefinedStatusObj = Object.entries(STATUS_TO_RETURN).find(([key]) => key === status)?.[1];
        return (possiblePredefinedStatusObj || {
            hasError: true,
            pinPosition: FAIL_OFFSET,
            cssClass: `${CSS_CLASS_PREFFIX}--disabled`,
        });
    };
    const { dayRangeStats, yearRangeStats } = getRangeStats();
    const { pinPosition: dayRangeBarPinPosition, cssClass: dayRangeBarCssClass, } = getRangeBarProps(dayRangeStats);
    const { pinPosition: yearRangeBarPinPosition, cssClass: yearRangeBarCssClass, } = getRangeBarProps(yearRangeStats);
    const changeValue = calculatePercentageChange();
    const formattedChangeValue = typeof changeValue !== "undefined"
        ? Math.abs(changeValue).toFixed(4)
        : "";
    const { priceChangeClass, bidQuoteClass, askQuoteClass } = getConditionaDataClasses();
    return (React.createElement("header", { className: "instrument-header" },
        React.createElement("div", { className: "instrument-header__instrument" },
            React.createElement("h1", { className: "instrument-header__instrument-name" }, instrumentName),
            React.createElement("div", { className: "instrument-header__instrument-code" }, instrumentCode),
            currencyCode ? (React.createElement("div", { className: "instrument-header__instrument-currency" },
                "Currency: ",
                React.createElement("img", { src: currencyIconUrl }),
                " ",
                currencyCode)) : ("")),
        React.createElement("div", { className: "instrument-header__price" },
            React.createElement("div", { className: "instrument-header__price-current" }, bidPrice),
            React.createElement("div", { className: `instrument-header__price-change ${priceChangeClass}` },
                formattedChangeValue,
                "%")),
        React.createElement("div", { className: "instrument-header__price-history" },
            React.createElement("div", { className: "instrument-header__price-history-range" },
                React.createElement("div", { className: "instrument-header__price-history-range-title" }, dayRangeTitle),
                React.createElement("div", { className: "instrument-header__price-history-range-value" },
                    React.createElement(InstrumentHeaderValue, { value: dayRangeStats.from })),
                React.createElement("div", { className: `instrument-header__price-history-range-bar ${dayRangeBarCssClass}`, style: {
                        "--offset-from-start": dayRangeBarPinPosition,
                    } }),
                React.createElement("div", { className: "instrument-header__price-history-range-value" },
                    React.createElement(InstrumentHeaderValue, { value: dayRangeStats.to })),
                React.createElement("div", { className: "instrument-header__price-history-range-title" }, week52RangeTitle),
                React.createElement("div", { className: "instrument-header__price-history-range-value" },
                    React.createElement(InstrumentHeaderValue, { value: yearRangeStats.from })),
                React.createElement("div", { className: `instrument-header__price-history-range-bar ${yearRangeBarCssClass}`, style: {
                        "--offset-from-start": `${yearRangeBarPinPosition}`,
                    } }),
                React.createElement("div", { className: "instrument-header__price-history-range-value" },
                    React.createElement(InstrumentHeaderValue, { value: yearRangeStats.to }))),
            React.createElement("div", { className: "instrument-header__price-history-openclose" },
                React.createElement("div", null, openTitle),
                React.createElement("div", null, open)),
            React.createElement("div", { className: "instrument-header__price-history-openclose" },
                React.createElement("div", null, prevCloseTitle),
                React.createElement("div", null, prevClose))),
        React.createElement("div", { className: "instrument-header__quotes" },
            React.createElement(BidAskQuote, { title: bidTitle, className: `instrument-header__quotes-quote ${bidQuoteClass}`, linkUrl: bidAskLinksUrl },
                React.createElement(InstrumentHeaderValue, { value: bidPrice })),
            React.createElement(BidAskQuote, { title: askTitle, className: `instrument-header__quotes-quote ${askQuoteClass}`, linkUrl: bidAskLinksUrl },
                React.createElement(InstrumentHeaderValue, { value: askPrice })))));
};
const InstrumentHeaderValue = ({ value }) => {
    if (typeof value === "undefined") {
        return React.createElement(React.Fragment, null, "-");
    }
    return React.createElement(React.Fragment, null, value);
};
