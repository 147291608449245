import React, { useMemo } from "react";
import { usePageViewStyle } from "../../hooks";
export const ImgV2 = ({ smallUrl, mediumUrl, largeUrl, strictRender, src, ...rest }) => {
    const { isSmall, isMedium, isLarge } = usePageViewStyle();
    const chosenUrl = useMemo(() => {
        if (isMedium) {
            return mediumUrl;
        }
        if (isLarge) {
            return largeUrl;
        }
        if (isSmall) {
            return smallUrl;
        }
        return undefined;
    }, [isSmall, isMedium, isLarge, smallUrl, mediumUrl, largeUrl]);
    const renderSmallImage = () => (smallUrl && React.createElement("img", { src: smallUrl, ...rest }));
    const renderMediumImage = () => (mediumUrl && React.createElement("img", { src: mediumUrl, ...rest }));
    const renderLargeImage = () => (largeUrl && React.createElement("img", { src: largeUrl, ...rest }));
    const getAppropriateImageUrl = () => (chosenUrl || src || mediumUrl || largeUrl || smallUrl);
    if (strictRender) {
        if (isSmall) {
            return React.createElement(React.Fragment, null, renderSmallImage());
        }
        if (isMedium) {
            return React.createElement(React.Fragment, null, renderMediumImage());
        }
        if (isLarge) {
            return React.createElement(React.Fragment, null, renderLargeImage());
        }
        return React.createElement(React.Fragment, null);
    }
    return React.createElement("img", { src: getAppropriateImageUrl(), ...rest });
};
