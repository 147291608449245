import React, { useCallback } from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils";
import { Heading } from "../Heading";
import { webIcon } from "../svgIcons/webIcon";
import { mobileIcon } from "../svgIcons/mobileIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
SwiperCore.use([Navigation, Pagination]);
export const CardsGridWithFullWidthCenteredImagesAndTagsV2 = ({ title, cards, designStyles, columns }) => {
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    const renderCard = useCallback((card) => (React.createElement("div", { className: `${columns === "4" ? "cards-grid-with-full-width-centered-images-and-tags-v2__card col-4-card" : "cards-grid-with-full-width-centered-images-and-tags-v2__card col-3-card"}` },
        React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__top-end mb-20" },
            React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__image-container", style: { backgroundColor: card.cardBackgroundColor } },
                card.tags && React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__tags-strip" },
                    React.createElement("span", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__text-before-tags" }, card.textBeforeTags),
                    card.tags.split("|")?.map((tag, i) => (React.createElement("div", { key: i, className: "cards-grid-with-full-width-centered-images-and-tags-v2__tag centered-container" },
                        tag === "Web" ? webIcon : mobileIcon,
                        tag)))),
                React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__image-container__imageWrapper" },
                    React.createElement("img", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__image-container__imageWrapper__image", src: card.imageUrl, alt: card.imageAlt })))),
        React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__bottom-end" },
            React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__generic-title-description" },
                React.createElement("h5", { className: "h5-lp mb-12" }, card.title),
                React.createElement("p", { className: "copy-lp" }, card.description))))), [columns]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { style: { color: textColor }, className: "cards-grid-with-full-width-centered-images-and-tags-v2" },
                React.createElement("div", { className: "cards-grid-with-full-width-centered-images-and-tags-v2__title-container mb-40" },
                    React.createElement(Heading, { design: designStyles?.titleDesign }, title)),
                React.createElement("div", { className: `hide-small-ndb ${columns === "4" ? "cards-grid-with-full-width-centered-images-and-tags-v2__cards-container col-4" : "cards-grid-with-full-width-centered-images-and-tags-v2__cards-container col-3"}` }, cards.map(c => renderCard(c))),
                React.createElement("div", { className: "hide-medium-ndb hide-large-ndb" },
                    React.createElement(Swiper, { spaceBetween: 37, slidesPerView: 1.14, loop: true, pagination: { clickable: true }, speed: 2000, className: "awardsSli1derDark__swiper2" }, cards.map((c, i) => React.createElement(React.Fragment, null,
                        React.createElement(SwiperSlide, { key: i },
                            React.createElement("div", { className: "animated-image-boxes-grid__payment-slide-img-container222 bg-color-1pure-white" }, renderCard(c)))))))))));
};
