import React, { useCallback, useState } from "react";
import { getColors } from "../../utils/getColors";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { shortArrowIcon } from "../svgIcons/shortArrow";
export const InstantSwiper = ({ cards, backgroundColor, title, subtitle }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const { bgColor } = getColors(backgroundColor);
    const handleChangeBtnClick = useCallback((direction) => () => {
        if (direction === "left" && activeSlide > 0) {
            setActiveSlide(prev => prev - 1);
            return;
        }
        else if (direction === "right" && activeSlide < cards.length - 1) {
            setActiveSlide(prev => prev + 1);
            return;
        }
    }, [activeSlide, cards.length]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "instant-swiper-root" },
            React.createElement("div", { className: "instant-swiper-root__titles-section" },
                React.createElement("div", { className: "instant-swiper-root__title" },
                    React.createElement("h2", null, title)),
                React.createElement("div", { className: "instant-swiper-root__subtitle" },
                    React.createElement("p", null, subtitle))),
            React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
                React.createElement("div", { className: "instant-swiper-root__activeButtonsWrapper" }, cards.map((card, index) => (React.createElement("span", { key: `unique${card.title}`, className: `${index <= activeSlide ? "active-button" : ""} ` })))),
                React.createElement("div", { className: "instant-swiper-root__textsWrapper" },
                    React.createElement("h3", null, cards[activeSlide].title),
                    React.createElement("p", { dangerouslySetInnerHTML: { __html: cards[activeSlide].subtitle } })),
                React.createElement("div", { className: "instant-swiper-root__buttonsWrapper" },
                    React.createElement("span", { onClick: handleChangeBtnClick("left") }, shortArrowIcon({ fill: "000", width: 16, height: 16 })),
                    React.createElement("span", { onClick: handleChangeBtnClick("right") }, shortArrowIcon({ fill: "000", width: 16, height: 16 }))),
                React.createElement("div", { className: "instant-swiper-root__imageWrapper" },
                    React.createElement(Img, { src: cards[activeSlide].imageUrl }))))));
};
