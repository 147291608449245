import React from "react";
import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils";
import { VideoCardDeckSliderCard } from "./VideoCardDeckSliderCard/VideoCardDeckSliderCard";
import { ButtonV2 } from "../../ButtonV2";
export const VideoCardDeckSlider = ({ cards, backgroundColor, mainTitle, loadMoreUrl, loadMoreText, openInNewTabButton }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement("div", { style: { backgroundColor: bgColor }, className: "video-card-deck-slider-g-container-fullwidth-outer-background" },
        React.createElement(ContainerFW, { backgroundColor: bgColor, className: "video-card-deck-slider-g-container-fullwidth" },
            React.createElement("div", { className: "video-card-deck-slider video-card-d1eck-slider-g-container-fullwidth" },
                React.createElement(Container, null,
                    React.createElement("div", { className: "video-card-deck-slider__container" },
                        mainTitle && React.createElement("div", { className: "video-card-deck-slider__mainTitle" },
                            React.createElement("h2", null, mainTitle)),
                        React.createElement("div", { className: "video-card-deck-slider__swiperWrapper" },
                            React.createElement(Container, null,
                                React.createElement(Swiper, { navigation: {
                                        nextEl: ".arrow-next-selector",
                                        prevEl: ".arrow-prev-selector"
                                    }, modules: [Mousewheel], loop: false, mousewheel: true, breakpoints: {
                                        [370]: {
                                            slidesPerView: 1.2,
                                            spaceBetween: 24,
                                        },
                                        [601]: {
                                            slidesPerView: 2,
                                            spaceBetween: 24,
                                        },
                                        [1025]: {
                                            slidesPerView: 3.2,
                                            spaceBetween: 24,
                                        }
                                    } }, cards.map((card, i) => (React.createElement(SwiperSlide, { key: `slide-${i}` }, React.createElement(VideoCardDeckSliderCard, { ...card }))))))))),
                loadMoreUrl && React.createElement(ButtonV2, { href: loadMoreUrl, design: "ghost-medium-wbg", openInNewTab: openInNewTabButton }, loadMoreText || "Load more")))));
};
