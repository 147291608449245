import React, { useState, useCallback } from "react";
import { IconNoResults } from "../../svgIcons";
import { JobBoardPagination } from "../../JobBoard/JobBoardPagination/JobBoardPagination";
import { Col, Container, ContainerFW } from "../../global";
import { AcademyArticleCard, getTranslation } from "./AcademyArticleCard/AcademyArticleCard";
import { getColors } from "../../../utils";
import { useHandleWindowLocationPage } from "../../FilterAcademyCards/utils";
import { usePageViewStyle } from "../../../hooks";
export const AcademyArticleCardDeck = (props) => {
    const { viewStyle: view } = usePageViewStyle();
    const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
    const { bgColor, textColor } = getColors(props.backgroundColor);
    const [selectedFilterOption, setSelectedFilterOption] = useState(props?.filterOptions?.[0]);
    const articlesPerPage = 12;
    useHandleWindowLocationPage(currentPage, setCurrentPage);
    const applyAnyInitialFilters = () => {
        if (props?.filterOptions?.[0] && selectedFilterOption !== props?.filterOptions?.[0]) {
            return props.cards.filter(e => e.articleLevel === selectedFilterOption);
        }
        return props.cards;
    };
    const filteredCards = applyAnyInitialFilters();
    const selectedArticles = filteredCards.slice((currentPage - 1) * articlesPerPage, (currentPage) * articlesPerPage);
    const numOfPages = Math.ceil(filteredCards.length / articlesPerPage);
    const thisComponentRef = React.useRef(null);
    const setSelectedFilterOptionCallback = useCallback((event) => {
        setSelectedFilterOption(event.currentTarget.dataset.option);
        setCurrentPage(1);
    }, []);
    const handlePaginationChange = React.useCallback((i) => {
        setCurrentPage(i + 1);
    }, []);
    const renderFilterRadio = useCallback(() => (React.createElement(React.Fragment, null, props.filterOptions && React.createElement("div", { className: "academyArticleCardDeck__radio" }, props?.filterOptions?.map((option, i) => (React.createElement("div", { key: i, className: `academyArticleCardDeck__radio-option centered-container p-regular ${selectedFilterOption === option ? "active bg-color-dark-default" : ""}`, onClick: setSelectedFilterOptionCallback, "data-option": option }, getTranslation(option, props.cultureCode))))))), [props.cultureCode, props.filterOptions, selectedFilterOption, setSelectedFilterOptionCallback]);
    const pagination = useCallback(() => numOfPages > 1 && React.createElement("div", { className: "jobBoard__pagination" },
        React.createElement(JobBoardPagination, { currentPage: currentPage - 1, numOfPages: numOfPages, setCurrentPage: handlePaginationChange, isSmallScreen: view !== "large", linkPagination: true })), [numOfPages, currentPage, handlePaginationChange, view]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "newsCardDeck__controls" },
            props.filtersRibbonElement,
            props.filtersElement,
            React.createElement("div", { ref: thisComponentRef, style: { color: textColor }, className: "academyArticleCardDeck" },
                React.createElement(Container, null,
                    React.createElement("div", { className: "academyArticleCardDeck__title-and-radio" },
                        React.createElement("h4", { className: "academyArticleCardDeck__title h4-bold-small" },
                            props.articlesString,
                            " (",
                            filteredCards.length,
                            ")"),
                        renderFilterRadio()),
                    selectedArticles.length ?
                        React.createElement(React.Fragment, null,
                            !props.isListView && React.createElement("span", { className: "academyArticleCardDeck__cards-grid" }, selectedArticles.map((card, i) => (React.createElement(Col, { key: i },
                                React.createElement(AcademyArticleCard, { articleReadingTimeTimeUnit: props.articleReadingTimeTimeUnit, categoryLinks: props.categoryLinks, culture: props.culture, ...card, isListView: props.isListView, cultureCode: props.cultureCode }))))),
                            props.isListView && React.createElement("div", { className: "academyArticleCardDeck__gap" },
                                selectedArticles.map((card, i) => (React.createElement(AcademyArticleCard, { articleReadingTimeTimeUnit: props.articleReadingTimeTimeUnit, categoryLinks: props.categoryLinks, culture: props.culture, key: i, ...card, isListView: props.isListView, cultureCode: props.cultureCode }))),
                                " "),
                            pagination())
                        : React.createElement("div", { className: "alphabet-list__noresults" },
                            IconNoResults,
                            props.noResultsText))))));
};
