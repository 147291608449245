import React, { useState, useCallback } from "react";
import { IconNoResults } from "../../svgIcons";
import { JobBoardPagination } from "../../JobBoard/JobBoardPagination/JobBoardPagination";
import { Col, Container, ContainerFW } from "../../global";
import { AcademyArticleCard } from "./AcademyArticleCard/AcademyArticleCard";
import { getColors } from "../../../utils";
import { usePageViewStyle } from "../../../hooks";
export const AcademyArticleCardDeckPool = ({ isListView, cards, categoryLinks, filtersRibbonElement, filtersElement, culture, articleReadingTimeTimeUnit, articlesString, noResultsText, backgroundColor, filterOptions, currentPage, pageSize, totalArticlesCount, getPage }) => {
    const { viewStyle: view } = usePageViewStyle();
    const [currentPageNum, setCurrentPageNum] = useState(currentPage);
    const { bgColor, textColor } = getColors(backgroundColor);
    const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions?.[0]);
    const articlesPerPage = pageSize;
    const currentPageHandler = useCallback((i) => {
        getPage(i + 1);
        setCurrentPageNum(i + 1);
    }, [getPage]);
    const numOfPages = Math.ceil(totalArticlesCount / articlesPerPage);
    const thisComponentRef = React.useRef(null);
    const setSelectedFilterOptionCallback = useCallback((event) => {
        setSelectedFilterOption(event.currentTarget.dataset.option);
        setCurrentPageNum(0);
    }, []);
    const renderFilterRadio = useCallback(() => (React.createElement(React.Fragment, null, filterOptions && React.createElement("div", { className: "academyArticleCardDeck__radio" }, filterOptions?.map((option, i) => (React.createElement("div", { key: i, className: `academyArticleCardDeck__radio-option centered-container p-regular ${selectedFilterOption === option ? "active bg-color-dark-default" : ""}`, onClick: setSelectedFilterOptionCallback, "data-option": option }, option)))))), [filterOptions, selectedFilterOption, setSelectedFilterOptionCallback]);
    const pagination = useCallback(() => numOfPages > 1 && React.createElement("div", { className: "jobBoard__pagination" },
        React.createElement(JobBoardPagination, { currentPage: currentPageNum - 1, numOfPages: numOfPages, setCurrentPage: currentPageHandler, isSmallScreen: view !== "large", linkPagination: true })), [numOfPages, currentPageNum, currentPageHandler, view]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "newsCardDeck__controls" },
            filtersRibbonElement,
            filtersElement,
            React.createElement("div", { ref: thisComponentRef, style: { color: textColor }, className: "academyArticleCardDeck" },
                React.createElement(Container, null,
                    React.createElement("div", { className: "academyArticleCardDeck__title-and-radio" },
                        React.createElement("h4", { className: "academyArticleCardDeck__title h4-bold-small" },
                            articlesString,
                            " (",
                            totalArticlesCount,
                            ")"),
                        renderFilterRadio()),
                    cards.length ?
                        React.createElement(React.Fragment, null,
                            !isListView && React.createElement("span", { className: "academyArticleCardDeck__cards-grid" }, cards.map((card, i) => (React.createElement(Col, { key: i },
                                React.createElement(AcademyArticleCard, { articleReadingTimeTimeUnit: articleReadingTimeTimeUnit, categoryLinks: categoryLinks, culture: culture, ...card, isListView: isListView }))))),
                            isListView && React.createElement("div", { className: "academyArticleCardDeck__gap" },
                                cards.map((card, i) => (React.createElement(AcademyArticleCard, { articleReadingTimeTimeUnit: articleReadingTimeTimeUnit, categoryLinks: categoryLinks, culture: culture, key: i, ...card, isListView: isListView }))),
                                " "),
                            pagination())
                        : React.createElement("div", { className: "alphabet-list__noresults" },
                            IconNoResults,
                            noResultsText))))));
};
