import React, { useState, useEffect, useCallback, useRef } from "react";
import { YOUTUBE_IFRAME_ID_PREFIX, controlIframedVideo } from "../YouTubeVideoEmbed";
import { ConditionalPortal } from "./ConditionalPortal";
export const SimpleLightbox = (props) => {
    const [isLightBoxOn, setIsLightboxOn] = useState(false);
    const hasLightboxBeenOnAlreadyRef = useRef(false);
    const lightboxRef = useRef(null);
    const showLightbox = useCallback(() => {
        setIsLightboxOn(true);
    }, []);
    const hideLightbox = useCallback(() => {
        hasLightboxBeenOnAlreadyRef.current = true;
        setIsLightboxOn(false);
    }, []);
    useEffect(() => {
        if (lightboxRef.current) {
            if (!isLightBoxOn && hasLightboxBeenOnAlreadyRef.current) {
                const videoIframeEl = lightboxRef.current.querySelector(`.${YOUTUBE_IFRAME_ID_PREFIX}`);
                if (videoIframeEl) {
                    controlIframedVideo(videoIframeEl, "stop");
                }
            }
        }
    }, [isLightBoxOn]);
    useEffect(() => {
        document.body.style.overflow = isLightBoxOn ? "hidden" : "revert";
    }, [isLightBoxOn]);
    useEffect(() => {
        if (!isLightBoxOn) {
            return;
        }
        const keyEventListener = (event) => {
            if (event.key === "Escape" && isLightBoxOn) {
                hideLightbox();
            }
        };
        document.addEventListener("keydown", keyEventListener);
        return () => {
            document.removeEventListener("keydown", keyEventListener);
        };
    }, [isLightBoxOn]);
    return (React.createElement(ConditionalPortal, { isActivePortal: isLightBoxOn },
        React.createElement("div", { ref: lightboxRef, className: `simplelightbox ${isLightBoxOn ? "simplelightbox--on" : ""}`, role: isLightBoxOn ? "dialog" : "presentation", "aria-labelledby": "YouTube video" },
            React.createElement("div", { className: "simplelightbox__content-container simplelightbox__content-container--youtube" },
                isLightBoxOn ? (React.createElement("div", { className: "simplelightbox__close-icon-wrapper" },
                    React.createElement("div", { className: "simplelightbox__close-icon", onClick: hideLightbox, role: "button", "aria-label": "Close" }, closeIcon))) : React.createElement("div", { className: props.className, onClick: showLightbox, role: "button", "aria-label": "Open" }),
                props.children))));
};
const closeIcon = React.createElement("svg", { width: "44", height: "44", viewBox: "5 5 44 44", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#er4555lbla)" },
        React.createElement("path", { d: "m27.999 24.7 11.55-11.55 3.3 3.3L31.298 28l11.55 11.55-3.3 3.3-11.55-11.55-11.55 11.55-3.3-3.3L24.7 28 13.15 16.45l3.3-3.3 11.55 11.55z", fill: "#fff" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "er4555lbla" },
            React.createElement("path", { fill: "#fff", d: "M0 0h56v56H0z" }))));
