import React from "react";
import { StillHaveQuestionItem } from "./StillHaveQuestionItem";
import { ContainerFW, Container } from "../global";
import { Heading } from "../Heading";
import { getColors } from "../../utils";
export const StillHaveQuestionsItems = ({ items, mainTitle, subTitle, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "still-have-questions-items-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "still-have-questions-items-root__titleWrapper" },
                    React.createElement(Heading, { design: "h2-regular" }, mainTitle),
                    React.createElement("h5", { dangerouslySetInnerHTML: {
                            __html: subTitle || "",
                        } })),
                React.createElement("div", { className: "still-have-questions-items-root__itemContainer" }, items.length > 0 && items.map((item) => (React.createElement(StillHaveQuestionItem, { key: `unique${item.title}`, title: item.title, imgUrl: item.imgUrl, imgAlt: item.imgAlt, content: item.content, linkUrl: item.linkUrl, cardUrl: item.cardUrl }))))))));
};
