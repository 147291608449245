const mouseClickEvents = ["mousedown", "click", "mouseup"];
export const simulateMouseClick = (element) => {
    mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1
    })));
};
export const simulateMouseClickOnZendeskChatIcon = (e) => {
    const el = e.target.closest("A");
    if (el && e.currentTarget.contains(el)) {
        const element = document.querySelector("a[class=\"chat-toggle\"]");
        simulateMouseClick(element);
    }
};
export const handleOnUrlCalls = (providedUrl) => {
    window.open(providedUrl, "_blank");
};
export const handleCardClick = (cardUrl) => () => {
    if (cardUrl) {
        handleOnUrlCalls(cardUrl);
    }
};
export const determineContactItemType = (itemUrl) => itemUrl?.match(/^([A-Za-z]+):/)?.[1];
