import React, { useState, useEffect, useCallback } from "react";
import { Container, ContainerFW } from "../../global";
import Countdown from "react-countdown";
import { Heading } from "../../Heading";
import { Breadcrumbs } from "../../Breadcrumbs";
import { usePageViewStyle } from "../../../hooks";
import { ImgV2 } from "../../ImgV2";
export const CountdownBanner = (props) => {
    const [fullDate, setFullDate] = useState("2024-11-05T07:00:00");
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const [background, setBackground] = useState(props.backgroundImageURL);
    useEffect(() => {
        setBackground(isLarge ? props.backgroundImageURL : isMedium ? props.backgroundImageURLTablet : isSmall ? props.backgroundImageURLMobile : props.backgroundImageURL);
    }, [isSmall, isMedium, isLarge]);
    const countdown1 = useCallback((ren => React.createElement("span", { className: "countdown-banner__countdown-days-wrapper" },
        React.createElement("span", { className: "countdown-banner__countdown-days-text" }, props.daysLabel),
        React.createElement("span", { className: "countdown-banner__countdown-days-number" }, ren.days))), [props.daysLabel]);
    const countdown2 = useCallback((ren => React.createElement("span", { className: "countdown-banner__countdown-hours-wrapper" },
        React.createElement("span", { className: "countdown-banner__countdown-hours-text" }, props.hoursLabel),
        React.createElement("span", { className: "countdown-banner__countdown-hours-number" }, ren.hours))), [props.hoursLabel]);
    const countdown3 = useCallback((ren => React.createElement("span", { className: "countdown-banner__countdown-minutes-wrapper" },
        React.createElement("span", { className: "countdown-banner__countdown-minutes-text" }, props.minutesLabel),
        React.createElement("span", { className: "countdown-banner__countdown-minutes-number" }, ren.minutes))), [props.minutesLabel]);
    const countdown4 = useCallback((ren => React.createElement("span", { className: "countdown-banner__countdown-seconds-wrapper" },
        React.createElement("span", { className: "countdown-banner__countdown-seconds-text" }, props.secondsLabel),
        React.createElement("span", { className: "countdown-banner__countdown-seconds-number" }, ren.seconds))), [props.secondsLabel]);
    useEffect(() => {
        setFullDate(`${props.yearValue}-${props.monthValue}-${props.dateValue}T${props.hoursValue}:${props.minetsValue}:${props.secondsValue}`);
    }, [props.yearValue, props.monthValue, props.dateValue, props.hoursValue, props.minetsValue, props.secondsValue]);
    const optionalBreadCrumb = React.useCallback(() => {
        if (props.breadcrumbs !== undefined) {
            return React.createElement(React.Fragment, null,
                React.createElement(Breadcrumbs, { breadcrumbs: props.breadcrumbs, lightMode: props.backgroundImageURL === "white" }));
        }
        return React.createElement(React.Fragment, null);
    }, [props.backgroundImageURL, props.breadcrumbs]);
    return (React.createElement("div", { className: "countdown-banner", style: { "--background-image-url": `url(${background})` } },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                optionalBreadCrumb(),
                React.createElement("div", { className: "countdown-banner__container" },
                    React.createElement("h1", { className: "h1-regular-small", dangerouslySetInnerHTML: { __html: props.title } }),
                    React.createElement("div", { className: "countdown-banner__content" },
                        React.createElement(Countdown, { date: fullDate, renderer: countdown1 }),
                        React.createElement(Countdown, { date: fullDate, renderer: countdown2 }),
                        React.createElement("span", { className: "countdown-banner__countdown-colum" }, ":"),
                        React.createElement(Countdown, { date: fullDate, renderer: countdown3 }),
                        React.createElement("span", { className: "countdown-banner__countdown-colum" }, ":"),
                        React.createElement(Countdown, { date: fullDate, renderer: countdown4 })),
                    React.createElement("div", { className: "countdown-banner__text-content" },
                        React.createElement(Heading, { design: "h5-semi-bold" }, props.subTitle)))),
            React.createElement(ImgV2, { src: props.backgroundImageURLMobile, className: "countdown-banner__image-mobile" }))));
};
