import React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { CulturesContainer } from "./CulturesContainer";
export const TitleWithImageAndCountries = ({ title, imgUrl, imgAlt, backgroundColor, cultures }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
    React.createElement("div", { className: "title-with-image-and-countries-root" },
        React.createElement(Container, null,
            React.createElement("div", { className: "title-with-image-and-countries-root__contentWrapper" },
                React.createElement("h2", null, title),
                React.createElement("div", { className: "title-with-image-and-countries-root__contentWrapper__imageContainer" },
                    React.createElement(Img, { src: imgUrl, alt: imgAlt })),
                React.createElement("div", { className: "title-with-image-and-countries-root__contentWrapper__culturesWrapper" }, cultures.length > 0 && cultures.map((a) => (React.createElement(CulturesContainer, { key: `unique${a.title}`, title: a.title, itemInfo: a.itemInfo })))))))));
