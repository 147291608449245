import React from "react";
import { Heading } from "../../../Heading";
import { Img } from "../../../Img";
export const NewsCard = ({ imageURL, articleDate, articleTitle, articleSummary, articleAuthor, articleCategory, articleCategoryTranslated, articleURL, articleCfdTrading, isListView = false, articleImageAlt }) => {
    const publishDate = new Date(articleDate ? articleDate : "").toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
    articleImageAlt === "" ? articleImageAlt = articleTitle : articleImageAlt = articleImageAlt;
    return (React.createElement("div", { className: `newsCard ${isListView ? "newsCard--list" : ""}` },
        React.createElement(Img, { className: "newsCard__img", src: imageURL, alt: articleImageAlt }),
        React.createElement("div", { className: "newsCard__content" },
            React.createElement("div", { className: "newsCard__content-inner" },
                React.createElement("div", { className: "newsCard__published" },
                    articleCfdTrading && React.createElement("span", { className: "newsCard__cfd-trading" }, articleCfdTrading),
                    React.createElement("span", { className: "newsCard__category" }, articleCategory),
                    React.createElement("span", { className: "newsCard__date" }, publishDate)),
                React.createElement(Heading, { design: "h4-bold-small" },
                    React.createElement("a", { href: articleURL }, articleTitle))),
            React.createElement("p", { className: "newsCard__text" }, articleSummary),
            React.createElement("div", { className: "newsCard__author" }, articleAuthor))));
};
export const iconUser = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 8.067a2.447 2.447 0 0 1-2.434-2.434C5.566 4.3 6.666 3.2 8 3.2c1.333 0 2.433 1.1 2.433 2.433 0 1.334-1.1 2.434-2.433 2.434zm0-4.4c-1.1 0-1.967.9-1.967 1.966C6.033 6.7 6.933 7.6 8 7.6c1.066 0 1.966-.9 1.966-1.967A1.97 1.97 0 0 0 8 3.667z", fill: "#007C8C" }),
    React.createElement("path", { d: "M12.7 12.833a.287.287 0 0 1-.267-.266c0-2.434-2-4.434-4.433-4.434s-4.433 2-4.433 4.434a.287.287 0 0 1-.267.266.287.287 0 0 1-.267-.266A4.98 4.98 0 0 1 8 7.6a4.98 4.98 0 0 1 4.966 4.967.287.287 0 0 1-.266.266z", fill: "#007C8C" })));
export const iconBook = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 12.737a.227.227 0 0 1-.174-.077c-.666-.747-1.483-1.097-2.566-1.097-.747 0-1.494.25-2.217.744a.232.232 0 0 1-.363-.193V4.28c0-.077.04-.15.103-.193.803-.547 1.637-.824 2.48-.824 1.21 0 2.163.41 2.917 1.254a.24.24 0 0 1 .06.156v7.83c0 .097-.06.184-.15.217a.247.247 0 0 1-.084.017H8zm-2.74-1.64c1 0 1.826.28 2.506.853V4.763C7.11 4.06 6.31 3.733 5.26 3.733c-.714 0-1.424.227-2.117.674v7.283c.69-.397 1.4-.597 2.117-.597v.004z", fill: "#007C8C" }),
    React.createElement("path", { d: "M8 12.737a.247.247 0 0 1-.082-.017.231.231 0 0 1-.15-.217V4.67a.23.23 0 0 1 .06-.157c.75-.843 1.703-1.253 2.916-1.253.844 0 1.677.277 2.48.823a.237.237 0 0 1 .104.194v7.83a.232.232 0 0 1-.364.193c-.723-.493-1.47-.743-2.216-.743-1.084 0-1.9.346-2.567 1.096a.238.238 0 0 1-.173.077L8 12.737zm.234-7.974v7.187c.68-.573 1.507-.853 2.507-.853.717 0 1.427.2 2.117.596V4.407c-.694-.447-1.404-.674-2.117-.674-1.053 0-1.853.327-2.507 1.03z", fill: "#007C8C" })));
