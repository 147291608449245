import React from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
export const InnovationBlockThinkZero = (props) => (React.createElement(ContainerFW, null,
    React.createElement(Container, null,
        React.createElement("div", { className: "innovation-block innovation-block--demo innovation-block--thinkzero" },
            React.createElement("div", { className: "innovation-block__content" },
                React.createElement("h2", { className: "innovation-block__title", dangerouslySetInnerHTML: { __html: props.title } }),
                React.createElement(Img, { src: props.imageUrl, alt: props.title, className: "innovation-block__image--mobile" }),
                React.createElement("p", { className: "innovation-block__paragraph" }, props.paragraph),
                React.createElement("div", { className: "innovation-block-forex__footer" },
                    React.createElement("p", { className: "innovation-block-forex__footer-title innovation-block__footer-title--md-centered" }, props.footerTitle),
                    React.createElement("div", { className: "innovation-block-forex__buttons" }, props.footerButtons.map((button, i) => (React.createElement("a", { key: `innovation-block-forex__footer-button-${i}`, href: button.url, className: "innovation-block-forex__footer-button" },
                        React.createElement(Img, { className: "innovation-block-forex__footer-button-image", src: button.imageUrl, alt: button.imageAlt }))))))),
            React.createElement(Img, { src: props.imageUrl, alt: props.title, className: "innovation-block__image" })))));
