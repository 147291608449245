import React, { useCallback } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImgV2 } from "../ImgV2/ImgV2";
import { Heading } from "../Heading/Heading";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils";
export const AnimatedImageBoxesGridSimple = ({ markets, social, paymentMethods, designStyles }) => {
    if (!designStyles) {
        designStyles = {
            titleDesign: "h3-bold",
            backgroundColor: "white"
        };
    }
    const paymentRoller = useCallback((items, reverseDirection = false) => (React.createElement(Swiper, { spaceBetween: 37, slidesPerView: 2, loop: true, autoplay: {
            delay: 0,
            disableOnInteraction: false,
            reverseDirection,
        }, breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 175
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 100
            },
        }, speed: 2000, modules: [Autoplay], className: "animated-image-boxes-grid-simple__swiper" }, [...items,
        ...items,
        ...items].filter(item => item.image).map((item, index) => (React.createElement(SwiperSlide, { key: index },
        React.createElement("div", { className: "animated-image-boxes-grid-simple__payment-slide-img-container bg-color-pure-white" },
            React.createElement(ImgV2, { src: item.image, alt: item.alt, loading: "eager" }))))))), []);
    const { bgColor, textColor } = getColors(designStyles?.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { style: { color: textColor }, className: "animated-image-boxes-grid-simple" },
                React.createElement("div", { className: "animated-image-boxes-grid-simple__markets-container bg-color-neutral-accent-background" },
                    React.createElement("div", { className: "centered-container-column animated-image-boxes-grid-simple__title-container" },
                        React.createElement("p", { className: "caption-bold" }, markets.upperTitleMarkets),
                        React.createElement(Heading, { design: designStyles?.titleDesign ? designStyles?.titleDesign : "h3-bold" }, markets.titleMarkets)),
                    React.createElement("div", { className: "centered-container" },
                        React.createElement(ImgV2, { className: "centered-container animated-image-boxes-grid-simple__main-image", smallUrl: markets?.mainImageSmall, largeUrl: markets?.mainImageLarge, alt: markets?.mainImageAlt }))),
                React.createElement("div", { className: "animated-image-boxes-grid-simple__social-container bg-color-neutral-accent-background" },
                    React.createElement("div", { className: "centered-container-column animated-image-boxes-grid-simple__title-container" },
                        React.createElement("p", { className: "caption-bold" }, social.upperTitleSocial),
                        React.createElement(Heading, { design: designStyles?.titleDesign ? designStyles?.titleDesign : "h3-bold" }, social.titleSocial)),
                    React.createElement("div", { className: "centered-container" },
                        React.createElement("div", { className: "animated-image-boxes-grid-simple__social-img-container" },
                            React.createElement(ImgV2, { src: social.imageMain, alt: social.altMain, className: "animated-image-boxes-grid-simple__social-img" })))),
                React.createElement("div", { className: "animated-image-boxes-grid-simple__payment-container bg-color-neutral-accent-background" },
                    React.createElement("div", { className: "centered-container-column animated-image-boxes-grid-simple__title-container" },
                        React.createElement("p", { className: "caption-bold" }, paymentMethods.upperTitlePayment),
                        React.createElement(Heading, { design: designStyles?.titleDesign }, paymentMethods.titlePayment)),
                    paymentRoller(paymentMethods.paymentImagesUpperRow),
                    paymentRoller(paymentMethods.paymentImagesLowerRow, true))))));
};
