import React, { useState, useRef, useCallback } from "react";
export const Draggable = ({ children }) => {
    const initialRef = undefined;
    const ourRef = useRef(initialRef);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    const handleDragStartCallback = useCallback(((e) => {
        if (!ourRef.current) {
            return;
        }
        const slider = ourRef.current?.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
        setIsMouseDown(true);
        document.body.style.cursor = "grabbing";
    }), []);
    const handleDragEndCallback = useCallback((() => {
        setIsMouseDown(false);
        if (!ourRef.current) {
            return;
        }
        document.body.style.cursor = "default";
    }), []);
    const handleDragCallback = useCallback(((e) => {
        if (!isMouseDown || !ourRef.current) {
            return;
        }
        e.preventDefault();
        const slider = ourRef.current?.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }), [isMouseDown]);
    return (React.createElement("div", { ref: ourRef, onMouseDown: handleDragStartCallback, onMouseUp: handleDragEndCallback, onMouseMove: handleDragCallback }, children));
};
