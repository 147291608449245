import React from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
import { webIcon } from "../svgIcons/webIcon";
import { mobileIcon } from "../svgIcons/mobileIcon";
import { Heading } from "../Heading";
const WhySpreadBetWithUsCard = ({ title, description, tags, imageUrl, imageAlt, iconUrl, iconAlt }) => (React.createElement("div", { className: "why-spread-bet-with-us__card" },
    React.createElement("div", { className: "why-spread-bet-with-us__card-content-upper" },
        iconUrl && React.createElement(React.Fragment, null,
            React.createElement("img", { className: "why-spread-bet-with-us__icon icon", src: iconUrl, alt: iconAlt }),
            " ",
            React.createElement("div", null)),
        React.createElement("h5", { className: "h5-lp" }, title),
        React.createElement("div", { className: "copy-lp $dark-80" }, description),
        tags !== "" && React.createElement("div", { className: "why-spread-bet-with-us__tags-container" }, tags?.split("|").map((tag, i) => (React.createElement("span", { key: i, className: "why-spread-bet-with-us__tag" },
            tag === "Mobile" ? mobileIcon : "",
            tag === "Web" ? webIcon : "",
            tag))))),
    imageUrl && React.createElement("img", { className: "why-spread-bet-with-us__img ", src: imageUrl, alt: imageAlt })));
export const WhySpreadBetWithUs = ({ title, cards, disclaimer, designStyles }) => {
    const { bgColor } = getColors(designStyles?.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: "why-spread-bet-with-us" },
                React.createElement("div", { className: "centered-container" },
                    React.createElement(Heading, { design: designStyles?.titleDesign }, title)),
                React.createElement("div", null),
                React.createElement("div", { className: "why-spread-bet-with-us__cards-grid" }, cards.map((card, i) => React.createElement(WhySpreadBetWithUsCard, { key: i, ...card }))),
                React.createElement("div", null),
                React.createElement("p", { className: "why-spread-bet-with-us__custom-disclaimer-font" }, disclaimer)))));
};
