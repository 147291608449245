import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils";
import { NumberedListImageCard } from "./NumberedListImageCard/NumberedListImageCard";
export const NumberedListImageCardDeck = ({ title, subtitle, cards, backgroundColor }) => {
    const colors = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: colors.bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "numbered-list-card-deck" },
                React.createElement("h2", null, title),
                React.createElement("h5", { className: "h5-semi-bold" }, subtitle),
                React.createElement("div", { className: "numbered-list-card-deck__cards" }, cards.map((card, index) => React.createElement(NumberedListImageCard, { key: `numbered-list-card-${index}`, ...card })))))));
};
