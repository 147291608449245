import React from "react";
import { getColors } from "../../utils/getColors";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { CardWithImageAndTitleAnimated } from "./CardWithImageAndTitleAnimated/CardWithImageAndTitleAnimated";
export const CardDeckWithImageAndTitleAnimated = ({ title, subTitle, cards, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "card-deck-with-image-and-title-animated-root" },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "card-deck-with-image-and-title-animated-root__container" },
                    React.createElement("div", { className: "card-deck-with-image-and-title-animated-root__container__titleWrapper" },
                        React.createElement(Heading, { design: "h2-regular" }, title),
                        React.createElement("p", { className: "card-deck-with-image-and-title-animated-root__container__titleWrapper--subTitle", dangerouslySetInnerHTML: { __html: subTitle } })),
                    React.createElement("div", { className: "card-deck-with-image-and-title-animated-root__container__cardsWrapper" }, cards.length > 0 && cards.map((card) => (React.createElement(CardWithImageAndTitleAnimated, { key: `unique${card.cardSubTitle}`, cardTitle: card.cardTitle, cardSubTitle: card.cardSubTitle, cardAnimation: card.cardAnimation, cardColor: card.cardColor })))))))));
};
