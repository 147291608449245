import * as React from "react";
import { ContainerFW } from "../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination } from "swiper";
import { Img } from "../Img";
export const DemoAccountTabsSlider = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "careerTabsSlider demoAccountTabsSlider" },
        React.createElement("h2", { className: "demoAccountTabsSlider__title" }, props.title),
        props.text && (React.createElement("p", { dangerouslySetInnerHTML: { __html: props.text } })),
        React.createElement("div", { className: "careerTabsSlider__sliderWrap" },
            React.createElement("div", { className: "careerTabsSlider__customPagination" }),
            React.createElement(Swiper, { effect: "fade", pagination: {
                    el: ".careerTabsSlider__customPagination",
                    clickable: true,
                    renderBullet: (index, className) => `<h2 class="careerTabsSlider__bullets swiper-pagination-bullet ${className}">${props.cards[index].title}</h2>`,
                }, modules: [EffectFade, Pagination], className: "careerTabsSlider__swiper" }, props.cards.map((card, index) => (React.createElement(SwiperSlide, { key: index },
                React.createElement("div", { className: "careerTabsSlider__card" },
                    React.createElement(Img, { src: card.imageUrl, alt: card.title }),
                    React.createElement("div", { className: "careerTabsSlider__card-text" },
                        React.createElement("h4", null, card.title),
                        React.createElement("p", null, card.text),
                        card.linkTitle && (React.createElement("a", { href: card.linkUrl },
                            card.linkTitle,
                            " ",
                            arrowIcon()))))))))))));
const arrowIcon = () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "#007c8c", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#6cv15a3sra)" },
        React.createElement("path", { d: "m16.172 11-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2h12.172z", fill: "inherit" })),
    React.createElement("defs", null,
        React.createElement("clipPath", null,
            React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
