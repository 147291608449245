import React, { useCallback } from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
import { OtherCard } from "./OtherCard/OtherCard";
export const OtherAccountsVariationCardsDeck = ({ title, subTitle, imageUrl, cards, backgroundColor, twoColumns }) => {
    const { bgColor } = getColors(backgroundColor);
    const handleConditionalHeader = useCallback(() => {
        if (title || subTitle) {
            return (React.createElement("div", { className: "other-accounts-variation-cards-deck_headerWrapper" },
                React.createElement("div", { className: "other-accounts-variation-cards-deck_textsWrapper" },
                    title && React.createElement(Heading, { design: "h2-regular" }, title),
                    subTitle && React.createElement(Heading, { design: "h5-semi-bold" }, subTitle)),
                imageUrl && React.createElement("div", { className: "other-accounts-variation-cards-deck_imgWrapper" },
                    React.createElement(ImgV2, { src: imageUrl }))));
        }
        return React.createElement(React.Fragment, null);
    }, [imageUrl, subTitle, title]);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "other-accounts-variation-cards-deck_root" },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: ` ${twoColumns && "centered-container"}` }, handleConditionalHeader()),
                React.createElement("div", { className: `other-accounts-variation-cards-deck_cardsWrapper ${twoColumns}` }, cards.length > 0 && cards.map((a) => (React.createElement(OtherCard, { key: `unique${a.title}`, ...a }))))))));
};
