import React from "react";
import { Container, ContainerFW } from "../global";
import { getColors } from "../../utils/getColors";
import { SectionWithAwardsCard } from "./SectionWithAwardsCard";
export const SectionWithAwards = ({ cards, title, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "section-with-awards-root" },
            React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
                React.createElement("h2", null, title),
                React.createElement("div", { className: "section-with-awards-root__cardsWrapper" }, cards.length > 0 && cards.map((card) => React.createElement(SectionWithAwardsCard, { key: `unique${card.content}`, ...card })))))));
};
