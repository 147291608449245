export const sanitizeJSON = (key, value) => {
    if (value === undefined || typeof value === "undefined") {
        return null;
    }
    if (typeof value === "number" && !isFinite(value)) {
        return null;
    }
    return value;
};
export const serializeQuery = (queryObject, keysToRemove) => {
    const copiedQueryObject = { ...queryObject };
    if (Array.isArray(keysToRemove)) {
        keysToRemove.forEach(key => delete copiedQueryObject[key]);
    }
    return JSON.stringify(copiedQueryObject, sanitizeJSON);
};
