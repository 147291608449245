import React from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import { getColors } from "../../utils/getColors";
export const BrokerLocationMap = ({ title, subtitle, imageUrl, imageAlt, backgroundColor }) => {
    const { textColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "broker-location-map", style: { "--text-color": textColor } },
                React.createElement("h2", { className: "broker-location-map__title", dangerouslySetInnerHTML: { __html: title } }),
                React.createElement("h6", { className: "broker-location-map__subtitle", dangerouslySetInnerHTML: { __html: subtitle } }),
                React.createElement(Img, { className: "broker-location-map__mapImage", alt: imageAlt, src: imageUrl })))));
};
