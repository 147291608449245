import React from "react";
import { getColors } from "../../utils/getColors";
import { FaqAccordionList } from "../FaqAccordionList";
import { Container, ContainerFW } from "../global";
import { SideBarSection } from "./SideBarSection";
export const SupportBody = ({ faqTitle, faqItems, sideBarProps, itemTitle, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "support-body-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "support-body-root__wrapper" },
                    React.createElement("div", { className: "support-body-root__wrapper__sideBar" },
                        React.createElement(SideBarSection, { sideBarInfo: sideBarProps, itemTitle: itemTitle })),
                    React.createElement(FaqAccordionList, { title: faqTitle, items: faqItems }))))));
};
