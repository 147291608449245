import React from "react";
import { ButtonV2 } from "../../../ButtonV2";
import { handleLinkTarget } from "../../../../utils";
export const ButtonWrapper = ({ buttonHref, buttonLabel, linkHref, linkLabel, openInNewTabButton, linkOpenInNewTab }) => {
    const linkHrefTarget = handleLinkTarget(linkOpenInNewTab);
    return (React.createElement("div", { className: "tradeWithComponent-buttonWrapper" },
        buttonLabel && React.createElement(ButtonV2, { design: "cta-large-dbg", href: buttonHref, openInNewTab: openInNewTabButton },
            " ",
            buttonLabel),
        linkLabel && (React.createElement("span", { className: "tradeWithComponent-buttonWrapper-linkWrapper" },
            React.createElement("a", { href: linkHref, target: linkHrefTarget }, linkLabel)))));
};
