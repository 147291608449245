import React from "react";
import { getColors } from "../../utils";
import { Container, ContainerFW } from "../global";
import { StillHaveQuestionItem } from "../StillHaveQuestionsItems/StillHaveQuestionItem";
export const ContactOptions = ({ contactOptions, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "contact-options-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "contact-options-root__itemWrapper" }, contactOptions.length > 0 && contactOptions.map((option) => (React.createElement(StillHaveQuestionItem, { key: `unique${option.title}`, title: option.title, imgUrl: option.imgUrl, imgAlt: option.imgAlt, linkUrl: option.linkUrl }))))))));
};
