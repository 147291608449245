import * as React from "react";
import ".";
import { Container, ContainerFW } from "../global";
import { ImgV2 } from "../ImgV2/ImgV2";
import { Button } from "../Button";
import { usePageViewStyle } from "../../hooks";
import { getColors } from "../../utils/getColors";
import { LoadingSpinner } from "../LoadingSpinner";
import HCaptcha from "@hcaptcha/react-hcaptcha";
export const ContactFormBanner = (props) => {
    const { isLarge } = usePageViewStyle();
    const initialFormData = {
        region: props.regionOptions[0],
        fullName: "",
        email: "",
        mobilePhone: "",
        formFile: [],
    };
    const initialValidationHints = {
        region: false,
        fullName: false,
        email: false,
        mobilePhone: false,
        runOnInput: false,
    };
    const OverlayState = {
        HIDDEN: "HIDDEN",
        WAITING: "WAITING",
        ERROR: "ERROR",
        SUCCESS: "SUCCESS",
    };
    const { bgColor } = getColors(props.backgroundColor);
    const [dataToSend, setDataToSend] = React.useState();
    const [validationHints, setValidationHints] = React.useState(initialValidationHints);
    const [generalError, setGeneralError] = React.useState("");
    const [generalSuccess, setGeneralSuccess] = React.useState("");
    const [overlayState, setOverlayState] = React.useState(OverlayState.HIDDEN);
    const [isactiveSubmitBtn, setIsactiveSubmitBtn] = React.useState(true);
    const [formData, setFormData] = React.useState(initialFormData);
    const overlayRef = React.useRef(null);
    const captchaRef = React.useRef(null);
    const handleCaptchaError = React.useCallback(() => {
        setGeneralError(props.generalError);
    }, [props.generalError]);
    const { region, fullName, email, mobilePhone } = formData;
    const isValidName = (fullName) => !/[!@#$%&=_~<>?:1234567890]/.test(fullName) && fullName.length <= 300 && fullName.length > 0;
    const isValidPhone = (phone) => /^\+?[0-9]([-\s]?\d)*$/.test(phone) && phone.length <= 30;
    const isValidEmail = (email) => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email) && email.length <= 300;
    const isValidRegion = React.useCallback((region) => props.regionOptions[0] !== region, [props.regionOptions]);
    const [captchaSize, setCaptchaSize] = React.useState("invisible");
    const [token, setToken] = React.useState();
    const handleCaptchaSize = React.useCallback((value) => ((setCaptchaSize(value))), []);
    React.useEffect(() => {
        if (validationHints.runOnInput) {
            const newValidationHints = {
                ...validationHints,
                fullName: !isValidName(formData.fullName),
                email: !isValidEmail(formData.email),
                mobilePhone: !isValidPhone(formData.mobilePhone),
                region: !isValidRegion(formData.region),
            };
            setValidationHints(newValidationHints);
        }
    }, [formData]);
    const inputHandler = React.useCallback((e) => {
        setGeneralError("");
        setGeneralSuccess("");
        const { name, value } = e.target;
        if (isactiveSubmitBtn) {
            setFormData((formData) => ({ ...formData, [name]: value }));
        }
    }, [isactiveSubmitBtn]);
    const resetForm = React.useCallback(() => {
        setValidationHints(initialValidationHints);
        setTimeout(() => {
            setFormData(initialFormData);
        }, 10);
        setGeneralError("");
        setIsactiveSubmitBtn(true);
    }, []);
    const constructRequestFormData = React.useCallback((captchaResponse) => {
        const requestFormData = new FormData();
        requestFormData.append("captcha_response", captchaResponse ? captchaResponse : "");
        for (const key in formData) {
            if (key !== "formFile") {
                requestFormData.append(key, formData[key]);
            }
            else {
                const files = formData[key];
                files.forEach((file) => {
                    requestFormData.append("formFile", file);
                });
            }
        }
        props.campaignId && requestFormData.append("campaignId", props.campaignId);
        return requestFormData;
    }, [formData]);
    const getValidation = React.useCallback(() => {
        const newValidationHints = {
            ...validationHints,
            fullName: !isValidName(formData.fullName),
            email: !isValidEmail(formData.email),
            mobilePhone: !isValidPhone(formData.mobilePhone),
            region: !isValidRegion(formData.region),
        };
        return { valid: !(Object.values({ ...newValidationHints, runOnInput: false }).some(value => value === true)), newValidationHints };
    }, [validationHints, formData.fullName, formData.email, formData.mobilePhone, formData.region, isValidRegion]);
    const setOverlaySucess = React.useCallback(() => {
        setGeneralSuccess(props.generalSuccess);
        setOverlayState(OverlayState.SUCCESS);
        resetForm();
    }, [OverlayState.SUCCESS, props.generalSuccess, resetForm]);
    const setOverlayError = React.useCallback(() => {
        setIsactiveSubmitBtn(true);
        setOverlayState(OverlayState.ERROR);
        setGeneralError(props.generalError);
    }, [OverlayState.ERROR, props.generalError]);
    const sendRequest = React.useCallback(async () => {
        setOverlayState(OverlayState.WAITING);
        try {
            const r = await fetch("contact-us", {
                method: "POST",
                body: dataToSend
            });
            if (r.status === 200) {
                setOverlaySucess();
            }
            else {
                setOverlayError();
            }
        }
        catch {
            setOverlayError();
        }
    }, [OverlayState.WAITING, dataToSend, setOverlayError, setOverlaySucess]);
    const handleCaptchaResponse = React.useCallback(() => (captchaRef.current?.execute()), []);
    const submitHandler = React.useCallback(async (e) => {
        e.preventDefault();
        setIsactiveSubmitBtn(false);
        setGeneralSuccess("");
        const { valid, newValidationHints } = getValidation();
        if (!valid) {
            newValidationHints.runOnInput = true;
            setValidationHints(newValidationHints);
            setIsactiveSubmitBtn(true);
            return;
        }
        const captchaString = handleCaptchaResponse();
        const requestFormData = constructRequestFormData(captchaString || "");
        handleCaptchaSize("normal");
        setDataToSend(requestFormData);
        return;
    }, [constructRequestFormData, getValidation, handleCaptchaResponse, handleCaptchaSize]);
    const closeSendingOverlay = React.useCallback(() => {
        setOverlayState(OverlayState.HIDDEN);
        setToken(undefined);
        handleCaptchaSize("invisible");
    }, [OverlayState.HIDDEN, handleCaptchaSize]);
    const RequiredStar = React.useCallback(function RequiredStar() {
        return React.createElement("span", { className: "contact-form__required-star" }, "*");
    }, []);
    const RequiredLabel = React.useCallback(function RequiredLabel({ field }) {
        return (validationHints[field] && React.createElement("span", { className: "contact-form__required-error" }, props.requiredFieldHint));
    }, [validationHints]);
    const SubmitBtn = React.useCallback(() => React.createElement(Button, { label: props.submitLabel, primary: true, onClick: submitHandler, classes: `${!isactiveSubmitBtn && "contact-form__disabled"} ` }), [props.submitLabel, submitHandler, isactiveSubmitBtn]);
    React.useEffect(() => {
        function handleClickOutside(event) {
            if (overlayRef.current && !overlayRef.current.contains(event.target)) {
                if ((overlayState === OverlayState.ERROR) || (overlayState === OverlayState.SUCCESS)) {
                    closeSendingOverlay();
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [overlayRef, overlayState]);
    React.useEffect(() => {
        if (token && dataToSend !== undefined) {
            void sendRequest();
            setToken(undefined);
            setDataToSend(undefined);
        }
    }, [dataToSend, sendRequest, token]);
    const OverlayMessage = React.useCallback(() => (React.createElement(React.Fragment, null,
        " ",
        overlayState === OverlayState.WAITING && React.createElement(React.Fragment, null,
            React.createElement("span", { className: "contact-form__msg-overlay" }, props.popUpFormIsBeingSendMessage)),
        overlayState === OverlayState.ERROR && React.createElement(React.Fragment, null,
            React.createElement("span", { className: "contact-form__msg-overlay" }, props.generalError)),
        overlayState === OverlayState.SUCCESS && React.createElement(React.Fragment, null,
            React.createElement("span", { className: "contact-form__msg-overlay" }, props.popUpFormSucessfullySentMessage)))), [overlayState]);
    const Overlay = React.useCallback(() => (React.createElement(React.Fragment, null, overlayState !== OverlayState.HIDDEN &&
        React.createElement("div", { className: "contact-form__loading-overlay" },
            React.createElement("div", { ref: overlayRef, className: "contact-form__loading-popup" },
                React.createElement("div", { className: "contact-form__loading-popup-msg-container" },
                    React.createElement(OverlayMessage, null)),
                React.createElement("span", { className: "contact-form__spinner-container" }, overlayState === OverlayState.WAITING && React.createElement(React.Fragment, null,
                    React.createElement(LoadingSpinner, null))),
                overlayState !== OverlayState.WAITING && React.createElement(React.Fragment, null,
                    React.createElement(Button, { label: props.popUpOkButtonLabel, primary: true, onClick: closeSendingOverlay })))))), [OverlayMessage, OverlayState.HIDDEN, OverlayState.WAITING, closeSendingOverlay, overlayState, props.popUpOkButtonLabel]);
    const handleCaptchaOnVerify = (token) => (setToken(token));
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "contact-form-banner" },
                React.createElement("div", { className: "contact-form-banner__text-wrapper" },
                    React.createElement("h3", { className: "contact-form-banner__text-wrapper__title" }, props.title),
                    React.createElement("div", { className: "contact-form-banner__text-wrapper__subtitle", dangerouslySetInnerHTML: {
                            __html: props.subTitle,
                        } }),
                    isLarge && React.createElement("div", { className: "contact-form-banner__text-wrapper__image" },
                        React.createElement(ImgV2, { largeUrl: props.imageUrlDesktop, alt: props.imageAlt }))),
                React.createElement("div", { className: "contact-form-banner__form-wrapper" },
                    React.createElement(Overlay, null),
                    React.createElement("div", { className: "contact-form-banner__field contact-form-banner__field--half firstName-input" },
                        React.createElement("label", { htmlFor: "fullName", className: "contact-form-banner__label contact-form-banner__label--required" },
                            props.nameLabel,
                            React.createElement(RequiredStar, null),
                            React.createElement(RequiredLabel, { field: "fullName" })),
                        React.createElement("input", { onChange: inputHandler, value: fullName, type: "text", name: "fullName", id: "fullName", placeholder: props.namePlaceholder })),
                    React.createElement("div", { className: "contact-form-banner__field contact-form-banner__field--half email-input" },
                        React.createElement("label", { htmlFor: "email", className: "contact-form-banner__label contact-form-banner__label--required" },
                            props.emailLabel,
                            React.createElement(RequiredStar, null),
                            React.createElement(RequiredLabel, { field: "email" })),
                        React.createElement("input", { onChange: inputHandler, value: email, type: "email", name: "email", id: "email", placeholder: props.emailPlaceholder })),
                    React.createElement("div", { className: "contact-form-banner__field contact-form-banner__field--half mobilePhone-input" },
                        React.createElement("label", { htmlFor: "mobilePhone", className: "contact-form-banner__label contact-form-banner__label--required" },
                            props.phoneLabel,
                            React.createElement(RequiredStar, null),
                            React.createElement(RequiredLabel, { field: "mobilePhone" })),
                        React.createElement("input", { onChange: inputHandler, value: mobilePhone, type: "text", name: "mobilePhone", id: "mobilePhone", placeholder: props.phonePlaceholder })),
                    React.createElement("div", { className: "contact-form-banner__field contact-form-banner__field--half region-input" },
                        React.createElement("label", { htmlFor: "region", className: "contact-form-banner__label contact-form-banner__label--required" },
                            props.regionLabel,
                            React.createElement(RequiredStar, null),
                            React.createElement(RequiredLabel, { field: "region" })),
                        React.createElement("select", { onChange: inputHandler, name: "region", id: "region", value: region }, props.regionOptions.map((option, i) => (React.createElement("option", { key: `region-option-${i}`, value: option, disabled: i === 0 }, option))))),
                    React.createElement("div", { className: "contact-form__field captcha-input" },
                        React.createElement(HCaptcha, { sitekey: props.hcaptchaKey, onError: handleCaptchaError, ref: captchaRef, size: captchaSize, languageOverride: props.hcaptchaLanguageCode, onVerify: handleCaptchaOnVerify })),
                    generalError && React.createElement("div", { className: "contact-form__general-error-label" }, generalError),
                    generalSuccess && React.createElement("div", { className: "contact-form__general-success-label" }, generalSuccess),
                    React.createElement("div", { className: "contact-form-banner__button" },
                        React.createElement(SubmitBtn, null)))))));
};
