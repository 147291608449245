import React, { useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
import { Img } from "../../Img";
import { Heading } from "../../Heading";
export const SimpleBanner = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    const rollingTitle = useCallback((items) => (React.createElement(React.Fragment, null,
        React.createElement(Swiper, { spaceBetween: 11, slidesPerView: 1, loop: true, allowTouchMove: false, noSwiping: true, autoplay: {
                delay: 1000,
                disableOnInteraction: false,
            }, direction: "vertical", speed: 300, modules: [Autoplay], className: "simple-banner__swiper-container" }, items.map((item, index) => (React.createElement(SwiperSlide, { key: index },
            React.createElement("div", null,
                React.createElement(Heading, { design: "h2-regular" }, item)))))))), []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { className: "simple-banner" },
                React.createElement("div", { className: "simple-banner__image-container" },
                    React.createElement(Img, { src: props.imageURL, alt: props.imageURLAlt }),
                    React.createElement("div", { className: "simple-banner__text-container" },
                        React.createElement("div", { className: "simple-banner__title-container centered-container" }, rollingTitle(props.rollingTitles)),
                        React.createElement("div", { className: "simple-banner__description-container" },
                            React.createElement("p", null, props.subTitle))))))));
};
