import React, { useCallback, useState } from "react";
import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, ContainerFW } from "../global";
import { IconChevronDown } from "../svgIcons";
import { CardDescription } from "./CardDescription/CardDescription";
import { EmployeeTestimonialSliderCard } from "./EmployeeTestimonialSliderCard/EmployeeTestimonialSliderCard";
import { getColors } from "../../utils";
export const EmployeeTestimonialSlider = ({ mainTitle, mainDescription, cards, backgroundColor }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { bgColor = "#F2F6F6" } = getColors(backgroundColor);
    const handleCurrentIndex = useCallback((swiper) => {
        setCurrentIndex(swiper.realIndex);
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "employee-testimonial-slider__container" },
            React.createElement(Container, null,
                React.createElement("div", { className: "employee-testimonial-slider" },
                    React.createElement("div", { className: "description-container" },
                        React.createElement(CardDescription, { mainTitle: mainTitle, mainDescription: mainDescription }),
                        React.createElement("span", { className: "employee-testimonial-slider__arrowsWrapper" },
                            React.createElement("a", { className: "arrow-prev-selector" }, IconChevronDown({})),
                            React.createElement("p", { className: "employee-testimonial-slider__arrowsWrapper__currentPage" },
                                currentIndex + 1,
                                " of ",
                                cards.length),
                            React.createElement("a", { className: "arrow-next-selector" }, IconChevronDown({})))),
                    React.createElement("div", { className: "employee-testimonial-slider__swiperWrapper" },
                        React.createElement(Container, null,
                            React.createElement(Swiper, { navigation: {
                                    nextEl: ".arrow-next-selector",
                                    prevEl: ".arrow-prev-selector"
                                }, modules: [Mousewheel], onRealIndexChange: handleCurrentIndex, loop: false, mousewheel: false, breakpoints: {
                                    [370]: {
                                        slidesPerView: 1,
                                        spaceBetween: 24,
                                    },
                                    [601]: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 24,
                                    },
                                    [770]: {
                                        slidesPerView: 1.5,
                                        spaceBetween: -204,
                                    },
                                    [810]: {
                                        slidesPerView: 1.5,
                                        spaceBetween: -4,
                                    },
                                    [1025]: {
                                        slidesPerView: 1.5,
                                        spaceBetween: -185,
                                    },
                                } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `trader-slide-${i}` }, React.createElement(EmployeeTestimonialSliderCard, { ...card })))))))))));
};
