import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils";
import { ImgV2 } from "../../ImgV2";
export const IconAndTitleCardDeck2Rows = ({ title, description, cards, backgroundColor, bottomLinkUrl, bottomLinkText, opensInNewTab }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "centered-container" },
            React.createElement("div", { className: "icon-and-title-card-deck-2rows" },
                React.createElement("div", { className: "icon-and-title-card-deck-2rows__text-container" },
                    React.createElement("h2", null, title),
                    description && React.createElement("p", null, description)),
                React.createElement("div", { className: "icon-and-title-card-deck-2rows__items-container" }, cards?.map((card, i) => React.createElement(React.Fragment, null, React.createElement("a", { href: card.linkURL, key: i, className: "icon-and-title-card-deck-2rows__card" },
                    React.createElement(ImgV2, { className: "icon-and-title-card-deck-2rows__image", src: card.iconURL, alt: card.iconAlt }),
                    React.createElement("p", { className: "p-bold" }, card.title),
                    React.createElement("div", { className: "icon-and-title-card-deck-2rows__card-sub color-dark-80" }, card.subtitle))))),
                bottomLinkUrl && bottomLinkText && React.createElement("a", { className: "h5-bold icon-and-title-card-deck-2rows__bottom-link", target: opensInNewTab ? "_blank" : "_self", rel: "noreferrer", href: bottomLinkUrl }, bottomLinkText)))));
};
