import * as React from "react";
import { IconBrowser } from "../../svgIcons/browser";
import { IconSmartphone } from "../../svgIcons/smartphone";
import { Container, ContainerFW } from "../../global";
import { Img } from "../../Img";
import { IconAppStore } from "../../svgIcons/app-store";
import { IconGooglePlay } from "../../svgIcons/google-play";
import { handleLinkTarget } from "../../../utils";
export const AnimatedBanner = ({ title, subtitle, image, imageAlt, qrImage, qrImageAlt, downloadText, launchText, launchButtonText, launchButtonLink, footerQuote, googlePlayLink, appstroreLink, linkOpenInNewTab }) => {
    const [animate, setAnimate] = React.useState(false);
    React.useEffect(() => {
        const timeoutId = setTimeout(() => setAnimate(true), 2000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);
    const linkHrefTarget = handleLinkTarget(linkOpenInNewTab);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "AnimatedBanner" },
                React.createElement("h1", null, title),
                React.createElement("h5", null, subtitle),
                React.createElement("div", { className: `AnimatedBanner__image ${animate ? "animated" : ""}` },
                    React.createElement(Img, { src: image, alt: imageAlt })),
                React.createElement("div", { className: "AnimatedBanner__buttons-container" },
                    React.createElement("div", { className: `AnimatedBanner__buttons ${animate ? "animated" : ""}` },
                        React.createElement("div", { className: "AnimatedBanner__button" },
                            React.createElement("h4", null,
                                IconSmartphone,
                                " ",
                                downloadText),
                            React.createElement(Img, { className: "AnimatedBanner__button--image", src: qrImage, alt: qrImageAlt }),
                            React.createElement("div", { className: "AnimatedBanner__mobile-download" },
                                React.createElement("a", { href: googlePlayLink }, IconGooglePlay),
                                React.createElement("a", { href: appstroreLink }, IconAppStore))),
                        React.createElement("div", { className: "AnimatedBanner__button AnimatedBanner__button--launch" },
                            React.createElement("h4", null,
                                IconBrowser,
                                " ",
                                launchText),
                            " ",
                            React.createElement("a", { href: launchButtonLink, className: "button button--primary", target: linkHrefTarget }, launchButtonText),
                            " ")))))));
};
