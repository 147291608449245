import React from "react";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { getColors } from "../../utils";
import { WideCardWithButton } from "./WideCardWithButton/WideCardWithButton";
export const TwoWideCardsWithButtonsDeck = ({ title, subTitle, cards, backgroundColor }) => {
    const { bgColor, textColor } = getColors(backgroundColor || "white");
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "two-wide-cards-with-buttons-deck", style: { backgroundColor: bgColor || undefined, color: textColor || undefined } },
            React.createElement(Container, { className: "two-wide-cards-with-buttons-deck__container" },
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("p", { className: "two-wide-cards-with-buttons-deck__subtitle", dangerouslySetInnerHTML: { __html: subTitle } }),
                React.createElement("div", { className: "two-wide-cards-with-buttons-deck__cards" }, cards.length && cards.map((card) => (React.createElement(WideCardWithButton, { key: `unique${card.title}`, ...card }))))))));
};
