import React from "react";
import { getColors } from "../../../utils";
import { ButtonV2 } from "../../ButtonV2";
import { Container, ContainerFW } from "../../global";
import { Heading } from "../../Heading";
import { CardWithImage } from "./CardWithImage";
export const TwoCardsWithImages = ({ buttonHref, buttonLabel, cards, title, backGroundColor, openInNewTabButton }) => {
    const { bgColor } = getColors(backGroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "two-cards-with-images-root" },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("div", { className: "two-cards-with-images-root__cardsWrapper" }, cards.length > 0 && cards.map((card) => (React.createElement(CardWithImage, { key: `unique ${card.title}`, ...card })))),
                React.createElement(ButtonV2, { href: buttonHref, design: "cta-large-wbg", openInNewTab: openInNewTabButton }, buttonLabel)))));
};
