import React, { useCallback, useState } from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { arrowRight } from "../svgIcons/arrowRight";
const MIN_QUANTITY_INSTRUMENTS = 3;
export const PopularInstruments = ({ title, centeredTitle, bottomLinkUrl, bottomLinkText, instruments, buttonLoadMoreText, threeColumns, instrumentsQuantity = MIN_QUANTITY_INSTRUMENTS }) => {
    const [allowManyOnMobile, setAllowManyOnMobile] = useState(false);
    const renderInstrument = (inst, index) => (React.createElement("div", { key: inst.name + inst.linkUrl, className: `${((index < instrumentsQuantity) || allowManyOnMobile) ? "" : "hide-small-ndb"} popular-instruments__grid-item` },
        React.createElement("a", { href: inst.linkUrl, style: { textDecoration: "none", color: "inherit", display: "block" } },
            React.createElement("div", { className: "popular-instruments__instrument-container" },
                React.createElement("div", { className: "centered-container popular-instruments__icon" },
                    React.createElement(Img, { src: inst.iconImg, alt: inst.iconAlt, loading: "eager" })),
                React.createElement("div", { className: "centered-container1 popular-instruments__name" },
                    React.createElement("h5", { className: "h5-semi-bold" }, inst.name)),
                React.createElement("p", { className: "caption popular-instruments__description" }, inst.description),
                React.createElement("div", { className: "caption popular-instruments__arrow-icon" }, arrowRight())))));
    const showMore = useCallback((e) => {
        e.preventDefault();
        setAllowManyOnMobile(() => true);
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "popular-instruments" },
                React.createElement("div", { className: `popular-instruments__title-container ${centeredTitle ? "centered-container" : ""}` },
                    React.createElement("h2", null, title)),
                React.createElement("div", { className: `popular-instruments__grid ${threeColumns ? "popular-instruments__grid--three-cols" : ""}` }, instruments?.map((instrument, i) => renderInstrument(instrument, i))),
                React.createElement("div", { className: "centered-container" },
                    React.createElement("div", { className: "hide-medium-ndb hide-large-ndb" }, !allowManyOnMobile && React.createElement(ButtonV2, { design: "ghost-medium-wbg", onClick: showMore }, buttonLoadMoreText)),
                    bottomLinkText && React.createElement("a", { className: "color-dark-default popular-instruments__bottom-link", href: bottomLinkUrl },
                        React.createElement("b", null, bottomLinkText)))))));
};
