import React from "react";
import { getColors } from "../../../utils";
import { Container, ContainerFW } from "../../global";
import { CardWithImageAndLabel } from "./CardWithImageAndLabels/CardWithImageAndLabel";
export const CardDeckWithImages = ({ cards, backgroundColor }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "card-deck-with-images__root" }, cards.length > 0 && cards.map((a) => (React.createElement(CardWithImageAndLabel, { key: `unique${a.title}`, ...a })))))));
};
