import React from "react";
import { Img } from "../Img";
import { Container, ContainerFW } from "../global";
export const SectionWithList = ({ informationItems, mainSubTitle, mainTitle }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement(Container, null,
        React.createElement("div", { className: "TradeWithComponentWrapper SectionWithList" },
            React.createElement("div", { className: "" },
                React.createElement("h2", { className: "TradeWithComponentWrapper__mainTitle" }, mainTitle),
                React.createElement("p", { className: "TradeWithComponentWrapper__mainSubTitle", dangerouslySetInnerHTML: {
                        __html: mainSubTitle ? mainSubTitle : "",
                    } })),
            React.createElement("div", { className: "TradeWithComponentWrapper__sectionWrapper" }, informationItems.length > 0 && informationItems.map((a) => (React.createElement("div", { key: `unique${a.title}`, className: "TradeWithComponentWrapper__sectionWrapper__section" },
                React.createElement(Img, { src: a.icon, className: "TradeWithComponentWrapper__sectionWrapper__section__image", alt: a.iconAlt }),
                React.createElement("span", { className: "TradeWithComponentWrapper__sectionWrapper__section__titleWrapper" },
                    React.createElement("h5", { className: "TradeWithComponentWrapper__sectionWrapper__section__titleWrapper__title" }, a.title),
                    React.createElement("p", { className: "TradeWithComponentWrapper__sectionWrapper__section__titleWrapper__subTitle" }, a.subTitle))))))))));
