import * as React from "react";
import { pageViewStyle } from "../../../pageViewStyle";
export class Bye extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onViewUpdate = (view) => this.setState({ view });
        this.state = { view: pageViewStyle.getView() };
    }
    componentDidMount() {
        pageViewStyle.subscribe(this.onViewUpdate);
    }
    componentWillUnmount() {
        pageViewStyle.unsubscribe(this.onViewUpdate);
    }
    render() {
        return (React.createElement("h1", { className: "bye" },
            "Bye, ",
            React.createElement("span", { className: "bye__name" }, this.props.name),
            ", the view is: ",
            this.state.view));
    }
}
