import { useState, useEffect, useRef, useCallback } from "react";
import { SEARCH_PARAM_NAME, PAGENUM_PARAM_NAME } from "./GlobalSearch";
const tweakCurrentParamValues = (currentSearchValue, currentPageNum) => ({
    paramValue: currentSearchValue || undefined,
    pageNumValue: getCorrectedPageNum(currentPageNum),
});
const checkIsNewEntryAllowed = (currentSearchValue, newParamValue) => currentSearchValue !== null && newParamValue !== undefined;
const getCorrectedPageNum = (pageNum) => Number(pageNum) || 1;
const getNewParamValues = (newHistoryEntry) => ({
    newParamValue: newHistoryEntry?.newParamValue,
    newPageNum: newHistoryEntry?.newPageNum,
});
const addHistoryEntry = (newHistoryEntry, useCurrentParamValue = false, replaceCurrentEntry = false) => {
    const { href, origin, pathname, search, hash } = window.location;
    const searchParms = new URLSearchParams(search);
    const currentSearchValue = searchParms.get(SEARCH_PARAM_NAME);
    const currentPageNum = searchParms.get(PAGENUM_PARAM_NAME);
    const { newParamValue, newPageNum } = getNewParamValues(newHistoryEntry);
    let paramValue;
    let pageNumValue;
    let newUrl;
    if (useCurrentParamValue) {
        const tweakedParamValues = tweakCurrentParamValues(currentSearchValue, currentPageNum);
        paramValue = tweakedParamValues.paramValue;
        pageNumValue = tweakedParamValues.pageNumValue;
        newUrl = href;
    }
    else {
        if (!checkIsNewEntryAllowed(currentSearchValue, newParamValue)) {
            return;
        }
        paramValue = newParamValue;
        pageNumValue = newPageNum;
        if (paramValue !== undefined) {
            searchParms.set(SEARCH_PARAM_NAME, paramValue);
        }
        if (pageNumValue !== undefined) {
            searchParms.set(PAGENUM_PARAM_NAME, pageNumValue.toString());
        }
        else {
            searchParms.delete(PAGENUM_PARAM_NAME);
        }
        newUrl = `${origin}${pathname}?${searchParms.toString()}${hash}`;
    }
    const entryState = {
        pageName: "search",
        paramName: SEARCH_PARAM_NAME,
        paramValue,
        resultsPageNum: getCorrectedPageNum(pageNumValue)
    };
    if (replaceCurrentEntry) {
        window.history.replaceState(entryState, "", newUrl);
    }
    else {
        window.history.pushState(entryState, "", newUrl);
    }
};
export const useGlobalSearchNavigation = ({ searchFunction, onRefetch, status }) => {
    const [, setTriggerRerenderTime] = useState(undefined);
    const latestFetchTimestampRef = useRef(undefined);
    const getPossibleRefetchValue = (state) => {
        if (!state) {
            return undefined;
        }
        const { pageName, paramName, paramValue, resultsPageNum } = state;
        if (pageName === "search" && paramName === SEARCH_PARAM_NAME && paramValue) {
            return { paramValue, resultsPageNum };
        }
        return undefined;
    };
    const triggerRefetch = useCallback((searchValue, pageNum = 0) => {
        searchFunction({
            searchValue,
            pageNum
        }, true);
        setTriggerRerenderTime(Date.now());
        onRefetch(searchValue);
    }, [onRefetch, searchFunction]);
    useEffect(() => {
        const handleHistoryNavigation = (event) => {
            const refetchValue = getPossibleRefetchValue(event.state);
            if (refetchValue?.paramValue) {
                triggerRefetch(refetchValue.paramValue, refetchValue.resultsPageNum);
            }
        };
        window.addEventListener("popstate", handleHistoryNavigation);
        return () => {
            window.removeEventListener("popstate", handleHistoryNavigation);
        };
    }, [triggerRefetch]);
    useEffect(() => {
        const handleHistoryOnMount = () => {
            const refetchValue = getPossibleRefetchValue(window.history.state);
            if (refetchValue?.paramValue) {
                triggerRefetch(refetchValue.paramValue, refetchValue.resultsPageNum);
                return;
            }
            addHistoryEntry(undefined, true, true);
        };
        handleHistoryOnMount();
    }, [triggerRefetch]);
    useEffect(() => {
        const { requestTimestamp, requestedSearchValue, pageNum, isRefetch } = status;
        if (requestTimestamp === latestFetchTimestampRef.current) {
            return;
        }
        if (isRefetch) {
            return;
        }
        addHistoryEntry({
            newParamValue: requestedSearchValue,
            newPageNum: pageNum || undefined
        });
        latestFetchTimestampRef.current = requestTimestamp;
    });
};
