import React from "react";
import { Mousewheel } from "swiper";
import { Swiper, SwiperSlide, } from "swiper/react";
import { ThreeImagesCardComponent } from "../ThreeImagesCardComponent/ThreeImagesCardComponent";
export const ThreeImagesCardComponentSwiper = ({ cards }) => (React.createElement("div", { className: "threeImagesCardsWrapper__cardsWrapper" },
    React.createElement(Swiper, { modules: [Mousewheel], mousewheel: true, spaceBetween: 10, breakpoints: {
            [375]: {
                slidesPerView: "auto",
                spaceBetween: -60,
            },
            [601]: {
                slidesPerView: 2.2,
                spaceBetween: 24,
            },
        } }, cards.map((card, i) => React.createElement(SwiperSlide, { key: `threeImagesCard-slide-${i}` }, React.createElement(ThreeImagesCardComponent, { ...card }))))));
