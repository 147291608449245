import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
import { usePageViewStyle } from "../../../hooks";
import { getColors } from "../../../utils/getColors";
export const VideoBannerLP = ({ title, description, buttonLink, buttonLabel, opensInNewTab, shortText, videoSRC, backgroundColor }) => {
    const { isMedium } = usePageViewStyle();
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "video-banner-lp" },
                React.createElement("div", { className: "video-banner-lp__text-container" },
                    React.createElement("div", { className: "video-banner-lp__text-container__title-wrapper" },
                        React.createElement("h1", { dangerouslySetInnerHTML: {
                                __html: title,
                            } }),
                        isMedium && React.createElement("div", { className: "video-banner-lp__text-container__description-wrapper__button-box" },
                            React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonLink, openInNewTab: opensInNewTab }, buttonLabel),
                            React.createElement("p", { dangerouslySetInnerHTML: {
                                    __html: shortText,
                                } }))),
                    React.createElement("div", { className: "video-banner-lp__text-container__description-wrapper" },
                        React.createElement("div", { className: "video-banner-lp__text-container__description-wrapper__text-box" },
                            React.createElement("p", { dangerouslySetInnerHTML: {
                                    __html: description,
                                } })),
                        !isMedium && React.createElement("div", { className: "video-banner-lp__text-container__description-wrapper__button-box" },
                            React.createElement(ButtonV2, { design: "cta-large-wbg", href: buttonLink, openInNewTab: opensInNewTab }, buttonLabel),
                            React.createElement("p", { dangerouslySetInnerHTML: {
                                    __html: shortText,
                                } })))),
                React.createElement("div", { className: "video-banner-lp__video-container" },
                    React.createElement("video", { className: "video-banner-lp__video", autoPlay: true, loop: true, muted: true, playsInline: true },
                        React.createElement("source", { src: videoSRC, type: "video/mp4" })))))));
};
