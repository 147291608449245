import React from "react";
import { usePageViewStyle } from "../../../hooks/usePageViewStyle";
import { Gap } from "../../Gaps/Gap";
import { ContainerFW, Container } from "../../global";
import { NewsCard } from "./NewsCard/NewsCard";
import { JobBoardPagination } from "../../JobBoard/JobBoardPagination/JobBoardPagination";
import { Heading } from "../../Heading";
export const NewsCardDeck = ({ children, title, currentPage, currentPageItems, numOfPages, setCurrentPage, filtersRibbonElement, filtersElement, noWidgets }) => {
    const { viewStyle: view } = usePageViewStyle();
    const hasResultsOnPage = currentPageItems.length > 0;
    React.useEffect(() => {
        if (!noWidgets) {
            const script = document.createElement("script");
            script.src = "https://platform.twitter.com/widgets.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
        else {
            return undefined;
        }
    }, [noWidgets]);
    return (React.createElement(ContainerFW, null,
        React.createElement("section", { className: "newsCardDeck" },
            React.createElement(Container, null,
                React.createElement("div", { className: "newsCardDeck__inner-wrapper" },
                    title && React.createElement(Heading, { design: "h3-bold-small" }, title),
                    React.createElement("div", { className: "newsCardDeck__articles" },
                        React.createElement("div", { className: "newsCardDeck__controls" },
                            filtersRibbonElement,
                            filtersElement),
                        children,
                        React.createElement(Gap, { mobileGap: "24px", desktopGap: "32px", gapColor: "#fff" }),
                        hasResultsOnPage &&
                            (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "newsCardDeck__list" },
                                    currentPageItems.map((card, i) => (React.createElement(NewsCard, { key: i, ...card }))),
                                    " "),
                                React.createElement("div", { className: "jobBoard__pagination" },
                                    React.createElement(JobBoardPagination, { currentPage: currentPage, numOfPages: numOfPages, setCurrentPage: setCurrentPage, isSmallScreen: view !== "large" }))))),
                    !noWidgets &&
                        React.createElement("div", { className: "newsCardDeck__social-feed" },
                            React.createElement("div", { className: "widget-container" },
                                React.createElement("a", { className: "twitter-timeline", "data-tweet-limit": "3", "data-chrome": "nofooter transparent noborders", "data-link-color": "#7eaf3e", href: "https://twitter.com/ThinkMarkets_EN", target: "_blank", rel: "noreferrer" }, "Twitter feed for ThinkMarkets_EN"))))))));
};
