import React from "react";
import { Container, ContainerFW, Col, Row } from "../../global";
import { CardWithIcon } from "./CardWithIcon/CardWithIcon";
export const CardWithIconDeck = (props) => (React.createElement(ContainerFW, null,
    React.createElement(Container, null,
        React.createElement("div", { className: `card-with-icon-deck ${props.primaryLinkText ? "with-btn" : "without-btn"}` },
            props.title && (React.createElement("div", { className: "card-with-icon-deck__main-title" },
                React.createElement("h2", { dangerouslySetInnerHTML: { __html: props.title } }),
                props.text && React.createElement("p", null, props.text))),
            React.createElement("div", { className: "card-with-icon-deck__card-container" },
                React.createElement(Row.Cols3, null, props.content.map((item, i) => (React.createElement(Col, { key: i },
                    React.createElement(CardWithIcon, { title: item.title, text: item.text, imageAlt: item.imageAlt, imageUrl: item.imageUrl })))))))),
    React.createElement(Container, null, props.primaryLinkText && (React.createElement("div", { className: "card-with-icon-deck__links" },
        React.createElement("a", { className: "card-with-icon-deck__link card-with-icon-deck__primaryLink", href: props.primaryLinkUrl }, props.primaryLinkText),
        React.createElement("a", { className: "card-with-icon-deck__link card-with-icon-deck__secondaryLink", href: props.secondaryLinkUrl }, props.secondaryLinkText))))));
{
}
