import React from "react";
import { Img } from "../Img";
export const VideoBgComponentCard = ({ boldLeftTitle, boldRightTitle, LeftSubtitle, rightSubtitle, iconUrl }) => (React.createElement("div", { className: "video-bg-component-card" },
    React.createElement(Img, { src: iconUrl }),
    React.createElement("div", { className: "video-bg-component-card__cardTitlesWrapper" },
        React.createElement("div", { className: "video-bg-component-card__cardTitlesWrapper__leftTitles" },
            React.createElement("p", { className: "caption-bold" }, boldLeftTitle),
            React.createElement("p", null, LeftSubtitle)),
        React.createElement("div", { className: "video-bg-component-card__cardTitlesWrapper__rightTitles" },
            React.createElement("p", { className: "caption-bold" }, boldRightTitle),
            React.createElement("p", null, rightSubtitle)))));
