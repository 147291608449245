import React from "react";
import { TierInstrument } from "../TierInstrument";
export const TabWithInstrumentsAndTable = ({ instruments, tableData, instrumentsSectionTitle }) => (React.createElement("div", { className: "tab-with-instruments-and-table" },
    React.createElement("div", { className: "tab-with-instruments-and-table__instruments-container" },
        instrumentsSectionTitle && React.createElement("h3", null, instrumentsSectionTitle),
        React.createElement("div", { className: "tab-with-instruments-and-table__instruments-list" }, instruments.map((instrument, index) => React.createElement(TierInstrument, { key: index, name: instrument.name, iconUrl: instrument.iconUrl })))),
    React.createElement("div", { className: "tab-with-instruments-and-table__tableData-container" },
        React.createElement("div", { className: "tab-with-instruments-and-table__tabledata", dangerouslySetInnerHTML: {
                __html: tableData,
            } }))));
