import React, { useState, useEffect, useRef } from "react";
import { pageViewStyle } from "../../pageViewStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import { InstrumentSliderCard } from "../Cards/InstrumentSliderCard/InstrumentSliderCard";
import pako from "pako";
import { instrumentsMap } from "../PriceFeed/PriceFeed";
import { Autoplay } from "swiper";
export const TwoInstrumentSliders = ({ instrumentIcons, resources, session, wsUrl, activeMarket, noteUnderPrices }) => {
    const [instruments, setInstruments] = useState([]);
    const [filteredInstrumentIcons, setFilteredInstrumentIcons] = useState([]);
    const [view, setView] = useState(pageViewStyle.getView());
    const prevAskPrices = useRef(new Map());
    const prevBidPrices = useRef(new Map());
    useEffect(() => {
        !view && setView(pageViewStyle.getView());
        const websocket = new WebSocket(`${wsUrl}/price-feed/${session}`);
        websocket.binaryType = "arraybuffer";
        const pingRequest = new TextEncoder().encode("ping");
        let interval;
        websocket.onopen = () => {
            interval = setInterval(() => {
                websocket.send(pingRequest);
            }, 1000);
        };
        websocket.onmessage = (event) => {
            const data = event.data;
            const message = pako.inflate(data, { to: "string" });
            updatePriceFeed(message);
        };
        setFilteredInstrumentIcons(instrumentIcons.filter((item) => item.market === activeMarket).map(item => item.title === item.title ? { ...item, showTitle: item.title.replace("/", "") } : item));
        return () => {
            clearInterval(interval);
            websocket.close();
        };
    }, []);
    const updatePriceFeed = (message) => {
        const dict = JSON.parse(message);
        const instruments = [];
        for (const key in dict) {
            if (key.includes("quote")) {
                const group = key.split("_")[0];
                const parsedValues = JSON.parse(dict[key]);
                const n = JSON.parse(dict[`${group}_marketItem_${key.split("_")[2]}`]).Name;
                if (instrumentsMap[group.toLowerCase()].includes(parsedValues.Code)) {
                    const dailyChange = calcDailyChange(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict);
                    const prevBid = prevBidPrices.current.get(parsedValues.Code) ?? 0;
                    const prevAsk = prevAskPrices.current.get(parsedValues.Code) ?? 0;
                    const instrument = {
                        name: n,
                        code: parsedValues.Code,
                        groupCode: group,
                        bid: parsedValues.Bid,
                        bidStyle: bidAskStyle(parsedValues.Bid, prevBid),
                        bidIcon: bidAskIcon(parsedValues.Bid, prevBid),
                        ask: parsedValues.Ask,
                        askStyle: bidAskStyle(parsedValues.Ask, prevAsk),
                        askIcon: bidAskIcon(parsedValues.Ask, prevAsk),
                        spread: calcSpread(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict),
                        dailyChange,
                        dailyChangeStyle: dailyChangeStyle(+dailyChange),
                        dailyChangeIcon: dailyChangeIcon(+dailyChange),
                    };
                    prevAskPrices.current.set(parsedValues.Code, parsedValues.Ask);
                    prevBidPrices.current.set(parsedValues.Code, parsedValues.Bid);
                    instruments.push(instrument);
                }
            }
        }
        setInstruments(instruments.filter(instrument => instrument.groupCode === activeMarket));
    };
    const calcSpread = (group, code, bid, ask, dict) => {
        const marketItem = JSON.parse(dict[`${group}_marketItem_${code}`]);
        let decimalPrecision = 0;
        let tickSize = 0;
        if (dict[`${group}_marketItemInfo_${code}`]) {
            const marketItemInfo = JSON.parse(dict[`${group}_marketItemInfo_${code}`]);
            decimalPrecision =
                marketItemInfo.DecimalPrecision ?? marketItem.DecimalPrecision;
            tickSize = marketItemInfo.TickSize ?? marketItem.TickSize;
        }
        else {
            decimalPrecision = marketItem.DecimalPrecision;
            tickSize = marketItem.TickSize;
        }
        let decimal = tickSize >= 1.0 ? 0 : decimalPrecision;
        decimal = decimal > 2 ? decimal - 1 : decimal;
        const spread = ((ask - bid) * Math.pow(10, decimal)).toFixed(1);
        return spread;
    };
    const calcDailyChange = (group, code, bid, ask, dict) => {
        if (dict[`${group}_prevDayClosePrice_${code}`]) {
            const parsedValues = JSON.parse(dict[`${group}_prevDayClosePrice_${code}`]);
            const prevClose = parsedValues.PrevClose ?? 0;
            const averageCurrentPrice = (ask + bid) / 2;
            const dailyValue = averageCurrentPrice - prevClose;
            const dailyPercent = ((dailyValue / prevClose) * 100).toFixed(2);
            return `${dailyPercent}%`;
        }
        return "N/A";
    };
    const bidAskStyle = (currentPrice, previousPrice) => {
        if (currentPrice >= previousPrice) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    const dailyChangeStyle = (value) => {
        if (value >= 0) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    const bidAskIcon = (currentPrice, previousPrice) => {
        if (currentPrice >= previousPrice) {
            return resources[0];
        }
        else {
            return resources[1];
        }
    };
    const dailyChangeIcon = (value) => {
        if (value >= 0) {
            return resources[0];
        }
        else {
            return resources[1];
        }
    };
    const multiplyInstruments = (originalArray) => Array(originalArray.length * 3).fill(originalArray).flat();
    const paymentRoller = React.useCallback((items, reverseDirection = false) => React.createElement(Swiper, { spaceBetween: 16, slidesPerView: "auto", loop: true, autoplay: {
            delay: 0,
            disableOnInteraction: false,
            reverseDirection,
        }, speed: 4000, modules: [Autoplay], className: "awardsSli1derDark__swiper" }, [...items,
        ...items,
        ...items].map((item, index) => (React.createElement(SwiperSlide, { key: `instrument-card-${index}` },
        React.createElement(InstrumentSliderCard, { title: filteredInstrumentIcons.filter((el) => el.showTitle === item.code)[0]?.title ?? "", description: item.name, icon: filteredInstrumentIcons.filter((el) => el.showTitle === item.code)[0]?.imageUrl ?? "", price1: `${item.bid}`, price2: `${item.ask}`, price1status: item.bidStyle, price2status: item.askStyle }))))), [filteredInstrumentIcons]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "two-instrument-sliders" }, instruments.length ? (React.createElement(React.Fragment, null, view !== "large" ?
            React.createElement("div", { className: "mobile-slider" },
                paymentRoller(multiplyInstruments(instruments.filter((el, i) => i % 2 === 0))),
                React.createElement("br", null),
                paymentRoller(multiplyInstruments(instruments.filter((el, i) => i % 2 !== 0)), true)) : React.createElement(React.Fragment, null,
            React.createElement(Swiper, { spaceBetween: 16, direction: "vertical", loop: true, slidesPerView: "auto", className: "awardsSli1derDark__swiper", speed: 5000, slidesOffsetBefore: 20, autoplay: {
                    delay: 1,
                    disableOnInteraction: false,
                } }, multiplyInstruments(instruments.filter((el, i) => i % 2 === 0)).map((instrument, index) => React.createElement(SwiperSlide, { key: `instrument-card-${index}` },
                React.createElement(InstrumentSliderCard, { title: filteredInstrumentIcons.filter((el) => el.showTitle === instrument.code)[0]?.title ?? "", description: instrument.name, icon: filteredInstrumentIcons.filter((el) => el.showTitle === instrument.code)[0]?.imageUrl ?? "", price1: `${instrument.bid}`, price2: `${instrument.ask}`, price1status: instrument.bidStyle, price2status: instrument.askStyle })))),
            React.createElement(Swiper, { spaceBetween: 16, direction: "vertical", loop: true, slidesPerView: "auto", className: "awardsSli1derDark__swiper", speed: 5000, autoplay: {
                    delay: 1,
                    disableOnInteraction: false,
                    reverseDirection: true,
                } }, multiplyInstruments(instruments.filter((el, i) => i % 2 !== 0)).map((instrument, index) => React.createElement(SwiperSlide, { key: `instrument-card2-${index}` },
                React.createElement(InstrumentSliderCard, { title: filteredInstrumentIcons.filter((el) => el.showTitle === instrument.code)[0]?.title ?? "", description: instrument.name, icon: filteredInstrumentIcons.filter((el) => el.showTitle === instrument.code)[0]?.imageUrl ?? "", price1: `${instrument.bid}`, price2: `${instrument.ask}`, price1status: instrument.bidStyle, price2status: instrument.askStyle }))))))) : ""),
        noteUnderPrices && React.createElement("div", { className: "two-instrument-sliders__note-under-prices-container" },
            React.createElement("span", { className: "two-instrument-sliders__note-under-prices" }, noteUnderPrices))));
};
