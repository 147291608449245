import React from "react";
import { ContainerFW, Container } from "../../global";
import { ButtonV2 } from "../../ButtonV2";
import { Heading } from "../../Heading";
import { Person } from "./Person/Person";
export const WebinarBanner = ({ title, subTitle, buttonLabel, buttonUrl, bgImageDesktop, bgImageMobile, persons, openInNewTab }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement(Container, null,
        React.createElement("div", { className: "webinar-banner-section", style: { "--background-image-url-mobile": `url(${bgImageMobile})`, "--background-image-url-desktop": `url(${bgImageDesktop})` } },
            React.createElement("div", { className: "webinar-banner-section__textsWrapper" },
                React.createElement(Heading, { design: "h3-bold" }, title),
                React.createElement("p", { className: "webinar-banner-section__subtitle" }, subTitle),
                React.createElement(ButtonV2, { design: "tmx25-square-white-green", openInNewTab: true, href: buttonUrl }, buttonLabel)),
            React.createElement("div", { className: "webinar-banner-section__peopleWrapper" }, persons.length > 0 && persons.map((a) => (React.createElement(Person, { key: `unique${a.name}`, ...a }))))))));
