import * as React from "react";
import { Container, ContainerFW } from "../../global";
export const SearchBanner = (props) => {
    const [data, setData] = React.useState("");
    const searchFieldHandler = React.useCallback(e => setData(e.target.value), [data]);
    const optionsHandler = React.useCallback(value => (e) => {
        e.preventDefault();
        setData(value),
            props.search(value);
    }, [data]);
    const clickHandler = React.useCallback((e) => {
        e.key === "Enter" && props.search(data);
    }, [data]);
    return (React.createElement("div", { className: "search-banner" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "search-banner__container" },
                    React.createElement("div", { className: "search-banner__input-container" },
                        React.createElement("input", { className: "search-banner__input", type: "text", placeholder: props.inputPlaceholder, defaultValue: props.query, onChange: searchFieldHandler, onKeyDown: clickHandler })),
                    React.createElement("div", { className: "search-banner__title" },
                        React.createElement("p", null, props.title)),
                    React.createElement("div", { className: "search-banner__items" },
                        React.createElement("ul", null, props.items.map((item, index) => (React.createElement("li", { key: index },
                            React.createElement("a", { onClick: optionsHandler(item), href: "#" }, item)))))))))));
};
