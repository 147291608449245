import React from "react";
import { Heading } from "../Heading";
import { Img } from "../Img";
export const TitleWithCard = ({ title, cardContent, imageUrl, imageAlt }) => (React.createElement("div", { className: "title-with-card-root" },
    React.createElement("div", { className: "title-with-card-root__title-and-image" },
        React.createElement(Img, { src: imageUrl, alt: imageAlt }),
        React.createElement(Heading, { design: "h4-semi-bold" }, title)),
    React.createElement("div", { className: "title-with-card-root__card-content-wrapper", dangerouslySetInnerHTML: {
            __html: cardContent,
        } })));
