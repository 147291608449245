import React from "react";
import { Autoplay, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NotificationCard } from "./NotificationCard";
export const NotificationsGridContainer = ({ mainTitle, subTitle, notifications, imageUrl }) => (React.createElement("div", { className: "notificationsGridContainerWrapper" },
    React.createElement("div", { className: "notificationsGridContainerWrapper__textsWrapper" },
        React.createElement("h3", { className: "notificationsGridContainerWrapper__textsWrapper__mainTitle" }, mainTitle),
        React.createElement("h5", { className: "notificationsGridContainerWrapper__textsWrapper__subTitle" }, subTitle)),
    React.createElement("div", { className: "notificationsGridContainerWrapper__notificationsWrapper" },
        React.createElement(Swiper, { autoHeight: true, slidesPerView: "auto", loop: true, mousewheel: true, autoplay: {
                delay: 1000,
                disableOnInteraction: false,
            }, speed: 2000, modules: [Autoplay, Mousewheel], direction: "vertical" }, [...notifications, ...notifications].map((card, i) => React.createElement(SwiperSlide, { key: `trader-slide-${i}` }, React.createElement(NotificationCard, { key: `unique${card.mainTitle}${i}`, imageUrl: imageUrl, ...card })))))));
