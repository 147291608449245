import React, { useCallback, useState } from "react";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils";
import { Heading } from "../../Heading";
export const AccordionCardDeckWithImageV2 = (props) => {
    const { bgColor, textColor } = getColors(props.designStyles?.backgroundColor);
    const [imageIndexState, setImageIndexState] = useState(0);
    const setIndexImageStateCallback = useCallback((e) => {
        const indexElement = e.currentTarget.getAttribute("data-index");
        const index = parseInt(indexElement, 10);
        if (!isNaN(index)) {
            setImageIndexState(index);
        }
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "accordion-card-deck-with-image-v2" },
                React.createElement("div", { style: { color: textColor }, className: "accordion-card-deck-with-image-v2__title-container" },
                    React.createElement(Heading, { design: props.designStyles?.titleDesign }, props.title),
                    React.createElement("p", { className: "copy-lp", dangerouslySetInnerHTML: {
                            __html: props.description,
                        } })),
                React.createElement("div", { className: "hide-small hide-medium" },
                    React.createElement("div", { className: "accordion-card-with-image-container-large" },
                        React.createElement("div", { className: "right-menu-container-large background-pure-white" }, props.cards.map((card, i) => React.createElement("div", { key: `card-large-${i}`, "data-index": i, className: `card-large ${imageIndexState === i ? "hovered" : ""}`, onMouseEnter: setIndexImageStateCallback },
                            React.createElement("p", null,
                                React.createElement("div", { className: "card-title-container", key: `accordion-card-${i}` },
                                    React.createElement("div", { className: "flex" },
                                        card.iconUrl && React.createElement(ImgV2, { largeUrl: card.iconUrl, strictRender: true, className: "accordion-card-deck-with-image-v2__platform-image-icon" }),
                                        React.createElement("div", { className: "accordion-card-deck-with-image-v2__custom-title-font" }, card.title)),
                                    React.createElement("div", { className: "card-expand-description accordion-card-deck-with-image-v2__custom-description-font" }, card.text)))))),
                        React.createElement("div", { className: "left-image-container-large" },
                            React.createElement(ImgV2, { largeUrl: props.cards[imageIndexState] ? props.cards[imageIndexState].imageUrlDesktop : "", alt: props.cards[imageIndexState] ? props.cards[imageIndexState].alt : "", strictRender: true, className: "image-large-deck" }))),
                    React.createElement("div", { className: "reference-footnote" }, props.disclaimer)),
                React.createElement("div", { className: "hide-large" },
                    React.createElement("div", { className: "accordion-card-deck-with-image-v2__grid-cards" }, props.cards.map((card, i) => (React.createElement("div", { key: i },
                        React.createElement("div", { className: "accordion-card-deck-with-image-v2__tablet-card" },
                            React.createElement("div", null),
                            React.createElement("div", { className: "accordion-card-deck-with-image-v2__tablet-card-image-container" },
                                React.createElement(ImgV2, { strictRender: true, smallUrl: card.imageUrlMobile, mediumUrl: card.imageUrlMobile, alt: card.alt, className: "accordion-card-deck-with-image-v2__tablet-card-image" })),
                            React.createElement("img", { src: card.iconUrl, alt: card.iconAlt, className: "accordion-card-deck-with-image-v2__tablet-card-icon-image" }),
                            React.createElement("h5", { className: "accordion-card-deck-with-image-v2__custom-title-font" }, card.title),
                            React.createElement("p", { className: "accordion-card-deck-with-image-v2__custom-description-font" }, card.text)))))))))));
};
