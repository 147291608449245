import * as React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { IconPDF } from "../svgIcons/pdf";
export const WideCardWithList = ({ title, subtitle, text, icon, iconAlt, linkText, linkUrl, listItems }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement(Container, null,
        React.createElement("h2", { className: "WideCardWithList__title" }, title),
        React.createElement("div", { className: "WideCardWithList__subtitle" },
            React.createElement(Img, { src: icon, alt: iconAlt }),
            React.createElement("h4", null, subtitle)),
        React.createElement("div", { className: "WideCardWithList" },
            React.createElement("div", { className: "color-dark-80", dangerouslySetInnerHTML: { __html: text } }),
            React.createElement("div", { className: "WideCardWithList__link-container" },
                React.createElement("a", { href: linkUrl, className: "button button--secondary WideCardWithList__link" },
                    IconPDF,
                    linkText)),
            React.createElement("ol", { className: "WideCardWithList__list" }, listItems.map((item, i) => React.createElement("li", { key: `WideCardWithList-item-${i}` },
                i + 1,
                ". ",
                item.text)))))));
