import React, { useState, useEffect } from "react";
import { usePageViewStyle } from "../../../../hooks";
import { Img } from "../../../Img";
import { SimpleLightbox } from "../../VideoCardDeck/SimpleLightbox/SimpleLightbox";
import { YouTubeVideoEmbed } from "../../VideoCardDeck/YouTubeVideoEmbed";
export const VideoCardDeckSliderCard = ({ videoURL, videoTitle, videoUploadTime, iconURL }) => {
    const { isSmall, isMedium, isLarge } = usePageViewStyle();
    const [maxTitleLength, setMaxTitleLength] = useState(0);
    useEffect(() => {
        isLarge ? setMaxTitleLength(60) : (isMedium ? setMaxTitleLength(43) : (isSmall ? setMaxTitleLength(42) : setMaxTitleLength(50)));
    }, [isLarge, isMedium, isSmall]);
    return (React.createElement("div", { className: "video-card-deck-slider-card" },
        React.createElement(SimpleLightbox, { className: "video-card-deck-slider-card__lightbox-trigger" },
            React.createElement(YouTubeVideoEmbed, { videoUrl: videoURL, webSiteRootUrl: videoURL }),
            React.createElement("div", { className: "video-card-deck-slider-card__text-container" },
                React.createElement("div", { className: "video-card-deck-slider-card__slide-upload-time" },
                    React.createElement(Img, { src: iconURL, alt: "clock icon" }),
                    React.createElement("p", null, videoUploadTime)),
                React.createElement("div", { className: "video-card-deck-slider-card__slide-title" },
                    React.createElement("h5", null, videoTitle.length > maxTitleLength ? (`${videoTitle.substr(0, maxTitleLength)}...`) : videoTitle))))));
};
