import * as React from "react";
import ".";
import { Button } from "../Button";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { IconCloseWhite } from "../svgIcons/iconCloseWhite";
import { IconPlayButton } from "../svgIcons/playButton";
export const HeroBannerWithVideo = ({ title, text, imageUrl, imageAlt, linkText, linkUrl, videoText, videoUrl, buttonOpenInNewTab = false }) => {
    const [isPopoverOpen, setPopoverOpen] = React.useState(false);
    const openInNewTabValue = buttonOpenInNewTab !== true ? false : true;
    const handlePopoverToggle = React.useCallback((e) => {
        e.preventDefault();
        setPopoverOpen(!isPopoverOpen);
    }, [isPopoverOpen]);
    const handlePopoverClose = React.useCallback((e) => {
        e.preventDefault();
        setPopoverOpen(false);
    }, []);
    const FullscreenPopover = ({ isOpen, onClose, children }) => (React.createElement("div", { className: `fullscreen-popover ${isOpen ? "open" : ""}` },
        React.createElement("div", { className: "fullscreen-popover__content" },
            React.createElement("span", { onClick: onClose, className: "fullscreen-popover__close" }, IconCloseWhite),
            children)));
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement(Container, null,
            React.createElement("div", { className: "HeroBannerWithVideo" },
                React.createElement("h1", { className: "color-dark-default" }, title),
                React.createElement("h5", { className: "color-dark-default" }, text),
                React.createElement(Button, { label: linkText, href: linkUrl, openinnewtab: openInNewTabValue.toString(), style: { color: "#0E1D31" }, primary: true }),
                React.createElement(Img, { src: imageUrl, alt: imageAlt }),
                React.createElement("a", { onClick: handlePopoverToggle, href: "#", className: "HeroBannerWithVideo__link color-dark-default" },
                    IconPlayButton,
                    " ",
                    React.createElement("span", null, videoText)))),
        React.createElement(FullscreenPopover, { isOpen: isPopoverOpen, onClose: handlePopoverClose },
            React.createElement("iframe", { width: "100%", height: "100%", src: videoUrl, title: "Introducing ThinkCopy", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }))));
};
