import * as React from "react";
import { getColors } from "../../utils";
import { Button } from "../Button";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
export const HeroBannerWithVideoBG = ({ title, text, imageUrl, imageAlt, linkText, linkUrl, backgroundColor, videoUrl }) => {
    const { textColor } = getColors(backgroundColor);
    return (React.createElement("div", { className: "HeroBannerWithVideoBG__container" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "HeroBannerWithVideoBG", style: { color: textColor } },
                    React.createElement("h1", null, title),
                    React.createElement("p", { dangerouslySetInnerHTML: {
                            __html: text,
                        } }),
                    linkText && React.createElement(Button, { label: linkText, href: linkUrl, style: { color: "#0E1D31" }, primary: true }),
                    React.createElement(Img, { src: imageUrl, alt: imageAlt })))),
        React.createElement("div", { className: "HeroBannerWithVideoBG__video-container" },
            React.createElement("div", { className: "HeroBannerWithVideoBG__video-mask" }),
            React.createElement("video", { className: "HeroBannerWithVideoBG__video", autoPlay: true, loop: true, muted: true },
                React.createElement("source", { src: videoUrl, type: 'video/mp4' })))));
};
