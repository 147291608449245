import React from "react";
import { Heading } from "../../Heading";
import { Container, ContainerFW } from "../../../header";
import { getColors } from "../../../utils";
import { IconAndTitleSquaredCard } from "./IconAndTitleCardSquared";
export const IconAndTitleCardSquaredDeck = ({ cards, backgroundColor, title, linkLabel, linkUrl }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "icon-title-card-squared-deck" },
            React.createElement(Container, null,
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("div", { className: "icon-title-card-squared__cardWrapper" }, cards.length && cards.map((card) => (React.createElement(IconAndTitleSquaredCard, { key: `unique${card.title}`, ...card })))),
                React.createElement("a", { className: "icon-title-card-squared__link", href: linkUrl }, linkLabel)))));
};
