import * as React from "react";
import ".";
import { Button } from "../Button";
import { Container, ContainerFW } from "../global";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { LoadingSpinner } from "../LoadingSpinner";
export const PartnershipForm = (props) => {
    const initialFormData = {
        region: props.regionOptions[0],
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: "",
        department: props.aboutQuestionOptions[0],
        message: "",
        formFile: [],
    };
    const initialValidationHints = {
        region: false,
        firstName: false,
        lastName: false,
        email: false,
        mobilePhone: false,
        department: false,
        runOnInput: false,
        message: false,
    };
    const OverlayState = {
        HIDDEN: "HIDDEN",
        WAITING: "WAITING",
        ERROR: "ERROR",
        SUCCESS: "SUCCESS",
    };
    const [formData, setFormData] = React.useState(initialFormData);
    const [dataToSend, setDataToSend] = React.useState();
    const [validationHints, setValidationHints] = React.useState(initialValidationHints);
    const [generalError, setGeneralError] = React.useState("");
    const [generalSuccess, setGeneralSuccess] = React.useState("");
    const [overlayState, setOverlayState] = React.useState(OverlayState.HIDDEN);
    const [isactiveSubmitBtn, setIsactiveSubmitBtn] = React.useState(true);
    const overlayRef = React.useRef(null);
    const captchaRef = React.useRef(null);
    const handleCaptchaError = React.useCallback(() => {
        setGeneralError(props.generalError);
    }, [props.generalError]);
    const { region, firstName, lastName, email, mobilePhone, department, message, } = formData;
    const isValidName = (name) => !/[!@#$%&=_~<>?:1234567890]/.test(name) && name.length <= 300 && name.length > 0;
    const isValidPhone = (phone) => /^\+?[0-9]([-\s]?\d)*$/.test(phone) && phone.length <= 30;
    const isValidEmail = (email) => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email) && email.length <= 300;
    const isValidQuestion = React.useCallback((question) => props.aboutQuestionOptions.length !== 1 ? props.aboutQuestionOptions[0] !== question : true, [props.aboutQuestionOptions]);
    const isValidRegion = React.useCallback((region) => props.regionOptions[0] !== region, [props.regionOptions]);
    const [captchaSize, setCaptchaSize] = React.useState("invisible");
    const [token, setToken] = React.useState();
    const handleCaptchaSize = React.useCallback((value) => ((setCaptchaSize(value))), []);
    React.useEffect(() => {
        if (validationHints.runOnInput) {
            const newValidationHints = {
                ...validationHints,
                firstName: !isValidName(formData.firstName),
                lastName: !isValidName(formData.lastName),
                email: !isValidEmail(formData.email),
                mobilePhone: !isValidPhone(formData.mobilePhone),
                department: !isValidQuestion(formData.department),
                region: !isValidRegion(formData.region),
                message: !formData.message
            };
            setValidationHints(newValidationHints);
        }
    }, [formData]);
    const inputHandler = React.useCallback((e) => {
        setGeneralError("");
        setGeneralSuccess("");
        const { name, value } = e.target;
        setFormData((formData) => ({ ...formData, [name]: value }));
    }, []);
    const resetForm = React.useCallback(() => {
        setValidationHints(initialValidationHints);
        setTimeout(() => {
            setFormData(initialFormData);
        }, 10);
        setGeneralError("");
        setIsactiveSubmitBtn(true);
    }, []);
    const constructRequestFormData = React.useCallback((captchaResponse) => {
        const requestFormData = new FormData();
        requestFormData.append("captcha_response", captchaResponse ? captchaResponse : "");
        for (const key in formData) {
            if (key !== "formFile") {
                requestFormData.append(key, formData[key]);
            }
            else {
                const files = formData[key];
                files.forEach((file) => {
                    requestFormData.append("formFile", file);
                });
            }
        }
        props.campaignId && requestFormData.append("campaignId", props.campaignId);
        return requestFormData;
    }, [formData]);
    const getValidation = React.useCallback(() => {
        const newValidationHints = {
            ...validationHints,
            firstName: !isValidName(formData.firstName),
            lastName: !isValidName(formData.lastName),
            email: !isValidEmail(formData.email),
            mobilePhone: !isValidPhone(formData.mobilePhone),
            department: !isValidQuestion(formData.department),
            region: !isValidRegion(formData.region),
            message: !formData.message
        };
        return { valid: !(Object.values({ ...newValidationHints, runOnInput: false }).some(value => value === true)), newValidationHints };
    }, [validationHints, formData.firstName, formData.lastName, formData.email, formData.mobilePhone, formData.department, formData.region, formData.message, isValidQuestion, isValidRegion]);
    const setOverlaySucess = React.useCallback(() => {
        setGeneralSuccess(props.generalSuccess);
        setOverlayState(OverlayState.SUCCESS);
        resetForm();
    }, [OverlayState.SUCCESS, props.generalSuccess, resetForm]);
    const setOverlayError = React.useCallback(() => {
        setIsactiveSubmitBtn(true);
        setOverlayState(OverlayState.ERROR);
        setGeneralError(props.generalError);
    }, [OverlayState.ERROR, props.generalError]);
    const sendRequest = React.useCallback(async () => {
        setOverlayState(OverlayState.WAITING);
        try {
            const r = await fetch("", {
                method: "POST",
                body: dataToSend
            });
            if (r.status === 200) {
                setOverlaySucess();
            }
            else {
                setOverlayError();
            }
        }
        catch {
            setOverlayError();
        }
    }, [OverlayState.WAITING, dataToSend, setOverlayError, setOverlaySucess]);
    const handleCaptchaResponse = React.useCallback(() => (captchaRef.current?.execute()), []);
    const submitHandler = React.useCallback(async (e) => {
        e.preventDefault();
        setIsactiveSubmitBtn(false);
        setGeneralSuccess("");
        const { valid, newValidationHints } = getValidation();
        if (!valid) {
            newValidationHints.runOnInput = true;
            setValidationHints(newValidationHints);
            setIsactiveSubmitBtn(true);
            return;
        }
        const captchaString = handleCaptchaResponse();
        const requestFormData = constructRequestFormData(captchaString || "");
        handleCaptchaSize("normal");
        setDataToSend(requestFormData);
        return;
    }, [constructRequestFormData, getValidation, handleCaptchaResponse, handleCaptchaSize]);
    const closeSendingOverlay = React.useCallback(() => {
        setOverlayState(OverlayState.HIDDEN);
        setToken(undefined);
        handleCaptchaSize("invisible");
    }, [OverlayState.HIDDEN, handleCaptchaSize]);
    const RequiredStar = React.useCallback(function RequiredStar() {
        return React.createElement("span", { className: "contact-form__required-star" }, "*");
    }, []);
    const RequiredLabel = React.useCallback(function RequiredLabel({ field }) {
        return (validationHints[field] && React.createElement("span", { className: "contact-form__required-error" }, props.requiredFieldHint));
    }, [validationHints]);
    const SubmitBtn = React.useCallback(() => React.createElement(Button, { label: props.submitLabel, primary: true, onClick: submitHandler, classes: `${!isactiveSubmitBtn && "contact-form__disabled"} ` }), [props.submitLabel, submitHandler, isactiveSubmitBtn]);
    const OverlayMessage = React.useCallback(() => (React.createElement(React.Fragment, null,
        " ",
        overlayState === OverlayState.WAITING && React.createElement(React.Fragment, null,
            React.createElement("span", { className: "contact-form__msg-overlay" }, props.popUpFormIsBeingSendMessage)),
        overlayState === OverlayState.ERROR && React.createElement(React.Fragment, null,
            React.createElement("span", { className: "contact-form__msg-overlay" }, props.generalError)),
        overlayState === OverlayState.SUCCESS && React.createElement(React.Fragment, null,
            React.createElement("span", { className: "contact-form__msg-overlay" }, props.popUpFormSucessfullySentMessage)))), [overlayState]);
    React.useEffect(() => {
        function handleClickOutside(event) {
            if (overlayRef.current && !overlayRef.current.contains(event.target)) {
                if ((overlayState === OverlayState.ERROR) || (overlayState === OverlayState.SUCCESS)) {
                    closeSendingOverlay();
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [overlayRef, overlayState]);
    React.useEffect(() => {
        if (token && dataToSend !== undefined) {
            void sendRequest();
            setToken(undefined);
            setDataToSend(undefined);
        }
    }, [dataToSend, sendRequest, token]);
    const Overlay = React.useCallback(() => (React.createElement(React.Fragment, null, overlayState !== OverlayState.HIDDEN &&
        React.createElement("div", { className: "contact-form__loading-overlay" },
            React.createElement("div", { ref: overlayRef, className: "contact-form__loading-popup" },
                React.createElement("div", { className: "contact-form__loading-popup-msg-container" },
                    React.createElement(OverlayMessage, null)),
                React.createElement("span", { className: "contact-form__spinner-container" }, overlayState === OverlayState.WAITING && React.createElement(React.Fragment, null,
                    React.createElement(LoadingSpinner, null))),
                overlayState !== OverlayState.WAITING && React.createElement(React.Fragment, null,
                    React.createElement(Button, { label: props.popUpOkButtonLabel, primary: true, onClick: closeSendingOverlay })))))), [OverlayMessage, OverlayState.HIDDEN, OverlayState.WAITING, closeSendingOverlay, overlayState, props.popUpOkButtonLabel]);
    const handleCaptchaOnVerify = (token) => (setToken(token));
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "contact-form__container-root" },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "contact-form__container" },
                    React.createElement("div", { className: "contact-form__form" },
                        React.createElement("h3", null, props.title),
                        React.createElement(Overlay, null),
                        React.createElement("div", { className: `contact-form__form-container contact-form__form-container--partnership  ${overlayState !== OverlayState.HIDDEN ? "contact-form__semi-transparent" : ""}` },
                            React.createElement("div", { className: "contact-form__field contact-form__field--half firstName-input" },
                                React.createElement("label", { htmlFor: "firstName", className: "contact-form__label contact-form__label--required" },
                                    props.firstNameLabel,
                                    React.createElement(RequiredStar, null),
                                    React.createElement(RequiredLabel, { field: "firstName" })),
                                React.createElement("input", { onChange: inputHandler, value: firstName, type: "text", name: "firstName", id: "firstName", placeholder: props.firstNamePlaceholder })),
                            React.createElement("div", { className: "contact-form__field contact-form__field--half region-input" },
                                React.createElement("label", { htmlFor: "region", className: "contact-form__label contact-form__label--required" },
                                    props.regionLabel,
                                    React.createElement(RequiredStar, null),
                                    React.createElement(RequiredLabel, { field: "region" })),
                                React.createElement("select", { onChange: inputHandler, name: "region", id: "region", value: region }, props.regionOptions.map((option, i) => (React.createElement("option", { key: `region-option-${i}`, value: option, disabled: i === 0 }, option))))),
                            React.createElement("div", { className: "contact-form__field contact-form__field--half mobilePhone-input" },
                                React.createElement("label", { htmlFor: "mobilePhone", className: "contact-form__label contact-form__label--required" },
                                    props.phoneLabel,
                                    React.createElement(RequiredStar, null),
                                    React.createElement(RequiredLabel, { field: "mobilePhone" })),
                                React.createElement("input", { onChange: inputHandler, value: mobilePhone, type: "number", name: "mobilePhone", id: "mobilePhone", placeholder: props.phonePlaceholder })),
                            React.createElement("div", { className: "contact-form__field message-input" },
                                React.createElement("label", { htmlFor: "message", className: "contact-form__label" }, props.messageLabel),
                                React.createElement("textarea", { onChange: inputHandler, name: "message", id: "message", value: message, rows: 6, placeholder: props.messagePlaceholder })),
                            React.createElement("div", { className: "contact-form__field contact-form__field--half lastName-input" },
                                React.createElement("label", { htmlFor: "lastName", className: "contact-form__label contact-form__label--required" },
                                    props.lastNameLabel,
                                    React.createElement(RequiredStar, null),
                                    React.createElement(RequiredLabel, { field: "lastName" })),
                                React.createElement("input", { onChange: inputHandler, value: lastName, type: "text", name: "lastName", id: "lastName", placeholder: props.lastNamePlaceholder })),
                            React.createElement("div", { className: "contact-form__field contact-form__field--half email-input" },
                                React.createElement("label", { htmlFor: "email", className: "contact-form__label contact-form__label--required" },
                                    props.emailLabel,
                                    React.createElement(RequiredStar, null),
                                    React.createElement(RequiredLabel, { field: "email" })),
                                React.createElement("input", { onChange: inputHandler, value: email, type: "email", name: "email", id: "email", placeholder: props.emailPlaceholder })),
                            React.createElement("div", { className: "contact-form__field contact-form__field--half aboutQuestion-input" },
                                React.createElement("label", { htmlFor: "aboutQuestion", className: "contact-form__label contact-form__label--required" },
                                    props.aboutQuestionLabel,
                                    React.createElement(RequiredStar, null),
                                    React.createElement(RequiredLabel, { field: "department" })),
                                React.createElement("select", { onChange: inputHandler, name: "department", id: "department", value: department }, props.aboutQuestionOptions.map((option, i) => (React.createElement("option", { key: `aboutQuestion-option-${i}`, value: option, disabled: props.aboutQuestionOptions.length !== 1 && i === 0 }, option))))),
                            React.createElement("div", { className: "contact-form__button" },
                                React.createElement(SubmitBtn, null)),
                            React.createElement("div", { className: "contact-form__field captcha-input" },
                                React.createElement(HCaptcha, { sitekey: props.hcaptchaKey, onError: handleCaptchaError, ref: captchaRef, size: captchaSize, languageOverride: props.hcaptchaLanguageCode, onVerify: handleCaptchaOnVerify })),
                            generalError && React.createElement("div", { className: "contact-form__general-error-label" }, generalError),
                            generalSuccess && React.createElement("div", { className: "contact-form__general-success-label" }, generalSuccess))))))));
};
