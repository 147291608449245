import * as React from "react";
import { Container, ContainerFW } from "../../global";
export const AdditionalInformation = ({ subtitle1, subtitle2, content1, content2 = "", miniContent = "", showMiniContent = false }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "additionalInformation" },
        React.createElement(Container, null,
            React.createElement("div", { className: "additionalInformation__content" },
                !showMiniContent && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "additionalInformation__subContent" },
                        subtitle1 && React.createElement("h3", null, subtitle1),
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: content1 } })),
                    content2 && React.createElement("div", { className: "additionalInformation__subContent" },
                        subtitle2 && React.createElement("h3", null, subtitle2),
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: content2 } })))),
                showMiniContent && (React.createElement("div", { className: "additionalInformation__subContent" }, React.createElement("div", { dangerouslySetInnerHTML: { __html: miniContent } }))))))));
