import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
export const RibbonWithOutStatLine = ({ buttonHref, buttonLabel, opensInNewTab, subTitle, title, backgroundColor }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor || "inherit" },
    React.createElement("div", { className: "ribbon-without-stat-line-root" },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "ribbon-without-stat-line-root__contentWrapper" },
                React.createElement("div", { className: "ribbon-without-stat-line-root__contentWrapper__textWrapper" },
                    React.createElement(Heading, { design: "h4-semi-bold" },
                        React.createElement("span", { dangerouslySetInnerHTML: {
                                __html: title,
                            } })),
                    React.createElement("div", { className: "ribbon-without-stat-line-root__contentWrapper__subtitle", dangerouslySetInnerHTML: {
                            __html: subTitle,
                        } })),
                React.createElement(ButtonV2, { href: buttonHref, design: "tmx25-square-green-green", openInNewTab: opensInNewTab }, buttonLabel))))));
