import React from "react";
import { ContainerFW, Container } from "../global";
import { getColors } from "../../utils/getColors";
import { handleLinkTarget } from "../../utils";
export const BubbleTitleDescButtons = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    const linkHrefTarget = handleLinkTarget(props.linkOpenInNewTab);
    const buttonkHrefTarget = handleLinkTarget(props.buttonOpenInNewTab);
    return (React.createElement("div", { className: "bubbleTitleDescButtons" },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("div", { className: "bubbleTitleDescButtons__main-container" },
                    React.createElement("div", { className: "bubbleTitleDescButtons__text-container" },
                        React.createElement("h3", null, props.title),
                        props.description && React.createElement("p", { dangerouslySetInnerHTML: { __html: props.description } })),
                    (props.buttonLabel || props.linkLabel) && React.createElement("div", { className: "bubbleTitleDescButtons__buttons-container" },
                        props.buttonLabel && React.createElement("a", { className: "bubbleTitleDescButtons__main-button", href: props.buttonURL, target: buttonkHrefTarget }, props.buttonLabel),
                        props.linkLabel && (React.createElement("a", { className: "bubbleTitleDescButtons__demo-link bubbleTitleDescButtons__with-arrow", href: props.linkURL, target: linkHrefTarget }, props.linkLabel))))))));
};
