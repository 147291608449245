import React from "react";
import { Container } from "../global";
import { IconChevronRight } from "../svgIcons/chevronRight";
import { IconHome } from "../svgIcons/homeIcon";
export const Breadcrumbs = ({ breadcrumbs, lightMode }) => (React.createElement(Container, null,
    React.createElement("div", { className: `breadcrumbs ${lightMode ? "breadcrumbs--light" : ""}`, itemScope: true, itemType: "http://schema.org/BreadcrumbList" },
        React.createElement("span", { itemProp: "itemListElement", itemScope: true, itemType: "http://schema.org/ListItem" },
            React.createElement("a", { className: "breadcrumbs__home-link", href: "/", itemProp: "item" },
                React.createElement("span", { itemProp: "name" }, IconHome)),
            breadcrumbs.map((item, index) => {
                if (index + 1 ===
                    breadcrumbs.length) {
                    return (React.createElement("span", { key: index, className: "breadcrumbs__item", itemProp: "name" },
                        " ",
                        IconChevronRight,
                        " ",
                        React.createElement("span", { dangerouslySetInnerHTML: {
                                __html: item.breadcrumbText,
                            } }),
                        " ",
                        React.createElement("meta", { itemProp: "position", content: `${index + 1}` })));
                }
                else {
                    return (React.createElement("a", { key: index, className: "breadcrumbs__item--link", href: item.breadcrumbLink, itemProp: "item" },
                        " ",
                        IconChevronRight,
                        React.createElement("span", { dangerouslySetInnerHTML: {
                                __html: item.breadcrumbText,
                            } }),
                        " ",
                        React.createElement("meta", { itemProp: "position", content: `${index + 1}` })));
                }
            })))));
