import React from "react";
import { Img } from "../../Img";
import { usePageViewStyle } from "../../../hooks";
import { Container, ContainerFW } from "../../global";
import { ButtonV2 } from "../../ButtonV2/ButtonV2";
const getScroolPX = ({ isLarge, isMedium }) => {
    if (isLarge) {
        return 3700;
    }
    if (isMedium) {
        return 4150;
    }
    return 4700;
};
export const TradeWithThinkCopy = ({ imageUrl, imageAlt, informationItems, mainSubTitle, mainTitle, buttonLabel, buttonHref, imagePosition = "left" }) => {
    const { isLarge, isMedium } = usePageViewStyle();
    const scrollPX = getScroolPX({ isLarge, isMedium });
    const scrollTopHandler = React.useCallback(() => {
        window.scroll({ top: scrollPX, left: 0, behavior: "smooth" });
    }, [scrollPX]);
    return (React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement("div", { className: "TradeWithThinkCopy-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: `${imagePosition === "left" ? "TradeWithThinkCopyWrapper" : "TradeWithThinkCopyWrapperRight"} ` },
                    React.createElement("h2", { className: "TradeWithThinkCopyWrapper__mainTitle" }, mainTitle),
                    React.createElement("p", { className: "TradeWithThinkCopyWrapper__mainSubTitle", dangerouslySetInnerHTML: { __html: mainSubTitle } }),
                    React.createElement(Img, { src: imageUrl, className: "TradeWithThinkCopyWrapper__image", alt: imageAlt }),
                    React.createElement("div", { className: "TradeWithThinkCopyWrapper__sectionWrapper" }, informationItems.length > 0 && informationItems.map((a) => (React.createElement("div", { key: `unique${a.title}`, className: "TradeWithThinkCopyWrapper__sectionWrapper__section" },
                        React.createElement(Img, { src: a.icon, alt: a.iconAlt, className: "TradeWithThinkCopyWrapper__sectionWrapper__section__image" }),
                        React.createElement("div", { className: "TradeWithThinkCopyWrapper__sectionWrapper__section__titleWrapper" },
                            React.createElement("h5", { className: "TradeWithThinkCopyWrapper__sectionWrapper__section__titleWrapper__title", dangerouslySetInnerHTML: { __html: a.title } }),
                            React.createElement("div", { className: "TradeWithThinkCopyWrapper__sectionWrapper__section__titleWrapper__subTitle", dangerouslySetInnerHTML: { __html: a.subTitle } })))))),
                    buttonLabel && React.createElement("div", { className: "TradeWithThinkCopyWrapper__button-container" },
                        React.createElement(ButtonV2, { design: "cta-large-wbg", classes: "TradeWithThinkCopyWrapper__squaredButton", href: buttonHref, onClick: buttonHref ? undefined : scrollTopHandler }, buttonLabel)))))));
};
