export const CS_FIELDS_TRANSLATE_CONFIG = [
    {
        cultures: ["de-DE", "pt-BR", "es-CO", "es-ES", "th-TH"],
        instrumentGroupsCfg: {
            Energy: [
                {
                    fieldName: "TradingHours",
                    targetString: "Mon",
                    translationKey: "weekdays.Mon"
                },
                {
                    fieldName: "TradingHours",
                    targetString: "Fri",
                    translationKey: "weekdays.Fri"
                }
            ],
            Futures: [
                {
                    fieldName: "ContractMonths",
                    targetString: "Monthly",
                    translationKey: "futures.monthly"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Mar",
                    translationKey: "months.Mar"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Jun",
                    translationKey: "months.Jun"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Sep",
                    translationKey: "months.Sep"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Dec",
                    translationKey: "months.Dec"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Continuous contract",
                    translationKey: "futures.continuousContract"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "Continuous contract",
                    translationKey: "futures.continuousContract"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The last business day of the second month preceding the contract month.",
                    translationKey: "futures.lastTradingDay.lastBusinessDayOf2ndMonthPrecedingCM"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The 4th business day prior to the 25th calendar day of the month preceding the contract month.",
                    translationKey: "futures.lastTradingDay.4thBusinessDayPriorTo25thCalendarDayOfMonthPrecedingCM"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The 3rd Friday of the contract month",
                    translationKey: "futures.lastTradingDay.3rdFridayOfCM"
                },
            ]
        }
    },
    {
        cultures: ["ar-AE"],
        instrumentGroupsCfg: {
            Indices: [
                {
                    fieldName: "Currency",
                    targetString: "EUR",
                    translationKey: "currencies.EUR"
                },
                {
                    fieldName: "Currency",
                    targetString: "GBP",
                    translationKey: "currencies.GBP"
                }
            ],
            Futures: [
                {
                    fieldName: "ContractMonths",
                    targetString: "Mar",
                    translationKey: "months.Mar"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Jun",
                    translationKey: "months.Jun"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Sep",
                    translationKey: "months.Sep"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Dec",
                    translationKey: "months.Dec"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Monthly",
                    translationKey: "futures.monthly"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Continuous contract",
                    translationKey: "futures.continuousContract"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "Continuous contract",
                    translationKey: "futures.continuousContract"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The 3rd Friday of the contract month",
                    translationKey: "futures.lastTradingDay.3rdFridayOfCMo"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The 4th business day prior to the 25th calendar day of the month preceding the contract month.",
                    translationKey: "futures.lastTradingDay.4thBusinessDayPriorTo25thCalendarDayOfMonthPrecedingCM"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The last business day of the second month preceding the contract month.",
                    translationKey: "futures.lastTradingDay.lastBusinessDayOf2ndMonthPrecedingCM"
                },
            ]
        }
    },
    {
        cultures: ["zh-TW"],
        instrumentGroupsCfg: {
            Indices: [
                {
                    fieldName: "Instrument",
                    targetString: undefined,
                    translationKey: undefined,
                    translationKeyType: "dynamicOriginal",
                    translationKeyPath: "instrumentNames.indices"
                },
            ],
            Energy: [
                {
                    fieldName: "Instrument",
                    targetString: undefined,
                    translationKey: undefined,
                    translationKeyType: "dynamicOriginal",
                    translationKeyPath: "instrumentNames.commodities"
                },
                {
                    fieldName: "Instrument",
                    targetString: "Mon",
                    translationKey: "weekdays.Mon"
                },
                {
                    fieldName: "TradingHours",
                    targetString: "Mon",
                    translationKey: "weekdays.Mon"
                },
                {
                    fieldName: "TradingHours",
                    targetString: "Fri",
                    translationKey: "weekdays.Fri"
                }
            ],
            "Shares TT & MT5": [{
                    fieldName: "Instrument",
                    targetString: undefined,
                    translationKey: undefined,
                    translationKeyType: "dynamicOriginal",
                    translationKeyPath: "instrumentNames.shares"
                },
            ],
            Crypto: [
                {
                    fieldName: "Instrument",
                    targetString: undefined,
                    translationKey: undefined,
                    translationKeyType: "dynamicOriginal",
                    translationKeyPath: "instrumentNames.crypto"
                },
            ],
            Futures: [
                {
                    fieldName: "Instrument",
                    targetString: undefined,
                    translationKey: undefined,
                    translationKeyType: "dynamicOriginal",
                    translationKeyPath: "instrumentNames.futures"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Monthly",
                    translationKey: "futures.monthly"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Mar",
                    translationKey: "months.Mar"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Jun",
                    translationKey: "months.Jun"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Sep",
                    translationKey: "months.Sep"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Dec",
                    translationKey: "months.Dec"
                },
                {
                    fieldName: "ContractMonths",
                    targetString: "Continuous contract",
                    translationKey: "futures.continuousContract"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "Continuous contract",
                    translationKey: "futures.continuousContract"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The last business day of the second month preceding the contract month.",
                    translationKey: "futures.lastTradingDay.lastBusinessDayOf2ndMonthPrecedingCM"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The 4th business day prior to the 25th calendar day of the month preceding the contract month.",
                    translationKey: "futures.lastTradingDay.4thBusinessDayPriorTo25thCalendarDayOfMonthPrecedingCM"
                },
                {
                    fieldName: "LastDealingDay",
                    targetString: "The 3rd Friday of the contract month",
                    translationKey: "futures.lastTradingDay.3rdFridayOfCM"
                },
            ]
        }
    },
];
