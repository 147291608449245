import React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
import { CardWithOutHoverAnimation } from "./CardWithOutHoverAnimation/CardWithOutHoverAnimation";
export const CardsWithOutHoverAnimation = ({ content, backgroundColor, }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement("div", { className: "card-without-hover-animation__root" },
            React.createElement(Container, { className: "g-container--tablet-margins" }, content.length > 0 && content.map((a) => (React.createElement(CardWithOutHoverAnimation, { key: `unique${a.title}`, ...a })))))));
};
