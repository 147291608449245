import React, { Fragment } from "react";
import { ButtonV2 } from "../ButtonV2";
import { Img } from "../Img";
import { Heading } from "../Heading";
import { handleLinkTarget } from "../../utils";
import { CardsDeckInfoBox } from "./CardsDeckInfoBox";
export const CardsDeckWithTitle = ({ itemSection, mainTitle, mainButtonHref, mainButtonLabel, firstButtonHref, firstButtonLabel, secondButtonHref, secondButtonLabel, firstButtonImage, secondButtonImage, buttonDesign = "cta-large-wbg", secondInfoTitle, secondInfoSubtitle, darkMode, openInNewTabButton, firstButtonTarget, secondButtonTarget, infoTextIcon, infoText, infoBox }) => {
    const renderSecondInfoTitle = () => (secondInfoTitle && React.createElement("div", { className: "cardsDeckWithTitle-container__second-info-container__info-title", dangerouslySetInnerHTML: { __html: secondInfoTitle } }));
    const renderSecondInfoSubtitle = () => (secondInfoSubtitle && React.createElement("p", { className: "cardsDeckWithTitle-container__second-info-container__info-subtitle" }, secondInfoSubtitle));
    const renderThirdInfoSection = () => (infoText && React.createElement("div", { className: "cardsDeckWithTitle-container__second-info-container__info-text-dis" },
        React.createElement(Img, { src: infoTextIcon }),
        React.createElement("p", null, infoText)));
    const renderMainButtonSection = () => (mainButtonLabel && React.createElement(ButtonV2, { design: buttonDesign, href: mainButtonHref, openInNewTab: openInNewTabButton, classesWrapper: "cardsDeckWithTitle-container__main-button" }, mainButtonLabel));
    const renderButtonsSection = () => ((firstButtonLabel || secondButtonLabel) && React.createElement("div", { className: "cardsDeckWithTitle-container__conditionalButtonsWrapper" },
        firstButtonLabel && React.createElement("span", { className: "cardsDeckWithTitle-container__conditionalButtonsWrapper__firstButtonWrapper" },
            React.createElement(Img, { src: firstButtonImage }),
            React.createElement("a", { href: firstButtonHref, target: firstLinkHrefTarget }, firstButtonLabel)),
        secondButtonLabel && React.createElement("span", { className: "cardsDeckWithTitle-container__conditionalButtonsWrapper__secondButtonWrapper" },
            React.createElement(Img, { src: secondButtonImage }),
            React.createElement("a", { href: secondButtonHref, target: secondLinkHrefTarget }, secondButtonLabel))));
    const firstLinkHrefTarget = handleLinkTarget(firstButtonTarget);
    const secondLinkHrefTarget = handleLinkTarget(secondButtonTarget);
    return (React.createElement("div", { className: `cardsDeckWithTitle-container ${darkMode ? "cardsDeckWithTitle-container--dark" : ""}` },
        React.createElement("div", { className: "cardsDeckWithTitle-container__mainTitle" },
            React.createElement(Heading, { design: "h2-regular" }, mainTitle)),
        React.createElement("div", { className: "cardsDeckWithTitle-container__sectionWrapper" }, itemSection.length > 0 && itemSection.map((a, index) => (React.createElement(Fragment, { key: `card-${index}` },
            React.createElement("div", { className: "cardsDeckWithTitle-container__image-container" },
                React.createElement(Img, { className: "cardsDeckWithTitle-container__section-image", src: a.imageUrl, alt: a.imageAlt })),
            React.createElement("div", { className: "cardsDeckWithTitle-container__text-container" },
                React.createElement("h5", { dangerouslySetInnerHTML: {
                        __html: a.title
                    } }),
                React.createElement("div", { className: "cardsDeckWithTitle-container__comments", dangerouslySetInnerHTML: {
                        __html: a.comment
                    } })))))),
        renderButtonsSection(),
        infoBox?.text && React.createElement(CardsDeckInfoBox, { text: infoBox.text, title: infoBox.title }),
        renderMainButtonSection(),
        React.createElement("div", { className: "cardsDeckWithTitle-container__second-info-container__info-text" },
            renderSecondInfoTitle(),
            renderSecondInfoSubtitle(),
            renderThirdInfoSection())));
};
