import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { getVideoType } from "../../../src/utils";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
export const LargeImageSection = (props) => {
    const isPlaybackTriggered = useRef(false);
    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const videoType = getVideoType(props.imageUrl);
    useEffect(() => {
        if (!videoType || !inView || isPlaybackTriggered.current) {
            return;
        }
        const videoEl = entry?.target;
        const videoSourceEl = videoEl.querySelector("source");
        if (!videoSourceEl) {
            return;
        }
        videoSourceEl.src = videoSourceEl?.dataset.src || "";
        videoEl.load();
        isPlaybackTriggered.current = true;
    }, [entry, inView, videoType]);
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "largeImageSection" },
            React.createElement(Container, null,
                React.createElement("h2", null, props.title),
                React.createElement("p", { dangerouslySetInnerHTML: { __html: props.subtitle } }),
                React.createElement("div", { className: "video-wrapper" },
                    React.createElement("div", { className: "video-container" }, videoType ? (React.createElement("video", { autoPlay: true, loop: true, muted: true, playsInline: true, ref: ref },
                        React.createElement("source", { "data-src": props.imageUrl, type: videoType })))
                        : React.createElement(Img, { src: props.imageUrl, alt: props.title })))))));
};
