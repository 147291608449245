import React from "react";
import { Img } from "../../Img";
import { ContainerFW, Container } from "../../global";
import { handleLinkTarget } from "../../../utils";
export const InstrumentCategoryDeck = ({ cards, title, titleSize, subTitle, secondTitle, buttonLabel, buttonLink, buttonIcon, buttonIconHover, linkOpenInNewTab }) => {
    const linkHrefTarget = handleLinkTarget(linkOpenInNewTab);
    const renderCard = (card, key) => (React.createElement("a", { href: card.link, key: key, className: "instrument-category-deck__card-link" },
        React.createElement("div", { className: "instrument-category-deck__card centered-container", style: {
                "--instrument-category-deck-icon-nest": `url(${card.iconNest})`,
                "--instrument-category-deck-icon-nest-hover": `url(${card.iconNestHover})`
            } },
            React.createElement("div", { className: "instrument-category-deck__icon-vertical-container" },
                React.createElement("div", { className: "instrument-category-deck__icon-img-container centered-container" },
                    React.createElement(Img, { src: card.img, alt: card.alt }))),
            React.createElement("div", { className: "instrument-category-deck__card-title-container centered-container" },
                React.createElement("h5", null,
                    " ",
                    card.title)))));
    return (React.createElement("div", { className: "instrument-category-deck centered-container bg-color-pure-white" },
        React.createElement(ContainerFW, { backgroundColor: "#fff" },
            React.createElement(Container, null,
                React.createElement("div", { className: "instrument-category-deck__title-containr" },
                    titleSize === "h4" && React.createElement("h4", { className: "instrument-category-deck__title h4-semi-bold" }, title),
                    titleSize === "h2" && React.createElement("h2", { className: "instrument-category-deck__title" }, title),
                    subTitle && React.createElement("h5", null, subTitle)),
                React.createElement("div", { className: "centered-container" },
                    React.createElement("div", { className: "instrument-category-deck__cards-bar" }, cards.map((c, key) => renderCard(c, key)))),
                secondTitle && React.createElement("div", { className: "instrument-category-deck__second-component-container" },
                    React.createElement("div", { className: "instrument-category-deck__second-component-title" },
                        React.createElement("h5", null, secondTitle)),
                    React.createElement("div", { className: "instrument-category-deck__second-component-button" },
                        React.createElement("a", { href: buttonLink, target: linkHrefTarget },
                            React.createElement("span", null,
                                React.createElement(Img, { src: buttonIcon, alt: "icon" })),
                            buttonLabel)))))));
};
