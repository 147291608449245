import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
export const VideoSection = ({ title, subTitle, buttonLabel, buttonUrl, desktopImage, tabletImage, mobileImage, openNewTab }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "video-section-root" },
        React.createElement(Container, null,
            React.createElement("div", { className: "video-section__textsWrapper" },
                React.createElement(Heading, { design: "h2-regular" }, title),
                React.createElement("p", { className: "p-regular" }, subTitle),
                React.createElement(ButtonV2, { design: "secondary-large-wbg", openInNewTab: openNewTab, href: buttonUrl }, buttonLabel)),
            React.createElement("div", { className: "video-section__ImageWrapper" },
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: buttonUrl },
                    React.createElement(ImgV2, { smallUrl: mobileImage, mediumUrl: tabletImage, largeUrl: desktopImage })))))));
