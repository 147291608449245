import React, { useCallback, useEffect, useRef, useState } from "react";
import { getVideoType } from "../../../../src/utils";
import { ContainerFW, } from "../../global";
import { ClickAndPlayButton } from "./ClickAndPlayButton";
import { useInView } from "react-intersection-observer";
const checkHasVideos = (videosProp) => videosProp && videosProp.length ? true : false;
const getContainerClassName = (inView) => {
    let className = "click-and-play-video-card__video-container";
    if (inView) {
        className += " click-and-play-video-card__video-container--animated";
    }
    return className;
};
export const ClickAndPlayVideoCard = (props) => {
    const hasVideos = checkHasVideos(props.videos);
    const videoPosition = props.videoPosition || "left";
    const currentVideoIndex = hasVideos ? 0 : undefined;
    const [videoIndex, setVideoIndex] = useState(currentVideoIndex);
    const [playbackStatus, setPlaybackStatus] = useState("idle");
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const videoElRef = useRef(null);
    const allowAutoPlay = useRef(false);
    const activeVideo = videoIndex !== undefined ? props.videos[videoIndex] : undefined;
    const playVideo = async () => {
        await videoElRef?.current?.play()
            .then(() => {
            setPlaybackStatus("playing");
        })
            .catch(console.error);
    };
    const changeVideoStatus = useCallback((newIndex, forcedIsPlayingStatus = undefined) => {
        const isSameIndex = videoIndex === newIndex;
        if (isSameIndex) {
            if (playbackStatus === "playing" && forcedIsPlayingStatus === undefined) {
                return;
            }
            const newStatus = forcedIsPlayingStatus || "pending";
            setPlaybackStatus(newStatus);
        }
        else {
            videoElRef?.current?.load();
            setVideoIndex(newIndex);
            if (["idle", "playing"].includes(playbackStatus)) {
                setPlaybackStatus("pending");
            }
        }
    }, [playbackStatus, videoIndex]);
    const handleVideoTransitionEnd = useCallback(() => {
        allowAutoPlay.current = true;
        if (videoIndex === undefined) {
            return;
        }
        changeVideoStatus(videoIndex, "pending");
    }, [changeVideoStatus, videoIndex]);
    const handleButtonClick = useCallback((_event, index) => {
        changeVideoStatus(index);
    }, [changeVideoStatus]);
    useEffect(() => {
        if (videoIndex !== undefined && playbackStatus === "pending" && allowAutoPlay.current) {
            void playVideo();
        }
    });
    useEffect(() => {
        const onVideoEndHandler = () => {
            setPlaybackStatus("idle");
        };
        const videoEl = videoElRef?.current;
        videoEl?.addEventListener("ended", onVideoEndHandler);
        return () => videoEl?.removeEventListener("ended", onVideoEndHandler);
    }, []);
    const realVideoSrc = activeVideo?.src;
    return (React.createElement(ContainerFW, { className: "click-and-play-video-card-container" },
        React.createElement("div", { className: `click-and-play-video-card click-and-play-video-card--video-${videoPosition}` },
            React.createElement("header", null,
                React.createElement("h2", null, props.title),
                React.createElement("p", { dangerouslySetInnerHTML: { __html: props.text } })),
            hasVideos && (React.createElement(React.Fragment, null,
                React.createElement("ul", { className: "click-and-play-video-card__video-list" }, props.videos.map((videoData, index) => (React.createElement("li", { key: index },
                    React.createElement(ClickAndPlayButton, { buttonIndex: index, buttonLabel: videoData.title, onClick: handleButtonClick, isActive: index === videoIndex }))))),
                React.createElement("div", { ref: ref, className: getContainerClassName(inView), onAnimationEnd: handleVideoTransitionEnd }, realVideoSrc && React.createElement("video", { ref: videoElRef, muted: true, poster: activeVideo.posterUrl },
                    React.createElement("source", { src: realVideoSrc, type: getVideoType(realVideoSrc) }))))))));
};
