import React from "react";
import { Container, ContainerFW } from "../global";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { getColors } from "../../utils/getColors";
export const WhyTradersChooseUs = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement("div", { className: "why-traders-choose-us" },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement(Container, null,
                React.createElement("div", { className: "why-traders-choose-us__container" },
                    React.createElement("div", { className: "why-traders-choose-us__left-wrapper" },
                        React.createElement("div", { className: "why-traders-choose-us__left-title" },
                            React.createElement("h2", null, props.title)),
                        React.createElement("div", { className: "why-traders-choose-us__left-description" },
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: props.desc } })),
                        React.createElement("div", { className: "why-traders-choose-us__left-button" },
                            React.createElement(ButtonV2, { design: "cta-large-wbg", href: props.buttonLink, openInNewTab: props.openInNewTabButton }, props.buttonLabel))),
                    React.createElement("div", { className: "why-traders-choose-us__right-wrapper" }, props.content.map((item, index) => (React.createElement("div", { key: index, className: "why-traders-choose-us__items" },
                        React.createElement("div", { className: "why-traders-choose-us__item-year" },
                            React.createElement("p", null, item.year)),
                        React.createElement("div", { className: "why-traders-choose-us__item-content" }, item.yearItems.map((el, i) => (React.createElement("div", { key: i, className: "why-traders-choose-us__item-el" },
                            el.itemImg && React.createElement("div", { className: "why-traders-choose-us__el-icon" },
                                React.createElement("img", { src: el.itemImg, alt: el.itemImgAlt })),
                            React.createElement("div", { className: "why-traders-choose-us__el-title" }, el.itemTitle))))))))))))));
};
