import * as React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img/Img";
import { TwoInstrumentSliders } from "../TwoInstrumentSliders";
export const HeroBannerWithInstruments = (props) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "hero-banner-instruments" },
        React.createElement(Container, null,
            React.createElement("div", { className: "hero-banner-instruments__inner" },
                React.createElement("div", { className: "hero-banner-instruments__text" },
                    React.createElement("h1", { className: "hero-banner-instruments__desktop-title" }, props.title),
                    React.createElement("div", { className: "hero-banner-instruments__content", dangerouslySetInnerHTML: { __html: props.text } }),
                    props.linkText && (React.createElement(ButtonV2, { design: "cta-large-wbg", href: props.linkUrl, openInNewTab: props.linkOpenInNewTab }, props.linkText))),
                props.image ? React.createElement(Img, { src: props.image, className: "hero-banner-instruments__image" }) : React.createElement(TwoInstrumentSliders, { ...props.priceFeed }))))));
