import { useEffect, useRef } from "react";
import { capitalizeString } from "../../../utils";
import { getWindowLocationDifficulty, getWindowLocationPage, } from "../utils";
export const useFetchAcadArticlesOnMount = (currentPage, fetchFunction, setFiltersDataFunction) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (!isFirstRender.current) {
            return;
        }
        const difficultyInUrl = getWindowLocationDifficulty();
        if (difficultyInUrl) {
            const difficultyParam = capitalizeString(difficultyInUrl);
            fetchFunction({
                Difficulty: [difficultyParam],
                page: currentPage,
            });
            if (setFiltersDataFunction) {
                setFiltersDataFunction((prevFilters) => ({
                    ...prevFilters,
                    Difficulty: `${difficultyParam},`,
                }));
            }
            return;
        }
        const pageInUrl = getWindowLocationPage();
        fetchFunction({ page: pageInUrl || currentPage });
    }, [currentPage, fetchFunction, setFiltersDataFunction]);
    useEffect(() => {
        isFirstRender.current = false;
    }, []);
};
