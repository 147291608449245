import React, { useCallback, useEffect, useState } from "react";
import { Container, ContainerFW } from "../../Components";
import { InstrumentSliderNew } from "../InstrumentSliderNew";
import { CustomLottie } from "../CustomLottie/CustomLottie";
export const DynamicLeveragePricingTable = ({ title, marketsInfo, activeMarket, tableInfo, tableContent, animation }) => {
    const [activeRowIndex, setActiveRowIndex] = useState(-1);
    const [currentMarketTab, setCurrentMarketTab] = useState();
    const [filteredInstruments, setFilteredInstruments] = useState();
    const handleMarketClick = useCallback((market) => {
        setCurrentMarketTab(market);
    }, []);
    const handleRowClick = useCallback((event) => {
        event.preventDefault();
        const index = parseInt(event.currentTarget.getAttribute("data-id") ?? "0");
        setActiveRowIndex(index);
    }, []);
    useEffect(() => {
        if (currentMarketTab !== undefined) {
            const filteredInfo = tableContent.filter((a) => (a.marketName === currentMarketTab));
            setFilteredInstruments(filteredInfo[0]);
            return;
        }
        if (tableContent.length > 0 && tableContent[0].marketInfo !== undefined) {
            const filteredInfo = tableContent.filter((a) => (a.marketName === activeMarket));
            setFilteredInstruments(filteredInfo[0]);
        }
    }, [activeMarket, currentMarketTab, tableContent]);
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "dynamic-leverage-pricing-table-root" },
            React.createElement(Container, null,
                React.createElement("div", { className: "dynamic-leverage-pricing-table-root__animationContainer" },
                    React.createElement(CustomLottie, { animation: animation })),
                React.createElement("div", { className: "price-feed__tableWrapper" },
                    React.createElement("h2", { className: "price-feed__title" }, title),
                    React.createElement(InstrumentSliderNew, { items: marketsInfo, currentMarketTab: activeMarket, onMarketClick: handleMarketClick }),
                    React.createElement("div", { className: "price-feed__table" },
                        React.createElement("div", { className: "price-feed__header" }, tableInfo.map((item, i) => (React.createElement("div", { key: `price-feed__headerCell-${i}`, className: "price-feed__headerCell" }, item.title.toUpperCase())))),
                        filteredInstruments?.marketInfo.map((item, i) => (React.createElement("div", { key: `price-feed__row-${i}`, className: `price-feed__row ${activeRowIndex === i && "price-feed__highlighted"} price-feed__row--${i}`, "data-id": i, onClick: handleRowClick, onKeyPress: handleRowClick },
                            React.createElement("div", { className: "price-feed__cellContainer" },
                                React.createElement("div", { className: "price-feed__cellTitle" }, item.openLots)),
                            React.createElement("div", { className: "price-feed__cellContainer" },
                                React.createElement("div", { className: `price-feed__cellTitle ${item.maximumLeverage}` }, item.marginRequirement)),
                            React.createElement("div", { className: "price-feed__cellContainer" },
                                React.createElement("span", null, item.maximumLeverage)))))))))));
};
