import React from "react";
import { PhonesGridContainer } from "./MiniComponentsGridContainer/PhonesGridContainer/PhonesGridContainer";
import { Container, ContainerFW } from "../global";
import { ChartsGridContainer } from "./MiniComponentsGridContainer/ChartsGridContainer/ChartsGridContainer";
import { NotificationsGridContainer } from "./MiniComponentsGridContainer/NotificationsGridContainer/NotificationsGridContainer";
export const AnimatedBoxGrid = ({ mainTitle, subTitle, phoneGridContent, chartGridContent, notificationsGridContent, }) => (React.createElement(ContainerFW, { backgroundColor: "#fff" },
    React.createElement("div", { className: "animatedBoxGridWrapper" },
        React.createElement(Container, null,
            React.createElement("span", { className: "animatedBoxGridWrapper__titlesWrapper" },
                React.createElement("h2", { className: "animatedBoxGridWrapper__titlesWrapper__mainTitle" }, mainTitle),
                React.createElement("h5", { className: "animatedBoxGridWrapper__titlesWrapper__mainSubTitle" }, subTitle)),
            React.createElement("div", { className: "animatedBoxGridWrapper__phoneGridWrapper" },
                React.createElement(PhonesGridContainer, { mainTitle: phoneGridContent.mainTitle, subTitle: phoneGridContent.subTitle, upperTitle: phoneGridContent.upperTitle, phonesImageUrl: phoneGridContent.phonesImageUrl })),
            React.createElement("div", { className: "animatedBoxGridWrapper__chartsGridWrapper" },
                React.createElement(ChartsGridContainer, { mainTitle: chartGridContent.mainTitle, subTitle: chartGridContent.subTitle, chartsUrl: chartGridContent.chartsUrl })),
            React.createElement("div", { className: "animatedBoxGridWrapper__notificationsGridWrapper" },
                React.createElement(NotificationsGridContainer, { mainTitle: notificationsGridContent.mainTitle, subTitle: notificationsGridContent.subTitle, imageUrl: notificationsGridContent.imageUrl, notifications: notificationsGridContent.notifications }))))));
