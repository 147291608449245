import React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
export const RibbonWithDataForTrustPilot = ({ evaluationLabel, evaluationValue, stats, trustPilotIconImage, backgroundColor }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor ? backgroundColor : "inherit" },
    React.createElement("div", { className: "ribbon-with-data-for-trust-pilot-root" },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "ribbon-with-data-for-trust-pilot-root__container" },
                React.createElement("div", { className: "ribbon-with-data-for-trust-pilot-root__container__statsWrapper" }, stats.length > 0 && stats.map((a) => (React.createElement("div", { key: `unique${a.mainTitle}`, className: "ribbon-with-data-for-trust-pilot-root__container__statsWrapper__stats" },
                    React.createElement("div", null,
                        React.createElement("p", null, a.mainTitle),
                        React.createElement("p", null, a.subTitle)))))),
                evaluationLabel && React.createElement("div", { className: "ribbon-with-data-for-trust-pilot-root__container__evaluationWrapper" },
                    React.createElement("p", null, evaluationLabel),
                    React.createElement("p", null, evaluationValue),
                    React.createElement(Img, { src: trustPilotIconImage, alt: evaluationLabel })))))));
