import React from "react";
import { Img } from "../../Img";
export const EmployeeTestimonialSliderCard = (props) => (React.createElement("div", { className: "employee-testimonial-slider-card" },
    React.createElement(Img, { src: props.employeeImageUrl, alt: props.employeeImageAlt, className: "employee-testimonial-slider-card__image" }),
    React.createElement("p", { className: "employee-testimonial-slider-card__employee-name" }, props.employeeName),
    React.createElement("div", { className: "employee-testimonial-slider-card__employee-position", dangerouslySetInnerHTML: {
            __html: props.employeePosition,
        } }),
    React.createElement("div", { className: "employee-testimonial-slider-card__employee-description", dangerouslySetInnerHTML: {
            __html: props.description,
        } })));
