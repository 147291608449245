import React from "react";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { ImgV2 } from "../ImgV2";
export const GetTheBestOfBoth = ({ firstIcon, secondIcon, mainTitle, subtitle, buttonLabel, buttonUrl, bgImageMobile, bgImageTablet, bgImageDesktop }) => (React.createElement(ContainerFW, null,
    React.createElement("div", { className: "get-the-best-of-both" },
        React.createElement(Container, null,
            React.createElement(Heading, { design: "h2-lp" }, mainTitle),
            React.createElement("div", { className: "get-the-best-of-both__root" },
                React.createElement("div", { className: "get-the-best-of-both__titleWrapper" },
                    React.createElement("div", { className: "first-image-container" },
                        React.createElement(ImgV2, { src: firstIcon })),
                    React.createElement("div", { className: "second-image-container" },
                        React.createElement(ImgV2, { src: secondIcon })),
                    React.createElement("p", { className: "get-the-best-of-both__subtile" }, subtitle),
                    React.createElement(ButtonV2, { classes: "get-the-best-of-both__button", design: "secondary-large-dbg", href: buttonUrl }, buttonLabel)),
                React.createElement("div", { className: "get-the-best-of-both__bottom-images" },
                    React.createElement(ImgV2, { strictRender: true, largeUrl: bgImageDesktop, smallUrl: bgImageMobile, mediumUrl: bgImageTablet })))))));
