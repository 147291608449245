import * as React from "react";
import { Container, ContainerFW } from "../global";
export const SearchRibbon = (props) => {
    const [data, setData] = React.useState("");
    const searchFieldHandler = React.useCallback(e => setData(e.target.value), [data]);
    const clickHandler = React.useCallback((e) => {
        e.key === "Enter" && props.search(data);
    }, [data]);
    return (React.createElement(ContainerFW, { backgroundColor: props.backgrounColor ? props.backgrounColor : "inherit" },
        React.createElement("div", { className: "subscribeRibbon" },
            React.createElement(Container, null,
                React.createElement("div", { className: "subscribeRibbon__container" },
                    React.createElement("div", { className: "subscribeRibbon__widget subscribeRibbon__widget--search" },
                        React.createElement("input", { maxLength: 20, onKeyDown: clickHandler, onChange: searchFieldHandler, value: data, className: "search-banner__input subscribeRibbon__input subscribeRibbon__input--search", type: "text", placeholder: props.searchText })))))));
};
